import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import EventsSwitch from "../../../functions/EventsSwitch";
import Bl0xLoading from "../../loading/bl0xloading";
import "./profilelatesttransactions.css";

export function ProfileLatestTransactions({ profileData }) {
  const graffleLogo = (
    <Image
      fluid={+true}
      src="/assets/img/home/graffle.webp"
      alt="Graffle Logo"
    />
  );
  let soldActivity = {};
  const [eventsData, setEventsData] = useState("");
  const userAddress = profileData.address;
  const [pageSize, setPageSize] = useState(3);

  function compare(a, b) {
    if (a.eventDate < b.eventDate) {
      return 1;
    }
    if (a.eventDate > b.eventDate) {
      return -1;
    }
    return 0;
  }

  // //console.log(profileData);

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const graffleUrl = process.env.REACT_APP_GRAFFLE_SEARCH_URL;
  //unfortunately with graffle we cannot do wildcard search ie everything related to address
  //some more keys to add here could be newOwner, previousOwner for transfering names
  //also from and toAddress for gifting
  //no problems so far concerning loadtime but i am wary of too many requests

  //todo - style row widths and push new events from signalR (dont know how this will scale with filtering, currently the only filter for notifications is toAddress)

  useEffect(() => {
    const getEvents = async () => {
      // //console.log("getSales fired")
      let sellerData;
      let buyerData;
      let accountData;
      let ownerData;
      let fromData;
      let toData;
      let newOwnerData;
      let previousOwnerData;

      let sellerRes = await axios.get(
        graffleUrl + "search?seller=" + userAddress + "&pageSize=" + pageSize
      );
      let buyerRes = await axios.get(
        graffleUrl + "search?buyer=" + userAddress + "&pageSize=" + pageSize
      );
      let accountRes = await axios.get(
        graffleUrl + "search?account=" + userAddress + "&pageSize=" + pageSize
      );
      let ownerRes = await axios.get(
        graffleUrl + "search?owner=" + userAddress + "&pageSize=" + pageSize
      );
      let fromRes = await axios.get(
        graffleUrl + "search?from=" + userAddress + "&pageSize=" + pageSize
      );
      let toRes = await axios.get(
        graffleUrl + "search?toAddress=" + userAddress + "&pageSize=" + pageSize
      );
      let newOwnerRes = await axios.get(
        graffleUrl + "search?newOwner=" + userAddress + "&pageSize=" + pageSize
      );
      let previousOwnerRes = await axios.get(
        graffleUrl +
          "search?previousOwner=" +
          userAddress +
          "&pageSize=" +
          pageSize
      );
      sellerData = sellerRes.data;
      buyerData = buyerRes.data;
      accountData = accountRes.data;
      ownerData = ownerRes.data;
      fromData = fromRes.data;
      toData = toRes.data;
      newOwnerData = newOwnerRes.data;
      previousOwnerData = previousOwnerRes.data;
      let eventsArray = [
        ...buyerData,
        ...sellerData,
        ...accountData,
        ...ownerData,
        ...fromData,
        ...toData,
        ...newOwnerData,
        ...previousOwnerData,
      ];
      setEventsData(eventsArray.sort(compare));
    };
    getEvents();
  }, [pageSize, profileData]);

  // //console.log(eventsData);
  // //console.log(JSON.stringify(eventsData[0], 0, null));
  return (
    <Container
      fluid={+true}
      id="profile-latest-transactions"
      className="px-0 mt-3"
    >
      <Card>
        <Card.Body>
          <Row className="my-3 titleRow">
            <Col>
              <h4>Latest Activity</h4>
            </Col>
          </Row>
          {eventsData.length === 0 ? (
            <Row className="py-5">
              <Col className="flex-center">
                {" "}
                <Bl0xLoading />
              </Col>
            </Row>
          ) : (
            <Table id="profile-latest-transactions-table">
              <tbody className="tableContent">
                {eventsData.map((activity, i) => {
                  soldActivity = EventsSwitch(activity);
                  // console.log(activity);
                  // console.log(soldActivity);

                  let userEventType = soldActivity.eventType;
                  let userItemName = soldActivity.itemName;

                  //switch userEventType depending on user as buyer/seller
                  switch (soldActivity.eventType) {
                    case "Gifted:":
                      if (soldActivity.toAddress === profileData.address) {
                        userEventType = "Received Gift:";
                        userItemName = soldActivity.fromName;
                      }
                      break;
                    case "Offered:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Made Offer:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Was Offered:";
                      }
                      break;
                    case "Sold:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Bought:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Sold:";
                      }
                      break;
                    case "Bid:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Made Bid:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Received Bid:";
                      }
                      break;

                    case "Rejected:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Offer Rejected:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Rejected Offer:";
                      }
                      break;

                    case "Offer Accepted:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Offer Accepted:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Accepted Offer:";
                      }
                      break;
                  }

                  return (
                    <React.Fragment key={i}>
                      <tr
                        key={i + "row1"}
                        id={i + "row1"}
                        className=" no-border tableRow tableRow1"
                      >
                        <td className="no-border center-cell main-text iconCell">
                          {soldActivity.eventIcon}
                        </td>
                        <td className="no-border center-cell main-text eventTypeCell">
                          {userEventType}
                        </td>
                        <td className="no-border center-cell nameCell">
                          <span className="d-none d-md-flex align-items-center">
                            {soldActivity.itemType === "name" ||
                            soldActivity.itemType === "gift" ||
                            (soldActivity.fromIsName &&
                              soldActivity.itemType !== "nft") ? (
                              <Link
                                className="activityLink"
                                to={"/" + userItemName}
                              >
                                <span className="fw-bold align-items-end">
                                  <span className="themeColor">
                                    {userItemName}
                                  </span>
                                  .find
                                </span>
                              </Link>
                            ) : soldActivity.itemType === "nft" ? (
                              <Link
                                to={
                                 soldActivity.viewItem
                                }
                              >
                                <span className="fw-bold">
                                  {soldActivity.itemName}
                                </span>
                              </Link>
                            ) : (
                              <span className="fw-bold align-items-end">
                                {userItemName}{" "}
                              </span>
                            )}
                            {userEventType === "Sold:" && (
                              <span
                                className="d-none d-md-block small secondaryText"
                                style={{ paddingLeft: "5px" }}
                              >
                                {" "}
                                to{" "}
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.toName}
                                  </span>
                                )}
                              </span>
                            )}
                            {userEventType === "Bought:" && (
                              <span
                                className="d-none d-md-block small secondaryText"
                                style={{ paddingLeft: "5px" }}
                              >
                                {" "}
                                from{" "}
                                {soldActivity.fromIsName ? (
                                  <Link to={"/" + soldActivity.fromName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.fromName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.fromName}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                          {/* //itemname mobile */}
                          <span className="d-md-none align-items-center">
                            {soldActivity.itemType === "name" ||
                            soldActivity.itemType === "gift" ||
                            (soldActivity.fromIsName &&
                              soldActivity.itemType !== "nft") ? (
                              <Link
                                className="activityLink"
                                to={"/" + userItemName}
                              >
                                <span className="fw-bold align-items-end">
                                  <span className="themeColor">
                                    {userItemName}
                                  </span>
                                  .find
                                </span>
                              </Link>
                            ) : soldActivity.itemType === "nft" ? (
                              <span className="fw-bold align-items-end">
                                {userItemName}{" "}
                              </span>
                            ) : (
                              <span className="fw-bold align-items-end">
                                {userItemName}{" "}
                              </span>
                            )}
                            {userEventType === "Sold:" && (
                              <span
                                className="d-none d-md-block small secondaryText"
                                style={{ paddingLeft: "5px" }}
                              >
                                {" "}
                                to{" "}
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.toName}
                                  </span>
                                )}
                              </span>
                            )}
                            {userEventType === "Bought:" && (
                              <span
                                className="d-none d-md-block small secondaryText"
                                style={{ paddingLeft: "5px" }}
                              >
                                {" "}
                                from{" "}
                                {soldActivity.fromIsName ? (
                                  <Link to={"/" + soldActivity.fromName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.fromName}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        </td>

                        <td
                          align="end"
                          className=" d-none d-md-table-cell no-border center-cell secondaryText amountCell"
                        >
                          {soldActivity.txAmount}&nbsp;{soldActivity.txFtType}
                        </td>
                        <td
                          align="end"
                          className="no-border center-cell secondaryText d-none d-xl-block timeCell"
                        >
                          <div className="d-flex flex-column">
                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                            </small>

                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </div>
                        </td>
                      </tr>

                      {activity.blockEventData.message && (
                        <tr className=" tableRow2 d-none d-md-table-row ">
                          <td className=" no-border"></td>
                          <td className=" no-border"></td>
                          <td className=" no-border messageCell">
                            {activity.blockEventData.message &&
                              activity.blockEventData.message}
                          </td>
                          <td className=" no-border"></td>
                          <td className=" no-border"></td>
                        </tr>
                      )}

                      {(userEventType === "Sold:" ||
                        userEventType === "Bought:") && (
                        <tr
                          id="profile-latest-transactions-mobile-row-2  "
                          className="d-table-row d-md-none "
                        >
                          <td className="no-border">&nbsp;</td>
                          <td className="no-border">&nbsp;</td>

                          <td
                            className="no-border center-cell mobileTimeCell secondaryText"
                            style={{ textAlign: "end" }}
                          >
                            {userEventType === "Sold:" && (
                              <span className="d-block d-md-none small">
                                {" "}
                                to{" "}
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.toName}
                                  </span>
                                )}
                              </span>
                            )}
                            {userEventType === "Bought:" && (
                              <span className="d-block d-md-none small">
                                {" "}
                                from{" "}
                                {soldActivity.fromIsName ? (
                                  <Link to={"/" + soldActivity.fromName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.fromName}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}

                      <tr
                        id="profile-latest-transactions-mobile-row-1  "
                        className="d-table-row d-md-none "
                      >
                        <td className="no-border">&nbsp;</td>
                        <td
                          className="no-border center-cell mobileTimeCell secondaryText"
                          colSpan={1}
                        >
                          {" "}
                          <div className="d-flex flex-column">
                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                            </small>

                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </div>
                        </td>
                        <td className="mobileAmountCell no-border center-cell secondaryText">
                          {" "}
                          {soldActivity.txAmount}&nbsp;{soldActivity.txFtType}
                        </td>
                      </tr>

                      {activity.blockEventData.message && (
                        <tr
                          id="profile-latest-transactions-mobile-row-2"
                          className="d-table-row d-md-none"
                        >
                          <td className="no-border">&nbsp;</td>
                          <td className="messageCell no-border" colSpan={3}>
                            {" "}
                            {activity.blockEventData.message}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          )}

          {pageSize !== 100 && eventsData.length > 2 && (
            <Row>
              <Col>
                <Button variant="find-text" onClick={() => setPageSize(100)}>
                  View all activity
                </Button>
              </Col>
            </Row>
          )}
          <Row className="graffleLogoRow">
            <Col align="center">
              {" "}
              <a
                className="graffleLink"
                href="https://graffle.io"
                rel="noreferrer"
                target="_blank"
              >
                {graffleLogo} Powered by Graffle
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
