import { useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import "./fundwidget.css";
import { handleSendFungible } from "../../../functions/txfunctions";
import useToastTxStatus from "../../../functions/useToastTxStatus";

export function FundWidget({ profile }) {
  // //console.log(profile);
  const [fundSubmit, setFundSubmit] = useState(false);

  const toastStatus = useToastTxStatus();
  // console.log(toastStatus)

  // console.log(useToasterStore())

  // console.log(toastStatus)

  // console.log(profile)

  const [fundForm, setFundForm] = useState({
    name: profile.findName,
    amount: "",
    ftAlias: "Flow",
    tag: "gift",
    message: "",
  });

  const updateForm = (e) => {
    setFundForm({
      ...fundForm,
      [e.target.name]: e.target.value,
    });
    // //console.log(e.target);
    // //console.log(fundForm);
  };

  const handleSubmit = (e) => {
    setFundSubmit(true);
    e.preventDefault();
    handleSendFungible(fundForm);
  };

  return (
    <Container fluid={+true} id="fund-widget" className="mb-3 px-0 mx-0">
      <fieldset onSubmit={handleSubmit} disabled={useFormStatus()}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="px-3">
              <h4 style={{ marginBottom: "0px" }}>.fund {profile.name}</h4>
            </Accordion.Header>
            <Accordion.Body>
              <Form className="form-inputs">
                <Row xs="1">
                  <Col className="mb-3">
                    <span className="propertiesWrap">
                      Enter an amount and choose a token. Leave a message if
                      you'd like and press send. {profile.name} will receive the
                      funds shortly after.
                    </span>
                    {/* {txStatus === "success" &&
                    <p>
                      You did it, yay!!!
                    </p>} */}
                  </Col>
                  <Col className="mb-3">
                    <Form.Select name="ftAlias" onChange={updateForm}>
                      <option>Flow</option>
                      <option>FUSD</option>
                    </Form.Select>
                  </Col>
                  <Col className="mb-3">
                    <Form.Control
                      onChange={updateForm}
                      name="amount"
                      type="number"
                      placeholder="Amount e.g. 100"
                      required
                      min="0.01"
                      step=".01"
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Control
                      name="message"
                      onChange={updateForm}
                      as="textarea"
                      placeholder="Optionally enter a message (255 chars max)"
                      maxLength="255"
                      rows={3}
                    />
                  </Col>
                  <Col>
                    {/* do we want pop up here - are you sure you want to fund {profileName} with {currencyType} {currency amount?} */}
                    <Button type="submit" variant="find" className="w-100">
                      Send
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </fieldset>
    </Container>
  );
}
