import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { Autocomplete } from "../search/autocomplete/Autocomplete";
import { InstantSearch } from "react-instantsearch-hooks-web";

export default function SearchOffCanvas({ name, ...props }) {
  const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
  const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;
  
  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "nft_name, collection_name, seller_name, seller",
      //filter_by: "collection_name:bl0x"
    },
  });
  const searchBarClient = typesenseInstantsearchAdapter.searchClient;

  return (
    <>
      <InstantSearch
        indexName="market"
        searchClient={searchBarClient}
      >
        <Autocomplete
          searchClient={searchBarClient}
          placeholder=""
          openOnFocus
          className="nav"
          detachedMediaQuery="(max-width: 991px)"
        />
      </InstantSearch>
    </>
  );
}
