import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { UseThemeStatus } from "../../../../functions/themeMode";
import AvailableOnFindCardName from "../../../profile/profilecollection/nftdetailpage/availableonfindcardname";
import { ListForAuction } from "./listforauction/listforauction";
import { ListForSale } from "./listforsale/listforsale";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../../functions/script";
import "./listname.css";
import FusdFtSticker from "../../ftstickers/fusdftsticker";
import { useWalletType } from "../../../../functions/useWalletType";
import { useUserProfile } from "../../../../functions/useUserProfile";

export function ListName({ lease, user }) {
  const [salesType, setSalesType] = useState("sale");
  const [forSale, setIsForSale] = useState(false);
  const [forAuction, setIsForAuction] = useState(false);
  const [listingDetails, setListingDetails] = useState({});
  const walletType= useWalletType()
  const userProfile = useUserProfile()

    // console.log(userProfile.profile);
    // console.log(lease);

  useEffect(() => {
    if (lease.salePrice > 0) {
      setIsForSale(true);
      setSalesType("auction");
    }
    if (lease.auctionStartPrice > 0) {
      setIsForAuction(true);
    }
    if (lease.salePrice > 0 && lease.auctionStartPrice > 0) {
      setSalesType("none");
    }

    if (forSale || forAuction) {
      async function getNameListingDetails(name) {
        const listingDetailsResponse = await Script(scripts.getNameDetails, {
          user: name,
        });
        setListingDetails(listingDetailsResponse);
      }
      try {
        getNameListingDetails(lease.name);
      } catch (error) {
        //console.log(error);
      }
    }
  }, [lease]);

  const toggleSaleOption = (option) => {
    if (option === "sale") {
      return (
        <div>
          <ListForSale lease={lease.name} walletType={walletType} user={userProfile} validUntil={lease.validUntil}  />
        </div>
      );
    } else if (option === "auction") {
      return (
        <div>
          <ListForAuction lease={lease.name} walletType={walletType} user={userProfile} validUntil={lease.validUntil} />
        </div>
      );
    }
  };

  return (
    <div id="list-name" data-theme={UseThemeStatus()} className="form-inputs">
      <Row className="pt-3 align-items-center">
        <Col className="fw-bold">
          {!forSale && !forAuction && "List for Sale or Auction:"}
          {!forSale && forAuction && "List for Sale:"}
          {forSale && !forAuction && "List for Auction:"}
          {forSale && forAuction && "Listed for Sale and Auction"}
        </Col>
        <Col><FusdFtSticker/></Col>
      </Row>

      {!forSale && (
        <Row className="my-3">
          <Col>
            <Form.Check
              type="radio"
              id="sale"
              label="List for sale"
              name="salestype"
              onChange={() => setSalesType("sale")}
              defaultChecked={salesType === "sale" ? true : false}
            />
          </Col>
        </Row>
      )}
      {!forAuction && (
        <Row>
          <Col>
            {!forSale ? (
              <Form.Check
                type="radio"
                id="auction"
                label="List for auction"
                name="salestype"
                onChange={() => setSalesType("auction")}
              />
            ) : (
              <Form.Check
                type="radio"
                id="auction"
                label="List for auction"
                name="salestype"
                onChange={() => setSalesType("auction")}
                defaultChecked
              />
            )}
          </Col>
        </Row>
      )}
      <Row className="my-4">
        <Col>{toggleSaleOption(salesType)}</Col>
      </Row>


  

      {forSale && !forAuction && (
        <>
            <Row className="pt-3 pb-2">
            <Col className="fw-bold">
              Current listings:
            </Col>
          </Row>
        <Row className="align-items-center justify-content-center">
          <AvailableOnFindCardName lease={lease} isName={true} forSale={true}/>
        </Row></>
      )}
      {forAuction && !forSale && (
        <>
            <Row className="pt-3 pb-2">
            <Col className="fw-bold">
              Current listings:
            </Col>
          </Row>
        <Row className="align-items-center justify-content-center">
          <AvailableOnFindCardName
            lease={lease}
            isName={true}
            forAuction={true}
          />
        </Row></>
      )}

      {forAuction && forSale && (
        <>
            <Row className="pt-3 pb-2">
            <Col className="fw-bold">
              Current listings:
            </Col>
          </Row>
        <Row className="align-items-center justify-content-center">
          <AvailableOnFindCardName
            lease={lease}
            isName={true}
            forAuction={true}
            forSale={true}
          />
        </Row></>
      )}
    </div>
  );
}
