import React from "react";
import { useEffect } from "react";
import { Form, Row, Col, Image, Button } from "react-bootstrap";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../../functions/DisabledState";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import {
  handleBidNFTAuction,
  handleIncreaseBidNFTAuction,
} from "../../../../../functions/txfunctions";
import FlowFtSticker from "../../../../forms/ftstickers/flowftsticker";

const ColDetailMakeBidModal = ({
  listingStatus,
  auctionInfo,
  auctionListing,
}) => {
  const themeStatus = UseThemeStatus();
//   console.log(listingStatus);
//   console.log(auctionListing);
//   console.log(auctionInfo);

  let validated = false;

  const [formValues, setFormValues] = useImmer([
    {
      id: "address",
      value: auctionListing.seller,
    },
    {
      id: "amount",
      value: "0",
    },

    {
      id: "id",
      value: auctionListing.listingId,
    },
  ]);

  //console.log(formValues)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!auctionInfo.userHighestBidder) {
      handleBidNFTAuction(formValues);
    } else {
      handleIncreaseBidNFTAuction([
        {
          id: "id",
          value: auctionListing.listingId,
        },
        {
          id: "amount",
          value: formValues[1].value - auctionListing.amount,
        },
      ]);
    }
  };

  // if (
  //   !auctionListing.bidder &&
  //   parseFloat(formValues[1].value).toFixed(2)
  //    >=
  //     parseFloat(auctionListing.amount).toFixed(2)
  // ) {
  //   e.preventDefault();
  //   handleBidNFTAuction(formValues);
  // } else if (
  //   auctionListing.auction.bidder &&
  //   formValues[1].value >
  //     auctionListing.amount &&
  //   user.addr !== auctionListing.bidder
  // ) {
  //   e.preventDefault();

  //   handleBidNFTAuction(formValues);
  // } else if (
  //   user.addr === auctionListing.FindMarketAuctionEscrow.items[0].bidder &&
  //   formValues[1].value >
  //     auctionListing.FindMarketAuctionEscrow.items[0].amount
  // ) {
  //   e.preventDefault();
  //   handleIncreaseBidNFTAuction([
  //     {
  //       id: "id",
  //       value: nftDetails.uuid,
  //     },
  //     {
  //       id: "amount",
  //       value:
  //         formValues[1].value -
  //         auctionListing.amount,
  //     },
  //   ]);
  // } else {
  //   e.preventDefault();
  // }

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (varVal.value < 1 || varVal.value < auctionListing.amount) {
        e.target.classList.add("is-invalid");
        e.target.classList.remove("is-valid");
      } else {
        e.target.classList.add("is-valid");
        e.target.classList.remove("is-invalid");
      }
    });
    // //console.log(formValues);
  }

  return (
    <div id="bid-modal" data-theme={UseThemeStatus()} className="form-inputs">
      <Image
      crossOrigin="anonymous"
        id="modal-img"
        fluid={+true}
        src={parseImgUrl(listingStatus.itemThumbnail, 'thumbnail')}
      />

      <Row className="pt-3 fw-bold">
        <Col align="left" className="">
          Current bid:{" "}
        </Col>
        <Col align="right">
          {auctionListing.amount * 1} {auctionListing.ftAlias}
        </Col>
      </Row>

      {/* <Row className="align-items-center p-3">
        <Col align="left" className="profileMenuPic col-3">
          <Image fluid={+true} src={findUser.avatar} />
        </Col>
        <Col align="left">
          <Row>
            <small>Owned By</small>
          </Row>
          <Row className="find-text">
            <small>{findUser.name}</small>
          </Row>
        </Col>
      </Row> */}

      <Row className="pt-3">
        <Row className="align-items-center">
          <Col align="left" className="fw-bold">
            How much would you like to bid?
          </Col>
          <Col>
            <FlowFtSticker />
          </Col>
        </Row>
        <Row>
          <Form
            disabled={useFormStatus()}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="form-inputs pt-3"
          >
            <Row>
              <Form.Label>Bid amount:</Form.Label>
            </Row>
            <Row className="p-3">
              <Col xs="12" md="6">
                <Form.Control
                  type="number"
                  placeholder="450 Flow"
                  onChange={updateField}
                  name="amount"
                  required
                  min="0"
                />
              </Col>
              <Col className="mt-2 mt-md-0" xs="12" md="6">
                <Button
                  className="w-100"
                  variant="find-outline-commerce-dark"
                  type="submit"
                >
                  Make Bid
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
    </div>
  );
};

export default ColDetailMakeBidModal;
