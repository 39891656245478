import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Card,
  Modal,
  Form,
} from "react-bootstrap";
import { ProfileUserBar } from "../components/profile/profileuserbar/profileuserbar";
import { Helmet } from "react-helmet";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../functions/script";
import parseImgUrl from "../functions/parseImgUrl";
import truncateItemName from "../functions/truncateItemName";
import Loading from "../components/loading/loading";
import { useImmer } from "use-immer";
import toast from "react-hot-toast";
import { useStateChanged } from "../functions/DisabledState";
import {
  doBulkSetupAccount,
  parseCollectionData,
} from "../functions/parseCollectionData";

const LostAndFound = ({ findUser, user }) => {
  const [lostNFTs, setLostNFTs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let lostTypeArray = [];

  if (findUser) {
    if (findUser.lostAndFoundTypes) {
      Object.keys(findUser.lostAndFoundTypes).forEach((catalogType) => {
        // console.log(catalogType)
        lostTypeArray.push({
          [catalogType]: findUser.lostAndFoundTypes[catalogType],
        });
      });
    }
  }

  // console.log(findUser)

  // useEffect(() =>{

  //   setFilteredUnlinkedCols(catalogItems.filter)

  // }, [catalogItems, lostNFTs])
  //   const [sendBatchNFTsModalOpen, setSendBatchNFTsModalOpen] = useState(false);
  //   const [batchNFTsForm, setBatchNFTsForm] = useImmer({
  //     nftIdentifiers: [""],
  //     allReceivers: [""],
  //     ids: [""],
  //     memos: [""],
  //   });

  // console.log(lostNFTs)
  // console.log(catalogItems)

  //lost item collection
  //catalog filter for type
  //generate replacement code

  // console.log(doBulkSetupAccount(lostTypeArray))
  // console.log(lostTypeArray)

  useEffect(() => {
    if (user.loggedIn) {
      async function getLostAndFoundNFTs(addr) {
        const nfts = await Script(scripts.getLostAndFoundNFTs, { user: addr });
        setLostNFTs(nfts);
        setIsLoading(false);
      }
      try {
        getLostAndFoundNFTs(user.addr);
      } catch (error) {
        console.log(error);
      }
    }
  }, [user, useStateChanged()]);
  // console.log(lostNFTs)

  //   const handleClose = () => {
  //     setSendBatchNFTsModalOpen(false);
  //   };

  const redeemAll = async (value) => {
    try {
      await Tx({
        tx: transactions.redeemAllLostAndFoundNFTs,
        args: {},
        replacements: value,
        callbacks: {
          async onSuccess(status) {
            console.log("success");
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const redeemOne = async (value, replacements) => {
    // console.log(replacements);
    try {
      await Tx({
        tx: transactions.redeemLostAndFoundNFTs,
        args: {
          ids: value,
        },
        replacements: replacements,
        callbacks: {
          async onSuccess(status) {
            console.log("success");
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  //   const updateBatchNFTsForm = (e, index) => {
  //     var before = batchNFTsForm[e.target.name];
  //     before[index] = e.target.value;
  //     setBatchNFTsForm({
  //       ...batchNFTsForm,
  //       [e.target.name]: before,
  //     });
  //   };

  //   const addToForm = (key) => {
  //     setBatchNFTsForm({
  //       ...batchNFTsForm,
  //       [key]: [...batchNFTsForm[key], ""],
  //     });
  //   };

  const removeFromForm = (e, index) => {
    //only do this if current findUser links object keys includes link
    // if (Object.keys(findUser.links).includes(link)) {
    //   setRemoveLinksArray((removeLinksArray) => [...removeLinksArray, link]);
    // }
    // setNewLinksArray((links) => {
    //   const newLinks = { ...links };
    //   delete newLinks[link];
    //   return newLinks;
    // });
  };

  //   const clearForm = () => {
  //     setBatchNFTsForm({
  //       nftIdentifiers: [""],
  //       allReceivers: [""],
  //       ids: [""],
  //       memos: [""],
  //     });
  //   };

  //   const submitBatchNFTsForm = async () => {
  //     if (
  //       batchNFTsForm.nftIdentifiers.length !=
  //         batchNFTsForm.allReceivers.length ||
  //       batchNFTsForm.nftIdentifiers.length != batchNFTsForm.ids.length ||
  //       batchNFTsForm.nftIdentifiers.length != batchNFTsForm.memos.length
  //     ) {
  //       toast.error("Please make sure all fields are the same size");
  //     } else {
  //       try {
  //         await Tx({
  //           tx: transactions.sendNFTs,
  //           args: batchNFTsForm,
  //           callbacks: {
  //             async onSuccess(status) {
  //               console.log("success");
  //             },
  //           },
  //         });
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   };

  return (
    <Container
      fluid={+true}
      className="pb-5 pt-4"
      style={{
        backgroundColor: "var(--background-profile)",
        minHeight: "calc(100vh - 218px)",
      }}
    >
      <Helmet>
        <title>NFT Inbox / .find</title>
        <meta name="description" content=".find inbox" />
      </Helmet>
      <Container className="content-container">
        <Row className="align-items-center justify-content-center g-0">
          <h1 className="themeColor pb-3" style={{ textAlign: "center" }}>
            NFT Inbox
          </h1>
          {isLoading ? (
            <Loading />
          ) : !isLoading && Object.keys(lostNFTs).length === 0 ? (
            "No lost NFTs found"
          ) : null}
          {lostNFTs != null && Object.keys(lostNFTs).length > 0
            ? Object.keys(lostNFTs.nftCatalogTicketInfo).map((i) => {
                return lostNFTs.nftCatalogTicketInfo[i].map((nft) => {
                  // console.log(nft);
                  return (
                    <Col
                      key={nft.ticketID}
                      className="mt-3 d-flex align-items-center justify-content-center"
                      xs="6"
                      sm="6"
                      md="4"
                      lg="3"
                      xl="3"
                      xxl="2"
                      onClick={() =>
                        redeemOne(
                          { [nft.typeIdentifier]: [Number(nft.ticketID)] },
                          doBulkSetupAccount(
                            lostTypeArray.filter(
                              (type) => Object.keys(type) == nft.typeIdentifier
                            )
                          )
                        )
                      }
                    >
                      {/* {console.log(lostTypeArray.forEach(type => console.log( Object.keys( type)      )))} */}

                      <Card
                        //   onMouseEnter={() => setShowBuy(true)}
                        //   onMouseLeave={() => setShowBuy(false)}
                        //   className={
                        //     !moreFromCollection
                        //       ? "market-nft mx-0 mb-0 pb-0"
                        //       : moreFromCollection && detail
                        //       ? "market-nft-more-detail mx-1 mb-0 pb-0"
                        //       : "market-nft-more mx-1 mb-0 pb-0"
                        //   }
                        className="market-nft mx-0 mb-0 pb-0"
                      >
                        {/* <Link
                          className={isName ? "card-link px-2 py-2 mb-2 " : ' card-link px-0 mb-2'}
                      
                          to={
                            hit.collection_name === "FIND"
                              ? "/mp/" + hit.collection_alias + "/" + hit.nft_name
                              : "/mp/" + hit.collection_alias + "/" + hit.uuid
                          }
                        > */}
                        {nft.thumbnail && nft.thumbnail.includes(".mp4") ? (
                          <video
                            key={nft.thumbnail}
                            muted
                            alt={"Picture of " + nft.name}
                            loop=""
                            playsInline=""
                            style={{ cursor: "pointer" }}
                            onMouseOver={(event) => event.target.play()}
                            onMouseOut={(event) => event.target.pause()}
                            //   onClick={() =>
                            //     hit.collection_name === "FIND"
                            //       ? navigate("/mp/" + hit.collection_alias + "/" + hit.nft_name)
                            //       : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
                            //   }
                          >
                            <source
                              src={parseImgUrl(nft.thumbnail) + "#t=1.0"}
                              type="video/mp4"
                            ></source>
                            Sorry this video is not supported by your browser
                          </video>
                        ) : (
                          <Image
                          crossOrigin="anonymous"

                            //   className={hit.collection_alias === "FIND" ? "px-2 pt-2 img-wrap" : "img-wrap"}
                            fluid={+true}
                            src={parseImgUrl(nft.thumbnail, 'thumbnail')}
                            color="var(--background)"
                            style={{
                              borderRadius: "8px",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}

                            //   onClick={() =>
                            //     hit.collection_name === "FIND"
                            //       ? navigate("/mp/" + hit.collection_alias + "/" + hit.nft_name)
                            //       : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
                            //   }
                          />
                        )}
                        <Row className="py-0 px-2">
                          <Col align="left" className="mpCardNameRow">
                            <span className="nftName fw-bold">
                              <span>{truncateItemName(nft.name, 30)}</span>
                            </span>
                          </Col>
                        </Row>

                        {/* {sendBatchNFTsModalOpen && (
                          <Modal
                            show={sendBatchNFTsModalOpen}
                            onHide={handleClose}
                            centered
                          >
                            <Modal.Header
                              style={{ border: "none" }}
                              closeButton
                              closeLabel="close window"
                            >
                              <Modal.Title className="text-center w-100">
                                <h4
                                  className="fw-bold"
                                  style={{ marginBottom: "0px" }}
                                >
                                  Send Batch NFTs
                                </h4>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <fieldset>
                                <Form autoComplete="off">
                                  <Form.Label className="fw-bold">
                                    NFT Identifiers
                                  </Form.Label>
                                  {batchNFTsForm.nftIdentifiers.map(
                                    (identifier, index) => {
                                      return (
                                        <Form.Control
                                          key={index}
                                          className="mb-3 settings-input"
                                          name="nftIdentifiers"
                                          placeholder="A.35717efbbce11c74.Dandy.NFT"
                                          id="nftIdentifiers"
                                          onChange={(e) =>
                                            updateBatchNFTsForm(e, index)
                                          }
                                          value={
                                            batchNFTsForm.nftIdentifiers[index]
                                          }
                                        />
                                      );
                                    }
                                  )}
                                  <Row className="px-3 mb-3">
                                    <Button
                                      variant="find-skinny"
                                      onClick={() =>
                                        addToForm("nftIdentifiers")
                                      }
                                    >
                                      <small>Add</small>
                                    </Button>
                                  </Row>
                                  <Form.Label className="fw-bold">
                                    Receivers
                                  </Form.Label>
                                  {batchNFTsForm.allReceivers.map(
                                    (receiver, index) => {
                                      return (
                                        <Form.Control
                                          key={index}
                                          className="mb-3 settings-input"
                                          name="allReceivers"
                                          placeholder="0x00"
                                          id="allReceivers"
                                          onChange={(e) =>
                                            updateBatchNFTsForm(e, index)
                                          }
                                          value={
                                            batchNFTsForm.allReceivers[index]
                                          }
                                        />
                                      );
                                    }
                                  )}
                                  <Row className="px-3 mb-3">
                                    <Button
                                      variant="find-skinny"
                                      onClick={() => addToForm("allReceivers")}
                                    >
                                      <small>Add</small>
                                    </Button>
                                  </Row>
                                  <Form.Label className="fw-bold">
                                    NFT IDs
                                  </Form.Label>
                                  {batchNFTsForm.ids.map((id, index) => {
                                    return (
                                      <Form.Control
                                        key={index}
                                        className="mb-3 settings-input"
                                        name="ids"
                                        placeholder="108692828"
                                        id="ids"
                                        onChange={(e) =>
                                          updateBatchNFTsForm(e, index)
                                        }
                                        value={batchNFTsForm.ids[index]}
                                      />
                                    );
                                  })}
                                  <Row className="px-3 mb-3">
                                    <Button
                                      variant="find-skinny"
                                      onClick={() => addToForm("ids")}
                                    >
                                      <small>Add</small>
                                    </Button>
                                  </Row>
                                  <Form.Label className="fw-bold">
                                    Memos
                                  </Form.Label>
                                  {batchNFTsForm.memos.map((memo, index) => {
                                    return (
                                      <Form.Control
                                        key={index}
                                        className="mb-3 settings-input"
                                        name="memos"
                                        placeholder="Hello"
                                        id="memos"
                                        onChange={(e) =>
                                          updateBatchNFTsForm(e, index)
                                        }
                                        value={batchNFTsForm.memos[index]}
                                      />
                                    );
                                  })}
                                  <Row className="px-3 mb-3">
                                    <Button
                                      variant="find-skinny"
                                      onClick={() => addToForm("memos")}
                                    >
                                      <small>Add</small>
                                    </Button>
                                  </Row>
                                  <Row className="" align="center">
                                    <Col>
                                      <Button
                                        className="m-3"
                                        variant="find-outline-dark"
                                        type="button"
                                        onClick={clearForm}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="m-3"
                                        variant="find-nochange-dark"
                                        onClick={submitBatchNFTsForm}
                                      >
                                        Send
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </fieldset>
                            </Modal.Body>
                          </Modal>
                        )} */}
                      </Card>
                    </Col>
                  );
                });
              })
            : null}
        </Row>

        {lostNFTs != null &&
        Object.keys(lostNFTs.nftCatalogTicketInfo).length > 0 ? (
          <Col xs="12" className="mx-auto mt-3 px-4 pt-5" align="center">
            <Button
              variant="find"
              className="w-50"
              onClick={() => redeemAll(doBulkSetupAccount(lostTypeArray))}
            >
              Redeem All
            </Button>
          </Col>
        ) : (
          <p className="pt-4" align="center">
            No current items to redeem
          </p>
        )}

        {/* <Col xs="12" className="mx-auto mt-3 px-4" align="center">
          <Button
            variant="find-dark"
            className="w-50"
            onClick={() => setSendBatchNFTsModalOpen(true)}
          >
            Send Batch NFTs
          </Button>
        </Col> */}
      </Container>
    </Container>
  );
};

export default LostAndFound;
