import { Button, Form, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useFormStatus } from "../../../../functions/DisabledState";
// import { useNavigate } from "react-router";
import { useImmer } from "use-immer";
import { handleListNFTAuction } from "../../../../functions/txfunctions";
import toast from "react-hot-toast";
import "./listnft.scss";

const ListNftForAuction = ({
  listingStatus,
  showSalesModal,
  setShowSalesModal,
}) => {
  const [formValues, setFormValues] = useImmer([
    {
      id: "startPrice",
      value: "0",
    },
    {
      id: "ftAlias",
      value: listingStatus.aDapperItem
        ? listingStatus.dapperAllowedListingActions.FindMarketSale
            .ftIdentifiers[0]
        : listingStatus.allowedListingActions.FindMarketSale.ftAlias[0],
    },
    {
      id: "id",
      value: listingStatus.itemId,
    },
    {
      id: "nftAlias",
      value: listingStatus.itemType,
    },
    {
      id: "reservePrice",
      value: "0",
    },

    {
      id: "duration",
      value: null,
    },
    {
      id: "extensionOnLateBid",
      value: "60",
    },
    {
      id: "minimumBidIncrement",
      value: "1.0",
    },
    {
      id: "auctionValidUntil",
      value: null,
    },
  ]);

  function updateField(e, fieldName) {
    // console.log("hi");
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (fieldName === "reserve") {
        if (formValues[0].value * 1 > e.target.value * 1) {
          e.target.classList.add("is-invalid");
          e.target.classList.remove("is-valid");
        } else {
          e.target.classList.add("is-valid");
          e.target.classList.remove("is-invalid");
        }
      } else if (fieldName === "start") {
        if (e.target.value * 1 < 1) {
          e.target.classList.add("is-invalid");
          e.target.classList.remove("is-valid");
        } else {
          e.target.classList.add("is-valid");
          e.target.classList.remove("is-invalid");
        }
      } else if (fieldName === "duration") {
        if (e.target.value === "Select duration") {
          e.target.classList.add("is-invalid");
          e.target.classList.remove("is-valid");
        } else {
          e.target.classList.add("is-valid");
          e.target.classList.remove("is-invalid");
        }
      }
    });
    // console.log(formValues);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValues[0].value < 1) {
      toast.error(`Start price needs to be 1 or higher`);
      document.getElementById("startPrice").classList.add("is-invalid");
      document.getElementById("startPrice").classList.remove("is-valid");
    } else if (
      parseFloat(formValues[4].value) < parseFloat(formValues[0].value)
    ) {
      toast.error(`Reserve price needs to be ${formValues[0].value} or higher`);
      document.getElementById("reservePrice").classList.add("is-invalid");
      document.getElementById("reservePrice").classList.remove("is-valid");
    } else if (
      !formValues[5].value ||
      formValues[5].value === "Select duration"
    ) {
      toast.error(`Please select a duration`);
      document.getElementById("selectDuration").classList.add("is-invalid");
      document.getElementById("selectDuration").classList.remove("is-valid");
    } else {
      handleListNFTAuction(formValues);
    }
  };

  return (
    <div id="list-auction-wrapper">
      <form onSubmit={handleSubmit} noValidate>
        <fieldset disabled={useFormStatus()}>
          <div id="auction-description-row">
            The countdown timer will not start until someone makes their first
            bid at the starting price or higher.
          </div>

          <div id="auction-input-row">
            <div className="auction-input-wrapper">
              <label for="startPrice">Start Price</label>
              <input
                id="startPrice"
                placeholder="Enter an amount in Flow"
                type="number"
                name="startPrice"
                onChange={(e) => updateField(e, "start")}
                required
                min="0"
              />
            </div>
            &nbsp;
            <div className="auction-input-wrapper">
              <label for="reservePrice">Reserve Price</label>
              <input
                id="reservePrice"
                placeholder="Enter an amount in Flow"
                type="number"
                name="reservePrice"
                required
                min="0"
                onChange={(e) => updateField(e, "reserve")}
              />
            </div>
          </div>
          <div className="auction-input-wrapper">
            <label for="selectDuration">Select duration</label>

            <select
              id="selectDuration"
              aria-label="Duration Select"
              name="duration"
              onChange={(e) => updateField(e, "duration")}
            >
              <option>Select duration</option>
              {/* //change to 5 mins for test */}
              <option value="60">1 min</option>

              <option value="86400">1 Day</option>
              <option value="259200">3 Days</option>
              <option value="345600">4 Days</option>
              <option value="604800">7 Days</option>
            </select>
          </div>

          <div id="list-button-row">
            <Button
              className="w-100"
              onClick={() => setShowSalesModal(!showSalesModal)}
              variant="find-outline-commerce-light"
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              className="w-100"
              type="submit"
              variant="find-outline-commerce-dark"
              disabled={useFormStatus() || formValues[0].value < 1}
            >
              List
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default ListNftForAuction;
