import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../functions/DisabledState";
import { handleIncreaseBid } from "../../../../functions/txfunctions";

const IncreaseBidName = ({ lease }) => {
  const [formValues, setFormValues] = useImmer([
    {
      id: "bidAmt",
      value: lease.latestBid - lease.latestBid + 1,
    },
    {
      id: "name",
      value: lease.name,
    },
  ]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value - lease.latestBid;
    });
  }

  return (
    <Row className="align-items-center">
      <div className="seperator100 my-4"></div>

      <fieldset disabled={useFormStatus()}>
        <Form className="form-inputs">
          {" "}
          <Form.Label>
            <small>
              {" "}
              You can increase your bid by entering at least{" "}
              <b>{lease.latestBid * 1 + 1} FUSD</b>:
            </small>
          </Form.Label>
          <Row className="align-items-center">
            <Col>
              <Form.Control
                type="number"
                min="0"
                name="bidAmt"
                defaultValue={lease.latestBid * 1 + 1}
                placeholder="Enter an amount in FUSD"
                onChange={(e) => {
                  updateField(e);
                  if (e.target.value <= lease.latestBid) {
                    e.target.classList.add("is-invalid");
                    e.target.classList.remove("is-valid");
                  } else {
                    e.target.classList.add("is-valid");
                    e.target.classList.remove("is-invalid");
                  }
                }}
              />
            </Col>
            <Col xs="auto">
              <Button
                onClick={() => {
                  if (formValues[0].value * 1 > 0) {
                    //console.log("increase fired");
                    handleIncreaseBid(formValues);

                  } else {
                    console.log(
                      "failed amount is: " + JSON.stringify(formValues, null, 2)
                    );
                  }
                }}
                variant="find-outline-commerce-dark"
              >
                Increase Bid
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </Row>
  );
};

export default IncreaseBidName;
