import { Col } from "react-bootstrap";
import { useHits } from "react-instantsearch-hooks-web";
import { useLocation } from "react-router";
import MpHitsCard from "../search/mphitscard/mphitscard";
import CharityAuctionHitsCard from "./CharityAuctionHitsCard";
// import './marketplace.css'

export default function CustomCharityAuctionHits({
  props,
  showSideBarFilters,
}) {
  const { hits } = useHits(props);

  // useEffect(() => {

  // }, [location, hits])

  ////console.log(JSON.stringify(hits))
  // const offerFilteredHits = hits.filter(
  //   (hit) => hit.status !== "active_offered"
  // )
  // console.log(hits);

  return hits.map((hit, i) => (
    <Col
      key={i}
      className="mt-3 d-flex align-items-center justify-content-center"
      xs="12    "
      sm="6"
      lg="4"
      xl="3"
      xxl={showSideBarFilters ? 2 : 2}
    >
      <CharityAuctionHitsCard hit={hit} />
    </Col>
  ));
}
