import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import { Script } from "../../../../functions/script";
import { UseThemeStatus } from "../../../../functions/themeMode";
import {
  handleBuyerFulfillNFTAuction,
  handleFullfillAuction,
} from "../../../../functions/txfunctions";
import Loading from "../../../loading/loading";
import MarketplaceItemBoughtModal from "../../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";
import MpDetailMakeOffer from "../../../search/mpdetailpage/mpdetailmakeoffer";
import MpDetailRemoveListing from "../../../search/mpdetailpage/mpdetailremovelisting";
import FlowFtSticker from "../../ftstickers/flowftsticker";
import FusdFtSticker from "../../ftstickers/fusdftsticker";
import MakeBidNftMpModal from "./makebidnftmpmodal";
import { scripts } from "@findonflow/find-flow-contracts";
import parseNameLink from "../../../../functions/parseNameLink";
import { useSwipeable } from "react-swipeable";

const MakeBidNftMp = ({
  listingDetails,
  profileDetails,
  nftDetails,
  user,
  ownItem,
  isName,
  hit,
  userHighestBidder,
  forAuction,
  auctionEnded,
  listingStatus,
  toastStatus,
  themeStatus,
  latestBid,
  findUser,
  userHasOffer,
  hits,
}) => {
  const [show, setShow] = useState(false);
  const [buyerClickedFulfil, setBuyerClickedFulfil] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [profileAddress, setProfileAddress] = useState(null);

  // let profileAddress = latestBid.blockEventData.buyer;

  useEffect(() => {
    if (latestBid && latestBid.blockEventData.buyer) {
      setProfileAddress(latestBid.blockEventData.buyer);
    }
  }, [latestBid]);

  useEffect(() => {
    if (profileAddress) {
      // console.log(profileAddress)
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(profileAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [profileAddress]);

  // console.log(updatedProfile);

  // console.log(latestBid);
  // console.log(listingStatus);
  // console.log(listingDetails);
  // console.log(latestBid)
  // console.log(userHighestBidder);
  // console.log(listingStatus)

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleBuyerMpFulfil = () => {
    setBuyerClickedFulfil(true);
    if (isName) {
      handleFullfillAuction(nftDetails.nft_name, nftDetails.seller);
    } else {
      handleBuyerFulfillNFTAuction(nftDetails.uuid);
    }
  };

  // const handleSellerMpFulfil = () => {
  //   if (isName) {
  //     handleFulfillSale(nftDetails.nft_name);
  //   } else {
  //     handleFulfillMarketAuctionEscrowedNFT(nftDetails.uuid, nftDetails.seller);
  //   }
  // };

  // console.log(listingDetails)

  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      setShow(false);
    },
    ...config,
  });

  if (listingDetails && listingStatus.forAuction) {
    return (
      <Row className="py-2 justify-content-center mb-4  ">
        <Col xs="12">
          <Card
            id="mp-price-box-card-sale"
            className="mp-price-box-card px-4 pb-4 pt-4 mb-2"
          >
            <Row className="py-2">
              <Col>
                <span className="fw-bold">
                  {!listingStatus.auctionStarted
                    ? "Starting bid: "
                    : listingStatus.auctionStarted &&
                      listingStatus.auctionEndedAboveReserve
                    ? "Winning bid:"
                    : "Current bid:"}
                </span>
              </Col>
              <Col>
                {" "}
                {listingStatus.auctionStarted ? (
                  <div
                    style={{ float: "right" }}
                    className="d-flex align-items-end"
                  >
                    <span className=" secColor">Ends:&nbsp;</span>
                    <span className="fw-bold" style={{ float: "right" }}>
                      <ReactTimeago
                        date={
                          new Date(
                            isName
                              ? listingDetails.auctionEnds * 1000
                              : listingDetails.auction.auctionEndsAt * 1000
                          )
                        }
                        timeStyle="twitter"
                      />
                    </span>
                  </div>
                ) : (
                  <span className="propertiesWrap" style={{ float: "right" }}>
                    Timer begins on first bid &nbsp;
                    <i className="fa-solid fa-circle-info" />
                  </span>
                )}
              </Col>
            </Row>
            <Row className="pb-2">
              <Col>
                {!isName ? (
                  <div className="mp-price-row">
                    <h3 className="mb-0">
                      {isName &&
                        (listingDetails.latestBidBy
                          ? listingDetails.latestBid * 1 + " FUSD"
                          : listingDetails.auctionStartPrice * 1 + " FUSD")}
                      {!isName && listingDetails.amount * 1}&nbsp;
                    </h3>
                    {listingDetails?.ftAlias.includes("Flow") ? (
                      <FlowFtSticker noBg={"true"} />
                    ) : (
                      <FusdFtSticker noBg={"true"} />
                    )}
                  </div>
                ) : (
                  <div className="mp-price-row">
                    <h3 className="mb-0">
                      {listingDetails.auctionStartPrice * 1}
                    </h3>{" "}
                    <FusdFtSticker noBg={"true"} />
                  </div>
                )}
              </Col>
            </Row>
            {latestBid && updatedProfile && (
              <Row className="align-items-center pb-3">
                <Col xs="auto">
                  <Image
                                crossOrigin="anonymous"

                    className=""
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                    src={
                      updatedProfile
                        ? updatedProfile.avatar
                        : latestBid.blockEventData.buyerAvatar
                        ? latestBid.blockEventData.buyerAvatar
                        : "/assets/img/store/fnamestore.png"
                    }
                  />
                </Col>
                &nbsp;<Col>{parseNameLink(updatedProfile)}</Col>
              </Row>
            )}

            <Row className="pt-3">
              <Col
                align="center"
                xs="12"
                lg="6"
                className="pb-xs-2 mb-2 mb-lg-0"
              >
                {listingDetails &&
                listingStatus.listingDetails.linkedForMarket &&
                !listingStatus.ownItem ? (
                  <>
                    {!listingStatus.forSale && (
                      <MpDetailMakeOffer
                        hit={hit}
                        themeStatus={themeStatus}
                        findUser={findUser}
                        profileDetails={listingStatus.sellerProfile}
                        listingDetails={listingDetails}
                        userHasOffer={userHasOffer}
                        isName={isName}
                      />
                    )}
                  </>
                ) : listingDetails && listingStatus.ownItem ? (
                  <MpDetailRemoveListing
                    hits={hits}
                    isName={isName}
                    forSale={listingStatus.forSale}
                    forAuction={listingStatus.forSale}
                    listingDetails={listingDetails}
                    themeStatus={themeStatus}
                    listingStatus={listingStatus}
                  />
                ) : null}
              </Col>
              <Col align="center" xs="12" lg="6" className="pb-xs-2">
                {!ownItem && (
                  <>
                    <Row className="">
                      <Col xs="12">
                        {listingStatus.auctionEnded &&
                        listingStatus.userHighestBidder &&
                        listingStatus.auctionEndedAboveReserve ? (
                          <>
                            <Button
                              className="w-100"
                              variant="find-nochange-dark"
                              onClick={handleBuyerMpFulfil}
                            >
                              <small>Fulfill</small>
                            </Button>
                            {buyerClickedFulfil &&
                              toastStatus.status === "success" && (
                                <Modal
                                  data-theme={themeStatus}
                                  show={
                                    buyerClickedFulfil &&
                                    toastStatus.status === "success"
                                  }
                                  centered
                                >
                                    <MarketplaceItemBoughtModal
                                      nftDetails={hit}
                                      isName={isName}
                                    />
                                </Modal>
                              )}
                          </>
                        ) : (
                          !listingStatus.auctionEnded &&
                          !listingStatus.ownItem && (
                            <Button
                              className="w-100"
                              variant="find-nochange-dark"
                              onClick={handleShow}
                            >
                              <small> Make Bid</small>
                            </Button>
                          )
                        )}
                      </Col>
                    </Row>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      centered
                      data-theme={UseThemeStatus()}
                    >
            <div {...handlers}>

                      <Modal.Header
                        style={{ border: "none" }}
                        closeButton
                        closeLabel="close window"
                      >
                        <Modal.Title className="text-center w-100">
                          <h4
                            className="fw-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Make a bid for{" "}
                            {!isName ? (
                              hit.nft_name
                            ) : (
                              <span>
                                <span className="themeColor">
                                  {hit.nft_name}
                                </span>
                                .find
                              </span>
                            )}
                          </h4>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MakeBidNftMpModal
                          userHighestBidder={userHighestBidder}
                          listingDetails={listingDetails}
                          nftDetails={nftDetails}
                          profileDetails={profileDetails}
                          isName={isName}
                          hit={hit}
                          user={user}
                          listingStatus={listingStatus}
                          latestBid={latestBid}
                        />
                      </Modal.Body>
                      </div>
                    </Modal>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );

    // return (
    //   <>

    //     {listingDetails.auction && (
    //       <Row className="px-3 py-2">
    //         <Col>
    //           {listingStatus.auctionEnded ? "Auction Ended:" : "Auction Ends:"}
    //         </Col>
    //         <Col align="right">
    //           {listingDetails.bidder ? (
    //             <b>
    //               <ReactTimeago
    //                 date={new Date(listingDetails.auction.auctionEndsAt * 1000)}
    //                 timeStyle="twitter"
    //               />
    //             </b>
    //           ) : (
    //             <b>Timer begins on first bid</b>
    //           )}
    //         </Col>
    //       </Row>
    //     )}
    //     {listingDetails.auctionStartPrice && (
    //       <Row className="px-3 py-2">
    //         <Col>
    //           {listingStatus.auctionEnded ? "Auction Ended:" : "Auction Ends:"}
    //         </Col>
    //         <Col align="right">
    // {listingDetails.auctionEnds ? (
    //   <b>
    //     <ReactTimeago
    //       date={new Date(listingDetails.auctionEnds * 1000)}
    //       timeStyle="twitter"
    //     />
    //   </b>
    // ) : (
    //   <b>Timer begins on first bid</b>
    // )}
    //         </Col>
    //       </Row>
    //     )}

    //     <Row className=" px-3">
    //       <Col>{!listingDetails.bidder ? "Starting Bid:" : "Current Bid:"}</Col>
    //       <Col align="right">
    //         <b>
    // {isName &&
    //   (listingDetails.latestBidBy
    //     ? listingDetails.latestBid * 1 + " FUSD"
    //     : listingDetails.auctionStartPrice * 1 + " FUSD")}

    // {!isName &&
    //   listingDetails.amount * 1 + " " + listingDetails.ftAlias}
    //         </b>
    //       </Col>
    //     </Row>

    //     {listingDetails.bidder && (
    //       <Row className=" px-3">
    //         <Col></Col>
    //         <Col align="right">
    //           by{" "}
    //           {listingDetails.bidderName ? (
    //             <Link
    //               style={{ textDecoration: "none" }}
    //               to={"/" + listingDetails.bidderName}
    //             >
    //               <b>
    //                 <span className="themeColor">
    //                   {listingDetails.bidderName}
    //                 </span>
    //                 .find
    //               </b>
    //             </Link>
    //           ) : (
    //             <Link
    //               style={{ textDecoration: "none" }}
    //               to={"/" + listingDetails.bidder}
    //             >
    //               {" "}
    //               <b>{listingDetails.bidder}</b>
    //             </Link>
    //           )}
    //         </Col>
    //       </Row>
    //     )}

    // {!ownItem && (
    //   <>
    //     <Row className="my-3">
    //       <Col></Col>
    //       <Col md="6" xs="12">
    //         {listingStatus.auctionEnded &&
    //         listingStatus.userHighestBidder &&
    //         listingStatus.auctionEndedAboveReserve ? (
    //           <>
    //             <Button
    //               className="w-100"
    //               variant="find-nochange-dark"
    //               onClick={handleBuyerMpFulfil}
    //             >
    //               <small>Fulfill</small>
    //             </Button>
    //             {buyerClickedFulfil && toastStatus.status === "success" && (
    //               <Modal
    //                 data-theme={themeStatus}
    //                 show={
    //                   buyerClickedFulfil && toastStatus.status === "success"
    //                 }
    //                 centered
    //               >
    //                 <MarketplaceItemBoughtModal
    //                   nftDetails={hit}
    //                   isName={isName}
    //                 />
    //               </Modal>
    //             )}
    //           </>
    //         ) : (
    //           !listingStatus.auctionEnded &&
    //           !listingStatus.ownItem && (
    //             <Button
    //               className="w-100"
    //               variant="find-nochange-dark"
    //               onClick={handleShow}
    //             >
    //               <small> Make Bid</small>
    //             </Button>
    //           )
    //         )}
    //       </Col>
    //     </Row>
    //     <Modal
    //       show={show}
    //       onHide={handleClose}
    //       centered
    //       data-theme={UseThemeStatus()}
    //     >
    //       <Modal.Header
    //         style={{ border: "none" }}
    //         closeButton
    //         closeLabel="close window"
    //       >
    //         <Modal.Title className="text-center w-100">
    //           <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
    //             Make a bid for{" "}
    //             {!isName ? (
    //               hit.nft_name
    //             ) : (
    //               <span>
    //                 <span className="themeColor">{hit.nft_name}</span>.find
    //               </span>
    //             )}
    //           </h4>
    //         </Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body>
    //         <MakeBidNftMpModal
    //           userHighestBidder={userHighestBidder}
    //           listingDetails={listingDetails}
    //           nftDetails={nftDetails}
    //           profileDetails={profileDetails}
    //           isName={isName}
    //           hit={hit}
    //           user={user}
    //           listingStatus={listingStatus}
    //           latestBid={latestBid}
    //         />
    //       </Modal.Body>
    //     </Modal>
    //   </>
    // )}

    //     {listingStatus.ownItem && listingStatus.auctionEnded && (
    //       <Row className="my-3">
    //         <Col></Col>
    //         <Col md="6" xs="12">
    //           <Button
    //             className="w-100"
    //             variant="find-nochange-dark"
    //             onClick={handleSellerMpFulfil}
    //           >
    //             <small>Fulfill</small>
    //           </Button>
    //         </Col>
    //       </Row>
    //     )}
    //   </>
    // );
  } else {
    return <Loading />;
  }
};

export default MakeBidNftMp;
