import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./detailspropertycard.css";

const CatalogTraitsCardMarket = ({ listingStatus }) => {
  let itemCollectionName;
  let itemTraits;
  let itemRarity;
  let itemEdition;
  let itemEditionMax;
  let itemTraitsSorted;
  let indexNftAlias

  // console.log(listingStatus)

  if (Object.keys(listingStatus.listingDetails).length > 0) {
    if (
      listingStatus.listingDetails.nftDetail &&
      listingStatus.listingDetails.nftDetail.traits
    ) {
      itemTraits = listingStatus.listingDetails.nftDetail.traits;
      itemTraitsSorted = Object.values(itemTraits).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      // console.log(itemTraitsSorted);
    }

    if (listingStatus?.listingDetails?.nftDetail?.collection) {
      itemCollectionName =
        listingStatus.listingDetails.nftDetail.collection.name;
    }
  }

  if (listingStatus.itemEdition) {
    itemEdition = listingStatus.itemEdition;
    if (listingStatus.itemEditionMax) {
      itemEditionMax = listingStatus.itemEditionMax;
    }
  }

  if (Object.keys(listingStatus.indexHit).length > 0) {
    if (listingStatus.indexHit.nft_rarity) {
      itemRarity = listingStatus.indexHit.nft_rarity;
    }

    if (listingStatus.indexHit.nft_alias){
      indexNftAlias = listingStatus.indexHit.nft_alias
    }
  }

  return (
    <Card id="nft-detail-details-card" className="nft-details-card">
      <Card.Body className="px-0">
        {itemEdition && (
          <div className="flex-row pb-4 align-items-center">
            <i
              style={{ fontSize: "30px" }}
              className="fa-solid fa-newspaper mx-1 secColor"
            ></i>
            &nbsp; &nbsp;
            <span className="fw-bold small"> Edition:&nbsp;&nbsp;</span>
            <span className="secColor small fw-bold">
              {itemEdition}
              {itemEditionMax && <>&nbsp;/&nbsp;{itemEditionMax}</>}
            </span>
          </div>
        )}

        {itemRarity && (
          <div className="flex-row pb-4 align-items-center">
            <i
              style={{ fontSize: "30px" }}
              className="fa-solid fa-gem mx-1 secColor"
            ></i>
            &nbsp; &nbsp;{" "}
            <span className="fw-bold small"> Rarity:&nbsp;&nbsp;</span>
            <span className="secColor small">{UppercaseFirstLetter(itemRarity)}</span>
          </div>
        )}
        {itemTraits && Object.keys(itemTraits).length > 0 && (
          <>
            <div className="flex-row pb-4 align-items-center">
              <i
                style={{ fontSize: "30px" }}
                className="fas fa-star mx-1 secColor"
              />
              &nbsp;&nbsp;<span className="fw-bold small">Traits:&nbsp;</span>
            </div>
            {itemCollectionName && itemCollectionName !== "Crypto Pharaohs" && itemCollectionName !== "Pharaoh Cats" ? (
              <Row className="pb-3 propRow">
                {itemTraitsSorted.map((properties, i) => {
                  // console.log(properties);
                  // console.log(itemTraits);

                  return (
                    <Col
                      key={i}
                      xs="8"
                      sm="6"
                      lg="6"
                      xl="6"
                      xxl="4"
                      className="propertiesTraits mb-3"
                    >
                      <Link style={{textDecoration: 'none'}} to={'/mp?collection=' + indexNftAlias + '&traits=' + properties.displayType && properties.displayType.toLowerCase() + properties.displayType && '.' + encodeURI(properties.name.toLowerCase()) +'%3D' + encodeURI(properties.value)}>
                      <Card>
                        <Card.Body className="traitsWrap">
                          <div>
                            {properties.name && (
                              <Row>
                                <span className="fw-bold">
                                  {UppercaseFirstLetter(properties.name)}
                                </span>
                              </Row>
                            )}
                            {properties.value &&
                              (properties.displayType === "Number" ? (
                                <Row>
                                  <span className="themeColor">
                                    {properties.value * 1}
                                  </span>
                                </Row>
                              ) : properties.displayType === "date" ? (
                                <Row>
                                  <span className="themeColor">
                                    {new Date(
                                      properties.value * 1000
                                    ).toLocaleDateString()}
                                  </span>
                                </Row>
                              ) : (
                                <Row>
                                  <span className="themeColor">
                                    {UppercaseFirstLetter(properties.value)}
                                  </span>
                                </Row>
                              ))}
                            {properties?.rarity?.description ? (
                              <Row>
                                <span className="secColor">
                                  {UppercaseFirstLetter(
                                    properties.rarity.description
                                  )}
                                </span>
                              </Row>
                            ) : properties?.rarity?.score ? (
                              <Row>
                                <span className="secColor">
                                  {properties?.rarity?.score * 1}
                                  {properties?.rarity?.max &&
                                    " / " + properties?.rarity?.max * 1}
                                </span>
                              </Row>
                            ) : (
                              <Row>
                                <span className="secColor"></span>
                              </Row>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Row className="pb-3 propRow">
                {itemTraitsSorted.map((properties, i) => {
                  return (
                    <Col
                      key={i}
                      xs="8"
                      sm="6"
                      lg="6"
                      xl="6"
                      xxl="4"
                      className="propertiesTraits mb-3"
                    >
                      <Card>
                        <Card.Body className="traitsWrap">
                          {properties.name && (
                            <Row>
                              <span className="fw-bold">
                                {properties.name}
                              </span>
                            </Row>
                          )}

                          {properties.value &&
                            Object.keys(properties.value).length >
                              0 && (
                              <>
                                {properties.value.name && (
                                  <span className="themeColor">
                                    {properties.value.name}
                                  </span>
                                )}
                                {properties.value
                                  .additionalData && (
                                  <span className="secColor fw-bold">
                                    {
                                      properties.value
                                        .additionalData
                                    }
                                  </span>
                                )}
                                {properties.value.primaryPower && (
                                  <span className="secColor">
                                    {properties.value.primaryPower}
                                  </span>
                                )}{" "}
                                {properties.value
                                  .secondaryPower && (
                                  <span className="secColor">
                                    {
                                      properties.value
                                        .secondaryPower
                                    }
                                  </span>
                                )}
                                {properties.value.rarity && (
                                  <span className="secColor">
                                    {properties.value.rarity}
                                  </span>
                                )}
                              </>
                            )}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </>
        )}
        {/* <Row>
          <span className="fw-bold px-3  pb-4"> Rankings:&nbsp;</span>
        </Row>

        <Row>
          <span className="fw-bold px-3  pb-4"> Map bars here&nbsp;</span>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default CatalogTraitsCardMarket;
