import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  Button,
  Accordion,
  Modal,
  ModalBody,
  Image,
  Form,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./nftdetailpage.css";
import {
  handleBuyItemDirectSale,
  handleSetPfp,
} from "../../../../functions/txfunctions";
import { UseThemeStatus } from "../../../../functions/themeMode";
import { ListNft } from "../../../forms/selling/listnft/listnft";
import MakeOfferNftModal from "../../../forms/buying/makeoffernft/makeoffernftmodal";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../../../../functions/script";
import ReactTimeago from "react-timeago";
import MakeBidNft from "../../../forms/buying/makebidnft/makebidnft";
import * as fcl from "@onflow/fcl";
import AvailableOnFindCard from "./availableonfindcard";
import DetailsOffersMade from "./detailsoffersmade/detailsoffersmade";
import DetailsItemHistory from "./detailsitemhistory/detailsitemhistory";
import DetailsPropertyCard from "./detailspropertycard/detailspropertycard";
import useToastTxStatus from "../../../../functions/useToastTxStatus";
import MarketplaceItemBoughtModal from "../../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";
import CollectionDetailRemoveListing from "./collectiondetailremovelisting/collectiondetailremovelisting";
import useListingStatusCollection from "../../../../functions/useListingStatusCollection";
import {
  useFormStatus,
  useStateChanged,
} from "../../../../functions/DisabledState";
import parseImgUrl from "../../../../functions/parseImgUrl";
import Bl0xModal from "./bl0xmodal";
import FindPackModal from "./findpackmodal";
import toast from "react-hot-toast";
import ColDetailMediaContent from "./ColDetailMediaContent/ColDetailMediaContent";
import ColDetailNameAndCol from "./ColDetailNameAndCol/ColDetailNameAndCol";
import ColDetailBelowName from "./ColDetailBelowName/ColDetailBelowName";
import ColDetailAccordion from "./ColDetailAccordion/ColDetailAccordion";
import ColDetailShareIcons from "./ColDetailShareIcons/ColDetailShareIcons";
import ColDetailOffers from "./ColDetailOffers/ColDetailOffers";
import ColDetailRoyalties from "./ColDetailRoyalties/ColDetailRoyalties";
import ColDetailBackToCol from "./ColDetailBackToCol/ColDetailBackToCol";
import ColDetailForAuction from "./ColDetailForAuction/ColDetailForAuction";
import ColDetailForSale from "./ColDetailForSale/ColDetailForSale";
import ColDetailLatestBids from "./ColDetailLatestBids/ColDetailLatestBids";
import ColDetailOwnItem from "./ColDetailOwnItem/ColDetailOwnItem";
import ColDetailAvailableOnFlowty from "./ColDetailAvailableOn/ColDetailAvailableOnFlowty";
import ColDetailAvailableOnFlowtyRental from "./ColDetailAvailableOn/ColDetailAvailableOnFlowtyRental";
import ColDetailAvailableOnStorefront from "./ColDetailAvailableOn/ColDetailAvailableOnStorefront";
import ColDetailAvailableOnStorefrontV2 from "./ColDetailAvailableOn/ColDetailAvailableOnStorefrontV2";
import ColDetailAvailableOnFindAuction from "./ColDetailAvailableOn/ColDetailAvailableOnFindAuction";
import ColDetailAvailableOnFindSale from "./ColDetailAvailableOn/ColDetailAvailableOnFindSale";
import ColDetailBurnItem from "./ColDetailOwnItem/ColDetailBurnItem";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../../functions/useWalletType";
import Loading from "../../../loading/loading";

export function NftDetailPage({
  findUser,
  nftData,
  // type,
  profileData,
  addressNoProfile,
  searchedAddress,
}) {
  const [show, setShow] = useState(null);
  const [listingDetails, setListingDetails] = useState({});
  const handleClose = (id) => setShow(id);
  const handleShow = (id) => setShow(id);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const [fulfillClicked, setFulfillClicked] = useState(false);
  const [nftDetails, setNftDetails] = useState();
  const [openPackClicked, setOpenPackClicked] = useState(false);
  const [type, setType] = useState();
  const [showSalesModal, setShowSalesModal] = useState(false);
  const { nft, colName, name, walletProvider } = useParams();
  const user = useUserWalletStatus();
  const [profileAddress, setProfileAddress] = useState(null);
  const walletType = useWalletType();

  // let userAddress = profileData ? profileData.profile.address : searchedAddress;

  const toastStatus = useToastTxStatus();
  const themeStatus = UseThemeStatus();
  const navigate = useNavigate();
  const formStatus = useFormStatus();

  //set the look up address for nftdetails
  useEffect(() => {
    if (profileData) {
      if (walletProvider && walletProvider !== "main") {
        setProfileAddress(profileData.emeraldIDAccounts[walletProvider]);
      } else {
        setProfileAddress(profileData.profile.address);
      }
    } else {
      setProfileAddress(searchedAddress);
    }
  }, [walletProvider, profileData]);

  useEffect(() => {
    if (profileAddress) {
      let scriptName = null;
      if (nftData.shard === "RaribleNFT") {
        scriptName = "getNFTDetails" + "Socks";
      } else {
        scriptName = "getNFTDetails" + nftData.shard;
      }
      async function getNFTDetails(addr, extraIDsArray) {
        const nftDetailResponse = await Script(scripts[scriptName], {
          user: profileAddress,
          project: nftData.extraIDsIdentifier.replace("Bl0x", "bl0x"),
          id: parseInt(nft),
          views: [],
        });
        // console.log(nftDetailResponse);
        setNftDetails(nftDetailResponse);
      }
      try {
        // console.log(extraIDsArray)
        if (!fulfillClicked || !buyNowClicked || openPackClicked) {
          // console.log("OPEN");
          // console.log(openPackClicked);
          showSalesModal === true && setShowSalesModal(false);
          getNFTDetails();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [nft, profileAddress, useStateChanged()]);

  const listingStatus = useListingStatusCollection(
    nftDetails,
    user,
    profileData,
    nftData
  );
  // console.log(listingStatus);

  useEffect(() => {
    if (listingStatus.itemMetaData) {
      if (listingStatus.aCatalogItem) {
        let catalogType = listingStatus.itemType;
        setType(catalogType);
      } else {
        let address = listingStatus.itemMetaData.contract.address;
        let no0xAddress;
        if (address) {
          no0xAddress = address.substring(2);
        }
        if (no0xAddress) {
          setType(
            "A." + no0xAddress + "." + listingStatus.itemMetaData.contract.name
          );
        } else {
          setType("");
        }
      }
    }
  }, [listingStatus]);

  // console.log(listingStatus);

  return (
    <Container fluid={+true} id="col-detail-container" className="px-0 mx-0">
      <Card className="p-3" id="col-detail-card">
        <Row id="col-back-to-col-row" className="pb-4">
          <ColDetailBackToCol
            listingStatus={listingStatus}
            colName={colName}
            profile={name}
            walletProvider={walletProvider}
          />
        </Row>

        {nftDetails ? (
          <>
            <Row id="col-media-row" className="mb-2">
              <div id="col-media-col">
                <ColDetailMediaContent listingStatus={listingStatus} />
              </div>
            </Row>

            <Row>
              <Col id="col-detail-left-col" xs="12" lg="6">
                <Row className="">
                  <ColDetailNameAndCol listingStatus={listingStatus} />
                </Row>
                <Row className="my-2 mb-4">
                  <ColDetailBelowName listingStatus={listingStatus} />
                </Row>
                {/* detail acc lg view */}
                <Row className="d-none d-lg-block">
                  <ColDetailAccordion listingStatus={listingStatus} />
                </Row>
              </Col>

              <Col id="col-detail-right-col" xs="12" lg="6">
                {/* Own item options */}
                {listingStatus.owner.ownItem && (
                  <ColDetailOwnItem
                    listingStatus={listingStatus}
                    colName={colName}
                    openPackClicked={openPackClicked}
                    setOpenPackClicked={setOpenPackClicked}
                    showSalesModal={showSalesModal}
                    setShowSalesModal={setShowSalesModal}
                  />
                )}
                {/* sales status catalog */}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailForAuction
                    listingStatus={listingStatus}
                    fulfillClicked={fulfillClicked}
                    setFulfillClicked={setFulfillClicked}
                  />
                )}
                {listingStatus.allCurrentListings.forSale.forSale && (
                  <ColDetailForSale
                    listingStatus={listingStatus}
                    buyNowClicked={buyNowClicked}
                    setBuyNowClicked={setBuyNowClicked}
                  />
                )}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailLatestBids listingStatus={listingStatus} />
                )}

                {/* show if item is listed on find market, flowty, flowty loans or storefront  */}
                {listingStatus.allCurrentListings.forSale.forSale && (
                  <ColDetailAvailableOnFindSale listingStatus={listingStatus} />
                )}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailAvailableOnFindAuction
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.flowty && (
                  <ColDetailAvailableOnFlowty listingStatus={listingStatus} />
                )}
                {listingStatus.allCurrentListings.flowtyRental && (
                  <ColDetailAvailableOnFlowtyRental
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.storefront && (
                  <ColDetailAvailableOnStorefront
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.storefrontV2 && (
                  <ColDetailAvailableOnStorefrontV2
                    listingStatus={listingStatus}
                  />
                )}

                {/* show offers if catalog item */}
                {listingStatus.allowedListingActions &&
                  Object.keys(listingStatus.allowedListingActions).length > 0 &&
                  listingStatus.allowedListingActions !== "none" && (
                    <Row>
                      <Col>
                        <ColDetailOffers listingStatus={listingStatus} />
                      </Col>
                    </Row>
                  )}

                {/* show royalties if catalog item */}
                {Object.keys(listingStatus.itemRoyalties).length > 0 && (
                  <Row>
                    <Col>
                      <ColDetailRoyalties listingStatus={listingStatus} />
                    </Col>
                  </Row>
                )}

                {/* Burn buttons mobile / desktop - currently disabled after the great pie burn of 12/22 */}
                {/* Currently reenabled - after 1700 will be disabled just for partyfavorz */}
                {listingStatus.owner.ownItem &&
                  listingStatus.aCatalogItem &&
                  walletType !== "Dapper Wallet" && (
                    <Row className="d-none d-lg-block">
                      <Col align="center">
                        <ColDetailBurnItem listingStatus={listingStatus} />
                      </Col>
                    </Row>
                  )}
              </Col>

              {listingStatus.owner.ownItem &&
                listingStatus.aCatalogItem &&
                walletType !== "Dapper Wallet" && (
                  <Row className="d-block d-lg-none">
                    <Col align="center">
                      <ColDetailBurnItem listingStatus={listingStatus} />
                    </Col>
                  </Row>
                )}

              {/* detail acc mobile */}
              <Row className="d-block d-lg-none">
                <ColDetailAccordion listingStatus={listingStatus} />
              </Row>
              <Row className="pb-4 ">
                <Col className="">
                  <ColDetailShareIcons />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          // ! we can put a skeleton loading element here
          <Loading />
        )}
      </Card>
    </Container>
  );
}

//pre rebuild reference
// <Container fluid={+true} id="nft-detail-page" className="p-0">
//   {nftDetails && nftDetails !== "first_init" && (
//     <Card className="p-3 detail-nft">
//       <Row className="mb-3">
//         <Col className="d-none d-md-block">
//           {/* {listingStatus && listingStatus.aCatalogItem ? (
//             listingStatus?.itemMediaType === "video" ? (
//               <video
//                 key={imgUrl}
//                 muted
//                 alt={"Picture of " + nftDetails.name}
//                 loop=""
//                 playsInline=""
//                 controls
//                 onMouseOver={(event) => event.target.play()}
//                 onMouseOut={(event) => event.target.pause()}
//               >
//                 <source
//                   src={listingStatus?.itemMediaDisplay + "#t=0.5"}
//                   type="video/mp4"
//                 ></source>
//                 Sorry this video is not supported by your browser
//               </video>
//             ) : (
//               (listingStatus?.itemMediaType === "" ||
//                 listingStatus?.itemMediaType.includes("image") ||
//                 listingStatus?.itemMediaType.includes("thumbnail")) &&
//               // <Card.Img src={imgUrl} key={imgUrl} alt={"Picture of " + nftDetails.name} />
//               lightBox()
//             )
//           ) : //shard
//           listingStatus?.itemMediaType === "video" ? (
//             <video
//               key={imgUrl}
//               muted
//               alt={"Picture of " + nftDetails.name}
//               loop=""
//               playsInline=""
//               controls
//               onMouseOver={(event) => event.target.play()}
//               onMouseOut={(event) => event.target.pause()}
//             >
//               <source
//                 src={listingStatus?.itemMediaDisplay + "#t=0.5"}
//                 type="video/mp4"
//               ></source>
//               Sorry this video is not supported by your browser
//             </video>
//           ) : (
//             (listingStatus.itemMediaType === "" ||
//               listingStatus.itemMediaType.includes("image")) &&
//             // <Card.Img src={imgUrl} key={imgUrl} alt={"Picture of " + nftDetails.name} />
//             lightBox()
//           )} */}

//           {/* {nftDetails.mediaType.includes("text") && (
//             <div className="titletxt text-nft-wrapper-detail">
//               <div className="text-nft-detail m-auto">
//                 {nftDetails.media}
//               </div>
//             </div>
//           )} */}
//         </Col>
//         <Col>
//           <Row className="h-100 align-content-start">
//             <Row className=" my-2">
//               <Col className="align-items-center justify-content-center">
//                 {listingStatus.aCatalogItem ? (
//                   <Link to={"/mp/" + listingStatus.itemCollection}>
//                     <Image
//                       style={{
//                         height: "2rem",
//                         width: "2rem",
//                         marginRight: "0.5rem",
//                       }}
//                       fluid={+true}
//                       src="/assets/img/marketplace/verify1.png"
//                     />
//                     <span className="collection-name">
//                       {listingStatus.itemCollection}
//                     </span>
//                     <span></span>
//                   </Link>
//                 ) : (
//                   <>
//                     <Image
//                       style={{
//                         height: "2rem",
//                         width: "2rem",
//                         marginRight: "0.5rem",
//                       }}
//                       fluid={+true}
//                       src="/assets/img/marketplace/verify1.png"
//                     />
//                     <span className="collection-name">
//                       {listingStatus.itemCollection}
//                     </span>
//                   </>
//                 )}
//               </Col>
//             </Row>
//             {/* <Col xs="12" className="">
//               <h3>
//                 {listingStatus.itemName
//                   ? listingStatus.itemName
//                   : listingStatus.itemCollection +
//                     " " +
//                     listingStatus.itemEdition}{" "}
//                 {listingStatus.itemEditionMax && (
//                   <> / {listingStatus.itemEditionMax}</>
//                 )}
//               </h3>
//             </Col> */}
//             {/* <Col xs="12" className="d-block d-md-none mb-2">
//               {lightBox()}
//             </Col> */}

//             <Col className="" xs="12">
//               <p className="detail-nft-description">
//                 {listingStatus.itemDescription
//                   ? listingStatus.itemDescription
//                   : "This NFT has no description metadata."}
//               </p>
//             </Col>

//             {/* open find packs */}
//             {colName === "FindPack" &&
//               listingStatus.listingDetails.nftDetail &&
//               listingStatus.owner.ownItem && (
//                 <Col className="py-3">
//                   <Button
//                     className="w-100"
//                     variant="find-nochange-dark"
//                     onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
//                   >
//                     <small>Open pack</small>
//                   </Button>
//                   <Modal
//                     show={bl0xModalOpen}
//                     onHide={() => setBl0xModalOpen(!bl0xModalOpen)}
//                     centered
//                     data-theme={themeStatus}
//                   >
//                     <FindPackModal
//                       imgUrl={imgUrl}
//                       bl0xModalOpen={bl0xModalOpen}
//                       setBl0xModalOpen={setBl0xModalOpen}
//                       listingStatus={listingStatus}
//                       toastStatus={toastStatus}
//                       // openPackClicked={openPackClicked}
//                       // setOpenPackClicked={setOpenPackClicked}
//                     />
//                   </Modal>
//                 </Col>
//               )}

//             {/* open blox packs */}
//             {(colName === "bl0xPack" || colName === "Bl0xPack") &&
//               listingStatus.listingDetails.nftDetail &&
//               listingStatus.owner.ownItem && (
//                 <Col className="py-3">
//                   <Button
//                     className="w-100"
//                     variant="find-nochange-dark"
//                     onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
//                   >
//                     <small>Open pack</small>
//                   </Button>
//                   <Modal
//                     show={bl0xModalOpen}
//                     onHide={() => setBl0xModalOpen(!bl0xModalOpen)}
//                     centered
//                     data-theme={themeStatus}
//                   >
//                     <Bl0xModal
//                       imgUrl={imgUrl}
//                       bl0xModalOpen={bl0xModalOpen}
//                       setBl0xModalOpen={setBl0xModalOpen}
//                       listingStatus={listingStatus}
//                       toastStatus={toastStatus}
//                       openPackClicked={openPackClicked}
//                       setOpenPackClicked={setOpenPackClicked}
//                     />
//                   </Modal>
//                 </Col>
//               )}

//             <Col xs="12">
//               {listingStatus.allCurrentListings.forAuction.forAuction && (
//                 <MakeBidNft
//                   listingDetails={
//                     listingStatus.listingDetails.findMarket
//                       .FindMarketAuctionEscrow
//                   }
//                   type={type}
//                   findUser={findUser}
//                   nftDetails={nftDetails}
//                   user={user}
//                   imgUrl={imgUrl}
//                 />
//               )}
//               {listingStatus.allCurrentListings.forSale.forSale && (
//                 <Row className="my-4 px-1">
//                   <Col>Price:</Col>
//                   <Col align="right">
//                     <b>
//                       {listingStatus.listingDetails.findMarket
//                         .FindMarketSale?.amount * 1}{" "}
//                       {
//                         listingStatus.listingDetails.findMarket
//                           .FindMarketSale?.ftAlias
//                       }
//                     </b>
//                   </Col>
//                 </Row>
//               )}
//             </Col>

//             <Col xs="12" className="">
//               <fieldset disabled={formStatus}>
//                 <CustomToggle eventKey={nft}>
//                   {type === "owner" || listingStatus.ownItem === true
//                     ? "Sell or Auction"
//                     : "Make offer"}
//                 </CustomToggle>
//               </fieldset>

//               {/* sell modal no refresh */}
//               <Modal
//                 show={showSalesModal}
//                 onHide={handleShowSalesModal}
//                 centered
//                 data-theme={themeStatus}
//               >
//                 <Modal.Header
//                   style={{ border: "none" }}
//                   closeButton
//                   closeLabel="close window"
//                 >
//                   <Modal.Title className="text-center w-100">
//                     <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
//                       List {nftDetails.name} for sale or auction
//                     </h4>
//                   </Modal.Title>
//                 </Modal.Header>
//                 <ModalBody>
//                   <ListNft
//                     findUser={findUser}
//                     imgUrl={imgUrl}
//                     nftDetails={nftDetails.nftDetail}
//                     listingDetails={listingStatus.listingDetails}
//                   />
//                 </ModalBody>
//               </Modal>

//               {/* send NFT no refresh */}
//               <Modal
//                 show={showSendNFTModal}
//                 onHide={handleSendNFTModal}
//                 centered
//                 data-theme={themeStatus}
//               >
//                 <Modal.Header
//                   style={{ border: "none" }}
//                   closeButton
//                   closeLabel="close window"
//                 >
//                   <Modal.Title className="text-center w-100">
//                     <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
//                       Send {listingStatus.itemCollection} -&nbsp;
//                       {listingStatus.itemName}
//                     </h4>
//                   </Modal.Title>
//                 </Modal.Header>
//                 <ModalBody>
//                   <Form.Group>
//                     <Form.Label>Receiver Address</Form.Label>
//                     <Form.Control
//                       name="receiver"
//                       placeholder="Enter a wallet address or .find name"
//                       type="receiver"
//                       onChange={(e) => setSendNFTAddress(e.target.value)}
//                     ></Form.Control>
//                   </Form.Group>

//                   <Form.Group className="mt-3">
//                     <Form.Label>Message</Form.Label>
//                     <Form.Control
//                       name="memo"
//                       placeholder="Enter a message"
//                       type="memo"
//                       onChange={(e) => setSendNFTMemo(e.target.value)}
//                     ></Form.Control>
//                   </Form.Group>
//                   <Col align="center" className="mt-4">
//                     <Button
//                       type="submit"
//                       variant="find-outline-commerce-dark"
//                       disabled={sendNFTAddress.length <= 1}
//                       onClick={sendNFT}
//                     >
//                       Send
//                     </Button>
//                   </Col>
//                 </ModalBody>
//               </Modal>

//               {/* offer modal no refresh */}
//               <Modal
//                 show={showOfferModal}
//                 onHide={handleShowOfferModal}
//                 centered
//                 data-theme={themeStatus}
//               >
//                 <Modal.Header
//                   style={{ border: "none" }}
//                   closeButton
//                   closeLabel="close window"
//                 >
//                   {nftDetails.nftDetail && (
//                     <Modal.Title className="text-center w-100">
//                       <h4
//                         className="fw-bold"
//                         style={{ marginBottom: "0px" }}
//                       >
//                         Make an offer for{" "}
//                         {nftDetails.nftDetail.name
//                           ? nftDetails.nftDetail.name
//                           : nftDetails.nftDetail.collection +
//                             " #" +
//                             nftDetails.id}
//                       </h4>
//                     </Modal.Title>
//                   )}
//                 </Modal.Header>
//                 <ModalBody>
//                   <MakeOfferNftModal
//                     listingDetails={listingStatus.listingDetails}
//                     findUser={findUser}
//                     nftDetails={nftDetails.nftDetail}
//                     formStatus={formStatus}
//                     imgUrl={imgUrl}
//                   />
//                 </ModalBody>
//               </Modal>
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       {listingStatus.forAuction ||
//         (listingStatus.forSale && (
//           <Row className="px-3 pb-3 align-items-center">
//             <Col>
//               <h4>Available on:</h4>
//             </Col>
//           </Row>
//         ))}

//       {listingStatus.allCurrentListings.forSale.forSale && (
//         <AvailableOnFindCard
//           listingDetails={
//             listingStatus.listingDetails.findMarket.FindMarketSale
//           }
//           nftDetails={nftDetails}
//         />
//       )}
//       {listingStatus.allCurrentListings.forAuction.forAuction && (
//         <AvailableOnFindCard
//           listingDetails={
//             listingStatus.listingDetails.findMarket.FindMarketAuctionEscrow
//           }
//           nftDetails={nftDetails}
//         />
//       )}

//       <Row className="mb-3">
//         <Col>
//           <Accordion id="detail-acc" defaultActiveKey="0">
//             <Accordion.Item eventKey="0">
//               <Accordion.Header>
//                 <h4>Properties</h4>
//               </Accordion.Header>
//               <Accordion.Body>
//                 <DetailsPropertyCard
//                   listingDetails={listingDetails}
//                   nftDetails={nftDetails}
//                   listingStatus={listingStatus}
//                   searchedAddress={searchedAddress}
//                 />
//               </Accordion.Body>
//             </Accordion.Item>
//             <div className="seperator100 my-3"></div>

//             <Accordion.Item eventKey="2">
//               <Accordion.Header>
//                 <h4>History</h4>
//               </Accordion.Header>
//               <Accordion.Body>
//                 <DetailsItemHistory
//                   nftDetails={nftDetails}
//                   listingStatus={listingStatus}
//                 />
//               </Accordion.Body>
//             </Accordion.Item>
//             <div className="seperator100 my-3"></div>

//             <Accordion.Item eventKey="3">
//               <Accordion.Header>
//                 <h4>Offers</h4>
//               </Accordion.Header>
//               <Accordion.Body>
//                 <DetailsOffersMade
//                   nftDetails={nftDetails}
//                   listingDetails={listingDetails}
//                   isOffered={listingStatus.offered}
//                   isName={false}
//                 />
//               </Accordion.Body>
//             </Accordion.Item>
//           </Accordion>
//         </Col>
//       </Row>
//       <Row className="px-3">
//         <Button variant="find-outline-dark" onClick={() => navigate(-1)}>
//           Go back
//         </Button>
//       </Row>
//     </Card>
//   )}
// </Container>
