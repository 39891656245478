import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useFormStatus } from "../../../functions/DisabledState";
import { useUserProfile } from "../../../functions/useUserProfile";
import LiveFeedThoughtBox from "../../livefeed/LiveFeedThoughtBox";
import { BidsOffersReceived } from "../bidsoffersreceived/bidsoffersreceived";
import { BidsOffersSent } from "../bidsofferssent/bidsofferssent";
import { NamesForAuction } from "../namesforauction/namesforauction";
import { NamesForSale } from "../namesforsale/namesforsale";
import { NamesOwned } from "../namesowned/namesowned";
import { ProfileCollection } from "../profilecollection/profilecollection";
import { ProfileDetails } from "../profiledetails/profiledetails";
import { ProfileLatestTransactions } from "../profilelatesttransactions/profilelatesttransactions";
import ProfileStore from "../profilestore/profilestore";
import ProfileThoughts from "../ProfileThoughts/ProfileThoughts";
import "./profiletabs.css";

export function ProfileTabs({ profileData, type, display, tab }) {
  // //console.log(profileData)
  const [key, setKey] = useState("profile");
  const [ownProfile, setOwnProfile] = useState(false);

  const navigate = useNavigate();
  let { name, tabs } = useParams();
  const user = useUserProfile();

  function handleChangeTab(k) {
    if (type === "owner") {
      navigate("/me/" + k);
    } else navigate("/" + name + "/" + k);
  }

  useEffect(() => {
    if (tab) {
      setKey(tab);
    }
    if (tabs) {
      setKey(tabs);
    }
    if (!tab && !tabs) {
      setKey("profile");
    }
  }, [tab, tabs]);

  useEffect(() => {
    if (user) {
      if (
        type === "owner" ||
        name === user.profile.findName ||
        name === user.profile.address
      ) {
        setOwnProfile(true);
      } else {
        setOwnProfile(false);
      }
    } else {
      setOwnProfile(false);
    }
  }, [type, name, user]);

  return (
    <Container fluid={+true} id="profile-tabs" className="mb-3 px-0">
      <Row>
        <Col className="">
          <Tabs
            id="profile-tabs"
            className="profiletabs"
            activeKey={key}
            onSelect={(k) => handleChangeTab(k)}
          >
            <Tab eventKey="profile" title="Profile" mountOnEnter unmountOnExit>
              <fieldset disabled={useFormStatus()}>
                {ownProfile && (
                  <Card className="mb-3">
                    <LiveFeedThoughtBox user={profileData} />
                  </Card>
                )}
                <ProfileDetails profileData={profileData.profile} allProfileData={profileData}/>
                <ProfileLatestTransactions profileData={profileData.profile} />
              </fieldset>
            </Tab>
            <Tab eventKey="names" title="Names" mountOnEnter unmountOnExit>
              <fieldset disabled={useFormStatus()}>
                <NamesOwned
                  leases={profileData.leases}
                  type={type}
                  defaultLease={profileData?.profile?.findName}
                  profile={profileData.profile}
                />
                <div className="seperator100 my-5"></div>
                <NamesForSale
                  leases={profileData.leases}
                  type={type}
                  profile={profileData.profile}
                />
                <NamesForAuction
                  leases={profileData.leases}
                  type={type}
                  profile={profileData.profile}
                />
                {ownProfile && (
                  <>
                    <BidsOffersReceived
                      leases={profileData.leases}
                      type={type}
                    />
                    <div className="seperator100 my-5"></div>
                    <BidsOffersSent bids={profileData.bids} type={type} />
                  </>
                )}
              </fieldset>
            </Tab>
            <Tab eventKey="collection" title="Collection">
              <ProfileCollection
                profile={profileData.profile}
                type={type}
                display={display}
                profileData={profileData}
              />
            </Tab>
            <Tab eventKey="market" title="Market">
              <fieldset disabled={useFormStatus()}>
                <ProfileStore
                  mountOnEnter
                  unmountOnExit
                  itemsForSale={profileData.itemsForSale}
                  leases={profileData.leases}
                  type={type}
                />
              </fieldset>
            </Tab>
            <Tab eventKey="thoughts" title="Thoughts">
              <fieldset disabled={useFormStatus()}>
                <ProfileThoughts
                  mountOnEnter
                  unmountOnExit
                  profile={profileData}
                  type={type}
                />
              </fieldset>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
