
const getShardScriptName = (shard) => {
  switch (shard) {
    case "Shard1":
      return "getAlchemy1Items";

    case "Shard2":
      return "getAlchemy2Items";

    case "Shard3":
      return "getAlchemy3Items";

    case "Shard4":
      return "getAlchemy4Items";

    case "RaribleNFT":
      return "getSocksItems";

    case "NFTCatalog":
      return "getNFTCatalogItems";


    default:
      return null;
  }
};

export default getShardScriptName;
