import React from "react";
import "./DropPage.scss";
import DropPageMintBoxImage from "./DropPageMintBoxImage";
import DropPageMintBoxMint from "./DropPageMintBoxMint";
import DropPageMintBoxSplash from "./DropPageMintBoxSplash";
import DropPageMintBoxTitle from "./DropPageMintBoxTitle";

const DropPageMintBox = ({
  packDetails,
  numOfPacks,
  purchasePack,
  setNumOfPacks,
}) => {
  return (
    <div id="drop-page-mint-box-wrapper">
      <div id="drop-page-mint-box-content-container">
        <DropPageMintBoxImage packDetails={packDetails} />

        <DropPageMintBoxTitle />

        <DropPageMintBoxMint
          packDetails={packDetails}
          numOfPacks={numOfPacks}
          purchasePack={purchasePack}
          setNumOfPacks={setNumOfPacks}
        />
        <DropPageMintBoxSplash />
      </div>
    </div>
  );
};

export default DropPageMintBox;
