import React from "react";
import { Nav } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "./navhead.css";

const NavheadMobileLinks = ({ expanded, setExpanded }) => {
  return (
    <Nav className="" id="mainLinks">
      <Nav.Link
        onClick={() => setExpanded(false)}
        as={Link}
        to={"/lf"}
        className="ms-lg-3 my-2 mobile-link"
      >
        Live Feed
      </Nav.Link>
      <Nav.Link
        onClick={() => setExpanded(false)}
        as={Link}
        to={"/mp"}
        className="ms-lg-3 my-2 mobile-link"
      >
        Marketplace
      </Nav.Link>
         {/* Explore gone til redesign/build - Linear 789 */}

      {/* <Nav.Link
        onClick={() => setExpanded(false)}
        as={Link}
        to={"/p/explore"}
        className="ms-lg-3 my-2 mobile-link"
      >
        Explore
      </Nav.Link> */}
      <Nav.Link
        //<Nav.Link> is default
        //active false as external link does not deactivate
        active={false}
        href={"https://forms.gle/tHRvdELPrEBAVdkP9"}
        className="ms-lg-3 my-2 mobile-link"
        target="_blank"
        rel="noreferrer"
      >
        Launchpad <BoxArrowUpRight className="mx-1 mb-2" />
      </Nav.Link>
    </Nav>
  );
};

export default NavheadMobileLinks;
