import axios from "axios";

import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import DetailsItemHistoryCard from "./detailsitemhistorycard";

const DetailsItemHistory = ({
  nftDetails,
  isName,
  listingDetails,
  listingStatus,
  hit,
}) => {
  const [eventsData, setEventsData] = useState("");
  const [nftId, setNftId] = useState();
  // const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  // const nftId = nftDetails.nftDetail ? nftDetails.nftDetail.id :  nftDetails.id ? nftDetails.id : nftDetails.type;
  const itemName = nftDetails.name ? nftDetails.name : nftDetails.nft_name;

  useEffect(() => {
    if (hit) {
      setNftId(nftDetails.uuid);
    } else {
      if (nftDetails.nftDetail) {
        setNftId(nftDetails.nftDetail.uuid);
      } else {
        setNftId(nftDetails.uuid);
      }
    }
  }, [hit, nftDetails]);

  useEffect(() => {
    const getEvents = async () => {
      if (!isName) {
        // //console.log("getSales fired")
        let data;
        let res = await axios.get(
          "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?" +
            "&id=" +
            nftId +
            "&pageSize=10"
        );
        data = res.data;
        setEventsData(data);
      } else {
        let data;
        let res = await axios.get(
          "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?" +
            "&name=" +
            itemName +
            "&pageSize=10"
        );
        data = res.data;
        setEventsData(data);
      }
    };
    getEvents();
  }, [nftDetails, listingDetails, listingStatus, hit]);

  // console.log(eventsData);

  //   useEffect(() => {
  //     async function getProfile(addr) {
  //       const profile = await Script(scripts.getProfile, { user: addr });
  //       setProfile(profile);
  //     }
  //     try {
  //       getProfile(activity.blockEventData.buyer);
  //     } catch (error) {
  //       //console.log(error);
  //     }

  //   // eslint-disable-next-line
  // }, [activity]);

  return (
    <>
      {eventsData && eventsData.length > 0 ? (
        eventsData.map((activity, i) => {
          // console.log(activity);

          return <DetailsItemHistoryCard i={i} activity={activity} key={i} />;
        })
      ) : (
        <Row className="align-items-center justify-content-center ">
          <Col>
            <Card
              className="align-items-start justify-content-center "
              style={{
                backgroundColor: "var(--background-profile)",
                width: "100%",
              }}
            >
              <Card.Body>
                <Col>No recent history</Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DetailsItemHistory;
