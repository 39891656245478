import  { useEffect, useState } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import EventsSwitch from '../../../functions/EventsSwitch';
import { Script } from '../../../functions/script';
import { scripts } from "@findonflow/find-flow-contracts";
import parseNameLink from '../../../functions/parseNameLink';
import FlowFtSticker from '../../forms/ftstickers/flowftsticker';
import FusdFtSticker from '../../forms/ftstickers/fusdftsticker';
import "./mpdetailpage.css";



const MpDetailsOffersCard = ({activity, i}) => {
  const [updatedProfile, setUpdatedProfile] = useState(null);
  let profileAddress = activity.blockEventData.buyer ? activity.blockEventData.buyer : activity.blockEventData.bidder;
  let switchedHistory = EventsSwitch(activity);

  useEffect(() => {
    if (profileAddress) {
      // console.log(profileAddress)
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(profileAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [activity]);

  if (switchedHistory && activity) {
    // console.log(activity)
    return (
      <Row
        key={i}
        style={{
          height: "80px",
        }}
        className=" align-items-center my-1"
      >
        <Col>
          <Card
            className=" latestOffersCard px-1 py-0"
            style={{
              width: "100%",
            }}
          >
            <Row className="align-items-center">
              <Col id="history-img-col" className="" xs="2">
                <Image
                crossOrigin='anonymous'
                  className=""
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                  src={
                    updatedProfile
                      ? updatedProfile.avatar
                      : activity.blockEventData.buyerAvatar
                      ? activity.blockEventData.buyerAvatar
                      : "/assets/img/store/fnamestore.png"
                  }
                />{" "}
              </Col>
              <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
                <Row>{updatedProfile ? parseNameLink(updatedProfile) : null}</Row>
                <Row>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(activity.eventDate).toLocaleDateString()} -{" "}
                    {new Date(activity.eventDate).toLocaleTimeString()}{" "}
                  </span>
                </Row>
              </Col>
              <Col align="right" xs="3" md="3" lg="3" xl="3">
                <div className="flex-row">
                  <h3 className="mb-0">
                    {activity.blockEventData.amount * 1}{" "}
                  </h3>
                  &nbsp;
                  {switchedHistory.txFtType === "Flow" ? (
                    <FlowFtSticker noBg={'tertiary'} />
                  ) : (
                    <FusdFtSticker noBg={'tertiary'} />
                  )}
                </div>
              </Col>
              <Col xs="2" md="1" lg="2" xl="2" align="right">
                <a
                  href={
                    process.env.REACT_APP_BLOCK_VIEWER_URL +
                    activity.flowTransactionId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-arrow-right linkIcon" />{" "}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}



export default MpDetailsOffersCard