import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import parseImgUrl from "../../../../functions/parseImgUrl";
import "./collectionnft.scss";
import truncateItemName from "../../../../functions/truncateItemName";
import CollectionNFTMedia from "./CollectionNFTMedia";
import CollectionNFTLower from "./CollectionNFTLower";
import { useEffect, useState } from "react";

export function CollectionNft({
  nftData,
  idkey,
  name,
  object,
  collection,
  link,
  pfp,
  onSaleNFT,
  onAuctionNFT,
  activeTabKey,
}) {
  const [mediaType, setMediaType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [itemCollection, setItemCollection] = useState(null);
  const [collectionUrl, setCollectionUrl] = useState(null);

  const navigate = useNavigate();

  //useeffect to set mediatype and url
  useEffect(() => {
    if (nftData) {
      // console.log({nftData})
      //set media and type
      if (
        nftData.mediaType.includes("video") ||
        nftData.media.includes(".mp4")
      ) {
        setMediaType("video");
        setMediaUrl(parseImgUrl(nftData.media, 'fullSize'));
      } else if (nftData.mediaType.includes("text")) {
        setMediaType("image");
        setMediaUrl(parseImgUrl(nftData.image, 'thumbnail'));
      } else {
        setMediaType("image");
        setMediaUrl(parseImgUrl(nftData.media, 'thumbnail'));
      }
      //set item name and collection
      setItemName(
        truncateItemName(
          nftData.name ? nftData.name : nftData.collection + " #" + nftData.id,
          30
        )
      );
      setItemCollection(
        nftData.subCollection ? nftData.subCollection : nftData.collection
      );

      //set link for item detail page
      //bug here for MintStoreItem.DwayneWade collection
      let nftDetailId = nftData?.nftDetailIdentifier;
      // console.log(nftDetailId)
      if (nftDetailId && nftDetailId.includes(".") && nftDetailId.split(".")[0] ==='A') {
        nftDetailId = nftDetailId
          .split(".")[2]
          .replace("NFGv3", "NonFunGerbils");
      }

      if (name) {
        // url = url + name + '/collection/' + walletProviderLink + '/'
        setCollectionUrl(
          `/${name}/collection/${activeTabKey}/${nftDetailId}/${object.id}`
        );
      } else {
        setCollectionUrl(
          `/me/collection/${activeTabKey}/${nftDetailId}/${object.id}`
        );
      }
    }
    // console.log(nftData);
  }, [nftData]);

  // // console.log(nftData);
  const handleCollectionNavigate = () => {
    navigate(collectionUrl);
  };

  if (nftData && itemName && mediaType && mediaUrl) {
    return (
      <div onClick={handleCollectionNavigate} className="collection-nft-wrapper">
        <CollectionNFTMedia
          nftData={nftData}
          itemName={itemName}
          itemCollection={itemCollection}
          mediaUrl={mediaUrl}
          mediaType={mediaType}
          onSaleNFT={onSaleNFT}
          onAuctionNFT={onAuctionNFT}
        />
        <CollectionNFTLower
          itemName={itemName}
          itemCollection={itemCollection}
        />
      </div>
    );
  } else return null;
}
