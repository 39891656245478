import React from 'react'
import { NamesForAuction } from '../namesforauction/namesforauction'
import { NamesForSale } from '../namesforsale/namesforsale'
import NftsForSale from '../nftsforsale/nftsforsale'

const ProfileStore = ({leases, type, itemsForSale}) => {
  return (
      <>
   <NamesForSale leases={leases} type={type}/>
   <NamesForAuction leases={leases} type={type}/>
   <NftsForSale itemsForSale={itemsForSale} type={type}/>
   </>
  )
}

export default ProfileStore