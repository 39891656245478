import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLatestEvent } from "../../../../functions/LatestEvent";
import parseImgUrl from "../../../../functions/parseImgUrl";
import Bl0xLoading from "../../../loading/bl0xloading";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../../../../functions/script";
import ReactGA from "react-ga4";
import "./findpackmodal.scss";
import { useStateChanged } from "../../../../functions/DisabledState";

const FindPackModal = ({
  imgUrl,
  bl0xModalOpen,
  setBl0xModalOpen,
  listingStatus,
}) => {
  const [newImgUrl, setNewImgUrl] = useState();
  const [newItemName, setNewItemName] = useState();
  //   const [newItemRarity, setNewItemRarity] = useState();
  const [eventReceived, setEventReceived] = useState();
  const [openPackClicked, setOpenPackClicked] = useState(false);
  const [packTypeName, setPackTypeName] = useState();
  const [openedPackId, setOpenedPackId] = useState();
  const [txSuccess, setTxSuccess] = useState(null);
  const stateChanged = useStateChanged();
  const navigate = useNavigate();

  let packId;
  if (listingStatus.listingDetails.nftDetail) {
    packId = parseInt(listingStatus.listingDetails.nftDetail.id);
  }
  //console.log(packId);
  const clickHandleOpenBl0xPack = (e) => {
    e.preventDefault();
    // console.log("modal");
    // console.log(openPackClicked);
    setOpenPackClicked(true);
    handleOpenBl0xPack(packId);
  };

  const handleOpenBl0xPack = async (value) => {
    try {
      await Tx({
        tx: transactions.openFindPack,
        args: {
          packId: value,
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            ReactGA.event({
              category: "Find Pack Opened",
              action: "User Find Pack Opened",
              label: "FindPack",
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const latestEvent = useLatestEvent();

  useEffect(() => {
    // console.log(latestEvent);
    if (latestEvent) {
      if (
        latestEvent.flowEventId === "A.35717efbbce11c74.FindPack.PackReveal" ||
        latestEvent.flowEventId === "A.097bafa4e0b48eef.FindPack.PackReveal"
      ) {
        if (latestEvent.blockEventData.packId === parseInt(packId)) {
          console.log("find pack item received.");
          //console.log(latestEvent);
          setEventReceived(true);
          setPackTypeName(latestEvent.blockEventData.rewardFields.packType);
          setNewImgUrl(
            parseImgUrl(
              latestEvent.blockEventData.rewardFields.nftImage.replaceAll(
                " ",
                ""
              ), 'thumbnail'
            )
          );
          setNewItemName(latestEvent.blockEventData.rewardFields.nftName);
          //   setNewItemRarity(latestEvent.blockEventData.nftRarity);
          setOpenedPackId(latestEvent.blockEventData.rewardId);
        }
      }
    }
  }, [latestEvent]);

  useEffect(() => {
    if (openPackClicked && stateChanged) {
      setTxSuccess(true);
    }
  }, [stateChanged]);

  //   useEffect(() => {
  //     if (openPackClicked && !bl0xModalOpen) {
  //       setOpenPackClicked(false);
  //     }
  //   }, [bl0xModalOpen]);

  // console.log(toastStatus);
  return (
    <Modal.Body id="find-pack-modal-wrapper">
      {openPackClicked &&
        (openPackClicked && !eventReceived ? (
          <Row>
            <Col align="center">
              <h3 id="pack-open-title" className="fw-bold themeColor">
                Opening Pack...
              </h3>
            </Col>
            {txSuccess && (
              <Col xs="12" align="center">
                <span id="pack-open-id" className="fw-bold themeColor">
                  Nearly there, hold on!
                </span>
              </Col>
            )}
          </Row>
        ) : (
          <>
            <Row>
              <Col xs="12" align="center">
                <h3 id="pack-open-title" className="fw-bold themeColor">
                  {newItemName}
                </h3>
              </Col>
              <Col xs="12" align="center">
                <span id="pack-open-id" className="fw-bold themeColor">
                  #{openedPackId}
                </span>
              </Col>
            </Row>
            {/* <Row>
              <Col align="center">
                <span>{newItemRarity}</span>
              </Col>
            </Row> */}
          </>
        ))}
      <Row style={{ height: "50vh" }} className="pb-4">
        <Col className="flex-center">
          {!openPackClicked ? (
            <Image crossOrigin="anonymous" id="pack-img" fluid={+true} src={ imgUrl} />
          ) : openPackClicked && !eventReceived ? (
            <Image
              id="pack-img-loading"
              fluid={+true}
              src="/assets/img/DropPage/drop-info-1.jpeg"
            />
          ) : openPackClicked && eventReceived ? (
            <Image crossOrigin="anonymous" id="pack-img" fluid={+true} src={parseImgUrl(newImgUrl, 'thumbnail')} />
          ) : null}
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          {!openPackClicked && (
            <Button
              onClick={(e) => clickHandleOpenBl0xPack(e)}
              className="w-100"
              variant="find-nochange-dark"
            >
              <small>Open pack</small>
            </Button>
          )}
        </Col>
      </Row>
      <Row className="pb-3">
        <Col>
          {!eventReceived && !openPackClicked ? (
            <Button
              onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
              className="w-100"
              variant="find-dark"
            >
              <small>Go back</small>
            </Button>
          ) : !eventReceived && openPackClicked ? null : (
            <a href={"/me/collection/main/" + packTypeName + "/" + openedPackId}>
              <Button
                // onClick={() =>
                //   navigate("/me/collection/" + packTypeName + "/" + openedPackId)
                // }
                className="w-100"
                variant="find-dark"
              >
                <small>View item</small>
              </Button>
            </a>
          )}
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default FindPackModal;
