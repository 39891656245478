import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import { useWalletType } from "../../../../../functions/useWalletType";
import ColDetailListItem from "./ColDetailListItem";

const ColDetailOwnItemSaleOptions = ({
  listingStatus,
  showSalesModal,
  setShowSalesModal,
}) => {
  const themeStatus = UseThemeStatus();
  const ftType = listingStatus?.FindMarketSale?.ftAlias[0]
  const walletType = useWalletType();

  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      setShowSalesModal(false);
    },
    ...config,
  });
  console.log(listingStatus)
  return (
    <>
      <Button
        onClick={() => setShowSalesModal(!showSalesModal)}
        variant="find-collection-outline"
      >
        Sell {!ftType==="DUC" && "/ Auction"}
      </Button>
      <Modal
        show={showSalesModal}
        onHide={() => setShowSalesModal(!showSalesModal)}
        centered
        data-theme={themeStatus}
      >
                <div {...handlers}>

        <Modal.Header
          style={{ border: "none" }}
          closeButton
          closeLabel="close window"
        >
          {/* <Modal.Title className="text-center w-100">
            <h4 className="fw-bold pb-0" style={{ marginBottom: "0px" }}>
              Sell or list {listingStatus.itemName}
            </h4>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <ColDetailListItem
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
            listingStatus={listingStatus}
          />
          {/* <ListNft
              findUser={findUser}
              imgUrl={imgUrl}
              nftDetails={nftDetails.nftDetail}
              listingDetails={listingStatus.listingDetails}
            /> */}
        </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ColDetailOwnItemSaleOptions;
