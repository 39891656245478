import React from "react";
import { Button } from "react-bootstrap";
import { handleCancelNFTSale } from "../../../../../functions/txfunctions";
import { useWalletType } from "../../../../../functions/useWalletType";


const ColDetailRemoveListing = ({ saleDetails }) => {
  const wallet = useWalletType()
  return (
    <Button
      onClick={() =>
        handleCancelNFTSale(saleDetails.listingId, wallet)
      }
      variant="find-collection-outline-red"
    >
      Remove
    </Button>
  );
};

export default ColDetailRemoveListing;
