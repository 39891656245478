import React from "react";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import CustomHitsCarousel from "../../search/CustomHitsCarousel/CustomHitsCarousel";

const MoreFromCollection = ({ isName, colName, detail }) => {

// console.log(colName)

  const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
  const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;
  
  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "nft_name, nft_alias, seller_name, seller",
    },
  });
  const searchClient2 = typesenseInstantsearchAdapter.searchClient;

  return (
    <>
      <InstantSearch indexName="market" searchClient={searchClient2}>
        <Configure
          hitsPerPage={10}
          facetFilters={["nft_alias:" + colName]}
        />
        <CustomHitsCarousel detail={detail} moreFromCollection={true}/>

        {/* {eventsData && eventsData.length > 0 ? (
          eventsData.map((activity, i) => {
            // console.log(activity);

            return (
              <MoreFromCollectionCard isName={isName} nftDetail={activity} />
            );
          })
        ) : (
          <Row className="align-items-center justify-content-center ">
            <Col>
              <Card
                className="align-items-start justify-content-center "
                style={{
                  backgroundColor: "var(--background-profile)",
                  width: "100%",
                }}
              >
                <Card.Body>
                  <Col>No items from this collection found</Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )} */}
        {/* <div className="d-flex align-items-center justify-content-center pb-2">
          <Link to={/mp/ + nftCollection}>
            <Button variant="find-dark">View Collection</Button>
          </Link>
        </div> */}
      </InstantSearch>
    </>
  );
};

export default MoreFromCollection;
