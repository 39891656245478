import React from "react";
import { Col, DropdownButton, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavheadRegister from "./navheadregister";
import NavheadThemeToggle from "./navheadthemetoggle";
import NavheadWalletDropdown from "./navheadwalletdropdown";
import "./navhead.css";
import parseImgUrl from "../../functions/parseImgUrl";

const NavheadMainTopEnd = ({
  user,
  profileStatus,
  switchTheme,
  profile,
  lostAndFoundItems,
}) => {
  return (
    <div id="lgmenu" className="p-3 p-lg-0 mx-auto d-none d-lg-block">
      {user.loggedIn &&
        (profileStatus.profile === "found" ? (
          <Row className="align-items-center">
            <Col>
              <NavheadThemeToggle switchTheme={switchTheme} />
            </Col>
            <Col>
              <div id="lAndFContainer">
                <Link to={"/lost-and-found"}>
                  <i className="fa-solid fa-envelope secColor" />
                </Link>
                {lostAndFoundItems && <span id="lAndFNotify">{lostAndFoundItems}</span>}
              </div>
            </Col>
            <Col className="v-seperator"></Col>
            <Col className="profileMenuPic">
              {profileStatus.profile && profile.avatar && (
                <Link to="/me">
                  <Image crossOrigin="anonymous" src={parseImgUrl(profile.avatar, 'icon')} />
                </Link>
              )}
            </Col>
            <Col>
              <DropdownButton
                align="end"
                title={profile.name}
                id="dropdown-menu-align-end"
                data-toggle="dropdown"
              >
                <NavheadWalletDropdown user={user} profile={profile} />
              </DropdownButton>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <Col>
              <NavheadThemeToggle switchTheme={switchTheme} />
            </Col>
            <Col className="v-seperator"></Col>
            <Col className="profileMenuPic">
              <Link to="/me"></Link>
            </Col>
            <Col>
              <DropdownButton
                title={user.addr}
                variant="find"
                data-toggle="dropdown"
              >
                <NavheadRegister user={user} />
              </DropdownButton>
            </Col>{" "}
          </Row>
        ))}
    </div>
  );
};

export default NavheadMainTopEnd;
