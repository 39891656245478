import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLatestEvent } from "../../../../functions/LatestEvent";
import parseImgUrl from "../../../../functions/parseImgUrl";
import { handleOpenBl0xPack } from "../../../../functions/txfunctions";
import Bl0xLoading from "../../../loading/bl0xloading";

const Bl0xModal = ({
  imgUrl,
  bl0xModalOpen,
  setBl0xModalOpen,
  listingStatus,
  openPackClicked,
  setOpenPackClicked
}) => {
  const [newImgUrl, setNewImgUrl] = useState();
  const [newItemName, setNewItemName] = useState();
  const [newItemRarity, setNewItemRarity] = useState();
  const [eventReceived, setEventReceived] = useState();

  const navigate = useNavigate();

  let packId;
  if (listingStatus.listingDetails.nftDetail) {
    packId = parseInt(listingStatus.listingDetails.nftDetail.id);
  }
  //console.log(packId)
  const clickHandleOpenBl0xPack = (e) => {
    e.preventDefault();
    setOpenPackClicked(true);
    handleOpenBl0xPack(packId);
  };

  const latestEvent = useLatestEvent();
  
  useEffect(() => {
    //console.log(latestEvent)
    if (latestEvent){
      if (
        latestEvent.flowEventId === "A.e8124d8428980aa6.Bl0xPack.PackReveal" ||
        latestEvent.flowEventId === "A.7620acf6d7f2468a.Bl0xPack.PackReveal"
      ) {
        if (latestEvent.blockEventData.packId === parseInt(packId)) {
          //console.log("bl0x item received.")
          setEventReceived(true);
          setNewImgUrl(parseImgUrl(latestEvent.blockEventData.nftImage, 'thumbnail'));
          setNewItemName(latestEvent.blockEventData.nftName);
          setNewItemRarity(latestEvent.blockEventData.nftRarity)
   
        }
      }
  }}, [latestEvent]);


  useEffect(() =>{
    if (openPackClicked && !bl0xModalOpen){
      setOpenPackClicked(false)
  }}, [bl0xModalOpen])

  // console.log(toastStatus);
  return (
    <Modal.Body>
      {openPackClicked &&
        (openPackClicked && !eventReceived ? (
          <Row>
            <Col align="center">
              <h3 className="fw-bold themeColor">Opening Pack...</h3>
            </Col>
          </Row>
        ) : (
          <>
          <Row>
            <Col align="center">
              <h3 className="fw-bold themeColor">{newItemName}</h3>
            </Col>
          </Row>
          <Row>
            <Col align='center'>
              <span>{newItemRarity}</span>
            </Col>
          </Row>
          </>
        ))}
      <Row style={{ height: "50vh" }} className="py-4">
        <Col className="flex-center">
          {!openPackClicked ? (
            <Image crossOrigin="anonymous" fluid={+true} src={imgUrl} />
          ) : openPackClicked && !eventReceived ? (
            <Bl0xLoading />
          ) : openPackClicked && eventReceived ? (
            <Image crossOrigin="anonymous" fluid={+true} src={newImgUrl} />
          ) : null}
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          {!openPackClicked && (
            <Button
              onClick={(e) => clickHandleOpenBl0xPack(e)}
              className="w-100"
              variant="find-nochange-dark"
            >
              <small>Open pack</small>
            </Button>
          )}
        </Col>
      </Row>
      <Row className="pb-3">
        <Col>
          {!eventReceived ? (
            <Button
              onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
              className="w-100"
              variant="find-dark"
            >
              <small>Go back</small>
            </Button>
          ) : (
            <Button
              onClick={() => navigate("me/collection/main/bl0x/" + packId)}
              className="w-100"
              variant="find-dark"
            >
              <small>View item</small>
            </Button>
          )}
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default Bl0xModal;
