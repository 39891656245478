import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import { Tx } from "../../../functions/script";
import "./registernamebox.css";
import ReactGA from "react-ga";
import { transactions } from "@findonflow/find-flow-contracts";
import { useNavigate } from "react-router";
import { useWalletType } from "../../../functions/useWalletType";

const RegisterNameBox = ({ profileData, searchedName }) => {
  let navigate = useNavigate();

  const walletType = useWalletType();

  const handleRegister = async (e) => {
    e.preventDefault();
    // console.log(searchedName, parseFloat(profileData.cost).toFixed(2), walletType);
    // console.log(process.env.REACT_APP_MERCHANT_ACCOUNT)
    if (walletType === "Dapper Wallet") {
      try {
        await Tx({
          tx: transactions.registerDapper,
          args: {
            merchAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
            name: searchedName,
            amount: parseFloat(profileData.cost).toFixed(2),
          },
          callbacks: {
            async onSuccess(status) {
              //console.log("success");
              ReactGA.event({
                category: "Commerce",
                action: "Name Registered - Dapper",
                label: "Purchase",
              });
              navigate("/me/names");
              window.scrollTo(0, 0);
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    } else
      try {
        await Tx({
          tx: transactions.register,
          args: {
            name: searchedName,
            amount: parseFloat(profileData.cost).toFixed(2),
          },
          callbacks: {
            async onSuccess(status) {
              //console.log("success");
              ReactGA.event({
                category: "Commerce",
                action: "Name Registered",
                label: "Purchase",
              });
              navigate("/me/names");
              window.scrollTo(0, 0);
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
  };

  return (
    <Container
      id="register-name-box"
      className="d-flex flex-column align-items-center justify-content-center p-3 pt-5 pb-5"
    >
      <Row>
        <Col>
          <Row className="pb-5">
            <Col align="center">
              {/* this needs to show profileData name */}
              <h1 className="dark-text">
                Woohoo! <span className="themeColor">{searchedName}</span>.find
                is <span className="theme-accent-text">available</span>
              </h1>
            </Col>
          </Row>

          <fieldset
            disabled={useFormStatus()}
            id="a"
            align="center"
            className="pb-5"
          >
            <div>
              <span
                className="fw-bold m-3 me-4 align-middle"
                style={{ fontSize: "26px" }}
              >
                {profileData.cost * 1} FUSD
              </span>
              <Button
                onClick={handleRegister}
                text="Confirm"
                variant="find-nochange-dark"
              >
                Register
              </Button>
            </div>
          </fieldset>

          <Row>
            <Col align="center">
              <div className="disclaimer">
                The use of this service and the names available are subject to
                our terms of service.
                <br /> Any unauthorised use of names is not the responsibility
                of find.xyz - we want everybody to play nicely!
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterNameBox;
