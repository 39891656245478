import { useEffect, useState } from "react";
import { useStateChanged } from "./DisabledState";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "./script";

const network = process.env.REACT_APP_NETWORK;

const useGetFactoryCollections = (address) => {
  const [collectionItems, setCollectionItems] = useState();
  const [collectionCounter, setCollectionCounter] = useState(1);
  const [getFinished, setGetFinished] = useState(false)

  useEffect(() => {
    async function getUserCollections(addr) {
      // here we will rename to getAlcehmy1IDs for example
      if (network === "mainnet") {
        const collectionResponse1 = await Script(scripts.getAlchemy4IDs, {
          user: addr,
          collections: [],
        });
        setCollectionItems((collectionItems) => {
          setCollectionCounter((collectionCounter) => collectionCounter + 1);
          return { ...collectionItems, ...collectionResponse1 };
        });
      }

      const collectionResponse2 = await Script(scripts.getAlchemy1IDs, {
        user: addr,
        collections: [],
      });
      if (network === "mainnet") {
        const collectionResponse3 = await Script(scripts.getAlchemy2IDs, {
          user: addr,
          collections: [],
        });
        const collectionResponse4 = await Script(scripts.getAlchemy3IDs, {
          user: addr,
          collections: [],
        });
        setCollectionItems((collectionItems) => {
          setCollectionCounter((collectionCounter) => collectionCounter + 1);

          return { ...collectionItems, ...collectionResponse3 };
        });
        setCollectionItems((collectionItems) => {
          setCollectionCounter((collectionCounter) => collectionCounter + 1);

          return { ...collectionItems, ...collectionResponse4 };
        });
      }

      const collectionResponse5 = await Script(scripts.getSocksIDs, {
        user: addr,
        collections: [],
      });
      const collectionResponse6 = await Script(scripts.getNFTCatalogIDs, {
        user: addr,
        collections: [],
      });

      setCollectionItems((collectionItems) => {
        setCollectionCounter((collectionCounter) => collectionCounter + 1);

        return { ...collectionItems, ...collectionResponse2 };
      });
      setCollectionItems((collectionItems) => {
        setCollectionCounter((collectionCounter) => collectionCounter + 1);

        return { ...collectionItems, ...collectionResponse5 };
      });
      setCollectionItems((collectionItems) => {
        setCollectionCounter((collectionCounter) => collectionCounter + 1);

        return { ...collectionItems, ...collectionResponse6 };
      });

      // console.log(collectionResponse1)
      // console.log(collectionResponse2)
      // console.log(collectionResponse5);
    }
    try {
      getUserCollections(address);
      // console.log(collectionItems)
    } catch (error) {
      //console.log(error);
    }

  

    // eslint-disable-next-line
  }, [useStateChanged()]);

// console.log(collectionItems)
// console.log(getFinished)
 

useEffect(()=>{
  if ((network === "testnet" && collectionCounter === 4) || (network === "mainnet" && collectionCounter === 7)){
    setGetFinished(true)
  }
}, [collectionCounter])


  return {
    collectionItems,
    getFinished,
  };
};

export default useGetFactoryCollections;
