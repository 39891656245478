import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Accordion,
  useAccordionButton,
  Modal,
  ModalBody,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { UseThemeStatus } from "../../../functions/themeMode";
import { MakeOfferName } from "../../forms/buying/makeoffername/makeoffername";
import { ListName } from "../../forms/selling/listname/listname";
import "./namesowned.css";
import "../profile.css";
import { handleExtend, SetMainName } from "../../../functions/txfunctions";
import TransferName from "../transfername/transfername";
import TimeAgo from "react-timeago";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import { useParams } from "react-router";

export function NamesOwned({ leases, type, defaultLease, profile }) {
  const [show, setShow] = useState(null);
  const [ownProfile, setOwnProfile] = useState(false);
  const handleClose = (id) => setShow(id);
  const handleShow = (id) => setShow(id);
  const themeStatus = UseThemeStatus();
  const currentDate = (new Date().getTime() / 1000).toFixed(0);
  const walletType = useWalletType();
  const user = useUserWalletStatus();

  // const toastStatus = useToastTxStatus();
  // console.log(toastStatus)

  useEffect(() => {
    if (user.addr === profile.address) {
      setOwnProfile(true);
    }
  }, [user, profile]);

  const renderTooltipComingSoon = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Coming soon</span>
    </Tooltip>
  );

  const disabledStyle = { opacity: "0.65" };

  function CustomToggle({ children, eventKey, lease, manage }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    if (ownProfile && !manage) {
      return (
        <>
          {walletType === "Dapper Wallet" ? (
            <OverlayTrigger placement="top" overlay={renderTooltipComingSoon}>
              <Button style={disabledStyle} variant="find-outline-commerce">
                Sell name
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant="find-outline-commerce"
              onClick={() => handleShow(eventKey)}
            >
              Sell name
            </Button>
          )}
        </>
      );
    } else
      return (
        <>
          <div className="d-none d-lg-block">
            {manage ? (
              <Button variant="find-text" size="sm" onClick={decoratedOnClick}>
                {children}
              </Button>
            ) : (
              <>
                {walletType === "Dapper Wallet" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltipComingSoon}
                  >
                    <Button
                      style={disabledStyle}
                      variant="find-outline-commerce"
                      size="sm"
                      // onClick={decoratedOnClick}
                    >
                      {children}
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <Button
                    variant="find-outline-commerce"
                    size="sm"
                    onClick={decoratedOnClick}
                  >
                    {children}
                  </Button>
                )}
              </>
            )}
          </div>

          {walletType !== "Dapper Wallet" && (
            <div className="d-lg-none">
              <Button
                onClick={decoratedOnClick}
                style={{
                  backgroundImage: "var(--accordion-chevron)",
                  width: "26px",
                  height: "26px",
                  backgroundColor: "var(--background)",
                  borderColor: "var(--background)",
                  boxShadow: "none",
                }}
              ></Button>
            </div>
          )}
        </>
      );
  }

  return (
    <Container fluid={+true} id="names-owned" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names owned</h4>
            </Col>
          </Row>
          {leases.length !== 0 ? (
            <div id="names-owned-accordion">
              <Accordion>
                {leases.map((lease, i) => {
                  let cost = 5.0;
                  if (lease.name.length === 3) {
                    cost = 500.0;
                  } else if (lease.name.length === 4) {
                    cost = 100.0;
                  }

                  let expiresIn = lease.validUntil * 1 - currentDate * 1;
                  let loomingExpiry = false;
                  let expired = false;
                  if (expiresIn <= 0) {
                    expired = true;
                  }
                  if (expiresIn <= 2592000 && expiresIn > 0) {
                    loomingExpiry = true;
                  }

                  return (
                    <Card key={i}>
                      <Card.Header>
                        <Row className="align-items-center">
                          <Col xs="12" md="auto">
                            <span className="fw-bold">
                              <span className="themeColor">{lease.name}</span>
                              .find
                            </span>
                          </Col>

                          {/* //sticker col for expiry status */}
                          <Col>
                            {lease.name === defaultLease ? (
                              <span className="profile-main-name-sticker">
                                Main name
                              </span>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {expired && (
                              <span className="profile-expired-sticker">
                                Expired{" "}
                                <TimeAgo
                                  date={new Date(lease.validUntil * 1000)}
                                  timestyle="twitter"
                                  suffix="none"
                                />
                              </span>
                            )}
                            {loomingExpiry && (
                              <span className="profile-expires-in-sticker">
                                Expires{" "}
                                <TimeAgo
                                  date={new Date(lease.validUntil * 1000)}
                                  timestyle="twitter"
                                  suffix="none"
                                />
                              </span>
                            )}
                          </Col>

                          <Col xs="auto" md="auto" className="ms-auto">
                            {ownProfile && (
                              <CustomToggle
                                eventKey={i + "manage"}
                                lease={lease}
                                manage={true}
                              >
                                Manage
                              </CustomToggle>
                            )}
                          </Col>
                          {/* CustomToggle here opens toggle for Make offer (if loggedin and viewing another's profile -> study and apply to make bid )  */}
                          <Col xs="auto" className="">
                            <CustomToggle eventKey={i} lease={lease}>
                              {ownProfile ? "Sell name" : "Make offer"}
                            </CustomToggle>
                            <Modal
                              id="list-name-modal"
                              show={show === i ? true : false}
                              onHide={handleClose}
                              centered
                              data-theme={themeStatus}
                            >
                              <Modal.Header
                                className="noBorder"
                                closeButton
                                closeLabel="close window"
                              >
                                <Modal.Title className="text-center w-100 noBorder">
                                  <h3
                                    className="fw-bold"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    List{" "}
                                    <span style={{ color: "var(--theme-main" }}>
                                      {lease.name}
                                    </span>
                                    .find for sale or auction
                                  </h3>
                                </Modal.Title>
                              </Modal.Header>
                              <ModalBody>
                                <ListName lease={lease} />
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                          <div className="collapse show" id="collapseExample">
                            {ownProfile ? (
                              <ListName lease={lease} profile={profile} />
                            ) : (
                              <MakeOfferName lease={lease} />
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Accordion.Collapse eventKey={i + "manage"}>
                        <Card.Body>
                          <div className="collapse show" id="collapseExample">
                            {ownProfile && (
                              <div className="commerceinfo p-3">
                                <Row>
                                  <Col>
                                    <div className="fw-bold">
                                      Manage{" "}
                                      <span className="themeColor">
                                        {lease.name}
                                      </span>
                                      .find
                                    </div>
                                    <small>
                                      Here you can extend your lease, make this
                                      your default name or transfer your name to
                                      another .find profile
                                    </small>
                                  </Col>
                                </Row>
                                <div className="seperator100 my-3"></div>
                                <Row>
                                  <Col>Extend Lease</Col>
                                </Row>
                                <Row className="align-items-center">
                                  <Col xs="auto">
                                    <div className="valid-for ms-auto">
                                      <img
                                        src="/assets/img/profile/namesowned/tick-circle.png"
                                        alt="green tick"
                                        width="16"
                                        height="16"
                                      />
                                      <span style={{ color: "black" }}>
                                        Will be valid until{" "}
                                        {new Date(
                                          Number(
                                            lease.validUntil * 1000 +
                                              31536000000
                                          )
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col xs="auto" className="ms-auto fw-bold">
                                    {cost} FUSD
                                  </Col>
                                  <Col
                                    xs="auto"
                                    className="mt-3 mt-md-auto ms-auto"
                                  >
                                    <Button
                                      variant="find-outline-commerce"
                                      onClick={() =>
                                        handleExtend(
                                          lease.name,
                                          cost,
                                          walletType
                                        )
                                      }
                                    >
                                      Extend
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="seperator100 my-3"></div>
                                <Row className="align-items-center">
                                  <Col>
                                    Make{" "}
                                    <b>
                                      <span className="themeColor">
                                        {lease.name}
                                      </span>
                                      .find
                                    </b>{" "}
                                    your main name
                                  </Col>
                                  <Col xs="auto" className="ms-auto">
                                    <Button
                                      variant="find-outline-commerce"
                                      onClick={() => SetMainName(lease.name)}
                                    >
                                      Default
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="seperator100 my-3"></div>
                                <TransferName lease={lease} />
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </div>
          ) : (
            <div>
              <small>No names, sorry.</small>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
