import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import DetailsItemHistoryCard from '../detailsitemhistory/detailsitemhistorycard';

const ColDetailHistory = ({listingStatus}) => {
  const [historyData, setHistoryData] = useState(null)

  useEffect(() => {
    if (listingStatus.itemUuid){
    const getEvents = async () => {
        // //console.log("getSales fired")
        let data;
        let res = await axios.get(
          "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?" +
            "&id=" +
            listingStatus.itemUuid +
            "&pageSize=10"
        );
        data = res.data;
        setHistoryData(data);
      } 
    
      getEvents();}
  }, [listingStatus.itemUuid]);

  return (
    <>
    {historyData && historyData.length > 0 ? (
      historyData.map((activity, i) => {
        return <DetailsItemHistoryCard i={i} activity={activity} key={i} />;
      })
    ) : (
      <Row className="align-items-center justify-content-center ">
        <Col>
          <Card
            className="align-items-start justify-content-center "
            style={{
              backgroundColor: "var(--background-profile)",
              width: "100%",
            }}
          >
            <Card.Body>
              <Col>No recent history</Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )}
  </>
  )
}

export default ColDetailHistory