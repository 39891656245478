import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../functions/script";
import { useFormStatus } from "../../../functions/DisabledState";
import "./createprofile.scss";
import { handleRegisterName } from "../../../functions/txfunctions";

const CreateProfileFindNameInput = ({
  formValues,
  setFormValues,
  validation,
  walletType,
}) => {
  const [findNameResponse, setFindNameResponse] = useState(null);
  const [findNameAvailable, setFindNameAvailable] = useState(null);
  const [findNameCost, setFindNameCost] = useState(null);

  // console.log(validation);

  function updateField(e) {
    setFormValues(e.target.value);
  }

  async function getFindName(searchName) {
    const findName = await Script(scripts.getStatus, {
      user: searchName.toLowerCase(),
    });
    setFindNameResponse(findName);
  }

  useEffect(() => {
    // console.log(findNameResponse);
    if (findNameResponse) {
      setFindNameAvailable(
        findNameResponse.NameReport.status === "FREE"
          ? "available"
          : "unavailable"
      );
      setFindNameCost(findNameResponse.NameReport.cost * 1);
    }
  }, [findNameResponse]);

  useEffect(() => {
    // console.log(validation)
    setFindNameResponse(null);
    setFindNameAvailable(null);
    setFindNameCost(null);
    if (validation && validation.status === "valid" && formValues.length > 2) {
      getFindName(formValues);
    }
  }, [formValues && validation]);

  const submitFindName = (e) => {
    e.preventDefault();
    handleRegisterName(formValues.toLowerCase(), findNameCost, walletType);
  };

  return (
    <div id="create-profile-input-wrapper">
      <Form onSubmit={submitFindName}>
        <fieldset disabled={useFormStatus()}>
          <Row>
            <Col id="validation-error-wrapper">
              {validation.status === "invalid" ? (
                <span className="validation-error-invalid">
                  {validation.error}
                </span>
              ) : validation.error === "none" ? (
                <span className="validation-error-valid">
                  All good <i className="fa-solid fa-check" />
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Form.Control
                onChange={updateField}
                type="text"
                placeholder="Your .find name"
                maxLength={13}
                className={
                  validation.status === null
                    ? ""
                    : validation.status === "valid"
                    ? "valid"
                    : "invalid"
                }
              ></Form.Control>
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              {validation && validation.status === "valid" ? (
                <div id="find-name-status-wrapper">
                  <span>
                    {findNameAvailable && findNameAvailable === "available" && (
                      <img src="assets/img/profile/createprofile/name-valid-icon.svg" />
                    )}
                    <span id="find-name-status-status">
                      <span id="find-name-status-name">
                        <span className="themeColor">{formValues.toLowerCase()}</span>.find
                      </span>
                      {findNameAvailable && " is " + findNameAvailable}
                    </span>
                  </span>
                  <span id="find-name-status-cost">
                    {findNameCost &&
                      findNameAvailable === "available" &&
                      findNameCost + " FUSD"}
                  </span>
                </div>
              ) : (
                <div id="find-name-status-wrapper">&nbsp;</div>
              )}
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <Button id="create-profile-input-button" type="submit">
                Register
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default CreateProfileFindNameInput;
