import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useImmer } from "use-immer";
import { AuthCluster } from "../../../auth-cluster";
import { useFormStatus } from "../../../functions/DisabledState";
import { handleProfile } from "../../../functions/txfunctions";
import { useWalletType } from "../../../functions/useWalletType";
import "./createprofile.scss";
import CreateProfileExploreFind from "./CreateProfileExploreFind";
import CreateProfileForm from "./CreateProfileForm";
import CreateProfileRegisterFindName from "./CreateProfileRegisterFindName";
import CreateProfileStageBar from "./CreateProfileStageBar";
import CreateProfileStages from "./CreateProfileStages";
import { Helmet } from "react-helmet";

const CreateProfile = ({ user, profileCurrentStage, findUser }) => {
  const [formValues, setFormValues] = useImmer("");
  const [currentStage, setCurrentStage] = useState(null);
  const [validation, setValidation] = useState({ status: null, error: null });
  const formStatus = useFormStatus();
  const walletType = useWalletType();

  useEffect(() => {
    setCurrentStage(profileCurrentStage);
  }, [profileCurrentStage]);

  //validate form values onchange via regex
  useEffect(() => {
    // console.log(formValues);
    //waiting for feedback on profile name length/characters
    const profileName = /[]/;
    const isValidProfileName = profileName.test(
      String(formValues).toLowerCase()
    );

    const validProfileNameError = "Profile names must be between 3-64 chars";

    const findName = /^(?!\s)[A-Za-z0-9-\-s]+$/;
    const isValidFindName = findName.test(String(formValues).toLowerCase());
    const validFindNameError =
      ".find names must be between 3-13 chars letters or numbers";

    if (currentStage === 2) {
      //validate profile name
      if (!formValues) {
        setValidation({ status: null, error: null });
      } else if (formValues.length > 2 && formValues.length < 64) {
        setValidation({ ...validation, status: "valid", error: "none" });
      } else {
        setValidation({
          ...validation,
          status: "invalid",
          error: validProfileNameError,
        });
      }
    } else if (currentStage === 3) {
      //validate findname
      if (!formValues) {
        setValidation({ status: null, error: null });
      } else if (
        isValidFindName &&
        formValues.length > 2 &&
        formValues.length < 14
      ) {
        setValidation({ ...validation, status: "valid", error: "none" });
      } else {
        setValidation({
          ...validation,
          status: "invalid",
          error: validFindNameError,
        });
      }
    }
  }, [formValues]);

  //reset form value and validation on stage change
  useEffect(() => {
    setFormValues("");
    setValidation({ status: null, error: null });
  }, [currentStage]);

  return (
    <div id="create-profile-wrapper" className="">
      <Helmet>
        <title>Create profile / .find</title>
        <meta name="description" content="The .find profile creation page" />
      </Helmet>
      <Row>
        <Col
          xs="0"
          lg="3"
          id="create-profile-left-col"
          className="d-none d-lg-flex"
        >
          <CreateProfileStages currentStage={currentStage} />
        </Col>
        <Col xs="12" lg="9" id="create-profile-right-col">
          {currentStage === 2 ? (
            <CreateProfileForm
              formValues={formValues}
              setFormValues={setFormValues}
              validation={validation}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              walletType={walletType}
            />
          ) : currentStage === 3 ? (
            <CreateProfileRegisterFindName
              formValues={formValues}
              setFormValues={setFormValues}
              validation={validation}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              walletType={walletType}
              user={user}
            />
          ) : currentStage === 4 ? (
            <CreateProfileExploreFind
              currentStage={currentStage}
              user={user}
              findUser={findUser}
            />
          ) : null}

          <CreateProfileStageBar
            setCurrentStage={setCurrentStage}
            currentStage={currentStage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateProfile;

{
  /* <div className="create-wrapper form-inputs">
        <Row>
          <Col>
            <Row>
              <Col align="center">
                <h1>Choose a profile name</h1>
                <p
                  className="mt-3 mt-md-5 m-auto fw-bold"
                  style={{ color: "var(--text-secondary)" }}
                >
                  Assign a profile name to create your .find profile and view
                  your Flow NFT collections. <br />
                  You can always change this at a later date and link it to a
                  .find name of your choosing.
                  <br /> Creating a profile is free of charge.
                </p>
              </Col>
            </Row>
            <Row
              className="mb-md-5 mt-md-5 mb-3 mt-4 justify-content-center"
              xs={1}
              md={1}
            >
              <Col className="mb-2">
                <Form onSubmit={submitProfile}>
                  <fieldset id="a" disabled={formStatus}>
                    <Col
                      xs="12"
                      md="12"
                      className="mb-3 px-3 mb-md-0 formInputs mx-auto"
                    >
                      <Form.Group align="center">
                        <Form.Control
                          className="w-50"
                          required
                          placeholder="Your name"
                          onChange={updateField}
                          maxLength="16"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" className="mx-auto mt-3 px-4" align="center">
                      <Button variant="find" className="w-50" type="submit">
                        Create
                      </Button>
                    </Col>
                    <Col xs="12" className="mx-auto mt-3" align="center">
                      <div align="center">
                        <AuthCluster user={user} />
                      </div>
                    </Col>
                  </fieldset>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col align="center"></Col>
            </Row>
          </Col>
        </Row>
      </div> */
}
// <Container
//   id="create-profile-container"
//   fluid={+true}
//   className="p-5"
//   align="center"
// >
//   <Card className="create-profile-box">
//     <Form onSubmit={submitProfile}>
//       <Row className="p-2">
//         <Col className="d-flex justify-content-center text-center titletxt fw-bold">
//           You've connected your wallet but don't have a profile yet.
//         </Col>
//       </Row>
//       <Row className="m-1 p-2 mb-3">
//         <Col className="d-flex justify-content-center text-center">
//           No problem, just click below to create your profile. It's FREE.
//         </Col>
//       </Row>
//       <Row>
// <fieldset id="a" disabled={formStatus}>
//   <Col
//     xs="12"
//     md="12"
//     className="mb-3 px-3 mb-md-0 formInputs mx-auto"
//   >
//     <Form.Group align="center">
//       <Form.Label>Enter a name</Form.Label>
//       <Form.Control
//         className="w-50"
//         required
//         placeholder="Your name"
//         onChange={updateField}
//       />
//     </Form.Group>
//   </Col>
//   <Col xs="12" className="mx-auto mt-3 px-4" align="center">
//     <Button variant="find" className="w-50" type="submit">
//       Create
//     </Button>
//   </Col>
//   <Col xs="12" className="mx-auto mt-3" align="center">
//     <div align="center">
//       <AuthCluster user={user} />
//     </div>
//   </Col>
// </fieldset>
//       </Row>
//     </Form>
//   </Card>
// </Container>
