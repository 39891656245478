import React from "react";
import { Image } from "react-bootstrap";
import "./ftstickers.css";

const FusdFtSticker = ({noBg}) => {
  return (
    <div className="sticker-box">
      <span className={noBg === 'true' ? 'sticker-span-no-bg' : noBg === 'tertiary' ? 'sticker-span-tertiary' : 'sticker-span'}>
        <div>
          <Image
            className="sticker-image"
            src="/assets/img/stickerlogos/fusd-sticker-logo.jpeg"
          />
        </div>
        &nbsp;
        <span className="ft-span secColor">FUSD</span>
      </span>
    </div>
  );
};

export default FusdFtSticker;
