import { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {  useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import CollectionPage from "../components/marketplace/collectionpage/CollectionPage";

const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: typesenseHostUrl,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nft_name, collection_name, seller_name, seller",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;
////console.log(searchClient)

function Collection({ user, findUser }) {
  const [indexName, setIndexName] = useState("market");

  const { colName } = useParams();

  return (
    <Container
      fluid={+true}
      className="pb-5"
      style={{
        backgroundColor: "var(--background-profile)",
        minHeight: "calc(100vh - 218px)",
      }}
      id="collection-page"
    >
      <Helmet>
          <title>
            {colName} / .find
          </title>
          <meta
            name="description"
            content={"The" + {colName} + " collection page on the .find marketplace" }
          />
        </Helmet>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          hitsPerPage={18}
          facetFilters={["collection_alias:" + colName, "listing_alias:-DirectOffer"]}

        />

        <Container id='collection-container' className="content-container">
          <Col className="py-2" xs="12" lg="auto">
            <Breadcrumbs />
            <CollectionPage colName={colName} />
          </Col>
        </Container>
      </InstantSearch>
    </Container>
  );
}

export default Collection;
