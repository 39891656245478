import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthCluster } from "../../auth-cluster";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

const NavheadWalletDropdown = ({ user, profile }) => {
  function runCopy(copyData) {
    copy(copyData);
    toast(<span align="center">{copyData} copied to clipboard</span>, {
      duration: 2000,
      style: {},
    });
  }
  return (
    <>
      {" "}
      <div className="p-2 fw-bold" style={{ fontSize: "20px" }}>
        {profile.name ? profile.name : user.addr}'s Wallet
      </div>
      <div
        className="p-2"
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={() => runCopy(user.addr)}
      >
        {user.addr} <i className="copyicon fa fa-copy"></i>
      </div>
      {profile.wallets &&
        profile.wallets.map((wallet, i) => {
          if (wallet.name !== "DUC" && wallet.name !== "FUT")
            return (
              <Row key={i}>
                <Col className="mx-2" style={{ textTransform: "uppercase" }}>
                  {wallet.name}:
                </Col>
                <Col>
                  <b>{parseFloat(wallet.balance).toFixed(4)}</b>
                </Col>
              </Row>
            );
        })}
      <div></div>
      <Dropdown.Divider />
      <Dropdown.Item as={Link} to={"/me/settings"} className="p-5">
        Settings
      </Dropdown.Item>
      <Dropdown.Item as={Link} to={"/p/contact"}>
        Contact Us
      </Dropdown.Item>
      <Dropdown.Divider />
      <div align="center" className="mx-4">
        <AuthCluster user={user} />
      </div>
    </>
  );
};

export default NavheadWalletDropdown;
