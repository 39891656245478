import React from "react";
import { Col, Container, DropdownButton, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthCluster } from "../../auth-cluster";
import { MobileProfileUserBar } from "../profile/profileuserbar/mobileprofileuserbar";
import NavheadMobileLinks from "./navheadmobilelinks";
import NavheadThemeToggle from "./navheadthemetoggle";
import NavheadWalletDropdown from "./navheadwalletdropdown";
import { useSwipeable } from "react-swipeable";

const NavheadDropdown = ({
  user,
  profileStatus,
  switchTheme,
  profile,
  expanded,
  setExpanded,
  lostAndFoundItems
}) => {
  const config = {
    delta: 100, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250

  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setExpanded(false);
    },
    ...config,
  });

  // console.log(profileStatus);
  return user.loggedIn && profileStatus.profile ? (
    <Container  {...handlers} fluid={+true} className="p-1 py-3  d-lg-none">
      <Container className="dropdown-container">
        <div className="d-flex align-items-center px-2 pb-2">
          <div xs="auto" style={{marginRight:'auto'}} >
            {profileStatus.profile === "found" && (
              <DropdownButton
                align="end"
                title={profile.name}
                id="dropdown-menu-align-end"
                data-toggle="dropdown"
              >
                <NavheadWalletDropdown user={user} profile={profile} />
              </DropdownButton>
            )}
          </div>
          <div>
            <NavheadThemeToggle switchTheme={switchTheme} />
          </div>&nbsp;&nbsp;
          <div id="lAndFContainer">
                <Link to={"/lost-and-found"}>
                  <i className="fa-solid fa-envelope secColor" />
                </Link>
              {lostAndFoundItems && <span id="lAndFNotify">{lostAndFoundItems}</span>}
              </div>&nbsp;&nbsp;
          
          
        </div>
        <Row className="">
          <Col xs="auto">
            <MobileProfileUserBar />
          </Col>
        </Row>
      </Container>
    </Container>
  ) : (
    <Container  {...handlers} fluid={+true} className="p-1 py-3  d-lg-none">
      <Container className="dropdown-container">
        <Row className="align-items-center px-2 pb-2">
          <Col xs="10" align="start"></Col>
          <Col align="end">
            <NavheadThemeToggle switchTheme={switchTheme} />
          </Col>
        </Row>
        <NavheadMobileLinks expanded={expanded} setExpanded={setExpanded} />
        <Row className="justify-content-center dropdown-auth">
          <Col align="center">
            <AuthCluster user={user} />
          </Col>
        </Row>{" "}
      </Container>
    </Container>
  );
};

export default NavheadDropdown;
