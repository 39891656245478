import React from "react";
import { useParams } from "react-router";
import "./DropPage.scss";

const DropPageMintBoxTitle = () => {
  const { packId } = useParams();
  return (
    <div id="drop-page-mint-box-title-wrapper">
      <div>
        <span id="mint-box-subtitle-span">Drop {packId}</span>
      </div>
      <div>
        <span id="mint-box-title-span">Party Favors X Artist DRE</span>
      </div>
      <div>
        <span id="mint-box-subtitle-span">
          Connect your wallet to collect some awesome NFTs
        </span>
      </div>
    </div>
  );
};

export default DropPageMintBoxTitle;
