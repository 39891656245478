import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import {
  handleCancelNFTAuction,
  handleFulfillMarketAuctionEscrowedNFT,
} from "../../../../functions/txfunctions";

const ReviewOwnAuctionNftStore = ({ filteredBid, ended }) => {
  // console.log(ended);
  // console.log(filteredBid)
  return (
    <Container className="commerceinfo p-3">
      <Row>
        <Col>
          <p className="fw-bold">Auction Details for {filteredBid.nft.name}.</p>
          <div>
            <small>
              Starting Bid: &nbsp;
              <b>
                {parseFloat(filteredBid.auction.startPrice).toFixed(2)}{" "}
                {filteredBid.ftAlias}
              </b>
            </small>
          </div>
          <div>
            <small>
              {ended &&
              parseFloat(filteredBid.auction.currentPrice).toFixed(2) >=
                parseFloat(filteredBid.auction.reservePrice).toFixed(2)
                ? "Winning Bid: "
                : "Current Bid: "}
              {!filteredBid.bidder ? (
                <b>
                  {parseFloat(filteredBid.auction.startPrice).toFixed(2)}{" "}
                  {filteredBid.ftAlias}
                </b>
              ) : (
                <b>
                  {parseFloat(filteredBid.auction.currentPrice).toFixed(2)}{" "}
                  {filteredBid.ftAlias}
                </b>
              )}
            </small>
          </div>
          <div>
            <small>
              Reserve Price: &nbsp;
              <b>
                {" "}
                {parseFloat(filteredBid.auction.reservePrice).toFixed(2)}{" "}
                {filteredBid.ftAlias}
              </b>
            </small>
          </div>
          <div>
            <small>
              Auction {!ended ? "ends:" : "ended:"} &nbsp;
              <b>
                <ReactTimeago
                  date={new Date(filteredBid.auction.auctionEndsAt * 1000)}
                  timestyle="twitter"
                />
              </b>{" "}
              {}
            </small>
          </div>

          <div>
            <small>
              Highest Bidder: &nbsp;
              <b>{filteredBid.bidderName}.find</b>
            </small>
          </div>
        </Col>
      </Row>

      {!ended && (
        <Row>
          <Col align="right">
            <Button
              onClick={() => handleCancelNFTAuction(filteredBid.listingId)}
              variant="find-outline-commerce-red"
            >
              Remove
            </Button>
          </Col>
        </Row>
      )}

      {ended && filteredBid.amount >= filteredBid.auction.reservePrice && (
        <Row>
          <Col align="right">
            <Button
              variant="find-outline-commerce"
              onClick={() =>
                handleFulfillMarketAuctionEscrowedNFT(
                  filteredBid.listingId,
                  filteredBid.seller
                )
              }
            >
              Fulfill Auction
            </Button>
          </Col>
        </Row>
      )}

      {ended &&
        filteredBid.auction.reservePrice > filteredBid.auction.currentPrice && (
          <>
            <Row>
              <Col>
                <small>Your auction ended below the reserve price</small>
              </Col>
            </Row>
            <Row>
              <Col align="right">
                <Button
                  variant="find-outline-commerce-red"
                  onClick={() =>
                    handleFulfillMarketAuctionEscrowedNFT(
                      filteredBid.listingId,
                      filteredBid.seller
                    )
                  }
                >
                  End Auction
                </Button>
              </Col>
            </Row>
          </>
        )}
    </Container>
  );
};

export default ReviewOwnAuctionNftStore;

// onClick={() => handleFullfillAuction(filteredBid.name, filteredBid.address)}
