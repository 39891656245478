import { Container } from "react-bootstrap"
// import TermsDoc from '../Documents/Document.pdf';
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import terms from '../documents/terms.pdf'




const Terms = ({findUser, metaTitle, metaDescription}) => {


    if (findUser) {
        return (
            <>
                <Container
                    fluid={+true}
                    id="terms-wrapper"
                    style={{
                        backgroundColor: "var(--background-profile)",
                        minHeight: "calc(100vh - 218px)",
                    }}
                >
                    <Helmet>
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDescription} />
                    </Helmet>
                    <Container className="content-container pt-3">
                    <h3>Terms</h3>
                       <p>You can chcek out the .find terms of use <a href={terms} target="_blank" rel="noreferrer">here.</a></p>
                    </Container>
                </Container>
            </>
        );
    } else {
        return (
            <>
                <Breadcrumbs />
                <Container
                    fluid={+true}
                    id="terms-wrapper"
                    style={{
                        backgroundColor: "var(--background-profile)",
                        minHeight: "calc(100vh - 218px)",
                    }}
                >
                    <Helmet>
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDescription} />
                    </Helmet>
                    <Container className="content-container pt-3">
                    <h3>Terms</h3>
                       <p>You can check out the .find terms of use <a href={terms} target="_blank" rel="noreferrer">here.</a></p>
                    </Container>
                </Container>
            </>
        );
    }




}

export default Terms



