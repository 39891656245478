import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import useToastTxStatus from "../../../../../functions/useToastTxStatus";
import Bl0xModal from "../bl0xmodal";
import FindPackModal from "../findpackmodal";

const ColDetailPackOpen = ({
  listingStatus,
  colName,
  openPackClicked,
  setOpenPackClicked,
}) => {
  const [bl0xModalOpen, setBl0xModalOpen] = useState(false);
  const themeStatus = UseThemeStatus();
  const toastStatus = useToastTxStatus();

  return (
    <>
      <Button
        onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
        variant="find-collection-pack-outline"
        className="mb-3"
      >
        Open Pack
      </Button>
      <Modal
        show={bl0xModalOpen}
        onHide={() => setBl0xModalOpen(!bl0xModalOpen)}
        centered
        data-theme={themeStatus}
      >
        {colName === "Bl0xPack" ? (
          <Bl0xModal
            imgUrl={parseImgUrl(listingStatus.itemThumbnail)}
            bl0xModalOpen={bl0xModalOpen}
            setBl0xModalOpen={setBl0xModalOpen}
            listingStatus={listingStatus}
            toastStatus={toastStatus}
            openPackClicked={openPackClicked}
            setOpenPackClicked={setOpenPackClicked}
          />
        ) : (
          <FindPackModal
            imgUrl={parseImgUrl(listingStatus.itemThumbnail)}
            bl0xModalOpen={bl0xModalOpen}
            setBl0xModalOpen={setBl0xModalOpen}
            listingStatus={listingStatus}
            toastStatus={toastStatus}
            openPackClicked={openPackClicked}
            setOpenPackClicked={setOpenPackClicked}
          />
        )}
      </Modal>
    </>
  );
};

export default ColDetailPackOpen;
