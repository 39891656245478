import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
// import ExploreLatestDrops from "../components/explore/explorelatestdrops";
import ExploreLatestProfiles from "../components/explore/explorelatestprofiles";
import ExploreLiveAuctions from "../components/explore/exploreliveauctions";
// import ExploreUpcomingDrops from "../components/explore/exploreupcomingdrops";
import { JoinDiscord } from "../components/home/joindiscord/joindiscord";
import ExploreLatestListings from "../components/explore/explorelatestlistings";
import ExploreLatestOffers from "../components/explore/explorelatestoffers";
import { InstantSearch } from "react-instantsearch-hooks-web";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import ExploreCollectionCarousel from "../components/explore/explorecollectioncarousel/explorecollectioncarousel";
import { Helmet } from "react-helmet";
import { useUserWalletStatus } from "../functions/useWalletType";

const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: typesenseHostUrl,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nft_name, collection_name, seller_name, seller",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;
// console.log(searchClient);

const Explore = ({ findUser, metaTitle, metaDescription }) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const user = useUserWalletStatus();

  useEffect(() => {
    const triggerResize = async () => {
      await delay(100);
      window.dispatchEvent(new Event("resize"));
    };
    triggerResize();
  }, []);

  if (findUser) {
    return (
      <>
        <Container
          fluid={+true}
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px",
            paddingBottom: "4rem",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="pt-4 ">
              <Col>
                <ExploreCollectionCarousel />
                {/* <ExploreLatestDrops/>
                 <ExploreUpcomingDrops/> */}
                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLiveAuctions user={user} />
                </InstantSearch>
                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLatestListings user={user} />{" "}
                </InstantSearch>

                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLatestOffers user={user} />{" "}
                </InstantSearch>

                <ExploreLatestProfiles findUser={findUser} />
              </Col>
            </Row>
          </Container>
          <Row className="pb-5">
            <JoinDiscord />
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Breadcrumbs />
        <Container
          fluid={+true}
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="justify-content-center pb-5 pt-4">
              <Col>
                <ExploreCollectionCarousel />

                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLiveAuctions />
                </InstantSearch>
                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLatestListings />
                </InstantSearch>

                <InstantSearch indexName="market" searchClient={searchClient}>
                  <ExploreLatestOffers />
                </InstantSearch>

                <ExploreLatestProfiles findUser={findUser} />
              </Col>
            </Row>
          </Container>
          <Row className="pb-5">
            <JoinDiscord />
          </Row>
        </Container>
      </>
    );
  }
};

export default Explore;
