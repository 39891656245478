import React from "react";
import { Button } from "react-bootstrap";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import "./ColDetailOwnItemSendItem.scss";

const ColDetailOwnItemSendItemInfo = ({
  currentStage,
  setCurrentStage,
  listingStatus,
  sendNFTAddress,
  setSendNFTAddress,
  sendNFTMemo,
  setSendNFTMemo,
  handleSendNFTModal,
}) => {
  return (
    <div id="send-item-info-wrapper">
      <div id="send-item-icon-row">
        <img src={parseImgUrl(listingStatus.itemThumbnail)} />{" "}
        <img
          id="send-arrows-img"
          src="/assets/img/profile/send-item/arrows-right.svg"
        />
        <img src="/assets/img/profile/send-item/avatar-blank.svg" />
      </div>
      <div id="send-item-name-row">Send {listingStatus.itemName}</div>
      <div id="send-item-desc-row">
        Enter the .find name or the wallet address of the person you would like
        to send this item to
      </div>
      <div id="send-item-form-wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStage(2);
          }}
          id="send-item-form"
        >
          <label htmlFor="send-to-input">Send to</label>

          <input
            value={sendNFTAddress}
            id="send-to-input"
            label="Send to"
            maxLength="18"
            type='text'
            pattern="[a-zA-Z0-9-.]+"
            onChange={(e) => setSendNFTAddress(e.target.value)}
            placeholder="Enter a wallet or .find name"
          ></input>
          <label htmlFor="send-memo-input">Message (Optional)</label>
          <textarea
            value={sendNFTMemo}
            id="send-memo-input"
            onChange={(e) => setSendNFTMemo(e.target.value)}
            placeholder="Enter a message..."
          ></textarea>

          <div id="send-item-button-row">
            <Button
              variant="find-outline-commerce-light"
              onClick={() => handleSendNFTModal()}
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="find-outline-commerce-dark"
              type="submit"
              disabled={sendNFTAddress.length < 3}
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ColDetailOwnItemSendItemInfo;

{
  /* <div className="flex-center mb-3">
    <Image src={parseImgUrl(listingStatus.itemThumbnail)} />
  </div>
  <Form onSubmit={(e) => sendNFT(e)}>
    <fieldset disabled={useFormStatus()}>
      <Form.Group>
        <Form.Label>Receiver Address</Form.Label>
        <Form.Control
          name="receiver"
          placeholder="Enter a wallet address or .find name"
          type="receiver"
          onChange={(e) => setSendNFTAddress(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="mt-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          name="memo"
          placeholder="Enter a message"
          type="memo"
          onChange={(e) => setSendNFTMemo(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <Col align="center" className="mt-4">
        <Button
          type="submit"
          variant="find-outline-commerce-dark"
          disabled={sendNFTAddress.length <= 1}
          // onClick={sendNFT}
        >
          Send
        </Button>
      </Col>
    </fieldset>
  </Form> */
}
