import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import MpDetailsOffersCard from "../../../../search/mpdetailpage/mpdetailofferscard";
import ColDetailMakeOffer from "../ColDetailMakeOffer/ColDetailMakeOffer";

const ColDetailOffers = ({ listingStatus }) => {
  const [offersEventsData, setOffersEventsData] = useState();
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  useEffect(() => {
    const getEvents = async () => {
      // console.log("getSales fired")
      let data;
      let res = await axios.get(
        "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
          contractAddress +
          ".FindMarketDirectOfferEscrow.DirectOffer&id=" +
          listingStatus.itemId
      );
      data = res.data;
      setOffersEventsData(
        data.filter((event) => event.blockEventData.status === "active_offered")
      );
    };

    getEvents();
  }, [listingStatus]);

  return (
    <Accordion className="my-3">
      <Accordion.Item id="offers-accordion-header" eventKey="0">
        <Accordion.Header className=" px-3">
          <i
            style={{ fontSize: "30px" }}
            className="fa-solid fa-envelope-open-text mx-2 secColor"
          />
          <span className="fw-bold">Offers</span>{" "}
        </Accordion.Header>
        <Accordion.Body className="px-3 py-0">
          {offersEventsData && offersEventsData.length > 0 ? (
            offersEventsData.map((offer, i) => {
              return <MpDetailsOffersCard activity={offer} key={i} />;
            })
          ) : (
            <Row
              style={{
                height: "100px",
              }}
              className=" align-items-center my-0"
            >
              <Col>
                <Card
                  className=" latestOffersCard px-1 py-0"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row className="align-items-center px-2">
                    <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
                      <Row>
                        <span className="secColor small">No recent offers</span>
                      </Row>
                      <Row></Row>
                    </Col>
                    <Col align="right" xs="3" md="3" lg="3" xl="3"></Col>
                    <Col xs="2" md="1" lg="2" xl="2" align="right"></Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          {!listingStatus.allCurrentListings.forSale.forSale &&
            !listingStatus.allCurrentListings.forAuction.forAuction &&
            listingStatus.allCurrentListings.linkedForMarket && (
              <Row className="mt-2 mb-3">
                <Col className="d-none d-sm-block"></Col>
                <Col>
                  <ColDetailMakeOffer listingStatus={listingStatus} />
                </Col>
              </Row>
            )}

          {/* {offersEventsData && offersEventsData.length > 0 ? (
          offersEventsData.map((offer, i) => {
            return <MpDetailsOffersCard activity={offer} key={i} />;
          })
        ) : (
          <Row
            style={{
              height: "100px",
            }}
            className=" align-items-center my-0"
          >
            <Col>
              <Card
                className=" latestOffersCard px-1 py-0"
                style={{
                  width: "100%",
                }}
              >
                <Row className="align-items-center px-2">
                  <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
                    <Row>
                      <span className="secColor small">No recent offers</span>
                    </Row>
                    <Row></Row>
                  </Col>
                  <Col align="right" xs="3" md="3" lg="3" xl="3"></Col>
                  <Col xs="2" md="1" lg="2" xl="2" align="right"></Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )} */}
          {/* {hit && !listingStatus.forSale && !listingStatus.forAuction && (
          <Row className="mt-2 mb-3">
            <Col className="d-none d-sm-block"></Col>
            <Col>
              <MpDetailMakeOffer
                hit={hit}
                themeStatus={themeStatus}
                findUser={findUser}
                profileDetails={listingStatus.sellerProfile}
                listingDetails={listingDetails}
                userHasOffer={userHasOffer}
                isName={isName}
              />
            </Col>
          </Row>
        )} */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ColDetailOffers;
