import React, { useEffect, useState } from "react";
import Bl0xLoading from "../../../../loading/bl0xloading";
import "./ColDetailMediaContent.css";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import Lightbox from "react-image-lightbox";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
//swiper css imports
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import CustomImageFull from "../../../../CustomImage/CustomImageFull";

const ColDetailMediaSliderShard = ({ listingStatus }) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(null);
  const [navigation, setNavigation] = useState(false);

  useEffect(() => {
    if (listingStatus.itemMedia && listingStatus.itemMedia !== "thumbnail") {
      setNavigation(true);
    }
  }, [listingStatus]);
  let itemMedia = listingStatus.itemMedia;

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      rewind={true}
      // autoHeight={true}
      spaceBetween={40}
      navigation={navigation}
      modules={[Navigation, Pagination]}
      className="mySwiper mb-2"
    >
      <SwiperSlide>
        {listingStatus.itemThumbnail && (
          <>
            {listingStatus.itemMediaType === "video" ? (
              <>
                <video
                  key={listingStatus.itemThumbnail}
                  muted
                  alt={"Video of " + listingStatus.itemName}
                  loop=""
                  playsInline=""
                  autoPlay
                  controls
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                >
                  <source
                    src={
                      parseImgUrl(listingStatus.itemThumbnail, "maxWidth") +
                      "#t=0.5"
                    }
                    type="video/mp4"
                  ></source>
                  Sorry this video is not supported by your browser
                </video>
              </>
            ) : (
              <>
                <CustomImageFull
                  imgUrl={listingStatus.itemThumbnail}
                  // src={parseImgUrl(, "maxWidth")}
                />
              </>
            )}
          </>
        )}
      </SwiperSlide>

      {listingStatus && itemMedia && itemMedia !== "thumbnail" && (
        <>
          {itemMedia.slice(1).map((mediaItem, i) => {
            // console.log(Object.keys(itemMedia));
            // console.log(itemMedia[mediaItem]);
            return (
              <SwiperSlide key={mediaItem}>
                {mediaItem.mimetype.includes("video") ? (
                  <video
                    muted
                    loop=""
                    autoPlay
                    playsInline=""
                    controls
                    onMouseOver={(event) => event.target.play()}
                    onMouseOut={(event) => event.target.pause()}
                  >
                    <source
                      src={parseImgUrl(mediaItem.uri, "", "", true)}
                      type={mediaItem.mimetype}
                    />
                    Sorry this video is not supported by your browser
                  </video>
                ) : (
                  <>
                    <CustomImageFull
                      imgUrl={mediaItem.uri}
                      // src={parseImgUrl(, "maxWidth")}
                    />
                  </>
                )}
              </SwiperSlide>
            );
          })}
        </>
      )}
    </Swiper>
  );
};

export default ColDetailMediaSliderShard;
