import * as fcl from "@onflow/fcl";
import React from "react";
import { Tab } from "bootstrap";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../../../functions/script";
import { useEffect, useState, useImmer } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
  Tabs,
  Button,
  Modal,
  InputGroup,
  DropdownButton,
  Dropdown,
  ModalFooter,
} from "react-bootstrap";
import { useStateChanged } from "../../../functions/DisabledState";
import { CollectionTabPage } from "./collectiontabpage/collectiontabpage";
import "./profilecollection.css";
import { useNavigate, useParams } from "react-router";
import { CreateAlbum } from "./createalbum/createalbum";
import { NftDetailPage } from "./nftdetailpage/nftdetailpage";
import useGetFactoryCollections from "../../../functions/useGetFactoryCollections";
import Loading from "../../loading/loading";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import EstimatedCollectionValue from "./estimatedcollectionvalue";
import CSVReader from "./ProfileCollectionSendBatch/csvreader";
import getShardScriptName from "../../../functions/getShardScriptName";
import parseImgUrl from "../../../functions/parseImgUrl";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useUserWalletStatus } from "../../../functions/useWalletType";
import useGetFactoryCollectionsMulti from "../../../functions/useGetFactoryCollectionsMulti";
import ProfileCollectionWalletTabs from "./ProfileCollectionWalletTabs/ProfileCollectionWalletTabs";

const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: typesenseHostUrl,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nft_name, collection_name",
    //filter_by: "collection_name:bl0x"
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export function ProfileCollection({
  profile,
  type,
  display,
  profileData,
  addressNoProfile,
  searchedAddress,
}) {
  const user = useUserWalletStatus();
  const [profileAddressArray, setProfileAddressArray] = useState([]);
  const [profileMainAddress, setProfileMainAddress] = useState(null);
  const [nftData, setNftData] = useState(null);
  const { name, walletProvider, colName, albumName, nft } = useParams();

  //run shard/catalog collections for main profile wallet + emID wallets
  const userCollections = useGetFactoryCollectionsMulti(profileAddressArray);

  useEffect(() => {
    //set the lookup address for a searched address with no profile
    if (searchedAddress && !profile) {
      setProfileAddressArray([searchedAddress]);
      setProfileMainAddress(searchedAddress);
    }
    //set the user address array based on emeraldID linkage or lack thereof
    if (profileData) {
      setProfileMainAddress(profileData.profile.address);
      if (
        Object.keys(profileData.emeraldIDAccounts) &&
        Object.keys(profileData.emeraldIDAccounts).length > 0
      ) {
        Object.keys(profileData.emeraldIDAccounts).map((address) => {
          // console.log(profileAddressArray)
          setProfileAddressArray((profileAddressArray) => [
            ...profileAddressArray,
            profileData.emeraldIDAccounts[address],
          ]);
        });
      } else {
        setProfileAddressArray([profileData.profile.address]);
      }
    }
  }, [profileData, searchedAddress]);

  //get nft data such as type for detail page
  useEffect(() => {
    if (walletProvider && userCollections.getFinished && nft && colName) {
      if (walletProvider === "main") {
        setNftData(
          userCollections.collectionItems[profileMainAddress][colName]
        );
      } else {
        setNftData(
          userCollections.collectionItems[
            profileData.emeraldIDAccounts[walletProvider]
          ][colName]
        );
      }
    }
  }, [walletProvider, userCollections, nft, colName]);

  //   console.log(profileData);
  //   console.log(profileAddressArray);
  //   console.log(userCollections);
  // console.log(nftData)

  return (
    <Container fluid={+true} className="p-0" id="collections">
      <Card>
        <ProfileCollectionWalletTabs
          userCollections={userCollections}
          profileData={profileData}
          profileMainAddress={profileMainAddress}
          type={type}
          display={display}
          nftData={nftData}
          searchedAddress={searchedAddress}
          nft={nft}
          profile={profile}
        />
      </Card>
    </Container>
  );
}
