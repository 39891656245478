import React, { useEffect, useState } from "react";
import "./MpDetailMediaSlider.css";
import Lightbox from "react-image-lightbox";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
//swiper css imports
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import Bl0xLoading from "../../../loading/bl0xloading";
import parseImgUrl from "../../../../functions/parseImgUrl";
import CustomImageFull from "../../../CustomImage/CustomImageFull";

const MpDetailMediaSlider = ({ listingStatus, isName, hit }) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(null);
  const [navigation, setNavigation] = useState(false);

  useEffect(() => {
    if (listingStatus.itemMedia && listingStatus.itemMedia !== "thumbnail") {
      setNavigation(true);
    }
  }, [listingStatus]);

  let itemMedia = listingStatus.itemMedia;
  let itemThumbnail = isName
    ? parseImgUrl(
      "/assets/img/marketplace/find_name_with_find.png",
      "",
      hit.nft_name
    )
    : listingStatus.itemThumbnail;

  // console.log(listingStatus)
  return (
    <Swiper
      id="mpSlider"
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      rewind={true}
      // autoHeight={true}
      spaceBetween={40}
      navigation={navigation}
      modules={[Navigation, Pagination]}
      className="mySwiper"
    >
      {listingStatus && itemMedia && itemMedia !== "thumbnail" && (
        <>
          {Object.keys(itemMedia).map((mediaItem, i) => {
            // console.log(Object.keys(itemMedia));
            // console.log(itemMedia[mediaItem]);
            return (
              <SwiperSlide key={mediaItem}>
                {itemMedia[mediaItem].includes("video") ? (
                  <video
                  crossOrigin="anonymous"
                    muted
                    loop=""
                    playsInline=""
                    autoPlay
                    controls
                    onMouseOver={(event) => event.target.play()}
                    onMouseOut={(event) => event.target.pause()}
                  >
                    <source
                      src={parseImgUrl(mediaItem, "fullSize")}
                      type={itemMedia[mediaItem]}
                    />
                    Sorry this video is not supported by your browser
                  </video>
                ) : (
                  <>
                    <CustomImageFull
                      imgUrl={mediaItem}
                      isName={isName}
                    />
                  </>
                )}
              </SwiperSlide>
            );
          })}
        </>
      )}

      <SwiperSlide>
        {itemThumbnail &&
        (hit.collection_alias !== "THiNG.FUND Membership Badge") ? (
          <>
            <CustomImageFull
             imgUrl={itemThumbnail}
             isName={isName}
            />
            {/* {" "}
            <img
              src={
                !isName
                  ? parseImgUrl(listingStatus.itemThumbnail, "maxWidth")
                  : parseImgUrl(
                      "/assets/img/marketplace/find_name_with_find.png",
                      "",
                      hit.nft_name
                    )
              }
              onClick={() => setLightBoxOpen("thumbnail")}
            />
            {lightBoxOpen === "thumbnail" && (
              <Lightbox
                mainSrc={parseImgUrl(listingStatus.itemThumbnail, "", "", true)}
                onCloseRequest={() => setLightBoxOpen(null)}
              />
            )} */}
          </>
        ) : itemThumbnail &&
        (hit.collection_alias === "THiNG.FUND Membership Badge") ? (
          <video
          crossOrigin="anonymous"
            muted
            loop=""
            playsInline=""
            autoPlay
            controls
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
          >
            <source
              src={parseImgUrl(listingStatus.itemThumbnail, "fullSize")}
              type={"video/mp4"}
            />
            Sorry this video is not supported by your browser
          </video>
        ) : null}
      </SwiperSlide>
    </Swiper>
  );
};

export default MpDetailMediaSlider;
