import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import FlowFtSticker from "../../forms/ftstickers/flowftsticker";
import FusdFtSticker from "../../forms/ftstickers/fusdftsticker";
import MpDetailBuyNow from "./mpdetailbuynow";
import MpDetailMakeOffer from "./mpdetailmakeoffer";
import MpDetailRemoveListing from "./mpdetailremovelisting";
import { useWalletType } from "../../../functions/useWalletType";
import "../marketplace.css";

const MpDetailSalePrice = ({
  listingDetails,
  isName,
  listingStatus,
  hit,
  hits,
  themeStatus,
  findUser,
  userHasOffer,
  buyNowClicked,
  setBuyNowClicked,
  toastStatus,
  query,
}) => {
  const itemAmount = listingDetails?.findMarket?.FindMarketSale?.amount;

  const walletType = useWalletType();
  const noWallet = walletType === "none";
  const isDapper = walletType === "Dapper Wallet"
  const showButtons = isDapper || !noWallet;
  console.log(listingDetails)

  function contains(target, pattern){
    var value = 0;
    if(pattern && pattern.length > 0)
    pattern.forEach(function(word){
      value = value + target.includes(word);
    });
    return (value === 1)
}

  return (
    <Row className="py-2 justify-content-center">
      <Col xs="12">
        <Card
          id="mp-price-box-card-sale"
          className="mp-price-box-card px-4 pb-4 pt-4 mb-2"
        >
          <Row className="py-2">
            <Col>
              <span className="fw-bold">Sale price:</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {!isName ? (
                <div className="mp-price-row">
                  <h3 className="mb-0">
                    {listingDetails?.findMarket?.FindMarketSale?.ftAlias.includes(
                      "DUC"
                    ) && "$"}{parseFloat(itemAmount).toLocaleString()}
                  </h3>
                  &nbsp;
                  {contains(listingDetails?.findMarket?.FindMarketSale?.ftAlias, ["Flow", "FUT"]) > 0 && <FlowFtSticker noBg={"true"} />}
                  {contains(listingDetails?.findMarket?.FindMarketSale?.ftAlias,["fusd"]) > 0 && <FusdFtSticker noBg={"true"} />}
                </div>
              ) : (
                <div className="mp-price-row">
                  <h3 className="mb-0">
                    {listingDetails.leaseStatus.salePrice * 1}
                  </h3>{" "}
                  <FusdFtSticker />
                </div>
              )}
            </Col>
          </Row>
          {showButtons ?
            <Row className="pt-4">
              <Col
                align="center"
                xs="12"
                lg="6"
                className="pb-xs-2 mb-3 mb-lg-0"
              >
                {listingDetails &&
                listingStatus.listingDetails.linkedForMarket &&
                !listingStatus.ownItem ? (
                  <MpDetailMakeOffer
                    hit={hit}
                    themeStatus={themeStatus}
                    findUser={findUser}
                    profileDetails={listingStatus.sellerProfile}
                    listingDetails={listingDetails}
                    userHasOffer={userHasOffer}
                    isName={isName}
                  />
                ) : listingDetails && listingStatus.ownItem ? (
                  <MpDetailRemoveListing
                    hits={hits}
                    isName={isName}
                    forSale={listingStatus.forSale}
                    forAuction={listingStatus.forSale}
                    listingDetails={listingDetails}
                    themeStatus={themeStatus}
                    listingStatus={listingStatus}
                  />
                ) : null}
              </Col>
              <Col align="center" xs="12" lg="6" className="pb-xs-2">
                {listingStatus.forSale && !listingStatus.ownItem && (
                  <MpDetailBuyNow
                    hit={hit}
                    isName={isName}
                    listingDetails={listingDetails}
                    toastStatus={toastStatus}
                    themeStatus={themeStatus}
                    buyNowClicked={buyNowClicked}
                    setBuyNowClicked={setBuyNowClicked}
                    query={query}
                    findUser={findUser}
                    listingStatus={listingStatus}
                    itemAmount={itemAmount}
                  />
                )}
              </Col>
            </Row>
          : <Row className="pt-2">
              {contains(listingDetails?.findMarket?.FindMarketSale?.ftAlias ? listingDetails?.findMarket?.FindMarketSale?.ftAlias : ["DUC", "FUT"]) > 0 &&
              <p className={"fine-print"}>You need to be logged into a <b>Dapper Wallet</b> to be able to buy this item</p>}
            </Row>}
        </Card>
      </Col>

      {/* <Col>Price:</Col>
      <Col align="right">
        <b>
          {!isName ? (
            <>
              {listingDetails?.findMarket?.FindMarketSale?.amount * 1}{" "}
              {listingDetails?.findMarket?.FindMarketSale?.ftAlias}
            </>
          ) : (
            listingDetails.leaseStatus.salePrice * 1 + " FUSD"
          )}
        </b>
      </Col> */}
    </Row>
  );
};

export default MpDetailSalePrice;
