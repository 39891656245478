import React, {  useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./searchhome.css";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { Autocomplete } from "../../search/autocomplete/Autocomplete";

export function SearchHome() {
  const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
  const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;
  
  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "nft_name, collection_name, seller_name, seller",
      //filter_by: "collection_name:bl0x"
    },
  });
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  useEffect(() => {
    document.body.classList.remove("main-nav");
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const { name } = form.current;
  //   let searchName = name.value.toLowerCase();
  //   searchName = searchName.replace(/[^a-z0-9-]/g, "");
  //   if (!searchName) {
  //     return;
  //   }
  //   if (searchName.length < 3 || searchName.length > 16) {
  //     return;
  //   }
  //   navigate("/" + searchName);
  // };
  return (
    <Container
      id="searchhome"
      className="d-flex align-items-center justify-content-center p-3 p-lg-0"
    >
      <div className="search-wrapper form-inputs">
        <Row>
          <Col>
            <Row>
              <Col align="center">
                <h1>
                  .find - a place for your <br /> collections on Flow
                </h1>
                <p
                  className="mt-2 mt-md-2 m-auto fw-bold d-none d-md-block"
                  style={{ color: "var(--text-secondary)" }}
                >
                  Buy, sell, mint and showcase NFTs on the Flow blockchain with
                  <br />a named wallet and profile that brings an identity to
                  your crypto life.
                </p>
                <p
                  className="mt-3 mt-md-3 mb-4 m-auto fw-bold d-block d-md-none"
                  style={{ color: "var(--text-secondary)" }}
                >
                  Buy, sell, mint and showcase NFTs <br /> on the Flow blockchain with 
                  a <br /> named wallet and profile that <br />brings an identity to
                  your crypto life.
                </p>
              </Col>
            </Row>
            <Row
              className="mb-md-4 mt-md-4 mb-3 justify-content-center"
              xs={1}
              md={1}
            >
              <Col className="mb-2">
                {/* <Form ref={form} onSubmit={handleSubmit}>
            <Form.Control type="string" placeholder="Search for a name or 0xAddress" name="name" />
          </Form>
        </Col>
        <Col className="mt-3 mt-md-0" md="auto" align="center">
          <Button text="Confirm" onClick={handleSubmit} variant="find">Search</Button> */}
                <InstantSearch
                  indexName="market"
                  searchClient={searchClient}
                >
                  <Autocomplete
                    searchClient={searchClient}
                    placeholder=".find people and things"
                    openOnFocus
                  />
                </InstantSearch>
              </Col>
            </Row>
            <Row>
              <Col align="center"></Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="search-scroll-more">
        <p>
          <a href="#info-cards">
            <i className="fa-solid fa-angle-down p-3 pt-5 display-5 themeColor"></i>
          </a>
        </p>
      </div>
    </Container>
  );
}
