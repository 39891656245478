import { useContext } from "react";
import { Link } from "react-router-dom";
import context from "./context";
import { capitalizeTrait } from "./utils";

export default function RenderTrait(props) {
  const catalogName = useContext(context);
  const { trait } = props;
  const { shortName, value, displayType, name } = trait;
  const formatted = value.toString();
  const longValue = formatted.startsWith("0x");

  console.log({ catalogName });

  const to =
    "mp?collection=" + catalogName

  return (
    <section className={"card"}>
      <Link style={{ textDecoration: "none" }} to={to}>
        <header>{capitalizeTrait(shortName)}</header>
        <p className={`value ${longValue && "long-value"}`} title={value}>
          {value.toString()}
        </p>
      </Link>
    </section>
  );
}
