import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import { handleProfile } from "../../../functions/txfunctions";
import { useWalletType } from "../../../functions/useWalletType";
import "./createprofile.scss";

const CreateProfileInput = ({
  formValues,
  setFormValues,
  validation,
  walletType,
}) => {
  // console.log(validation);
  // console.log(formValues);

  function updateField(e) {
    setFormValues(e.target.value);
  }

  const submitProfile = (e) => {
    e.preventDefault();
    if (validation.status === "valid") {
      handleProfile(formValues, walletType);
    }
  };

  return (
    <div id="create-profile-input-wrapper">
      <Form onSubmit={submitProfile}>
        <fieldset disabled={useFormStatus()}>
          <Row>
            <Col id="validation-error-wrapper">
              {validation.status === "invalid" ? (
                <span className="validation-error-invalid">
                  {validation.error}
                </span>
              ) : validation.error === "none" ? (
                <span className="validation-error-valid">
                  All good <i className="fa-solid fa-check" />
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <Form.Control
                onChange={updateField}
                type="text"
                placeholder="Your profile name"
                maxLength={64}
                className={
                  validation.status === null
                    ? ""
                    : validation.status === "valid"
                    ? "valid"
                    : "invalid"
                }
              ></Form.Control>
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <Button id="create-profile-input-button" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default CreateProfileInput;
