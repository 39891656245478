import React from "react";
import { Image } from "react-bootstrap";
import CustomImageFull from "../CustomImage/CustomImageFull";
import "./DropPage.scss";

const DropPageMintBoxImage = ({ packDetails }) => {
  const headerBackgroundUrl = packDetails
    ? `https://find.mypinata.cloud/ipfs/${packDetails.collectionDisplay.bannerImage.file.cid}`
    : null;
  return (
    <div id="drop-page-mint-box-image-wrapper">
      <CustomImageFull imgUrl={headerBackgroundUrl} />
    </div>
  );
};

export default DropPageMintBoxImage;
