import { transactions } from "@findonflow/find-flow-contracts";
import parseImgUrl from "../../../functions/parseImgUrl";
import { handleExtend } from "../../../functions/txfunctions";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import Loading from "../../loading/loading";

function NameItem({
  hit,
  components,
  handleRegister,
}: {
  hit: any;
  components: any;
  handleRegister: any;
}) {
  const walletType = useWalletType();
  const user: any = useUserWalletStatus();
  const nameStatus = hit.findName.NameReport.status.toLowerCase();


  //name available
  return nameStatus === "free" ? (
    <div
      className="aa-ItemLink"
      onClick={(e) => handleRegister(e, transactions, hit, walletType)}
    >
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <strong>
              <span className="themeColor">{hit.name}</span>.find
            </strong>
          </div>
          <div
            className="aa-ItemContentDescription"
            style={{ color: "var(--text-secondary)" }}
          >
            {hit.findName.NameReport.cost * 1} FUSD
          </div>
        </div>
      </div>
      <div className="aa-ItemActions">
        <button type="button" className="register">
          Register
        </button>
      </div>
    </div>
  ) : //name locked
  nameStatus === "locked" ? (
    <a href={`/${hit.name}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <strong>
              <span className="themeColor">{hit.name}</span>.find
            </strong>
          </div>
          <div
            className="aa-ItemContentDescription"
            style={{ color: "var(--text-secondary)" }}
          >
            {hit.findName.NameReport.cost * 1} FUSD
          </div>
        </div>
      </div>
      {/* //locked - renew if owner disabled if not */}
      <div className="aa-ItemActions">
        {user && user.addr === hit.findName.NameReport.owner ? (
          <button
            onClick={() =>
              handleExtend(
                hit.name,
                parseFloat(hit.findName.NameReport.cost).toFixed(2),
                walletType
              )
            }
            type="button"
            className="register"
          >
            Renew
          </button>
        ) : (
          <button disabled={true} type="button" className="register">
            Locked
          </button>
        )}
      </div>
    </a>
  ) : //name has profile
  hit.findName.FINDReport ? (
    <a href={`/${hit.name}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <span>
              <img
                crossOrigin="anonymous"
                src={parseImgUrl(
                  hit.findName.FINDReport.profile.avatar,
                  "thumbnail"
                )}
                alt="avatar"
                width="40"
                height="40"
              />
              <strong>
                <span className="themeColor">{hit.name}</span>.find
              </strong>
            </span>
          </div>
        </div>
      </div>
    </a>
  ) : (
    <Loading />
  );
}

export default NameItem;
