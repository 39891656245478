import React from "react";
import "./DropPage.scss";
import DropPageInfoLastDrop from "./DropPageInfoLastDrop";
import DropPageInfoSection from "./DropPageInfoSection";

const DropPageInfo = () => {
  return (
    <div id="drop-page-info-wrapper">
      <div id="drop-page-info-container">
        <DropPageInfoSection textAlign="left" />
        <DropPageInfoSection textAlign="right" />
        <DropPageInfoLastDrop />
      </div>
    </div>
  );
};

export default DropPageInfo;
