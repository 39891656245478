//@ts-ignore
import type { SearchClient } from "algoliasearch/lite";
import type { BaseItem } from "@algolia/autocomplete-core";
import {
  AutocompleteOptions,
  Render,
  getAlgoliaResults,
} from "@algolia/autocomplete-js";
import {
  createElement,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";
import { usePagination, useSearchBox } from "react-instantsearch-hooks-web";
import { autocomplete } from "@algolia/autocomplete-js";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { debounce } from "@algolia/autocomplete-shared";
import { useNavigate } from "react-router";
import MarketplaceItem from "./MarketplaceItem";
import ReactGA from "react-ga";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../../../functions/script";
import NameItem from "./NameItem";
import { useWalletType } from "../../../functions/useWalletType";

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  searchClient: SearchClient;
  className?: string;
};

type SetInstantSearchUiStateOptions = {
  query: string;
  category?: string;
};

export function Autocomplete({
  searchClient,
  className,
  ...autocompleteProps
}: AutocompleteProps) {
  const autocompleteContainer = useRef<HTMLDivElement>(null);

  let navigate = useNavigate();
  const { query, refine: setQuery } = useSearchBox();
  const { refine: setPage } = usePagination();
  const wallet = useWalletType();
  
  const facetFilters = 
    wallet === "none" ?
    []
    :
    wallet === "Dapper Wallet" ?
    ["tenant:-find"]
    :
    ["tenant:-find_dapper"]

  const [instantSearchUiState, setInstantSearchUiState] =
    useState<SetInstantSearchUiStateOptions>({ query });
  const debouncedSetInstantSearchUiState = debounce(
    setInstantSearchUiState,
    500
  );

  async function getFindName(searchName: string) {
    const findName = await Script(scripts.getStatus, {
      user: searchName,
    });
    return findName;
  }

  //@ts-ignore
  const handleRegister = async (e, transactions, hit, walletType) => {
    e.preventDefault();
    // console.log(walletType);
    // console.log(hit);
    // console.log(transactions);
    if (walletType === "Dapper Wallet") {
      try {
        await Tx({
          tx: transactions.registerDapper,
          args: {
            merchAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
            name: hit.name,
            amount: parseFloat(hit.findName.NameReport.cost).toFixed(2),
          },
          callbacks: {
            async onSuccess() {
              //console.log("success");
              ReactGA.event({
                category: "Commerce",
                action: "Name Registered - Dapper",
                label: "Purchase",
              });
              navigate("/me/names");
              window.scrollTo(0, 0);
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await Tx({
          tx: transactions.register,
          args: {
            name: hit.name,
            amount: parseFloat(hit.findName.NameReport.cost).toFixed(2),
          },
          callbacks: {
            async onSuccess() {
              //console.log("success");
              ReactGA.event({
                category: "Commerce",
                action: "Name Registered",
                label: "Purchase",
              });
              navigate("/me/names");
              window.scrollTo(0, 0);
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    setQuery(instantSearchUiState.query);
    setPage(0);
  }, [instantSearchUiState]);

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: "instantsearch",
      limit: 3,
      //@ts-ignore
      transformSource({ source }) {
        return {
          ...source,
          getItemInputValue({ item }) {
            return item.label;
          },
          onSelect({ item }) {
            setInstantSearchUiState({
              query: item.label,
            });
          },
        };
      },
    });

    async function fetchHit(query: any) {
      let array = [];
      let findName = await getFindName(query);
      //@ts-ignore
      array[0] = [
        //@ts-ignore
        {
          name: query,
          findName,
        },
      ];
      return array;
    }

    const querySuggestionsInNamesCategory = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "market",
      //@ts-ignore
      transformSource({ source }) {
        return {
          ...source,
          getItemInputValue({ item }: { item: any }) {
            return item.name;
          },
          onSelect({ item }: { item: any }) {
            setInstantSearchUiState({
              query: item.query,
              category: item.__autocomplete_qsCategory,
            });
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }

            let array;
            let formattedQuery = params.state.query.toLowerCase();
            formattedQuery = formattedQuery.replace(
              /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]/g,
              ""
            );

            if (formattedQuery.length > 2 && formattedQuery.length <= 16) {
              array = fetchHit(formattedQuery);
            }

            return array ?? [];
          },
          templates: {
            ...source.templates,
            item({ item, html, components }) {
              return (
                <NameItem
                  hit={item}
                  components={components}
                  handleRegister={handleRegister}
                />
              );
            },
            header({ items, state }) {
              return (
                <Fragment>
                  <span className="aa-SourceHeaderTitle themeColor">
                    In Names
                  </span>
                  <span className="aa-SourceHeaderLine themeColor" />
                </Fragment>
              );
            },
          },
        };
      },
    });

    function highlight({ item, query }: { item: any; query: any }) {
      return {
        ...item,
        _highlightResult: {
          nft_name: {
            value:
              query.length > 0
                ? item.nft_name.replace(
                    new RegExp(query, "gi"),
                    `<b>${query}</b>`
                  )
                : item.nft_name,
          },
        },
      };
    }

    const querySuggestionsInMarketplaceCategory = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "market",
      //@ts-ignore
      transformSource({ source }) {
        return {
          ...source,
          getItemInputValue({ item }: { item: any }) {
            return item.nft_name;
          },

          sourceId: "market",
          onSelect({ item }: { item: any }) {
            if (item.collection_name.toString() === "FIND") {
              navigate(`/mp/${item.collection_name}/${item.nft_name}`);
            } else {
              navigate(`/mp/${item.collection_name}/${item.uuid}`);
            }
          },
          getItems(params) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: "market",
                  query: params.state.query,
                  params: {
                    clickAnalytics: true,
                    hitsPerPage: 24,
                    facetFilters: facetFilters,
                  },
                },
              ],
              //@ts-ignore
              transformResponse({ hits }) {
                let test = hits[0];
                let test1 = test.filter((item: any) => {
                  return (
                    item.nft_name
                      //@ts-ignore
                      .toLowerCase()
                      .includes(params.state.query.toLowerCase()) ||
                    (item.hasOwnProperty("seller_name")
                      ? item.seller_name
                          //@ts-ignore
                          .toLowerCase()
                          .includes(params.state.query.toLowerCase())
                      : false)
                  );
                });
                let test2 = test1.map((item: any) => {
                  return highlight({
                    item,
                    query: params.state.query.toLowerCase(),
                  });
                });
                hits[0] = test2;
                return hits;
              },
            });
          },
          templates: {
            ...source.templates,
            item({ item, html, components }) {
              return html`<div className="">
                <div className="aa-PanelSections">
                  <div className="aa-PanelSection--right">
                    ${(<MarketplaceItem hit={item} components={components} />)}
                  </div>
                </div>
              </div> `;
            },
            // noResults() {
            //   return "No products for this query.";
            // },
            header({ items }) {
              if (items.length === 0) {
                return <Fragment />;
              }

              return (
                <Fragment>
                  <span className="aa-SourceHeaderTitle themeColor">
                    In Marketplace
                  </span>
                  <span className="aa-SourceHeaderLine themeColor" />
                </Fragment>
              );
            },
          },
        };
      },
    });

    return [
      querySuggestionsInNamesCategory,
      querySuggestionsInMarketplaceCategory,
    ];
  }, []);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: { query },
      plugins,
      onReset() {
        setInstantSearchUiState({ query: "" });
      },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query });
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedSetInstantSearchUiState({
            query: state.query,
          });
        }
      },
      renderer: {
        createElement,
        Fragment,
        render: render as Render,
      },
    });

    return () => autocompleteInstance.destroy();
  }, [plugins]);

  return <div className={className} ref={autocompleteContainer} />;
}
