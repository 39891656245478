import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import { useImmer } from "use-immer";
import { handleFullfillAuction, handleIncreaseBid } from "../../../../functions/txfunctions";

const ReviewMadeBidAuctionStore = ({ filteredLease }) => {
const [ended, setEnded] = useState(false)
// //console.log(filteredLease)

  useEffect(() => {
    if (
      filteredLease.auctionEnds <
      filteredLease.currentTime
    ) {
      setEnded(true);
    }
    // eslint-disable-next-line
  }, [filteredLease]);

  const [formValues, setFormValues] = useImmer([
    {
      id: "bidAmt",
      value: filteredLease.latestBid - filteredLease.latestBid + 1,
    },
    {
      id: "name",
      value: filteredLease.name,
    },
  ]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value - filteredLease.latestBid;
    });
  }

  //   //console.log(filteredLease);
  return (
    <Container className="commerceinfo p-3">
      
      <Row>
        <Col>
          <p className="fw-bold">
            Auction Details for {filteredLease.name}.find
          </p>
          <div>
            <small>
              Starting Bid: &nbsp;
              <b>
                {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
            {ended &&
              parseFloat(filteredLease.latestBid).toFixed(2) >
                parseFloat(filteredLease.auctionReservePrice).toFixed(2)
                ? "Winning Bid: "
                : "Current Bid: "}
              {!filteredLease.latestBid ? (
                <b>
                  {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
                </b>
              ) : (
                <b>{parseFloat(filteredLease.latestBid).toFixed(2)} FUSD</b>
              )}
            </small>
          </div>
          <div>
            <small>
              Reserve Price: &nbsp;
              <b>
                {" "}
                {parseFloat(filteredLease.auctionReservePrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
              Auction {!ended ? "ends:" : "ended:"} &nbsp;
              <b>
                <ReactTimeago
                  date={new Date(filteredLease.auctionEnds * 1000)}
                  timestyle="twitter"
                />
              </b>{" "}
              {}
            </small>
          </div>

          <div>
            <small>
              Highest Bidder: &nbsp;
              <b>{filteredLease.latestBidBy}</b>
            </small>
          </div>
        </Col>
      </Row>
      

      {/* {//console.log(filteredLease)} */}

      {!ended ? (
    
       <Row className="align-items-center">
                        <div className="seperator100 my-4"></div>

          <fieldset>
            <Form className="form-inputs">
              {" "}
              <Form.Label>
                <small>
                  {" "}
                  You can increase your bid by entering at least{" "}
                  <b>{filteredLease.latestBid * 1 + 1} FUSD</b>:
                </small>
              </Form.Label>
              <Row className="align-items-center">
                <Col>
                  <Form.Control
                    type="number"
                    name="bidAmt"
                    required
                    min="0"
                    defaultValue={filteredLease.latestBid * 1 + 1}
                    placeholder="Enter an amount in FUSD"
                    onChange={(e) => {
                      updateField(e);
                      if (e.target.value <= filteredLease.latestBid) {
                        e.target.classList.add("is-invalid");
                        e.target.classList.remove("is-valid");
                      } else {
                        e.target.classList.add("is-valid");
                        e.target.classList.remove("is-invalid");
                      }
                    }}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      if (formValues[0].value * 1 > 0) {
                        //console.log("increase fired");
                        handleIncreaseBid(formValues);
                      } else {
                        console.log(
                          "failed amount is: " +
                            JSON.stringify(formValues, null, 2)
                        );
                      }
                    }}
                    variant="find-outline-commerce-dark"
                  >
                    Increase Bid
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Row>
      ) : (
        <Row>
          <Col align="right">
            <Button
              onClick={() => handleFullfillAuction(filteredLease.name, filteredLease.address)}
              variant="find-outline-commerce"
            >
              Fulfill Auction
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReviewMadeBidAuctionStore;
