import axios from "axios";
import React, { useEffect, useState } from "react";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../functions/script";
import { useStateChanged } from "../../../functions/DisabledState";
import ProfileThoughtsDataBox from "./ProfileThoughtsDataBox";
import { useLocation, useNavigate, useParams } from "react-router";
import LiveFeedThoughtBox from "../../livefeed/LiveFeedThoughtBox";
import { Card } from "react-bootstrap";
import "./ProfileThoughts.scss";
import { Link } from "react-router-dom";
import { useUserProfile } from "../../../functions/useUserProfile";

const ProfileThoughts = ({ profile, type }) => {
  const [grafflePageSize, setGrafflePageSize] = useState(50);
  const [thoughtsIds, setThoughtsIds] = useState([]);
  const [thoughtsAddrs, setThoughtsAddrs] = useState([]);
  const [thoughtsData, setThoughtsData] = useState(null);
  const [postedClicked, setPostedClicked] = useState(false);
  const [ownProfile, setOwnProfile] = useState(false);

  const { thoughtId, name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserProfile();
  // console.log(thoughtId);
  // const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  // const graffleUrl =
  //   process.env.REACT_APP_GRAFFLE_SEARCH_URL +
  //   "search?" +
  //   `eventType=A.${contractAddress}.FindThoughts.Published&creator=${profile.profile.address}`;

  //function to get owned thoughts and sort by created value
  const getOwnedThoughts = async (addr) => {
    const thoughtsResponse = await Script(scripts.getOwnedFindThoughts, {
      address: addr,
    });
    // console.log(thoughtsResponse);
    let sortedThoughtsResponse = thoughtsResponse.sort(
      (a, b) => b.created - a.created
    );
    setThoughtsData(sortedThoughtsResponse);

    //old graffle way
    // let data;
    // let res = await axios.get(graffleUrl + "&pageSize=" + grafflePageSize);
    // data = res.data;
    // thoughtsResponse.forEach((item) => {
    //   setThoughtsIds((thoughtsIds) => [...thoughtsIds, parseInt(item.id)]);
    //   setThoughtsAddrs((thoughtsAddrs) => [...thoughtsAddrs, item.creator]);
    // });
  };

  useEffect(() => {
    if (user) {
      if (
        type === "owner" ||
        name === user.profile.findName ||
        name === user.profile.address
      ) {
        setOwnProfile(true);
      } else{
        setOwnProfile(false)
      }
    } else{
      setOwnProfile(false)
    }
  }, [type, name, user]);

  //on page load - get all owned thoughts or set Id for single if id is given via url
  useEffect(() => {
    setThoughtsData(null);
    setThoughtsAddrs([]);
    setThoughtsIds([]);
    if (!thoughtId) {
      getOwnedThoughts(profile.profile.address);
    } else {
      setThoughtsIds([Number(thoughtId)]);
      setThoughtsAddrs([profile.profile.address]);
    }
  }, [thoughtId, location]);

  //if single Id run getfindthought for just that thought
  useEffect(() => {
    if (thoughtId) {
      setThoughtsData(null);
      async function getFindThoughts(addresses, ids) {
        const getThoughts = await Script(scripts.getFindThoughts, {
          addresses: addresses,
          ids: ids,
        });
        setThoughtsData(getThoughts);
      }
      try {
        getFindThoughts(thoughtsAddrs, thoughtsIds);
      } catch (error) {
        console.log(error);
      }
    }
  }, [thoughtsIds, location]);

  //on successful tx - if the post button was clicked get the new thoughts (stops rerender on reaction which is done in thought item)
  useEffect(() => {
    if (postedClicked) {
      getOwnedThoughts(profile.profile.address);
      setPostedClicked(false);
      console.log(thoughtsData);
    }
  }, [useStateChanged()]);

  return (
    <div id="profile-thoughts-wrapper">
      {ownProfile && !thoughtId && (
        <Card className="mb-3">
          <LiveFeedThoughtBox
            user={profile}
            setPostedClicked={setPostedClicked}
          />
        </Card>
      )}
      {thoughtId && (
        <div id="back-all-thoughts-row">
          <Link to={`/${name}/thoughts`}>
            <i className="fa fa-arrow-left " aria-hidden="true" />
            &nbsp;&nbsp;
            <span id="">Back to all thoughts</span>{" "}
          </Link>
        </div>
      )}
      <ProfileThoughtsDataBox profile={profile} thoughtsData={thoughtsData} />
    </div>
  );
};

export default ProfileThoughts;
