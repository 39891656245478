import React, { useEffect, useState } from "react";
import { Tabs, Tab, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import parseImgUrl from "../../../../functions/parseImgUrl";
import UppercaseFirstLetter from "../../../../functions/UppercaseFirstLetter";
import { useUserWalletStatus } from "../../../../functions/useWalletType";
import Loading from "../../../loading/loading";
import { NftDetailPage } from "../nftdetailpage/nftdetailpage";
import ProfileCollectionSendBatch from "../ProfileCollectionSendBatch/ProfileCollectionSendBatch";
import EmeraldWalletTab from "./EmeraldWalletTab";
import MainWalletTab from "./MainWalletTab";
import "./ProfileCollectionWalletTabs.scss";

const ProfileCollectionWalletTabs = ({
  userCollections,
  profileData,
  profileMainAddress,
  type,
  display,
  nftData,
  searchedAddress,
  nft,
  profile,
}) => {
  const [activeTabKey, setActiveTabKey] = useState("main");
  const navigate = useNavigate();
  const location = useLocation();
  const { walletProvider, name, colName } = useParams();
  const user = useUserWalletStatus();
  // console.log(profileData);
  // console.log(userCollections);

  const handleChangeTab = (e) => {
    setActiveTabKey(e);
    navigate("/" + e);
    if (type && type === "owner") {
      navigate("/me/collection/" + e);
    } else {
      navigate("/" + name + "/collection/" + e);
    }
  };

  //useeffect to select wallet tab based on url params
  useEffect(() => {
    if (walletProvider && activeTabKey !== walletProvider) {
      setActiveTabKey(walletProvider);
    }
  }, [walletProvider]);

  const renderTooltipEmerald = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>
        Wallets linked via EmeraldID <br /> External URL:
        (https://id.ecdao.org/)
      </span>
    </Tooltip>
  );

  if (userCollections.getFinished) {
    return (
      <div id="profile-collection-tab-wrapper">
        {display === "detail" &&
          userCollections.getFinished &&
          nftData &&
          nft && (
            <NftDetailPage
              profileData={profileData}
              findUser={profile}
              nftData={nftData}
              nft={nft}
              type={type}
              searchedAddress={searchedAddress}
            />
          )}
        <div
          id="profile-collection-wallet-tabs-wrapper"
          className={display === "detail" && nft ? "hidden" : ""}
        >
          <div id="em-id-powered-label-row" className="mt-3">
            <h4>
              {" "}
              <span>{type === "owner" ? "Your" : `${name}'s`}</span>{" "}
              {activeTabKey !== "main" && UppercaseFirstLetter(activeTabKey)}{" "}
              NFT collection
            </h4>
            {/* em id plug / link if profile has enabled*/}
            {profileData &&
              Object.keys(profileData.emeraldIDAccounts) &&
              Object.keys(profileData.emeraldIDAccounts).length > 0 && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://id.ecdao.org/"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltipEmerald}
                  >
                    <img
                      id="wallet-type-tab-img"
                      src={"https://id.ecdao.org/img/emerald_logo.png"}
                    />
                  </OverlayTrigger>
                </a>
              )}
          </div>
          {/* show send batch component if owned + signed up wallet - removed til rebuild  */}
          {/* {((type === "owner" && activeTabKey === "main") ||
            (user &&
              profileData &&
              Object.keys(profileData.emeraldIDAccounts) &&
              profileData.emeraldIDAccounts[activeTabKey] === user.addr)) && (
            <div id="send-batch-row">
              <ProfileCollectionSendBatch />
            </div>
          )} */}

          {/* tabs are hidden if display === detail, tab title hidden if no profile at address */}
          <Tabs
            className={
              ((!profileData && searchedAddress) || (profileData && Object.keys(profileData.emeraldIDAccounts).length === 0))
                ? "profile-collection-wallet-tabs hidden-nav"
                : "profile-collection-wallet-tabs"
            }
            onSelect={(e) => handleChangeTab(e)}
            activeKey={activeTabKey}
          >
            {/* main wallet tab shows for all */}
            <Tab
              mountOnEnter
              title={
                <img
                crossOrigin="anonymous"
                  id="wallet-type-tab-img"
                  src={
                    profileData && profileData.profile.avatar
                      ? parseImgUrl(profileData.profile.avatar, 'icon')
                      : "/logo192.png"
                  }
                  alt="wallet provider logo"
                />
              }
              eventKey="main"
            >
              <MainWalletTab
                walletAddress={profileMainAddress}
                walletCollection={
                  userCollections.collectionItems[profileMainAddress]
                }
                profileData={profileData}
                type={type}
                activeTabKey={activeTabKey}
              />
            </Tab>
            {/* emid wallet tab shows if profile linked */}
            {profileData &&
              Object.keys(profileData.emeraldIDAccounts) &&
              Object.keys(profileData.emeraldIDAccounts).map((wallet) => {
                let imgUrl;
                if (wallet === "dapper") {
                  imgUrl =
                    "/assets/img/walletProviders/wallet-provider-dapper.svg";
                } else if (wallet === "blocto") {
                  imgUrl =
                    "/assets/img/walletProviders/wallet-provider-blocto.png";
                } else if (wallet === "lilico") {
                  imgUrl =
                    "/assets/img/walletProviders/wallet-provider-lilico.png";
                } else {
                  imgUrl =
                    "/assets/img/walletProviders/wallet-provider-flow.png";
                }
                return (
                  <Tab
                    mountOnEnter
                    key={wallet}
                    title={
                      <img
                        id="wallet-type-tab-img"
                        src={imgUrl}
                        alt="wallet provider logo"
                      />
                    }
                    eventKey={wallet}
                  >
                    <EmeraldWalletTab
                      walletCollection={
                        userCollections.collectionItems[
                          profileData.emeraldIDAccounts[wallet]
                        ]
                      }
                      walletAddress={profileData.emeraldIDAccounts[wallet]}
                      profileData={profileData}
                      type={type}
                      activeTabKey={activeTabKey}
                    />
                    {/* {JSON.stringify(
                    userCollections.collectionItems[
                      profileData.emeraldIDAccounts[wallet]
                    ]
                  )} */}
                  </Tab>
                );
              })}
          </Tabs>
        </div>
      </div>
    );
  } else return <Loading />;
};

export default ProfileCollectionWalletTabs;
