import { Col, Row } from 'react-bootstrap'
import { CollectionNft } from '../collectionnft/collectionnft'
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useParams } from 'react-router'
import './collectiontabpage.css'
import { Script } from '../../../../functions/script'
import { scripts } from '@findonflow/find-flow-contracts'
import getShardScriptName from '../../../../functions/getShardScriptName'
import Loading from '../../../loading/loading'

export function CollectionTabPage({
  nfts,
  collectionData,
  name,
  address,
  profileData,
  activeTabKey
}) {
  //  //console.log(nfts)
  const [collectionItems, setCollectionItems] = useState()
  // const [arrayNextPosition, setArrayNextPosition] = useState(0);
  const [hasMore, setHasMore] = useState(false)
  const loadLength = 40
  const [collection, setCollection] = useState([])
  const [loadMoreIDs, setLoadMoreIDs] = useState()
  const [idsOfNFTsOnSale, setIdsOfNFTsOnSale] = useState()
  // function useQuery() {
  //   const { search } = useLocation();
  //   return useMemo(() => new URLSearchParams(search), [search]);
  // }
  //let query = useQuery();
  let { colName } = useParams()
  let extraIDsArr = []
  // console.log(collectionData.extraIDsIdentifier)

  useEffect(() => {
    if (collectionData) {
      extraIDsArr = collectionData.extraIDs.map(Number)
      if (collectionData.extraIDs.length > 0) {
        if (collectionData.extraIDs.length <= loadLength) {
          let extraIDsArray = {
            [collectionData.extraIDsIdentifier]: extraIDsArr,
          }
          let scriptName = null
          if (collectionData.shard) {
            scriptName = getShardScriptName(collectionData.shard)
          }
          // let scriptName =
          //   "getAdditionalFactoryCollectionItems" + collectionData.shard;
          async function getExtraItems(addr, extraIDsArray) {
            const additionalFactoryItemsResponse = await Script(
              scripts[scriptName],
              { user: addr, collectionIDs: extraIDsArray }
            )
            // console.log(additionalFactoryItemsResponse);
            setCollectionItems(additionalFactoryItemsResponse)
          }
          try {
            // console.log(extraIDsArray)
            if (scriptName) {
              getExtraItems(address, extraIDsArray)
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          setHasMore(true)
          let slicedExtraIDsArray = extraIDsArr.slice(0, loadLength)
          setLoadMoreIDs(extraIDsArr.slice(loadLength, extraIDsArr.length))
          // console.log(extraIDsArr)
          let extraIDsArray = {
            [collectionData.extraIDsIdentifier]: slicedExtraIDsArray,
          }
          let scriptName = null
          if (collectionData.shard) {
            scriptName = getShardScriptName(collectionData.shard)
          }

          async function getExtraItems(addr, extraIDsArray) {
            const additionalFactoryItemsResponse = await Script(
              scripts[scriptName],
              { user: addr, collectionIDs: extraIDsArray }
            )
            // console.log(additionalFactoryItemsResponse);
            setCollectionItems(additionalFactoryItemsResponse)
          }
          try {
            // console.log(extraIDsArray)
            getExtraItems(address, extraIDsArray)
          } catch (error) {
            console.log(error)
          }
        }
      }

      if (profileData) {
        let idsOfNFTsOnSale = []
        profileData.itemsForSale['FindMarketSale']?.items.forEach((item) => {
          if (item.nft.collectionName === collectionData.extraIDsIdentifier) {
            idsOfNFTsOnSale.push(item.nftId)
          }
        })
        setIdsOfNFTsOnSale(idsOfNFTsOnSale)
      }
    }
  }, [collectionData])

  // useEffect(() => {

  // },[loadMoreIDs])

  // console.log(loadMoreIDs)

  useEffect(() => {
    if (collectionItems && collectionItems[collectionData.extraIDsIdentifier]) {
      setCollection([
        ...collection,
        ...collectionItems[collectionData.extraIDsIdentifier],
      ])
    }
  }, [collectionItems])

  function LoadMoreCollection() {
    if (loadMoreIDs.length > 0) {
      if (loadMoreIDs.length < loadLength) {
        setHasMore(false)
        let extraIDsArray = {
          [collectionData.extraIDsIdentifier]: loadMoreIDs,
        }
        let scriptName = null
        if (collectionData.shard) {
          scriptName = getShardScriptName(collectionData.shard)
        }
        async function getExtraItems(addr, extraIDsArray) {
          const additionalFactoryItemsResponse = await Script(
            scripts[scriptName],
            { user: addr, collectionIDs: extraIDsArray }
          )
          // console.log(additionalFactoryItemsResponse);
          setCollectionItems(additionalFactoryItemsResponse)
        }
        try {
          // console.log(extraIDsArray)
          getExtraItems(address, extraIDsArray)
          setLoadMoreIDs()
        } catch (error) {
          console.log(error)
        }
      } else {
        let extraIDsArray = {
          [collectionData.extraIDsIdentifier]: loadMoreIDs.slice(0, loadLength),
        }
        let scriptName = null
        if (collectionData.shard) {
          scriptName = getShardScriptName(collectionData.shard)
        }
        async function getExtraItems(addr, extraIDsArray) {
          const additionalFactoryItemsResponse = await Script(
            scripts[scriptName],
            { user: addr, collectionIDs: extraIDsArray }
          )
          // console.log(additionalFactoryItemsResponse);
          setCollectionItems(additionalFactoryItemsResponse)
        }
        try {
          // console.log(extraIDsArray)
          getExtraItems(address, extraIDsArray)
          setLoadMoreIDs(loadMoreIDs.slice(loadLength, loadMoreIDs.length))
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  return (
    <>
      {collection && collection.length > 0 ? (
        collection.map((nft, i) => (
          <Col
            key={i}
            className={`collection-nft-col pb-2 collection-${colName}`}
            xs='6'
            md='4'
            lg='4'
            xl='3'
            xxl='3'
          >
            <CollectionNft
              nftData={nft}
              idkey={i}
              name={name}
              object={nft}
              collection={colName}
              link='enabled'
              onSaleNFT={profileData?.itemsForSale && profileData.itemsForSale['FindMarketSale']?.items ? profileData.itemsForSale['FindMarketSale']?.items.find(
                (item) => item.nftId === nft.id 
              ) : null}
              onAuctionNFT={profileData?.itemsForSale && profileData.itemsForSale['FindMarketAuctionEscrow']?.items ? profileData.itemsForSale['FindMarketAuctionEscrow']?.items.find(
                (item) => item.nftId === nft.id
              ):null}
              activeTabKey={activeTabKey}
            />
          </Col>
        ))
      ) : (
        <Loading />
      )}
      {hasMore && (
        <Row className='my-3'>
          <Col align='center'>
            <Button
              onClick={() => LoadMoreCollection()}
              variant='find-outline-commerce'
              className='w-100'
            >
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}
