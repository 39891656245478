import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import { useImmer } from "use-immer";
import {
  handleBuyerFulfillNFTAuction,
  handleIncreaseBidNFTAuction,
} from "../../../../functions/txfunctions";

const ReviewMadeBidNftStore = ({ filteredBid }) => {
  // console.log(filteredBid);
  const [ended, setEnded] = useState(false);

  useEffect(() => {
    if (
      filteredBid.item.auction.auctionEndsAt <
      filteredBid.item.auction.timestamp
    ) {
      setEnded(true);
    }
    // eslint-disable-next-line
  }, [filteredBid]);

  const [formValues, setFormValues] = useImmer([
    {
      id: "amount",
      value: filteredBid.item.amount - filteredBid.item.amount + 1,
    },
    {
      id: "id",
      value: filteredBid.item.listingId,
    },
  ]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value - filteredBid.item.amount;
    });
  }

  // //console.log(formValues)

  return (
    <Container className="commerceinfo p-3">
      <Row>
        <Col>
          <p className="fw-bold">
            Auction Details for {filteredBid.item.nft.name}
          </p>
          <div>
            <small>
              Starting Bid: &nbsp;
              <b>
                {parseFloat(filteredBid.item.auction.startPrice).toFixed(2)}{" "}
                {filteredBid.item.ftAlias}
              </b>
            </small>
          </div>
          <div>
            <small>
              {ended &&
              parseFloat(filteredBid.item.auction.currentPrice).toFixed(2) >
                parseFloat(filteredBid.item.auction.reservePrice).toFixed(2)
                ? "Winning Bid: "
                : "Current Bid: "}

              <b>
                {parseFloat(filteredBid.item.auction.currentPrice).toFixed(2)}{" "}
                {filteredBid.item.ftAlias}
              </b>
            </small>
          </div>
          <div>
            <small>
              Reserve Price: &nbsp;
              <b>
                {" "}
                {parseFloat(filteredBid.item.auction.reservePrice).toFixed(
                  2
                )}{" "}
                {filteredBid.item.ftAlias}
              </b>
            </small>
          </div>
          <div>
            <small>
              Auction{" "}
              {!ended
                ? "ends:"
                : "ended:"}
              &nbsp;
              <b>
                <ReactTimeago
                  date={new Date(filteredBid.item.auction.auctionEndsAt * 1000)}
                  timestyle="twitter"
                />
              </b>{" "}
              {}
            </small>
          </div>

          <div>
            <small>
              Highest Bidder: &nbsp;
              <b>
                {filteredBid.item.bidderName
                  ? filteredBid.item.bidderName + ".find"
                  : filteredBid.item.bidder}
              </b>
            </small>
          </div>
        </Col>
      </Row>

      {!ended ? (
        <Row className="align-items-center">
          <div className="seperator100 my-4"></div>

          <fieldset>
            <Form className="form-inputs">
              {" "}
              <Form.Label>
                <small>
                  {" "}
                  You can increase your bid by entering at least{" "}
                  <b>
                    {filteredBid.item.auction.currentPrice * 1 + 1}{" "}
                    {filteredBid.item.ftAlias}
                  </b>
                  :
                </small>
              </Form.Label>
              <Row className="align-items-center">
                <Col>
                  <Form.Control
                    type="number"
                    min="0"
                    required
                    name="amount"
                    defaultValue={filteredBid.item.auction.currentPrice * 1 + 1}
                    placeholder="Enter an amount in FUSD"
                    onChange={(e) => {
                      updateField(e);
                      if (
                        e.target.value <= filteredBid.item.auction.currentPrice
                      ) {
                        e.target.classList.add("is-invalid");
                        e.target.classList.remove("is-valid");
                      } else {
                        e.target.classList.add("is-valid");
                        e.target.classList.remove("is-invalid");
                      }
                    }}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      if (formValues[0].value * 1 > 0) {
                        //console.log("increase fired");
                        handleIncreaseBidNFTAuction(formValues);
                      } else {
                        console.log(
                          "failed amount is: " +
                            JSON.stringify(formValues, null, 2)
                        );
                      }
                    }}
                    variant="find-outline-commerce-dark"
                  >
                    Increase Bid
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Row>
      ) : (
        <Row>
          <Col align="right">
            <Button
              onClick={() =>
                handleBuyerFulfillNFTAuction(filteredBid.item.listingId)
              }
              variant="find-outline-commerce"
            >
              Fulfill Auction
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReviewMadeBidNftStore;
