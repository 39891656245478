import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useFormStatus, useStateChanged } from "../../functions/DisabledState";
import { SendFTCharity } from "../../functions/txfunctions";
import "./CharityAuction.scss";

const CharityAuctionGiftWidget = () => {
  const [donateAmount, setDonateAmount] = useState(null);
  const [donateMessage, setDonateMessage] = useState("With thanks");
  const [validated, setValidated] = useState(null);
  const formStatus = useFormStatus();

  useEffect(() => {
    if (donateAmount && donateAmount > 0) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [donateAmount]);

  useEffect(() => {
    setDonateAmount(null);
    setDonateMessage("With thanks");
    setValidated(null);
  }, [useStateChanged()]);

  console.log(donateAmount);
  console.log(donateMessage);

  return (
    <div id="charity-auction-gift-widget">
      <div id="charity-auction-gift-widget-inner">
        <div id="gift-widget-img-col">
          <img src="https://uc0e785f7e42ee0004c72e7abb65.previews.dropboxusercontent.com/p/thumb/ABta1O1nW7NYri_9_7mIr6995syAS9ClR9ahkl-CdoQK3_mKR0KBvUKVFyjQGt1Gh4x27TtFfaVMHFekAkHUa_3zd4U26iG3_MVDM6jou48N0tsSZhZPrJy5TgaOrYZfpYDcZl08tDTQN2x7V6fz_NBzNKEhp-aZNE9PWGmgCXc7e361wsRZ9vr_048q1rM3K7zBqrt6mfT-pWVUplKXATd4V-RJHDk6N_l4hPEuGC72O38eoYOQHbRed_aaj5D-BVnZCjGzgfXkUlDc0uTEiyqE6sz_c-tjrJtJule4XDltjlG32ri55KAHKXGlM8Vx8eQzUuODH91PLd6HClgeMC3-xrGjC8UKFN3wG2mTgis-cCa640CUChhY0GZxgYWg-E4/p.png" />
        </div>
        <div id="gift-widget-form-col">
          <div id="gift-widget-title">
            <span>Want to give something and be on our Wall of Fame?</span>
          </div>
          <div id="gift-widget-desc">
            <span id="gift-widget-desc-span">
              Any item gifts will be split up between collections yadayada
            </span>
          </div>
          <form id="gift-widget-form">
            <div className="form-item">
              <label>Enter the amount you would like to gift</label>
              <input
                type={"number"}
                value={donateAmount}
                min="0"
                required
                onChange={(e) => setDonateAmount(e.target.value)}
              />
            </div>
            <div className="form-item">
              <label>Enter an optional message</label>
              <textarea onChange={(e) => setDonateMessage(e.target.value)} />
            </div>
            <div id="form-button-row">
              <Button
                disabled={!validated || formStatus}
                onClick={() =>
                  SendFTCharity(
                    "ff4charity",
                    donateAmount,
                    "Flow",
                    "ff4charity",
                    donateMessage
                  )
                }
                variant="find-collection-outline-dark"
              >
                Flow
              </Button>
              &nbsp;
              <Button
                disabled={!validated || formStatus}
                onClick={() =>
                  SendFTCharity(
                    "ff4charity",
                    donateAmount,
                    "FUSD",
                    "ff4charity",
                    donateMessage
                  )
                }
                variant="find-collection-outline-dark"
              >
                FUSD
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CharityAuctionGiftWidget;
