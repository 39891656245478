import { Button, Modal } from "react-bootstrap";
import {
  handleBuy,
  handleBuyItemDirectSale,
} from "../../../functions/txfunctions";
import { useWalletType } from "../../../functions/useWalletType";
import MarketplaceItemBoughtModal from "../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";

const MpDetailBuyNow = ({
  hit,
  isName,
  listingDetails,
  toastStatus,
  themeStatus,
  buyNowClicked,
  setBuyNowClicked,
  query,
  findUser,
  itemAmount,
  listingStatus,
}) => {
  const walletType = useWalletType();
  return (
    <>
      {listingStatus.aDapperItem ? (
        <Button
          className="w-100"
          variant="find-nochange-dark"
          onClick={
            !isName
              ? () => {
                  setBuyNowClicked(true);
                  handleBuyItemDirectSale(
                    hit.seller,
                    itemAmount,
                    hit.uuid,
                    walletType
                  );
                }
              : () => {
                  setBuyNowClicked(true);
                  handleBuy(hit.nft_name, listingDetails.leaseStatus.salePrice);
                }
          }
        >
          <small> Buy Now</small>
        </Button>
      ) : (
        <Button
          className="w-100"
          variant="find-nochange-dark"
          onClick={
            !isName
              ? () => {
                  setBuyNowClicked(true);
                  handleBuyItemDirectSale(
                    hit.seller,
                    itemAmount,
                    hit.uuid,
                    walletType
                  );
                }
              : () => {
                  setBuyNowClicked(true);
                  handleBuy(hit.nft_name, listingDetails.leaseStatus.salePrice);
                }
          }
        >
          <small> Buy Now</small>
        </Button>
      )}

      {buyNowClicked && toastStatus.status === "success" && (
        <Modal
          data-theme={themeStatus}
          show={buyNowClicked && toastStatus.status === "success"}
          centered
        >
          <MarketplaceItemBoughtModal
            nftDetails={hit}
            isName={isName}
            query={query}
          />
        </Modal>
      )}
    </>
  );
};

export default MpDetailBuyNow;
