import useWaitForDOMRef from "@restart/ui/esm/useWaitForDOMRef";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import {
  handleCancelAuction,
  handleCancelNFTAuction,
  handleCancelNFTSale,
  handleWithdrawSale,
} from "../../../functions/txfunctions";
import { useWalletType } from "../../../functions/useWalletType";

const MpDetailRemoveListing = ({isName, forSale, forAuction, hits, listingDetails, themeStatus, listingStatus}) => {
  const [showRemoveListing, setShowRemoveListing] = useState(false);
  const wallet = useWalletType()


  // console.log(listingStatus)

  

  const handleRemoveListing = () => {
    if (listingStatus.isName) {
      if (listingStatus.forSale && !listingStatus.forAuction) {
        handleWithdrawSale(hits[0].nft_name);
      } else if (!listingStatus.forSale && listingStatus.forAuction) {
        handleCancelAuction(hits[0].nft_name);
      } else if (listingStatus.forSale && listingStatus.forAuction) {
        setShowRemoveListing(true);
      }
    } else if (!listingStatus.isName) {
      if (listingStatus.forSale && !listingStatus.forAuction) {
        handleCancelNFTSale(listingDetails.findMarket.FindMarketSale.listingId, wallet);
      } else if (!listingStatus.forSale && listingStatus.forAuction) {
        handleCancelNFTAuction(
          listingDetails.findMarket.FindMarketAuctionEscrow.listingId
        );
      } else if (listingStatus.forSale && listingStatus.forAuction) {
        setShowRemoveListing(true);
      }
    }
  };

  const handleCloseRemoveListing = () => {
    setShowRemoveListing(false);
  };

    //mobile swipedown close
    const config = {
      delta: 150, // min distance(px) before a swipe starts. *See Notes*
      preventScrollOnSwipe: true,
      swipeDuration: 250,
    };
    const handlers = useSwipeable({
      onSwipedDown: () => {
        // console.log('this')
        handleCloseRemoveListing()
      },
      ...config,
    });

  if (hits && listingDetails){
  return (
    <>
      {" "}
      {!listingStatus.auctionEnded &&
      <Button
        onClick={() => handleRemoveListing()}
        className="w-100"
        variant="find-outline-commerce-red"
        style={{ height: "52px" }}
      >
        <small> Remove Listing</small>
      </Button> }
      <Modal
        data-theme={themeStatus}
        show={showRemoveListing}
        onHide={handleCloseRemoveListing}
        centered
      >
              <div {...handlers}>
        <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
          <Modal.Title>
            <h3 align="center" className="px-3">
              Would you like to remove{" "}
              {isName ? (
                <span>
                  <span className="themeColor">{hits[0].nft_name}</span>
                  .find
                </span>
              ) : (
                hits[0].nft_name
              )}{" "}
              from Sale or Auction?
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="py-3 px-3">
            <Col align="center">
              On selecting the sales type to delist from it will delisted from
              that sales type only.
              <br />
              <br /> To also delist it from the other sales type click 'Remove
              Listing' again after the transaction has completed.
            </Col>
          </Row>
          <Row className="px-3 pt-4">
            <Col xs="12" md="6">
              <Button
                className="w-100"
                variant="find-outline-commerce-red"
                onClick={
                  isName
                    ? () => handleWithdrawSale(hits[0].nft_name)
                    : () =>
                        handleCancelNFTSale(
                          listingDetails.findMarket.FindMarketSale.listingId
                        )
                }
              >
                <small> Sale</small>
              </Button>
            </Col>
            <Col xs="12" md="6" className="mt-3 mt-md-0">
              <Button
                className="w-100"
                variant="find-outline-commerce-red"
                onClick={
                  isName
                    ? () => handleCancelAuction(hits[0].nft_name)
                    : () =>
                        handleCancelNFTAuction(
                          listingDetails.findMarket.FindMarketAuctionEscrow
                            .listingId
                        )
                }
              >
                <small> Auction</small>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        </div>
      </Modal>
    </>
  )} else{
    return null;
  };
};

export default MpDetailRemoveListing;
