import React from "react";
import { Button } from "react-bootstrap";
import { handleCancelNFTAuction } from "../../../../../functions/txfunctions";

const ColDetailRemoveListingAuction = ({ saleDetails }) => {
  return (
    <Button
      onClick={() =>
        handleCancelNFTAuction(saleDetails.listingId)
      }
      variant="find-collection-outline-red"
    >
      Remove
    </Button>
  );
};

export default ColDetailRemoveListingAuction;
