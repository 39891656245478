import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { useUserWalletStatus } from "../../functions/useWalletType";
import Bl0xLoading from "../loading/bl0xloading";
import MpHitsCard from "./mphitscard/mphitscard";

export default function CustomHitsInfinite(props) {
  const {
    hits,
    currentPageHits,
    results,
    isFirstPage,
    isLastPage,
    showPrevious,
    showMore,
    sendEvent,
  } = useInfiniteHits(props);
  const [loading, setLoading] = useState(false)
  const user = useUserWalletStatus();
// console.log(user)
  // console.log(hits)

  //the sentinel ref for tracking scroll position and loading more
  const sentinelRef = useRef(null);
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
            setLoading(true)
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  // console.log(hits);

  return (
    <>
      {hits.map((hit, i) => (
        <Col
          key={i}
          className="my-2 d-flex align-items-center justify-content-center"
          xs="6"
          sm="6"
          lg="4"
          xl="3"
          xxl={2}
        >
          <MpHitsCard hit={hit} user={user} />
        </Col>
      ))}
      <div ref={sentinelRef}/>
         {!isLastPage && loading && <Row className="my-5"><Col align='center'><Bl0xLoading/></Col></Row>}

      
    </>
  );
}
