import React, { useEffect, useRef, useState } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { CloseButton, Col, Row } from "react-bootstrap";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import NavHead from "./components/navhead/navhead";
import Home from "./pages/home";
import Footer from "./components/footer/footer";
import "./App.css";
import "./styles/buttons.css";
import { UseThemeStatus } from "./functions/themeMode";
import { Profile } from "./pages/profile";
import * as fcl from "@onflow/fcl";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "./functions/script";
import { changeState, useStateChanged } from "./functions/DisabledState";
import { SearchedProfile } from "./pages/searchedProfile";
import HelpHub from "./pages/helphub";
import LiveFeed from "./pages/livefeed";
import MarketPlace from "./pages/marketplace";
import Contact from "./pages/contact";
import UserStore from "./pages/userstore";
import Settings from "./pages/settings";
import ForgeCreation from "./pages/forgecreation";
import Explore from "./pages/explore";
import MarketplaceDetailPage from "./components/search/MarketplaceDetailPage";
import GraffleSDK from "./functions/graffle";
import EventsSwitch from "./functions/EventsSwitch";
import AlphaTesting from "./pages/alphatesting";
import { ProfileUserBar } from "./components/profile/profileuserbar/profileuserbar";
import ProfileUserBarSpacer from "./components/profile/profileuserbar/profileuserbarspacer";
import NavheadSpacer from "./components/navhead/navheadspacer";
import FooterSpacer from "./components/footer/footerspacer";
import ScrollToTop from "./functions/ScrollToTop";
import Collection from "./pages/collection";
import ReactGA from "react-ga4";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import { setLatestEvent } from "./functions/LatestEvent";
import LostAndFound from "./pages/lostandfound";
import PurchasePack from "./pages/purchasepack";
import { isMobile } from "react-device-detect";
import { setUserProfile } from "./functions/useUserProfile";
import {
  setUserWalletStatus,
  setWalletType,
  useWalletType,
} from "./functions/useWalletType";
import UpdateProfile from "./pages/updateprofile";
import CharityAuction from "./pages/charityauction";
import Index from "./pages/ImrpovedMarketplace";
import ImprovedMarketplace from "./pages/ImrpovedMarketplace";

const enableGoogleAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GAID, {
    gaOptions: {
      app_name: process.env.REACT_APP_APPNAME,
      app_version: "0.1",
    },
  });
  ReactGA.set({ hackathonTeam: process.env.REACT_APP_APPNAME });
  ReactGA.send("pageview");
};

function App() {
  document.title = ".find";

  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  const [findUser, setFindUser] = useState("first_init");
  const [newStreamEvent, setNewStreamEvent] = useState();
  const [lostAndFoundItems, setLostAndFoundItems] = useState(null);

  const themeStatus = UseThemeStatus();
  const walletType = useWalletType();
  // console.log(walletType)

  function showNotification(title, options, link) {
    var notification = new Notification(title, options);
    notification.addEventListener("click", function (event) {
      event.preventDefault();
      window.open(link, "_blank");
    });
  }
  useEffect(() => {
    if (!isMobile)
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        Notification.requestPermission();
      }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      if (Notification.permission === "granted") {
        if (newStreamEvent) {
          let newNotification = EventsSwitch(newStreamEvent);
          if (newNotification.toAddress === user.addr) {
            if (newNotification.notifiable) {
              let imageUrl = newNotification.imgUrl;
              var options = {
                body: newNotification.explanation,
                icon: "https://find.xyz/find.png",
                image: imageUrl,
                dir: "ltr",
              };
              var title = newNotification.reason;
              var link = "https://www.find.xyz";
              showNotification(title, options, link);
              changeState();
            }
          } else {
          }
        }
      } else {
      }
    } else {
    }
  }, [newStreamEvent]);
  // console.log(findUser)

  useEffect(() => {
    setUserWalletStatus(user);
    if (user.loggedIn) {
      setWalletType(user);
      async function getFindUser(addr) {
        const findUser = await Script(scripts.getStatus, { user: addr });
        setFindUser(findUser.FINDReport);
        setUserProfile(findUser.FINDReport);
        //set l + f here
      }
      try {
        getFindUser(user.addr);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFindUser("");
      setWalletType("none");
      setUserProfile(null);
    }
    // eslint-disable-next-line
  }, [user, useStateChanged()]);

  useEffect(() => {
    if (
      findUser.lostAndFoundTypes &&
      Object.keys(findUser.lostAndFoundTypes).length > 0
    ) {
      setLostAndFoundItems(Object.keys(findUser.lostAndFoundTypes).length);
    } else {
      setLostAndFoundItems(null);
    }
  }, [findUser, useStateChanged()]);

  let conn = useRef();
  useEffect(() => {
    if (user.loggedIn) {
      const streamSDK = new GraffleSDK();
      const feed = async (message) => {
        if (message) {
          //console.log(message)
          setLatestEvent(message);
        }
      };
      async function startConn() {
        conn.current = await streamSDK.stream(feed);
      }
      startConn();
    }
  }, [user]);

  useEffect(
    () => () => {
      console.log("Stopping the connection");
      conn.current.stop();
    },
    []
  );

  useEffect(() => {
    if (themeStatus === "dark") {
      document.body.classList.add("dark");
    }
  }, []);

  useEffect(() => {
    enableGoogleAnalytics();
  }, []);

  return (
    <div className="main-container" data-theme={themeStatus}>
      <div className="toastText">
        <Toaster
          toastOptions={{
            duration: Infinity,
            className: "toastNotification",
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <CloseButton
                      variant={themeStatus === "dark" && "white"}
                      onClick={() => toast.dismiss(t.id)}
                    ></CloseButton>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </div>
      <Router>
        <ScrollToTop />
        <NavheadSpacer />
        <NavHead
          user={user}
          findUser={findUser}
          lostAndFoundItems={lostAndFoundItems}
        />
        <Row className={"row__clear-margins"}>
          {user.loggedIn && (
            <Col className="d-none d-lg-block" xs="auto">
              <ProfileUserBarSpacer /> <ProfileUserBar />
            </Col>
          )}
          <Col className="px-0 mx-0">
            <Routes>
              {user.loggedIn ? (
                <Route
                  path="/"
                  element={<Profile findUser={findUser} type="owner" />}
                />
              ) : (
                <Route
                  path="/"
                  element={
                    <Home
                      metaTitle="Home / .find"
                      metaDescription=".find on Flow - Your Gateway to People and NFTs onFlow"
                    />
                  }
                />
              )}
              <Route
                path="/lf"
                element={
                  <LiveFeed
                    findUser={findUser}
                    metaTitle="Live Feed / .find"
                    metaDescription="A live view of all activity happening on .find"
                  />
                }
              />
              <Route
                path="/lf/:eventType"
                element={
                  <LiveFeed
                    findUser={findUser}
                    metaTitle="Live Feed / .find"
                    metaDescription="A live view of all activity happening on .find"
                  />
                }
              />
              <Route
                path="/me"
                element={
                  <Profile
                    findUser={findUser}
                    type="owner"
                    metaDescription="Your .find profile to access wallet information, names, NFTs, and more"
                  />
                }
              />
              <Route
                path="/me/thoughts/:thoughtId"
                element={
                  <Profile findUser={findUser} type="owner" tab="thoughts" />
                }
              />
              <Route
                path="/me/:tabs"
                element={<Profile findUser={findUser} type="owner" />}
              />
                   <Route
                path="/me/collection"
                element={
                  <Profile findUser={findUser} type="owner" tab="collection" />
                }
              />

              <Route
                path="/me/collection/:walletProvider"
                element={
                  <Profile findUser={findUser} type="owner" tab="collection" />
                }
              />
              <Route
                path="/me/collection/:walletProvider/:colName"
                element={
                  <Profile findUser={findUser} type="owner" tab="collection" />
                }
              />
              <Route
                path="/me/collection/:walletProvider/:colName/:nft"
                element={
                  <Profile
                    findUser={findUser}
                    type="owner"
                    display="detail"
                    tab="collection"
                  />
                }
              />
              <Route
                path="/me/:tabs/albums/:albumName"
                element={<Profile findUser={findUser} type="owner" />}
              />
              <Route
                path="/me/store"
                element={
                  <UserStore
                    findUser={findUser}
                    type="owner"
                    tab="listings"
                    metaTitle="Store / .find"
                    metaDescription="Your store to view all bids/offers on your listed names and NFTs and more"
                  />
                }
              />
              <Route
                path="/me/store/:tabs"
                element={
                  <UserStore
                    findUser={findUser}
                    type="owner"
                    metaTitle="Store / .find"
                    metaDescription="Your store to view all bids/offers on your listed names and NFTs and more"
                  />
                }
              />
              <Route
                path="/me/settings"
                element={
                  <Settings
                    findUser={findUser}
                    type="owner"
                    metaTitle="Settings / .find"
                    metaDescription="Your .find profile settings for you to edit your name, bio, tags, avatar, or links"
                  />
                }
              />
              <Route
                path="/me/updateprofile"
                element={
                  <UpdateProfile
                    findUser={findUser}
                    type="owner"
                    metaTitle="Update Profile / .find"
                    metaDescription="Update your .find profile"
                  />
                }
              />
              {/* <Route
                path="/me/forge"
                element={
                  <Forge
                    findUser={findUser}
                    type="owner"
                    metaTitle="Forge / .find"
                    metaDescription="The Forge - use the .forge tool to create and mint your own NFT collection with .find"
                  />
                }
              /> */}
              <Route
                path="/me/forge/create"
                element={<ForgeCreation findUser={findUser} type="owner" />}
              />
              <Route
                path="/:name"
                element={<SearchedProfile type="search" />}
              />
              <Route
                path="/:name/profile"
                element={<SearchedProfile type="search" tab="profile" />}
              />
              <Route
                path="/:name/names"
                element={<SearchedProfile type="search" tab="names" />}
              />
              <Route
                path="/:name/collection"
                element={<SearchedProfile type="search" tab="collection" />}
              />
               <Route
                path="/:name/collection/:walletProvider"
                element={<SearchedProfile type="search" tab="collection" />}
              />
              <Route
                path="/:name/collection/:walletProvider/:colName"
                element={<SearchedProfile type="search" tab="collection" />}
              />
              <Route
                path="/:name/collection/:walletProvider/:colName/:nft"
                element={
                  <SearchedProfile
                    type="search"
                    display="detail"
                    tab="collection"
                  />
                }
              />
              <Route
                path="/:name/collection/albums/:albumName"
                element={<SearchedProfile type="search" tab="collection" />}
              />
              <Route
                path="/:name/market"
                element={<SearchedProfile type="search" tab="market" />}
              />
              <Route
                path="/:name/thoughts"
                element={<SearchedProfile type="search" tab="thoughts" />}
              />
              <Route
                path="/:name/thoughts/:thoughtId"
                element={<SearchedProfile type="search" tab="thoughts" />}
              />
              <Route
                path="/p/helphub"
                element={
                  <HelpHub
                    findUser={findUser}
                    metaTitle="HelpHub / .find"
                    metaDescription="HelpHub - a guide to help you get started on .find"
                  />
                }
              />
              <Route
                path="/p/contact"
                element={
                  <Contact
                    findUser={findUser}
                    metaTitle="Contact / .find"
                    metaDescription="Looking for some help or have a question? Get in contact with the .find team"
                  />
                }
              />
            {/* Explore gone til redesign/build - Linear 789 */}
              {/* <Route
                path="/p/explore"
                element={
                  <Explore
                    findUser={findUser}
                    metaTitle="Explore / .find"
                    metaDescription="Enhance your .find journey by exploring people and NFTs on Flow"
                  />
                }
              /> */}
              <Route
                path="/p/testnethub"
                element={
                  <AlphaTesting
                    findUser={findUser}
                    metaTitle="Testnet Hub / .find"
                    metaDescription="TestnetHub - your guide to some of the things you can do on the .find testnet platform"
                  />
                }
              />
              <Route
                path="/p/privacy"
                findUser={findUser}
                element={
                  <Privacy
                    metaTitle="Privacy / .find"
                    metaDescription="Privacy - The .find privacy policy"
                  />
                }
              />
              <Route
                path="/p/terms"
                findUser={findUser}
                element={
                  <Terms
                    metaTitle="Terms / .find"
                    metaDescription="Terms - The .find terms of use"
                  />
                }
              />

              <Route
                path="/mp"
                element={
                  <MarketPlace
                    findUser={findUser}
                    metaTitle="Marketplace / .find"
                    metaDescription="Want to buy, bid, or give an offer for an NFT? Use our filters on the marketplace to .find exactly what you are looking for"
                  />
                }
              />
              <Route
                path="/mp/:colName"
                element={<Collection findUser={findUser} />}
              />
              <Route
                path="/mp/:colName/:nft"
                element={
                  <MarketplaceDetailPage findUser={findUser} user={user} />
                }
              />

              {/*We will plug new Marketplace on a different route*/}
              <Route
                path={"/imp/*"}
                findUSer={findUser}
                element={
                  <ImprovedMarketplace findUser={findUser} user={user} />
                }
                metaTitle={"Improved Marketplace"}
                metaDescription={
                  "Want to buy, bid, or give an offer for an NFT? Use our filters on the marketplace to .find exactly what you are looking for"
                }
              />

              <Route
                path="/lost-and-found"
                element={<LostAndFound findUser={findUser} user={user} />}
              />
              <Route
                path="/:colName/pack/:packId"
                element={<PurchasePack findUser={findUser} user={user} />}
              />
              <Route
                path="/flowfootball"
                element={<CharityAuction findUser={findUser} user={user} />}
              />
              {/* <Route path='/autocomplete' element={<AutocompletePage />} />
          <Route path='/n' element={<Notifications />} /> */}
            </Routes>
          </Col>
        </Row>
        <FooterSpacer />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
