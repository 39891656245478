import { useState, useEffect } from "react";
import React from "react";

import {
  Container,
  Col,
  Row,
  Card,
  Button,
  useAccordionButton,
  Accordion,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import ReactTimeago from "react-timeago";
import MakeBidName from "../../forms/buying/makebidname/makebidname";
import "./namesforauction.css";
import * as fcl from "@onflow/fcl";
import ReviewOwnAuction from "../../forms/selling/listname/reviewownauction/reviewownauction";
import "../profile.css";
import { Link } from "react-router-dom";
import { useWalletType } from "../../../functions/useWalletType";

export function NamesForAuction({ leases, type, profile }) {
  let filteredLeases = leases.filter((lease) => lease.auctionStartPrice > 0);
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  const walletType = useWalletType();
  ////console.log(user);

  const renderTooltipComingSoon = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Coming soon</span>
    </Tooltip>
  );

  function CustomToggle({ children, eventKey, lease }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <>
        <div className="d-none d-lg-block">
          {walletType === "Dapper Wallet" ? (
            <OverlayTrigger placement="top" overlay={renderTooltipComingSoon}>
              <Button
                variant="find-outline-commerce"
                size="sm"
                style={{ opacity: "0.65" }}
                // onClick={decoratedOnClick}
              >
                {children}
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant="find-outline-commerce"
              size="sm"
              onClick={decoratedOnClick}
            >
              {children}
            </Button>
          )}
        </div>
        {walletType !== "Dapper Wallet" && (
          <div className="d-lg-none">
            <Button
              onClick={decoratedOnClick}
              style={{
                backgroundImage: "var(--accordion-chevron)",
                width: "26px",
                height: "26px",
                backgroundColor: "var(--background)",
                borderColor: "var(--background)",
                boxShadow: "none",
              }}
            ></Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Container
      fluid={+true}
      id="names-for-auction"
      className="px-0 fade-in-right mb-3"
    >
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names for auction</h4>
            </Col>
          </Row>
          <Accordion>
            <table
              className="profile-table"
              id="profile-names-for-auction-table"
            >
              <tbody>
                {filteredLeases.length > 0 ? (
                  type === "owner" ? (
                    filteredLeases.map((filteredLease, i) => {
                      let amount;
                      // let started = false;
                      let ended;
                      let bidText = "Starting bid:";
                      //has the auction started?
                      if (
                        filteredLease.latestBid &&
                        filteredLease.auctionEnds > filteredLease.currentTime
                      ) {
                        amount = filteredLease.latestBid;
                        // started = true;
                        bidText = "Current bid:";
                      } else if (
                        filteredLease.latestBid &&
                        filteredLease.currentTime > filteredLease.auctionEnds
                      ) {
                        amount = filteredLease.latestBid;
                        bidText = "Winning bid:";
                      } else {
                        amount = filteredLease.auctionStartPrice;
                      }
                      if (
                        filteredLease.auctionEnds &&
                        filteredLease.auctionEnds < filteredLease.currentTime
                      ) {
                        ended = true;
                      }

                      return (
                        <React.Fragment
                          key={i + "profile-names-for-auction-row"}
                        >
                          <tr className="align-items-center mt-3 profile-names-table-names-row">
                            <td className="profile-names-table-names-col">
                              <Link to={"/mp/FIND/" + filteredLease.name}>
                                <span className="fw-bold">
                                  <span className="themeColor">
                                    {filteredLease.name}
                                  </span>
                                  .find
                                </span>
                              </Link>
                            </td>

                            <td
                              className="profile-names-table-price-col"
                              style={{ color: "var(--text-secondary)" }}
                            >
                              {bidText}{" "}
                              <b>{parseFloat(amount).toFixed(2)} FUSD</b>
                            </td>

                            <td className="profile-names-table-sticker-col">
                              <span className="profile-sticker">
                                {!filteredLease.auctionEnds ? (
                                  "Timer begins on bid"
                                ) : (
                                  <>
                                    {!ended ? "Ends: " : "Ended: "}
                                    <ReactTimeago
                                      date={
                                        new Date(
                                          filteredLease.auctionEnds * 1000
                                        )
                                      }
                                      timeStyle="twitter"
                                    />
                                  </>
                                )}
                              </span>
                            </td>

                            <td className="profile-names-table-button-col">
                              <CustomToggle eventKey={i}>
                                View Auction
                              </CustomToggle>
                            </td>
                          </tr>
                          <tr className="profile-mobile-row">
                            <td className="mobile-profile-sticker-col">
                              <span className="store-sticker">
                                {!filteredLease.auctionEnds ? (
                                  "Timer begins on bid"
                                ) : (
                                  <>
                                    {filteredLease.auctionEnds <
                                    filteredLease.currentTime
                                      ? "Ended: "
                                      : "Ends: "}
                                    <ReactTimeago
                                      date={
                                        new Date(
                                          filteredLease.auctionEnds * 1000
                                        )
                                      }
                                      timeStyle="twitter"
                                    />
                                  </>
                                )}
                              </span>
                            </td>
                            <td align="end">
                              <CustomToggle eventKey={i}>
                                View Auction
                              </CustomToggle>
                            </td>
                          </tr>
                          <tr>
                            <td className="profile-dropdown" colSpan={4}>
                              <Accordion.Collapse eventKey={i}>
                                <Card.Body>
                                  <div
                                    className="collapse show"
                                    id="collapseExample"
                                  >
                                    <ReviewOwnAuction
                                      filteredLease={filteredLease}
                                    />
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </td>
                          </tr>

                          <tr>
                            <td className="profile-mobile-dropdown" colSpan={2}>
                              <Accordion.Collapse eventKey={i}>
                                <Card.Body>
                                  <div
                                    className="collapse show"
                                    id="collapseExample"
                                  >
                                    <ReviewOwnAuction
                                      filteredLease={filteredLease}
                                    />
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    leases
                      .filter((lease) => lease.auctionStartPrice > 0)
                      .map((filteredLease, i) => (
                        <React.Fragment key={i}>
                          <tr className="align-items-center mt-3 profile-names-table-names-row">
                            <td className="profile-names-table-names-col">
                              <Link to={"/mp/FIND/" + filteredLease.name}>
                                <span className="fw-bold">
                                  <span className="themeColor">
                                    {filteredLease.name}
                                  </span>
                                  .find
                                </span>
                              </Link>
                            </td>
                            {user.loggedIn &&
                            filteredLease.latestBidBy === user.addr ? (
                              <td
                                xs="auto"
                                md="auto"
                                style={{ color: "var(--text-secondary)" }}
                              >
                                <span className="profile-sticker">
                                  Highest bidder
                                </span>
                              </td>
                            ) : (
                              <>
                                <td className="profile-names-table-price-col">
                                  {!filteredLease.latestBidBy
                                    ? "Starting Bid: "
                                    : "Current Bid: "}
                                  <b>
                                    {parseFloat(
                                      filteredLease.auctionStartPrice
                                    ).toFixed(2)}{" "}
                                    FUSD
                                  </b>
                                </td>
                              </>
                            )}
                            <td className="profile-names-table-sticker-col">
                              <span className="profile-sticker">
                                {!filteredLease.auctionEnds ? (
                                  "Timer begins on bid"
                                ) : (
                                  <>
                                    {filteredLease.auctionEnds <
                                    filteredLease.currentTime
                                      ? "Ended: "
                                      : "Ends: "}
                                    <ReactTimeago
                                      date={
                                        new Date(
                                          filteredLease.auctionEnds * 1000
                                        )
                                      }
                                      timeStyle="twitter"
                                    />
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="profile-names-table-button-col">
                              <CustomToggle eventKey={i} lease={filteredLease}>
                                {filteredLease.latestBidBy === user.addr
                                  ? "View Auction"
                                  : "Place Bid"}
                              </CustomToggle>
                            </td>
                          </tr>
                          <tr className="profile-mobile-row">
                            <td className="mobile-store-sticker-col">
                              <span className="store-sticker">
                                {!filteredLease.auctionEnds ? (
                                  "Timer begins on bid"
                                ) : (
                                  <>
                                    {filteredLease.auctionEnds <
                                    filteredLease.currentTime
                                      ? "Ended: "
                                      : "Ends: "}
                                    <ReactTimeago
                                      date={
                                        new Date(
                                          filteredLease.auctionEnds * 1000
                                        )
                                      }
                                      timeStyle="twitter"
                                    />
                                  </>
                                )}
                              </span>
                            </td>
                            <td align="end">
                              <CustomToggle eventKey={i}>
                                {filteredLease.latestBidBy === user.addr
                                  ? "View Auction"
                                  : "Place Bid"}
                              </CustomToggle>
                            </td>
                          </tr>
                          <tr>
                            <td className="profile-dropdown" colSpan={4}>
                              <Accordion.Collapse eventKey={i}>
                                <Card.Body>
                                  <div
                                    className="collapse show"
                                    id="collapseExample"
                                  >
                                    <MakeBidName lease={filteredLease} />
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </td>
                          </tr>
                          <tr>
                            <td className="profile-mobile-dropdown" colSpan={2}>
                              <Accordion.Collapse eventKey={i}>
                                <Card.Body>
                                  <div
                                    className="collapse show"
                                    id="collapseExample"
                                  >
                                    <MakeBidName lease={filteredLease} />
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                  )
                ) : (
                  <tr className="align-items-center mt-3">
                    <td>
                      <small>No names listed for auction</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
}

// function CustomToggle({ children, eventKey, lease }) {
//     const decoratedOnClick = useAccordionButton(eventKey);
//         return (
//             <>
//                 <div className="d-none d-lg-block">
//                     <Button
//                         variant='find-outline-commerce'
//                         size='sm'
//                         onClick={decoratedOnClick}
//                     >
//                         {children}
//                     </Button>
//                 </div>
//                 <div className="d-lg-none">
//                     <Button
//                         onClick={decoratedOnClick}
//                         style={{ backgroundImage: "var(--accordion-chevron)", width: "26px", height: "26px", backgroundColor: "var(--background)", borderColor: "var(--background)", boxShadow: "none" }}
//                     >
//                     </Button>
//                 </div>
//             </>
//         );
// }
