import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

const DropPageInfoSection = ({ textAlign }) => {
  const [sectionTitle, setSectionTitle] = useState(null);
  const [sectionDesc, setSectionDesc] = useState([]);

  useEffect(() => {
    if (textAlign === "left") {
      setSectionTitle("These NFTs Are Your Membership!");
      setSectionDesc([
        `Owning just 1 Favor from the current Drekwon series will grant you VIP access to this party!`,
        `If you collect all 3 from this series, you will recieve 1 (free) Party Favorz airdropped for next month's Party!`,
        `Snapshot dates will be announced in our discord if you want to prowl the .find Marketplace!`,
      ]);
    } else if (textAlign === "right") {
      setSectionTitle("The Elusive Party KEY!! Will You .find It?");
      setSectionDesc([
        "Manage to unwrap the Party Key and recieve great rewards:",
        "Recieve a special airdrop!",
        "Recieve 5 Party Favorz packs!",
        "Plus! 2x chances to win giveaways at the next party (boosted odds)."
      ]);
    }
  }, [textAlign]);

  return (
    <div id="drop-page-info-section-wrapper">
      <div
        id="drop-page-info-section-row"
        className={textAlign === "left" ? "textLeft" : "textRight"}
      >
        <div id="drop-page-info-section-text">
          <span id="drop-page-info-section-title-span">{sectionTitle}</span>
          <br />
          {sectionDesc &&
            sectionDesc.map((sectionSpan, i) => {
              return (
                <React.Fragment key={i}>
                  <span id="drop-page-info-section-desc-span">
                    {sectionSpan}
                  </span>
                  <br />
                </React.Fragment>
              );
            })}
        </div>
        <div id="drop-page-info-section-img">
          <Image
            src={
              textAlign === "left"
                ? "/assets/img/DropPage/drop-info-1.jpeg"
                : "/assets/img/DropPage/drop-info-2.jpeg"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DropPageInfoSection;
