import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import EventsSwitch from "../../../../../functions/EventsSwitch";
import { Script } from "../../../../../functions/script";
import { scripts } from "@findonflow/find-flow-contracts";
import "./detailsitemhistory.css";
import FlowFtSticker from "../../../../forms/ftstickers/flowftsticker";
import parseNameLink from "../../../../../functions/parseNameLink";
import FusdFtSticker from "../../../../forms/ftstickers/fusdftsticker";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import truncateItemName from "../../../../../functions/truncateItemName";

const DetailsItemHistoryCard = ({ activity, eventsData, i }) => {
  let switchedHistory = EventsSwitch(activity);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  // let profileAddress = activity.blockEventData.account;

  let actionAddress;

  if (
    switchedHistory.eventType === "Delisted:" ||
    switchedHistory.eventType === "Listed for Sale:" ||
    switchedHistory.eventType === "Listed for Auction:" ||
    switchedHistory.eventType === "Listed:" ||
    switchedHistory.eventType === "Rejected:" ||
    switchedHistory.eventType === "Sold:" ||
    switchedHistory.eventType === "Withdrew:"
  ) {
    actionAddress = activity.blockEventData.seller;
  } else {
    actionAddress = activity.blockEventData.buyer;
  }

  if (
    activity.flowEventId ===
    ("A.097bafa4e0b48eef.FIND.ForSale" || "A.097bafa4e0b48eef.FIND.ForSale")
  ) {
    actionAddress = activity.blockEventData.owner;
  }

  useEffect(() => {
    if (actionAddress) {
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(actionAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [actionAddress]);

  // const updatedProfile = useProfileLookup(actionAddress);

  // console.log(updatedProfile);

  if (switchedHistory && activity) {
    return (
      <Row
        key={i}
        style={{
          height: "100px",
        }}
        className=" align-items-center mb-3"
      >
        <Col>
          <Card
            className=" itemsHistoryCard px-1 py-2"
            style={{
              width: "100%",
            }}
          >
            <Row className="align-items-center">
              <Col
                id="history-img-col"
                className="d-sm-none d-md-block d-lg-none d-xl-block"
                xs="2"
              >
                {" "}
                <Image

                  className=""
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                  src={
                    updatedProfile
                      ? parseImgUrl(updatedProfile.avatar, 'icon')
                      : activity.blockEventData.buyerAvatar
                      ? parseImgUrl(activity.blockEventData.buyerAvatar, 'icon')
                      : "/assets/img/store/fnamestore.png"
                  }
                />{" "}
              </Col>
              <Col id="history-desc-col" xs="5" sm="5" md="5" lg="5" xl="5">
                <div className="div-row">
                  {switchedHistory.whatHappened && (
                    <>
                      <span className="fw-bold">
                        {" "}
                        { truncateItemName(switchedHistory.whatHappened[0].toUpperCase() +
                          switchedHistory.whatHappened.substring(1), 30) }
                      </span>
                      <br />
                      by&nbsp;
                    </>
                  )}
                  {updatedProfile ? parseNameLink(updatedProfile) : switchedHistory.fromName}
                </div>
                <Row>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(activity.eventDate).toLocaleDateString()} -{" "}
                    {new Date(activity.eventDate).toLocaleTimeString()}{" "}
                  </span>
                </Row>
              </Col>
              <Col align="right" xs="3" sm="4" md="3" lg="4" xl="3">
                <div className="flex-row">
                  <h3 className="mb-0">{switchedHistory.txAmount * 1} </h3>
                  &nbsp;
                  {switchedHistory.txFtType === "Flow" ? (
                    <FlowFtSticker noBg={"tertiary"} />
                  ) : (
                    <FusdFtSticker noBg={"tertiary"} />
                  )}
                </div>
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2" align="right">
                {" "}
                <a
                  href={
                    process.env.REACT_APP_BLOCK_VIEWER_URL +
                    activity.flowTransactionId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-arrow-right linkIcon" />{" "}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      // <Row
      //   key={i}
      //   style={{
      //     height: "100px",
      //   }}
      //   className=" align-items-center my-2"
      // >
      //   <Col>
      //     <Card
      //       className=" itemsHistoryCard px-1 py-3"
      //       style={{
      //         backgroundColor: "var(--background-profile)",
      //         width: "100%",
      //       }}
      //     >
      //       <Row className="align-items-center">
      //         <Col id="history-img-col" xs="auto" className="d-none d-lg-block">
      //           {" "}
      //           <Image
      //             className=""
      //             style={{
      //               height: "40px",
      //               width: "40px",
      //               borderRadius: "50%",
      //               marginLeft: "10px",
      //             }}
      // src={
      //   updatedProfile
      //     ? updatedProfile.avatar
      //     : activity.blockEventData.buyerAvatar
      //     ? activity.blockEventData.buyerAvatar
      //     : "/assets/img/store/fnamestore.png"
      // }
      //           />{" "}
      //         </Col>
      //         <Col id="history-desc-col" xs="auto">
      //           <Row>
      // {" "}
      // <span className="pb-0">
      //   {switchedHistory.whatHappened && (
      //     <b>
      //       {" "}
      //       {switchedHistory.whatHappened[0].toUpperCase() +
      //         switchedHistory.whatHappened.substring(1)}{" "}
      //     </b>
      //   )}
      //               by{" "}
      //               <span>
      //                 {updatedProfile
      //                   ? parseNameLink(updatedProfile)
      //                   : activity.blockEventData.buyer}
      //               </span>
      //             </span>
      //           </Row>
      //           <Row>
      //             <span className="small secColor">
      //               {new Date(activity.eventDate).toLocaleDateString()} -{" "}
      //               {new Date(activity.eventDate).toLocaleTimeString()}{" "}
      //             </span>
      //           </Row>
      //         </Col>
      //         <Col xs="auto">
      //           <div className="flex-row">
      //             <h3 className="mb-0">
      //               {activity.blockEventData.amount * 1}{" "}
      //             </h3>
      //             &nbsp;
      //             {switchedHistory.txFtType === "Flow" ? (
      //               <FlowFtSticker />
      //             ) : (
      //               <FusdFtSticker />
      //             )}
      //           </div>
      //         </Col>
      //         <Col align="right">
      //           {" "}
      //           <a
      //             href={
      //               "https:flowscan.org/transaction/" +
      //               activity.flowTransactionId
      //             }
      //             target="_blank"
      //             rel="noopener noreferrer"
      //           >
      //             <Image
      //               style={{
      //                 borderRadius: "0px",
      //                 height: "20px",
      //                 width: "20px",
      //               }}
      //               src="/assets/img/profile/linkToLight.svg"
      //             />
      //           </a>
      //         </Col>
      //       </Row>
      //     </Card>
      //   </Col>
      // </Row>

      // <Row
      //   key={i}
      //   style={{
      //     height: "100px",
      //   }}
      //   className=" justify-content-center my-2"
      // >
      //   <Col>
      //     <Card
      //       className=" itemsHistoryCard px-1"
      //       style={{
      //         backgroundColor: "var(--background-profile)",
      //         width: "100%",
      //       }}
      //     >
      //       <Row className="align-items-center justify-content-center py-2">
      //           <Row className="align-items-center justify-content-center py-1">
      // <Image
      //   className="d-none d-md-block"
      //   style={{
      //     height: "40px",
      //     width: "65px",
      //     borderRadius: "50%",
      //     marginLeft: "10px",
      //   }}
      //   src={
      //     updatedProfile
      //       ? updatedProfile.avatar
      //       : activity.blockEventData.buyerAvatar
      //       ? activity.blockEventData.buyerAvatar
      //       : "/assets/img/store/fnamestore.png"
      //   }
      // />

      //             <Col>
      //               <Col>
      // <span className="pb-0">
      // {switchedHistory.whatHappened && <b>
      //     {" "}
      //     {switchedHistory.whatHappened[0].toUpperCase() +
      //       switchedHistory.whatHappened.substring(1)}
      //   {" "}</b>}
      //   by{" "}
      //   <span>
      //     {updatedProfile && updatedProfile.findName ? (
      //       <Link
      //         style={{ textDecoration: "none" }}
      //         to={"/" + updatedProfile.findName}
      //       >
      //         <span>
      //           <span className="themeColor">
      //             {updatedProfile.findName}
      //           </span>
      //           .find
      //         </span>
      //       </Link>
      //     ) : updatedProfile && updatedProfile.address ? (
      //       <Link
      //         style={{ textDecoration: "none" }}
      //         to={"/" + updatedProfile.address}
      //       >
      //         <span>{updatedProfile.address}</span>
      //       </Link>
      //     ) : activity.blockEventData.buyerName ? (
      //       <Link
      //         style={{ textDecoration: "none" }}
      //         to={"/" + activity.blockEventData.buyerName}
      //       >
      //         <span>
      //           <span className="themeColor">
      //             {activity.blockEventData.buyerName}
      //           </span>
      //           .find
      //         </span>
      //       </Link>
      //     ) : (
      //       activity.blockEventData.buyer
      //     )}
      //   </span>
      // </span>
      //               </Col>
      //               <Col style={{ color: "var(--text-secondary" }}>
      // {new Date(activity.eventDate).toLocaleDateString()} -{" "}
      // {new Date(activity.eventDate).toLocaleTimeString()}{" "}
      //               </Col>
      //             </Col>
      //           </Row>
      //         <Col >
      // <h3 className="mb-0">
      //   {activity.blockEventData.amount * 1}{" "}

      // </h3>
      //         </Col>
      //         <Col className="d-none d-md-block" >
      //           <a
      //             href={
      //               process.env.REACT_APP_BLOCK_VIEWER_URL +
      //               activity.flowTransactionId
      //             }
      //             target="_blank"
      //             rel="noopener noreferrer"
      //           >
      //             <Image
      //               style={{
      //                 borderRadius: "0px",
      //                 height: "25px",
      //                 width: "25px",
      //               }}
      //               src="/assets/img/profile/linkToLight.svg"
      //             />
      //           </a>
      //         </Col>
      //       </Row>
      //     </Card>
      //   </Col>
      // </Row>
    );
  } else {
    return null;
  }
};

export default DetailsItemHistoryCard;
