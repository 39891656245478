export const update = (path, target, value) => {
  const key = path[0];
  if (path.length === 0) {
    return 0;
  }
  if (path.length === 1) {
    target[key] = value;
  } else {
    if (!target[key]) {
      target[key] = {};
    }
    update(path.slice(1), target[key], value);
  }
};

export const groupItems = (list) => {
  const result = list.reduce((acc, item) => {
    const path = item.name.split("_");
    update(path, acc, item);
    return acc;
  }, {});
  return result;
};

export const process = (groupped, options) => {
  const { group = "", level = 0 } = options;
  const t = Object.keys(groupped).reduce(
    (acc, key) => {
      const item = groupped[key];
      if (item.displayType) {
        item.shortName = key;
        if (!acc.singles) {
          acc.singles = [item];
        } else {
          acc.singles.push(item);
        }
      } else {
        const processed = process(item, { group: key, level: level + 1 });
        if (!acc.groups) {
          acc.groups = [processed];
        } else {
          acc.groups.push(processed);
        }
      }
      return acc;
    },
    { group, level }
  );

  return t;
};

export const capitalizeTrait = (str) => {
  if (!str) {
    return "";
  }
  return str
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(" ");
};

export const elipsiss = (str, limit = 20) => {
  if (str.length < limit) {
    return str;
  } else {
    return str.slice(0, 5) + "..." + str.slice(-5);
  }
};

export const capitalize = (str) => {
  if (!str) {
    return "";
  }
  return str[0].toUpperCase() + str.slice(1);
};
