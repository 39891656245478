import React from "react";
import { Button, Modal } from "react-bootstrap";
import { handleBuyItemDirectSale } from "../../../../../functions/txfunctions";
import useToastTxStatus from "../../../../../functions/useToastTxStatus";
import { useWalletType } from "../../../../../functions/useWalletType";
import ColDetailItemBoughtModal from "../ColDetailItemBoughtModal/ColDetailItemBoughtModal";

const ColDetailBuyNow = ({
  listingStatus,
  buyNowClicked,
  setBuyNowClicked,
  saleDetails,
  themeStatus,
}) => {
  // console.log(buyNowClicked);
  // console.log(saleDetails);

  const toastStatus = useToastTxStatus();
  const walletStatus = useWalletType()

  return (
    <>
      <Button
        className="w-100"
        variant="find-nochange-dark"
        onClick={() => {
          setBuyNowClicked(true);
          handleBuyItemDirectSale(
            saleDetails.seller,
            saleDetails.amount,
            listingStatus.itemUuid,
            walletStatus
          );
        }}
      >
        <small> Buy now</small>
      </Button>

      {/* toastStatus.status === "success" */}
      {buyNowClicked && toastStatus.status === "success" && (
        <Modal
          data-theme={themeStatus}
          show={buyNowClicked && toastStatus.status === "success"}
          centered
        >
          <ColDetailItemBoughtModal listingStatus={listingStatus} />
        </Modal>
      )}
    </>
  );
};

export default ColDetailBuyNow;
