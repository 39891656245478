import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import {
  handleCancelAuction,
  handleFulfillSale,

} from "../../../../../functions/txfunctions";

const ReviewOwnAuctionStore = ({ filteredLease, ended }) => {
  // //console.log(filteredLease);
  return (
    <Container className="commerceinfo p-3">
      <Row>
        <Col>
          <p className="fw-bold">
            Auction Details for {filteredLease.name}.find
          </p>
          <div>
            <small>
              Starting Bid: &nbsp;
              <b>
                {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
              {ended && parseFloat(filteredLease.latestBid).toFixed(2) >= parseFloat(filteredLease.auctionReservePrice).toFixed(2) ? "Winning Bid: " : "Current Bid: " }
              {!filteredLease.latestBid ? (
                <b>
                  {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
                </b>
              ) : (
                <b>{parseFloat(filteredLease.latestBid).toFixed(2)} FUSD</b>
              )}
            </small>
          </div>
          <div>
            <small>
              Reserve Price: &nbsp;
              <b>
                {" "}
                {parseFloat(filteredLease.auctionReservePrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
              Auction{" "}
              {!ended
                ? "ends: "
                : "ended: "}
              <b>
                <ReactTimeago
                  date={new Date(filteredLease.auctionEnds * 1000)}
                  timestyle="twitter"
                />
              </b>{" "}
              {}
            </small>
          </div>

          <div>
            <small>
              Highest Bidder: &nbsp;
              <b>{filteredLease.latestBidBy}</b>
            </small>
          </div>
        </Col>
      </Row>

      {ended ? (
        <Row>
          <Col align="right">
            <Button
              onClick={() => handleFulfillSale(filteredLease.name)}
              variant="find-outline-commerce"
            >
              Fulfill Auction
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col align="right">
            <Button
              onClick={() => handleCancelAuction(filteredLease.name)}
              variant="find-outline-commerce-red"
            >
              Cancel Auction
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReviewOwnAuctionStore;
