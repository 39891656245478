import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useProfileLookup from "../../../functions/useProfileLookup";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../functions/script";
import { useEffect, useState } from "react";
import "./latestbids.css";
import FlowFtSticker from "../../forms/ftstickers/flowftsticker";
import parseNameLink from "../../../functions/parseNameLink";
import EventsSwitch from "../../../functions/EventsSwitch";
import FusdFtSticker from "../../forms/ftstickers/fusdftsticker";

const LatestBidsCard = ({ activity, i, isName, listingDetails }) => {
  const [updatedProfile, setUpdatedProfile] = useState(null);
  let profileAddress = activity.blockEventData.buyer;
  let switchedHistory = EventsSwitch(activity);

  useEffect(() => {
    if (profileAddress) {
      // console.log(profileAddress)
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(profileAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [activity]);

  if (switchedHistory && activity) {
    return (
      <Row
        key={i}
        style={{
          height: "100px",
        }}
        className=" align-items-center my-2"
      >
        <Col>
          <Card
            className=" latestBidsCard px-1 py-3"
            style={{
              width: "100%",
            }}
          >
            <Row className="align-items-center">
              <Col id="history-img-col" className="" xs="2">
                {" "}
                <Image
                crossOrigin="anonymous"
                  className=""
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                  src={
                    updatedProfile
                      ? updatedProfile.avatar
                      : activity.blockEventData.buyerAvatar
                      ? activity.blockEventData.buyerAvatar
                      : "/assets/img/store/fnamestore.png"
                  }
                />{" "}
              </Col>
              <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
                <Row>{updatedProfile && parseNameLink(updatedProfile)}</Row>
                <Row>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(activity.eventDate).toLocaleDateString()} -{" "}
                    {new Date(activity.eventDate).toLocaleTimeString()}{" "}
                  </span>
                </Row>
              </Col>
              <Col align="right" xs="3" md="3" lg="3" xl="3">
                <div className="flex-row">
                  <h3 className="mb-0">
                    {activity.blockEventData.amount * 1}{" "}
                  </h3>
                  &nbsp;
                  {switchedHistory.txFtType === "Flow" ? (
                    <FlowFtSticker noBg={"true"} />
                  ) : (
                    <FusdFtSticker noBg={"true"} />
                  )}
                </div>
              </Col>
              <Col xs="2" md="1" lg="2" xl="2" align="right">
                {" "}
                <a
                  href={
                    process.env.REACT_APP_BLOCK_VIEWER_URL +
                    activity.flowTransactionId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-arrow-right linkIcon" />{" "}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      // <Row
      //   key={i}
      //   style={{
      //     height: "90px",
      //   }}
      //   className="align-items-center justify-content-center pb-3 my-3"
      // >
      //   <Col>
      //     <Card
      //       className="align-items-between justify-content-center"
      //       style={{
      //         backgroundColor: "var(--background-profile)",
      //         width: "100%",
      //       }}
      //     >
      //       <Row className="align-items-center justify-content-center py-2 px-1">
      //         <Col xs="7" md="9">
      //           <Row className="align-items-center justify-content-center py-2">
      //             <Image
      //               className="d-none d-md-block"
      //               style={{
      //                 height: "40px",
      //                 width: "65px",
      //                 borderRadius: "50%",
      //                 marginLeft: "10px",
      //               }}
      // src={
      //   updatedProfile
      //     ? updatedProfile.avatar
      //     : activity.blockEventData.buyerAvatar
      //     ? activity.blockEventData.buyerAvatar
      //     : "/assets/img/store/fnamestore.png"
      // }
      //             />

      //             <Col>
      //               <Col>
      //                 <p>
      //                   <b>
      //                     {updatedProfile && updatedProfile.findName ? (
      //                       <Link
      //                         style={{ textDecoration: "none" }}
      //                         to={"/" + updatedProfile.findName}
      //                       >
      //                         <span>
      //                           <span className="themeColor">
      //                             {updatedProfile.findName}
      //                           </span>
      //                           .find
      //                         </span>
      //                       </Link>
      //                     ) : updatedProfile && updatedProfile.address ? (
      //                       <Link
      //                         style={{ textDecoration: "none" }}
      //                         to={"/" + updatedProfile.address}
      //                       >
      //                         <span>{updatedProfile.address}</span>
      //                       </Link>
      //                     ) : activity.blockEventData.buyerName ? (
      //                       <Link
      //                         style={{ textDecoration: "none" }}
      //                         to={"/" + activity.blockEventData.buyerName}
      //                       >
      //                         <span>
      //                           <span className="themeColor">
      //                             {activity.blockEventData.buyerName}
      //                           </span>
      //                           .find
      //                         </span>
      //                       </Link>
      //                     ) : (
      //                       activity.blockEventData.buyer
      //                     )}
      //                   </b>
      //                 </p>
      //               </Col>
      //               <Col style={{ color: "var(--text-secondary" }}>
      //                 {new Date(activity.eventDate).toLocaleDateString()} -{" "}
      //                 {new Date(activity.eventDate).toLocaleTimeString()}{" "}
      //               </Col>
      //             </Col>
      //           </Row>
      //         </Col>
      //         <Col xs="4" md="2">
      //           <h3 className="mb-0">
      //             {isName
      //               ? activity.blockEventData.amount * 1 + " FUSD"
      //               : activity.blockEventData.amount * 1 +
      //                 " " +
      //                 listingDetails.ftAlias}
      //           </h3>
      //         </Col>
      //         <Col className="d-none d-md-block" xs="2" md="1">
      //           <a
      //             href={
      //               process.env.REACT_APP_BLOCK_VIEWER_URL +
      //               activity.flowTransactionId
      //             }
      //             target="_blank"
      //             rel="noopener noreferrer"
      //           >
      //             <Image
      //               style={{
      //                 borderRadius: "0px",
      //                 height: "25px",
      //                 width: "25px",
      //               }}
      //               src="/assets/img/profile/linkToLight.svg"
      //             />
      //           </a>
      //         </Col>
      //       </Row>
      //     </Card>
      //   </Col>
      // </Row>
    );
  } else {
    return null;
  }
};

export default LatestBidsCard;
