import React from "react";
import { Button } from "react-bootstrap";
import "./DropPage.scss";

const DropPageMintBoxMint = ({
  packDetails,
  numOfPacks,
  purchasePack,
  setNumOfPacks,
}) => {
  // console.log(numOfPacks);
  // console.log(packDetails);
  // console.log(purchasePack);

  return (
    <div id="drop-page-mint-box-mint-wrapper">
      <div>
        <span id="drop-page-mint-box-mint-purchase-span">Purchase</span>
      </div>
      <div>
        <span id="drop-page-mint-box-mint-price-span">
          Price:{" "}
          {packDetails && (
            <>
              {packDetails.saleInfos[0].price * 1}&nbsp;
              {packDetails.walletAlias}
            </>
          )}
        </span>
      </div>
      <div>
        {packDetails && (
          <span id="packs-available-span">
            Packs Available {packDetails.packsLeft}
          </span>
        )}
      </div>

      {packDetails && packDetails.packsLeft > 0 && packDetails.saleEnded === false && (
        <div id="mint-box-button-row">
          <div id="mint-box-button-row-col">
            <select onChange={(e) => setNumOfPacks(e.target.value)}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div id="mint-box-button-row-col">
            <Button onClick={purchasePack} variant="find-claim-pack-yellow">
              Buy
            </Button>
          </div>
        </div>
      )}
      {/* <div id="drop-page-mint-box-mint-price-span">
        Things got a bit crusty bros.
      </div>
      <div id="drop-page-mint-box-mint-price-span">
        We have disabled sales until we fix it.{" "}
      </div> */}
    </div>
  );
};

export default DropPageMintBoxMint;
