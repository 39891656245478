import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ColDetailNameAndCol.scss'

const ColDetailNameAndCol = ({ listingStatus }) => {
  return (
    <>
    <Link style={{ textDecoration: "none" }} to={/mp/}>
      <Row className=" my-2 align-items-center ">
        <Col className="">
          <Image
            style={{
              height: "1.2rem",
              width: "1.2rem",
              marginRight: "0.5rem",
            }}
            fluid={+true}
            src="/assets/img/marketplace/verify1.png"
          />
          <span>{listingStatus.itemCollection}</span>
        </Col>
        {listingStatus.itemRarity && (
          <Col className="" align="right" xs="auto">
            <span className="small fw-bold">
              {listingStatus.itemRarity[0].toUpperCase() +
                listingStatus.itemRarity.substring(1)}
            </span>
          </Col>
        )}

        <Col className="" align="right" xs="auto">
          {listingStatus.itemEdition && (
            <span id='edition-span' >&nbsp;
              {listingStatus.itemEdition}{" "}
              {listingStatus.itemEditionMax && (
                <> / {listingStatus.itemEditionMax}&nbsp;</>
              )}
            </span>
          )}
        </Col>
      </Row>
    </Link>
          <Col xs="12" className="">
          <h3 className="">
        {listingStatus.itemName}
          </h3>
        </Col>
        </>
  );
};

export default ColDetailNameAndCol;
