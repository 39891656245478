import { transactions } from "@findonflow/find-flow-contracts";
import { Tx } from "../functions/script";
import ReactGA from "react-ga4";
import TxFinishNavigate from "./TxFinishNavigate";
import { useWalletType } from "./useWalletType";

//Eventually all tx will happen here, this could be improved programatically.

//new handleProfile
export const handleProfile = async (value, walletType) => {
  console.log(walletType);
  // if (walletType === "Dapper Wallet") {
  try {
    await Tx({
      tx:
        walletType === "Dapper Wallet"
          ? transactions.createProfileDapper
          : transactions.createProfile,
      args: {
        name: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "User",
            action:
              walletType === "Dapper Wallet"
                ? "Created Profile - Dapper"
                : "Created Profile",
            label: "Profile",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//user registers a name
export const handleRegisterName = async (name, amount, walletType) => {
  // console.log(name, amount, walletType);
  // console.log(process.env.REACT_APP_CONTRACT_ADDRESS);
  if (walletType === "Dapper Wallet") {
    try {
      await Tx({
        tx: transactions.registerDapper,
        args: {
          merchAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
          name: name,
          amount: parseFloat(amount).toFixed(2),
        },
        callbacks: {
          async onSuccess(status) {
            //console.log("success");
            ReactGA.event({
              category: "Commerce",
              action: "Name Registered - Dapper",
              label: "Purchase",
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      await Tx({
        tx: transactions.register,
        args: {
          name: name,
          amount: parseFloat(amount).toFixed(2),
        },
        callbacks: {
          async onSuccess(status) {
            //console.log("success");
            ReactGA.event({
              category: "Commerce",
              action: "Name Registered",
              label: "Purchase",
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
};

//user edits their profile
export const handleEditProfile = async (
  settingsForm,
  tagsArray,
  linksArray,
  walletType
) => {
  // console.log( settingsForm,
  //   tagsArray,
  //   linksArray,
  //   walletType)
  try {
    await Tx({
      tx:
        walletType === "Dapper Wallet"
          ? transactions.editProfileDapper
          : transactions.editProfile,
      args: {
        name: settingsForm.name,
        description: settingsForm.description,
        avatar: settingsForm.avatar,
        tags: tagsArray,
        allowStoringFollowers: settingsForm.allowStoringFollowers,
        linkTitles: linksArray.linkTitles,
        linkTypes: linksArray.linkTypes,
        linkUrls: linksArray.linkUrls,
        removeLinks: linksArray.removeLinks.removeLinks,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "User",
            action:
              walletType === "Dapper Wallet"
                ? "Edited Profile - Dapper"
                : "Edited Profile",
            label: "Profile",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//Buyer makes a bid on a name auction
export const handleBid = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.bidName,
      args: {
        name: d.name,
        amount: parseFloat(d.bidAmt).toFixed(2),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Made a Bid",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer makes direct offer on NFT
export const handleMakeOfferNFT = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.bidMarketDirectOfferEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        user: d.address,
        amount: parseFloat(d.amount).toFixed(2),
        ftAliasOrIdentifier: d.ftAlias,
        id: parseInt(d.id),
        nftAliasOrIdentifier: d.nftAlias,
        validUntil: null,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Offer Made",
            label: "Offer",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer makes bid on NFT for auction
export const handleBidNFTAuction = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.bidMarketAuctionEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        user: d.address,
        amount: parseFloat(d.amount).toFixed(2),
        id: parseInt(d.id),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Made a Bid on an NFT",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer increases bid on an NFT for auction
export const handleIncreaseBidNFTAuction = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.increaseBidMarketAuctionEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(d.id),
        amount: parseFloat(d.amount).toFixed(2),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Increased a Bid on an NFT",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer fulfills won NFT auction
export const handleBuyerFulfillNFTAuction = async (value) => {
  try {
    await Tx({
      tx: transactions.fulfillMarketAuctionEscrowedFromBidder,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(value),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Buyer fulfilled won auction",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller lists NFT for direct price
export const handleListNFTDirectSale = async (e, walletType) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  if (walletType === "Dapper Wallet") {
    try {
      console.log(process.env.REACT_APP_MERCHANT_ACCOUNT);
      await Tx({
        tx: transactions.listNFTForSaleDapper,
        args: {
          marketplace: process.env.REACT_APP_DAPPER_TENANT_ADDRESS,
          id: parseInt(d.id),
          directSellPrice: parseFloat(d.amount).toFixed(2),
          ftAliasOrIdentifier: d.ftAlias,
          nftAliasOrIdentifier: d.nftAlias,
          validUntil: d.validUntil,
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            ReactGA.event({
              category: "NFT",
              action: "Listed NFT For Sale",
              label: "Direct Sale",
              value: parseFloat(d.amount).toFixed(2),
            });
            ReactGA.event("generate_lead", {
              currency: "GBP",
              value: parseFloat(d.amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else
    try {
      await Tx({
        tx: transactions.listNFTForSale,
        args: {
          marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
          id: parseInt(d.id),
          directSellPrice: parseFloat(d.amount).toFixed(2),
          ftAliasOrIdentifier: d.ftAlias,
          nftAliasOrIdentifier: d.nftAlias,
          validUntil: d.validUntil,
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            ReactGA.event({
              category: "NFT",
              action: "Listed NFT For Sale",
              label: "Direct Sale",
              value: parseFloat(d.amount).toFixed(2),
            });
            ReactGA.event("generate_lead", {
              currency: "GBP",
              value: parseFloat(d.amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
};

//seller lists NFT for auction
export const handleListNFTAuction = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.listNFTForAuctionEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(d.id),
        price: parseFloat(d.startPrice).toFixed(2),
        ftAliasOrIdentifier: d.ftAlias,
        nftAliasOrIdentifier: d.nftAlias,
        auctionReservePrice: parseFloat(d.reservePrice).toFixed(2),
        auctionDuration: parseFloat(d.duration).toFixed(2),
        minimumBidIncrement: parseFloat(d.minimumBidIncrement).toFixed(2),
        auctionExtensionOnLateBid: parseFloat(d.extensionOnLateBid).toFixed(2),
        auctionValidUntil: d.auctionValidUntil,
        auctionStartTime: null,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Listed NFT For Auction",
            label: "Auction",
          });
          ReactGA.event("generate_lead", {
            currency: "GBP",
            value: parseFloat(d.amount).toFixed(2),
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller fulfills NFT listed for auction
export const handleFulfillMarketAuctionEscrowedNFT = async (id, owner) => {
  try {
    await Tx({
      tx: transactions.fulfillMarketAuctionEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(id),
        owner: owner,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Seller fulfilled a Completed Auction",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller cancels NFT listed for directsale
export const handleCancelNFTSale = async (value, wallet) => {
  console.log(wallet)
  console.log(value)
  try {
    await Tx({
      tx: transactions.delistNFTSale,
      args: {
        marketplace: wallet === "Dapper Wallet" ? process.env.REACT_APP_DAPPER_CONTRACT_ADDRESS : process.env.REACT_APP_CONTRACT_ADDRESS,
        ids: [parseInt(value)],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Cancelled NFT Direct Sale",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller cancels NFT listed for auction
export const handleCancelNFTAuction = async (value) => {
  try {
    await Tx({
      tx: transactions.cancelMarketAuctionEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        ids: [parseInt(value)],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Cancelled NFT Auction",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller accepts direct offer for NFT
export const handleAcceptNFTOffer = async (value, amount) => {
  ReactGA.event("begin_checkout", {
    currency: "GBP",
    value: parseFloat(amount).toFixed(2),
  });
  try {
    await Tx({
      tx: transactions.fulfillMarketDirectOfferEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(value),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          // ReactGA.event({
          //   category: "NFT",
          //   action: "Accepted a Direct NFT Offer",
          //   label: "Direct Sale",
          // });
          ReactGA.event("purchase", {
            currency: "GBP",
            value: parseFloat(amount).toFixed(2),
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller rejects direct offer for NFT
export const handleRejectNFTOffer = async (value) => {
  try {
    await Tx({
      tx: transactions.cancelMarketDirectOfferEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        ids: [parseInt(value)],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Rejected a Direct NFT Offer",
            label: "Direct Sale",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer retracts their direct offer made for NFT
export const handleRetractNFTOffer = async (value) => {
  try {
    await Tx({
      tx: transactions.retractOfferMarketDirectOfferEscrowed,
      args: {
        marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
        id: parseInt(value),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "NFT",
            action: "Retracted a Direct NFT Offer",
            label: "Direct Sale",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//buyer buys an nft listed for directSale set price
export const handleBuyItemDirectSale = async (
  address,
  amount,
  id,
  walletType
) => {
  console.log(walletType)
  ReactGA.event("begin_checkout", {
    currency: "GBP",
    value: parseFloat(amount).toFixed(2),
  });
  if (walletType === "Dapper Wallet") {
    try {
      await Tx({
        tx: transactions.buyNFTForSaleDapper,
        args: {
          marketplace: process.env.REACT_APP_DAPPER_TENANT_ADDRESS,
          address: address,
          amount: parseFloat(amount).toFixed(2),
          id: parseInt(id),
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            TxFinishNavigate();
            ReactGA.event("purchase", {
              currency: "GBP",
              value: parseFloat(amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      await Tx({
        tx: transactions.buyNFTForSale,
        args: {
          marketplace: process.env.REACT_APP_CONTRACT_ADDRESS,
          user: address,
          amount: parseFloat(amount).toFixed(2),
          id: parseInt(id),
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            TxFinishNavigate();
            ReactGA.event("purchase", {
              currency: "GBP",
              value: parseFloat(amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
};

//increase a bid on a name
export const handleIncreaseBid = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.increaseNameBid,
      args: {
        name: d.name,
        amount: parseFloat(d.bidAmt).toFixed(2),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Increased a Bid",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//cancel a bid on a name
export const handleCancelBid = async (value) => {
  try {
    await Tx({
      tx: transactions.cancelNameBid,
      args: {
        names: [value],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Cancelled a Bid",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleOffer
export const handleOffer = async (e) => {
  var d = {};
  for (var i in e) {
    var datum = e[i];
    d[datum.id] = datum.value;
  }
  try {
    await Tx({
      tx: transactions.bidName,
      args: {
        name: d.name,
        amount: parseFloat(d.bidAmt).toFixed(2),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Made an Offer",
            label: "Direct Sale",
          });
          ReactGA.event("generate_lead", {
            currency: "USD",
            value: parseFloat(d.bidAmt).toFixed(2),
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleBuy
export const handleBuy = async (name, salePrice) => {
  ReactGA.event("begin_checkout", {
    currency: "GBP",
    value: parseFloat(salePrice).toFixed(2),
  });
  try {
    await Tx({
      tx: transactions.bidName,
      args: {
        name: name,
        amount: parseFloat(salePrice).toFixed(2),
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Commerce",
            action: "Purchased a Name",
            label: "Direct Sale",
          });
          ReactGA.event("purchase", {
            currency: "USD",
            value: parseFloat(salePrice).toFixed(2),
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleExtend - ARG HERE?
export const handleExtend = async (name, cost, walletType) => {
  ReactGA.event("begin_checkout", {
    currency: "GBP",
    value: parseFloat(cost).toFixed(2),
  });

  if (walletType === "Dapper Wallet") {
    try {
      await Tx({
        tx: transactions.renewNameDapper,
        args: {
          name: name,
          amount: parseFloat(cost).toFixed(2),
          merchAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            ReactGA.event({
              category: "Name",
              action: "Extended a Lease - Dapper",
              label: "Name",
            });
            ReactGA.event("purchase", {
              currency: "USD",
              value: parseFloat(cost).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      await Tx({
        tx: transactions.renewName,
        args: {
          name: name,
          amount: parseFloat(cost).toFixed(2),
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            ReactGA.event({
              category: "Name",
              action: "Extended a Lease",
              label: "Name",
            });
            ReactGA.event("purchase", {
              currency: "USD",
              value: parseFloat(cost).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
};

//new handleCancelAuction - NOT SURE OF EVENTS IN CALLBACKS HERE - initEvent depreciated
export const handleCancelAuction = async (value) => {
  try {
    await Tx({
      tx: transactions.cancelNameAuction,
      args: {
        names: [value],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Cancelled an auction",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller lists name for sale
export const handleListForSale = async (
  lease,
  amount,
  userAddress,
  validUntil,
  walletType
) => {
  if (walletType === "Dapper Wallet") {
    try {
      await Tx({
        tx: transactions.listLeaseForSaleDapper,
        args: {
          dapperAddress: userAddress,
          leaseName: lease,
          directSellPrice: parseFloat(amount).toFixed(2),
          ftAliasOrIdentifier: "DUC",
          validUntil: validUntil,
        },

        callbacks: {
          async onSuccess(status) {
            console.log("Success - " + lease + " listed for FUSD" + amount);
            ReactGA.event({
              category: "User",
              action: "Listed Name '" + lease + "' for set price sale",
              label: "Profile",
            });
            ReactGA.event("generate_lead", {
              currency: "USD",
              value: parseFloat(amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      await Tx({
        tx: transactions.listNameForSale,
        args: {
          name: lease,
          directSellPrice: parseFloat(amount).toFixed(2),
        },

        callbacks: {
          async onSuccess(status) {
            console.log("Success - " + lease + " listed for FUSD" + amount);
            ReactGA.event({
              category: "User",
              action: "Listed Name '" + lease + "' for set price sale",
              label: "Profile",
            });
            ReactGA.event("generate_lead", {
              currency: "USD",
              value: parseFloat(amount).toFixed(2),
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
};

//seller lists name for auction

export const handleListNameForAuction = async (
  lease,
  startPrice,
  reservePrice,
  duration,
  walletType
) => {
  try {
    await Tx({
      tx: transactions.listNameForAuction,
      args: {
        name: lease,
        auctionStartPrice: parseFloat(startPrice).toFixed(2),
        auctionReservePrice: parseFloat(reservePrice).toFixed(2),
        auctionDuration: parseFloat(duration).toFixed(2),
        auctionExtensionOnLateBid: parseFloat(300).toFixed(2),
      },

      callbacks: {
        async onSuccess(status) {
          console.log("Success - " + lease + " listed for FUSD" + startPrice);
          ReactGA.event({
            category: "User",
            action: "Listed Name '" + lease + "' for set price sale",
            label: "Profile",
          });
          ReactGA.event("generate_lead", {
            currency: "USD",
            value: parseFloat(startPrice).toFixed(2),
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//seller withdraws name from directsale
export const handleWithdrawSale = async (value) => {
  try {
    await Tx({
      tx: transactions.delistNameSale,
      args: {
        names: [value],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Withdrawn from Sale",
            label: "Direct Sale",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new buyer fulfill - SOME TYPOS HERE - IS OWNER ARG CORRECT?
export const handleFullfillAuction = async (name, address) => {
  try {
    await Tx({
      tx: transactions.fulfillNameAuctionBidder,
      args: {
        name: name,
        owner: address,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Auction Fulfilled",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new seller TX
export const handleRejectBlindBid = async (value) => {
  try {
    await Tx({
      tx: transactions.rejectNameDirectOffer,
      args: {
        names: [value],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Offer Rejected",
            label: "Direct Sale",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleFulfillSale - initEvent is depreciated
export const handleFulfillSale = async (value) => {
  try {
    await Tx({
      tx: transactions.fulfillName,
      args: {
        name: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Name",
            action: "Auction Fulfilled",
            label: "Auction",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//user sends FT via fundwidget
export const handleSendFungible = async (fundForm) => {
  try {
    await Tx({
      tx: transactions.sendFT,
      args: {
        name: fundForm.name,
        amount: parseFloat(fundForm.amount).toFixed(2),
        ftAliasOrIdentifier: fundForm.ftAlias,
        tag: fundForm.tag,
        message: fundForm.message,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Gift",
            action: "Sent FT",
            label: "FUSD/FLOW",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleSendFungibleTo = async (parsedFundReceiver, fundForm) => {
  try {
    await Tx({
      tx: transactions.sendFT,
      args: {
        name: parsedFundReceiver,
        amount: parseFloat(fundForm.amount).toFixed(2),
        ftAliasOrIdentifier: fundForm.ftAlias,
        tag: fundForm.tag,
        message: fundForm.message,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Gift",
            action: "Sent FT",
            label: "FUSD/FLOW",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleSetPfp
export const handleSetPfp = async (value) => {
  try {
    await Tx({
      tx: transactions.setProfile,
      args: {
        avatar: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "User",
            action: "Set Profile Picture",
            label: "Profile",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleSendNameToAddress - **PARAMS CORRECT?
export const handleSendNameToAddress = async (name, toAddress, walletType) => {
  try {
    await Tx({
      tx:
        walletType === "Dapper Wallet"
          ? transactions.moveNameToDapper
          : transactions.moveNameTO,
      args: {
        name: name,
        receiver: toAddress,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Gift",
            action:
              walletType === "Dapper Wallet"
                ? "Sent Name to Address - Dapper"
                : "Sent Name to Address",
            label: "Name",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new handleSendNameToName - **PARAMS CORRECT?
export const handleSendNameToName = async (name, toName, walletType) => {
  // console.log(name);
  // console.log(toName);
  try {
    await Tx({
      tx:
        walletType === "Dapper Wallet"
          ? transactions.moveNameToDapper
          : transactions.moveNameTO,
      args: {
        name: name,
        receiver: toName,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Gift",
            action:
              walletType === "Dapper Wallet"
                ? "Sent Name to another Name - Dapper"
                : "Sent Name to another Name",
            label: "Name",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new SetMainName - camelCase?
export const SetMainName = async (value) => {
  try {
    await Tx({
      tx: transactions.setMainName,
      args: {
        name: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "User",
            action: "Set their Default Name",
            label: "Profile",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new SendFUSDCharity - camelCase?
export const SendFTCharity = async (name, amount, ft, tag, message) => {
  // var d = {};
  // for (var i in e) {
  //   var datum = e[i];
  //   d[datum.id] = datum.value;
  // }
  try {
    await Tx({
      tx: transactions.sendFT,
      args: {
        name: name,
        amount: parseFloat(amount).toFixed(2),
        ftAliasOrIdentifier: ft,
        tag: tag,
        message: message,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Charity",
            action: "User Donated (2022)",
            label: ft,
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//User creates new album from existing collections
export const CreateNewAlbum = async (name, items) => {
  try {
    await Tx({
      tx: transactions.addCuratedCollection,
      args: {
        name: name,
        items: items,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Album Created",
            action: "User Created an Album",
            label: "Album",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

//new RemoveAlbum - TYPO ON SPREADSHEET METHOD "removeCur(r)atedCollection" - camelCase?
export const RemoveAlbum = async (value) => {
  try {
    await Tx({
      tx: transactions.removeCurratedCollection,
      args: {
        name: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Album Removed",
            action: "User Removed an Album",
            label: "Album",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleOpenBl0xPack = async (value) => {
  try {
    await Tx({
      tx: transactions.openBl0xPack,
      args: {
        packId: value,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Bl0x Pack Opened",
            action: "User Bl0x Pack Opened",
            label: "Bl0x",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handlePublishThought = async (thoughtInput, thoughtTagsArray) => {
  try {
    await Tx({
      tx: transactions.publishFindThought,
      args: {
        header: thoughtInput.header,
        body: thoughtInput.body,
        tags: thoughtTagsArray,
        mediaHash: null,
        mediaType: null,
        quoteNFTOwner: null,
        quoteNFTType: null,
        quoteNFTId: null,
        quoteCreator: null,
        quoteId: null,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Thought Published",
            action: "User Published Thought",
            label: "Thoughts",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleReactToThought = async (user, id, reaction) => {
  try {
    await Tx({
      tx: transactions.reactToFindThoughts,
      args: {
        users: [user],
        ids: [Number(id)],
        reactions: [reaction],
        undoReactionUsers: [],
        undoReactionIds: [],
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Thought Reacted To",
            action: "User Reacted To A Thought",
            label: "Thoughts",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleFixAccount = async (value) => {
  try {
    await Tx({
      tx: transactions.fixAccount,
      args: {},
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Fixed Account",
            action: "User Fixed Account",
            label: "Account Fix",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const handleHideFindThoughts = async (id, hide) => {
  try {
    await Tx({
      tx: transactions.hideFindThoughts,
      args: {
        ids: id,
        hide: hide,
      },
      callbacks: {
        async onSuccess(status) {
          console.log("success");
          ReactGA.event({
            category: "Thought Hidden",
            action: "User Hid Thought",
            label: "Thoughts",
          });
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};
