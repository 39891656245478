import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CollectionHeader from "./CollectionHeader";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../functions/script";
import CustomHitsInfinite from "../../search/CustomHitsInfinite";

const CollectionPage = (colName) => {
  const [catalogData, setCatalogData] = useState();
  const [allCatalogData, setAllCatalogData] = useState();



  // The RCRDSHP Collection

  useEffect(() => {
    async function getCollection(subCollection) {
      const catalogResponse = await Script(
        scripts.getCatalogCollectionDisplay,
        {
          collectionIdentifier: subCollection,
          type: null
        }
      );
      setCatalogData(catalogResponse);

      console.log({catalogResponse})
    }
    async function getAllCollections(subCollection) {
      const catalogResponseAll = await Script(scripts.getCatalog, {
        // no args
      });
      setAllCatalogData(catalogResponseAll);
    }
    try {
      getCollection(colName.colName.replace('FIND', 'Dandy').replace('Bl0x', 'bl0x'));
      // getAllCollections();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  // console.log(catalogData)
  // console.log(colName)
  // console.log(allCatalogData)

  return (
    <Container fluid={+true}>
      <CollectionHeader colName={colName} catalogData={catalogData} />
      <Row className="hits-row">
        <CustomHitsInfinite />
      </Row>
    </Container>
  );
};

export default CollectionPage;
