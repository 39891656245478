import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import parseImgUrl, { parseIPFSLink } from "../../functions/parseImgUrl";
import Loading from "../loading/loading";

const CustomImageFull = ({ imgUrl, isName }) => {
  const [blurImgUrl, setBlurImgUrl] = useState(null);
  const [fullSizeImgUrl, setFullSizeImgUrl] = useState(null);
  const [loadingStage, setLoadingStage] = useState(0);
  const [loadError, setLoadError] = useState(null);
  const [lightBoxOpen, setLightBoxOpen] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [detailImgUrl, setDetailImgUrl] = useState(null);
  const [thumbImgUrl, setThumbImgUrl] = useState(null);

  useEffect(() => {
    if (imgUrl) {
      if (isName) {
        setDetailImgUrl(imgUrl);
        setFullSizeImgUrl(imgUrl);
      } else {
        setDetailImgUrl(parseImgUrl(imgUrl, "detail"));
        setFullSizeImgUrl(parseImgUrl(imgUrl, "fullSize"));
        // setBlurImgUrl(
        //   `https://ik.imagekit.io/xyvsisxky/${parsedLink}?tr=bl-40/`
        // );
      }
    }
  }, [imgUrl]);

  // const handleFullSizeLoaded = () => {
  //   if (loadingStage === 1 || loadingStage === 0) {
  //     setLoadingStage(2);
  //   }
  // };
  // const handleBlurImgLoaded = () => {
  //   if (loadingStage === 0) {
  //     setLoadingStage(1);
  //   }
  // };

  // const handleImgLoadError = () => {
  //   if (loadingStage !== 2) {
  //     setLoadError(true);
  //     setBlurImgUrl(
  //       "https://static.wikia.nocookie.net/beekeepers21/images/c/c8/NotFound.png/revision/latest?cb=20200523132136"
  //     );
  //     setLoadingStage(1);
  //   }
  // };
  // console.log(fullSizeImgUrl);
  return (
    <div>
      {/* <div style={loadingStage === 0 ? {} : { display: "none" }}>
        <Loading />
      </div> */}
      {/* <img
        onClick={() => setLightBoxOpen(true)}
        crossOrigin="anonymous"
        alt="test thumb"
        src={thumbImgUrl}
      /> */}
      {detailImgUrl && detailImgUrl.includes("mypinata") ? (
        <img
          crossOrigin="anonymous"
          onClick={() => setLightBoxOpen(true)}
          // alt={`Picture of ${imgUrl}`}
          src={detailImgUrl}
        />
      ) : (
        <img
          onClick={() => setLightBoxOpen(true)}
          // alt={`Picture of ${imgUrl}`}
          src={detailImgUrl}
        />
      )}

      {/* <img crossOrigin="anonymous" alt="test full" src={fullSizeImgUrl} /> */}

      {/* <img
        style={loadingStage === 1 ? {} : { display: "none" }}
        src={blurImgUrl}
        onLoad={() => handleBlurImgLoaded()}
        onError={() => handleImgLoadError()}
      />
      <img
        style={loadingStage === 2 ? {} : { display: "none" }}
        src={parseImgUrl(fullSizeImgUrl, "maxWidth")}
        onLoad={() => handleFullSizeLoaded()}
        }
      /> */}
      {lightBoxOpen && (
        <Lightbox
          imageCrossOrigin={
            fullSizeImgUrl.includes("mypinata") ? "anonymous" : ""
          }
          mainSrc={fullSizeImgUrl}
          onCloseRequest={() => setLightBoxOpen(null)}
          enableZoom={false}
          // loader={<Loading/>}
        />
      )}
    </div>
  );
};

export default CustomImageFull;
