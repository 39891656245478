import RenderTrait from "./RenderTrait";
import { capitalize } from "./utils";

export default function RenderTree(props) {
  const { treeData } = props;
  const { singles, groups, group, level } = treeData;

  return (
    <div className={`group-container level-${level} ${group}`}>
      {group && (
        <div className={`group-head group-head--level-${level}`}>
          {capitalize(group)}
        </div>
      )}
      {singles && (
        <div className={`singles-grid ${singles.length}-items`}>
          {singles.map((trait) => (
            <RenderTrait trait={trait} />
          ))}
        </div>
      )}
      {groups && groups.map((data) => <RenderTree treeData={data} />)}
    </div>
  );
}
