import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import TimeAgo from "react-timeago";
import { handleBuy, handleWithdrawSale } from "../../../functions/txfunctions";
import "./namesforsale.css";
import "../profile.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";

export function NamesForSale({ leases, type, profile }) {
  const [ownProfile, setOwnProfile] = useState(false);
  const walletType = useWalletType();
  const user = useUserWalletStatus();

  useEffect(() => {
    if (user && profile && user.addr === profile.address) {
      setOwnProfile(true);
    }
  }, [user, profile]);

  let filteredLeases = leases.filter((lease) => lease.salePrice > 0);

  const renderTooltipComingSoon = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Coming soon</span>
    </Tooltip>
  );
  const disabledStyle = { opacity: "0.65" };

  return (
    <Container
      fluid={+true}
      id="names-for-sale"
      className="px-0 fade-in-right mb-3"
    >
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names for sale</h4>
            </Col>
          </Row>
          <table className="profile-table" id="profile-names-for-sale-table">
            <tbody>
              {filteredLeases.length > 0 ? (
                ownProfile ? (
                  leases
                    .filter((lease) => lease.salePrice > 0)
                    .map((filteredLease, i) => (
                      <React.Fragment key={i}>
                        <tr className="align-items-center mt-3 profile-names-table-names-row">
                          <td className="profile-names-table-names-col">
                            <Link to={"/mp/FIND/" + filteredLease.name}>
                              <span className="fw-bold">
                                <span className="themeColor">
                                  {filteredLease.name}
                                </span>
                                .find
                              </span>
                            </Link>
                          </td>

                          <td
                            className="profile-names-table-price-col"
                            style={{ color: "var(--text-secondary)" }}
                          >
                            <div>
                              List price:{" "}
                              <b>
                                {parseFloat(filteredLease.salePrice).toFixed(2)}{" "}
                                FUSD
                              </b>
                            </div>
                          </td>

                          <td className="profile-names-table-sticker-col">
                            {filteredLease.latestBid && (
                              <div className="sticker-wrapper">
                                <span className="store-sticker">
                                  Offer received
                                </span>
                              </div>
                            )}
                          </td>

                          <td className="profile-names-table-button-col">
                            <Button
                              variant="find-outline-commerce-red"
                              onClick={() =>
                                handleWithdrawSale(filteredLease.name)
                              }
                              size="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>

                        <tr className="profile-mobile-row">
                          <td className=" ">
                            {filteredLease.latestBid && (
                              <span className="store-sticker">
                                Offer received
                              </span>
                            )}
                          </td>
                          <td align="end">
                            <Button
                              variant="find-outline-commerce-red"
                              onClick={() =>
                                handleWithdrawSale(filteredLease.name)
                              }
                              size="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                ) : (
                  leases
                    .filter((lease) => lease.salePrice > 0)
                    .map((filteredLease, i) => (
                      <React.Fragment key={i}>
                        <tr className="align-items-center mt-3 profile-names-table-names-row">
                          <td className="profile-names-table-names-col">
                            <Link to={"/mp/FIND/" + filteredLease.name}>
                              <span className="fw-bold">
                                <span className="themeColor">
                                  {filteredLease.name}
                                </span>
                                .find
                              </span>
                            </Link>
                          </td>

                          <td className="profile-names-table-price-col">
                            <div>
                              List price:{" "}
                              <b>
                                {parseFloat(filteredLease.salePrice).toFixed(2)}{" "}
                                FUSD
                              </b>
                            </div>
                          </td>
                          <td className="profile-names-table-sticker-col"></td>
                          <td className="profile-names-table-button-col">
                            {walletType === "Dapper Wallet" ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={renderTooltipComingSoon}
                              >
                                <Button
                                  variant="find-outline-commerce"
                                  style={disabledStyle}
                                  // onClick={() =>
                                  //   handleBuy(
                                  //     filteredLease.name,
                                  //     filteredLease.salePrice
                                  //   )
                                  // }
                                  size="sm"
                                >
                                  Buy name
                                </Button>
                              </OverlayTrigger>
                            ) : (
                              <Button
                                variant="find-outline-commerce"
                                onClick={() =>
                                  handleBuy(
                                    filteredLease.name,
                                    filteredLease.salePrice
                                  )
                                }
                                size="sm"
                              >
                                Buy name
                              </Button>
                            )}
                          </td>
                        </tr>
                        <tr className="profile-mobile-row">
                          <td className="mobile-store-sticker-col">
                            <div className="valid-for ms-auto">
                              <img
                                src="/assets/img/profile/namesowned/tick-circle.png"
                                alt="green tick"
                                width="16"
                                height="16"
                              />
                              <span style={{ color: "black" }}>
                                Valid for{" "}
                                <TimeAgo
                                  date={
                                    new Date(filteredLease.validUntil * 1000)
                                  }
                                  timestyle="twitter"
                                />
                              </span>
                            </div>
                          </td>

                          {walletType !== 'Dapper Wallet' &&
                          <td className="mobile-store-buttons-col">
                            <Button
                              variant="find-outline-commerce"
                              onClick={() =>
                                handleBuy(
                                  filteredLease.name,
                                  filteredLease.salePrice
                                )
                              }
                              size="sm"
                            >
                              Buy
                            </Button>
                          </td>}
                        </tr>
                      </React.Fragment>
                    ))
                )
              ) : (
                <tr className="align-items-center mt-3">
                  <td>
                    <small>No names listed for sale</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
}
