import { Card, Col, Container, Row } from "react-bootstrap";
import Image from "material-ui-image";
import { useLocation } from "react-router";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import "./profileheader.css";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import parseImgUrl from "../../../functions/parseImgUrl";

export function ProfileHeader({ profileData }) {
  const [squareOpen, setSquareOpen] = useState();

  const location = useLocation();

  let userCheck = location.pathname;
  userCheck = userCheck.includes("/me")
    ? userCheck.replace(
        "/me",
        profileData.findName
          ? "/" + profileData.findName
          : "/" + profileData.address
      )
    : userCheck;
  // console.log(userCheck)

  let headerBackgroundUrl = "/findbg.webp";
  let profilePic = profileData.avatar;
  let profileName = profileData.name;
  let defaultName = profileData.findName;
  let profileDescription = profileData.description;
  let profileTags = profileData.tags;

  let title = "Check this out on .find"
  let url = `https://find.xyz${userCheck}/`

  // console.log(profileTags);
  // let profileTagsParse = profileTags

  // //console.log(profileData);
  // console.log(location)

  return (
    <Container fluid={+true} id="profile-header" className="mb-3 px-0">
        {squareOpen && (
                    <Lightbox
                    imageCrossOrigin={profilePic.includes('mypinata') ? "anonymous" : ''}
                      mainSrc={parseImgUrl(profilePic, 'fullSize')}
                      onCloseRequest={() => setSquareOpen(false)}
                    />
                  )}
      <Row>
        <Col className="">
          <Card className="outer-wrapper">
            <div
              className="header-card-bg"
              style={{ backgroundImage: "url('" + headerBackgroundUrl + "')" }}
            ></div>
            <Card.Body>
              <Row className="justify-content-sm-center justify-content-lg-start">
                <Col
                  className="ms-lg-2 mt-5 d-flex justify-content-center"
                  xs="12"
                  lg="auto"
                >
                  <div
                    className="profile-pic"
                    onClick={(e) => setSquareOpen(true)}
                  >
                    <img
                        crossOrigin="anonymous"
                      src={
                        profilePic
                          ? parseImgUrl(profilePic, 'thumbnail')
                          : "/assets/img/store/fnamestore.png"
                      }
                      alt={profileName + "'s pfp"}
                      color="var(--background)"
                      style={{ borderRadius: "50%", cursor: 'pointer' }}
                    />
                  </div>
                
                </Col>
                <Col lg="9" xxl="10">
                  <div className="header-name-padding d-none d-lg-block"></div>
                  <Row className="align-items-center">
                    <Col className="d-flex justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                      <h3>{profileName}</h3>
                    </Col>
                    <Col align="right" className="d-none d-lg-block">
                      <TwitterShareButton
                        className="m-1"
                        title={title}
                        url={url}
                        hashtags={["findonflow", "onFlow"]}
                      >
                        <i className="fab fa-twitter m-1 shareIcons" />
                      </TwitterShareButton>
                      <FacebookShareButton
                        className="m-1"
                        quote={title}
                        url={url}
                      >
                        <i className="fab fa-facebook m-1 shareIcons" />
                      </FacebookShareButton>
                      <LinkedinShareButton className="m-1" url={url}>
                        <i className="fab fa-linkedin m-1 shareIcons" />
                      </LinkedinShareButton>
                    </Col>
                  </Row>
                  {defaultName && (
                    <Row>
                      <Col className="d-flex justify-content-center justify-content-lg-start">
                        <p className="fw-bold">
                          <span className="themeColor">{defaultName}</span>.find
                        </p>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="justify-content-center text-center text-lg-start justify-content-lg-start">
                      <p>{profileDescription}</p>
                    </Col>
                  </Row>
                  {profileTags.length !== 0 && (
                    <Row className="secColor">
                      <Col className="d-flex align-items-center justify-content-center text-center text-lg-start justify-content-lg-start">
                        <i className="fa-solid fa-tags"></i> &nbsp;
                        <Row style={{ marginLeft: "5px" }}>
                          {profileTags.map((tag, index) => (
                            <Col key={index} xs="auto" className="tag-item m-1">
                              <span className="tag-text">{tag}</span>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
