import React, { useEffect, useState } from "react";
import Bl0xLoading from "../../../../loading/bl0xloading";
import "./ColDetailMediaContent.css";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import Lightbox from "react-image-lightbox";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
//swiper css imports
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { useParams } from "react-router";
import CustomImageFull from "../../../../CustomImage/CustomImageFull";

const ColDetailMediaSliderCatalog = ({ listingStatus }) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(null);
  const [navigation, setNavigation] = useState(false);
  const { colName } = useParams();

  useEffect(() => {
    if (listingStatus.itemMedia && listingStatus.itemMedia !== "thumbnail") {
      setNavigation(true);
    }
  }, [listingStatus]);

  // console.log(listingStatus)
  let itemMedia = listingStatus.itemMedia;
  return (
    <Swiper
      id="colSwiper"
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      rewind={true}
      // autoHeight={true}
      spaceBetween={40}
      navigation={navigation}
      modules={[Navigation, Pagination]}
      className="mySwiper mb-2"
    >
      {listingStatus && itemMedia && itemMedia !== "thumbnail" && (
        <>
          {Object.keys(itemMedia).map((mediaItem, i) => {
            // console.log(Object.keys(itemMedia));
            // console.log(itemMedia[mediaItem]);
            return (
              <SwiperSlide key={mediaItem}>
                {itemMedia[mediaItem].includes("video") ? (
                  <video
                  crossOrigin="anonymous"
                    muted
                    loop=""
                    playsInline=""
                    controls
                    autoPlay
                    onMouseOver={(event) => event.target.play()}
                    onMouseOut={(event) => event.target.pause()}
                  >
                    <source
                      src={parseImgUrl(mediaItem, "", "", true)}
                      type={itemMedia[mediaItem]}
                    />
                    Sorry this video is not supported by your browser
                  </video>
                ) : (
                  <>
                    <CustomImageFull
                      imgUrl={mediaItem}
                      // src={parseImgUrl(, "maxWidth")}
                    />
                  </>
                )}
              </SwiperSlide>
            );
          })}
        </>
      )}

      <SwiperSlide>
        {listingStatus.itemThumbnail && (colName !== "CaaPass" || colName !== "TFC") ? (
          <>
            {" "}
            <CustomImageFull
              imgUrl={listingStatus.itemThumbnail}
              // src={parseImgUrl(, "maxWidth")}
            />
          </>
        ) : listingStatus.itemThumbnail && (colName === "CaaPass"|| colName === "TFC" ) ? (
          <video
          crossOrigin="anonymous"
            muted
            loop=""
            playsInline=""
            controls
            autoPlay
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
          >
            <source
              src={parseImgUrl(listingStatus.itemThumbnail, "maxWidth")}
              type={"video/mp4"}
            />
            Sorry this video is not supported by your browser
          </video>
        ) : null}
      </SwiperSlide>
    </Swiper>
  );
};

export default ColDetailMediaSliderCatalog;
