import React from "react";
import { Button } from "react-bootstrap";
import "./DropPage.scss";

const DropPageHeader = ({ packDetails }) => {
  return (
    <div id="drop-page-header-wrapper">
      <a href="#header-claim-button-wrap">
        {" "}
        <div id="new-drop-span-wrapper">
          <span id="new-drop-span">New drop!</span>&nbsp; Learn more&nbsp;
          &nbsp;
          <i className="fa-solid fa-arrow-right" />
          &nbsp;
        </div>
      </a>
      <div id="header-title-span">Party Favorz x Artist DRE</div>
      <div id="header-claim-button-wrap">
        <a href="#mint-box-title-span">
          <Button variant="find-claim-pack-yellow">Claim or purchase</Button>
        </a>
      </div>
    </div>
  );
};

export default DropPageHeader;
