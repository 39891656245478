import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import {
  handleCancelAuction,
  handleFullfillAuction,
  handleWithdrawSale,
} from "../../../../functions/txfunctions";
import "./storenameslistings.css";
import "../../userstore.css";
import { Link } from "react-router-dom";

const StoreNamesListings = ({ leases, filterTerm }) => {
  let filteredSaleLeases = leases.filter((lease) => lease.salePrice > 0).filter(lease => lease.name.toLowerCase().includes(filterTerm.toLowerCase()));
  let filteredAuctionLeases = leases.filter(
    (lease) => lease.auctionStartPrice > 0
  ).filter(lease => lease.name.toLowerCase().includes(filterTerm.toLowerCase()));

  // //console.log(filteredAuctionLeases)

  const findLogo = (
    <Image
      className="store-thumb-image"
      src="/assets/img/store/fnamestore.png"
    />
  );

  return (
    <Container fluid={+true} id="store-names-listings" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col align="left">
              <h4>Names you have listed</h4>
            </Col>
            <Col align="right">
              {(filteredSaleLeases.length > 0 ||
                filteredAuctionLeases.length > 0) && (
                <Button variant="find-outline-commerce-red">Remove all</Button>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              <b>For sale:</b>
            </p>
          </Row>

          <table className="store-table" id="store-names-for-sale-table">
            <tbody>
            {filteredSaleLeases.length > 0 ? (
              filteredSaleLeases.map((lease, i) => {
                // //console.log(lease);

                return (
                  <React.Fragment key={i + "store-names-for-sale-row"}>
                    <tr
                      id={i + "store-names-for-sale-row"}
                      className="store-row"
                    >
                      <td className="store-thumb-col">
                        {findLogo}
                      </td>
                      <td className="store-name-col">
                        <Link to={'/mp/FIND/' + lease.name}>
                        <span className="fw-bold">
                          <span className="themeColor">{lease.name}</span>
                          .find{" "}
                        </span>
                        </Link>
                      </td>
                      <td className="store-sticker-col">
                        {!lease.auctionReservePrice && lease.latestBid && (
                          <span className="store-sticker">Offer received</span>
                        )}
                      </td>
                      <td className="store-price-col">
                        <div className="d-none d-lg-block">
                          {" "}
                          List price:{" "}
                          <b>{parseFloat(lease.salePrice).toFixed(2)} FUSD</b>
                        </div>
                        <div className="d-block d-lg-none">
                          <b>{parseFloat(lease.salePrice).toFixed(2)} FUSD</b>{" "}
                        </div>
                      </td>
                      <td className="store-time-left-col"></td>

                      <td className="store-buttons-col">
                        {" "}
                        <Button
                          onClick={() => handleWithdrawSale(lease.name)}
                          variant="find-outline-commerce-red"
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                    <tr className="mobile-row">
                      <td className="mobile-store-sticker-col">
                        {lease.latestBid && (
                          <span className="store-sticker">Offer </span>
                        )}
                      </td>
                      <td className="mobile-store-time-left-col"></td>
                      <td align="end">
                        <Button
                          onClick={() => handleWithdrawSale(lease.name)}
                          variant="find-outline-commerce-red"
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td>
                  <small>No names currently listed for sale</small>
                </td>
              </tr>
            )}
            </tbody>
          </table>
          <div className="seperator100 my-4"></div>

          <Row>
            <p>
              <b>For auction:</b>
            </p>
          </Row>
          <table className="store-table" id="store-names-for-auction-table">
            <tbody>
            {filteredAuctionLeases.length > 0 ? (
              filteredAuctionLeases.map((lease, i) => {
                // //console.log(lease);
                let ended;
                if (
                  lease.auctionEnds &&
                  lease.auctionEnds < lease.currentTime
                ) {
                  ended = true;
                } else {
                  ended = false;
                }
                return (
                  <React.Fragment key={i + "store-names-for-sale-row"}>
                    <tr
                      id={i + "store-names-for-sale-row"}
                      className="store-row"
                    >
                      <td className="store-thumb-col">{findLogo}</td>
                      <td className="store-name-col">
                        <Link to={'/mp/FIND/' + lease.name}>
                        <span className="fw-bold">
                          <span className="themeColor">{lease.name}</span>
                          .find{" "}
                        </span>
                        </Link>
                      </td>
                      <td className="store-sticker-col">
                        {lease.latestBid && (
                          <span className="store-sticker">New bids</span>
                        )}
                      </td>
                      <td className="store-price-col">
                        <div className="d-none d-lg-block">
                          {" "}
                          {!ended && lease.latestBid
                            ? "Current bid:"
                            : ended &&
                              lease.latestBid >= lease.auctionReservePrice
                            ? "Winning bid:"
                            : "Starting bid:"}{" "}
                          <b>
                            {lease.latestBid
                              ? parseFloat(lease.latestBid).toFixed(2)
                              : parseFloat(lease.auctionStartPrice).toFixed(
                                  2
                                )}{" "}
                            FUSD
                          </b>
                        </div>
                        <div className="d-block d-lg-none">
                          <b>
                            {lease.salePrice
                              ? parseFloat(lease.salePrice).toFixed(2)
                              : parseFloat(lease.auctionStartPrice).toFixed(
                                  2
                                )}{" "}
                            FUSD
                          </b>{" "}
                        </div>
                      </td>
                      <td className="store-time-left-col">
                        {lease.auctionEnds ? (
                          <span className="store-sticker">
                            {!ended ? "Ends: " : "Ended: "}
                            <ReactTimeago
                              date={new Date(lease.auctionEnds * 1000)}
                              timestyle="twitter"
                            />
                          </span>
                        ) : (
                          <span className="store-sticker">Begins on bid</span>
                        )}
                      </td>

                      <td align="end" className="store-buttons-col">
                        {" "}
                        {!ended ? (
                          <Button
                            onClick={() => handleCancelAuction(lease.name)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              handleFullfillAuction(lease.name, lease.address)
                            }
                            variant="find-outline-commerce"
                          >
                            Fulfill
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr className="mobile-row">
                      <td className="mobile-store-sticker-col">
                        {lease.latestBid && (
                          <span className="store-sticker">Bids</span>
                        )}
                      </td>
                      <td className="mobile-store-time-left-col">
                        {lease.auctionEnds ? (
                          <span className="store-sticker">
                            {!ended ? "Ends: " : "Ended: "}
                            <ReactTimeago
                              date={new Date(lease.auctionEnds * 1000)}
                              timestyle="twitter"
                            />
                          </span>
                        ) : (
                          <span className="store-sticker">Begins on bid</span>
                        )}
                      </td>
                      <td align="end">
                        {!ended ? (
                          <Button
                            onClick={() => handleCancelAuction(lease.name)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              handleFullfillAuction(lease.name, lease.address)
                            }
                            variant="find-outline-commerce"
                          >
                            Fulfill
                          </Button>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td>
                  <small>No names currently listed for auction</small>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreNamesListings;
