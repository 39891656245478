const parseImgUrl = (link, type, findNameValue, exclusion) => {
  let excluded = false;
  let findName = false;
  let nameTextOverlay =
    "tr:ot-" + findNameValue + ",ots-55,otc-58B792,ox-N166,oy-N24,ott-b";
  let findNameUrl = "/https://i.imgur.com/8W8NoO1.png";
  let parsedLink;
  let ipfsGatewayNeeded;

  if (link) {
    if (
      link.includes("bitku.art") ||
      link.includes("www.flowns.org") ||
      exclusion
    ) {
      excluded = true;
    }

    //isname
    if (link.includes("/assets/img/marketplace/find_name_with_find")) {
      findName = true;
    }

    //pinata gateway
    if (link.includes("ipfs://")) {
      const pinataRoot = process.env.REACT_APP_PINATA_ROOT || "https://gateway.pinata.cloud/"
      const pinata = pinataRoot + "ipfs/"
      parsedLink = link.replace("ipfs://", pinata);
      ipfsGatewayNeeded = true;
    } else if (link.includes("gateway.pinata")) {
      parsedLink = link.replace("gateway.pinata", "find.mypinata");
      ipfsGatewayNeeded = true;
    } else {
      parsedLink = link;
    }

    //return

    if (findName) {
      return (
        "https://ik.imagekit.io/xyvsisxky/" + nameTextOverlay + findNameUrl
      );
    }
    if (excluded || type === "fullSize" || link.includes("find.mypinata") || !type) {
      return parsedLink;
    } else if (type === "icon") {
      return ipfsGatewayNeeded
        ? `${parsedLink}` 
        : "https://ik.imagekit.io/xyvsisxky/tr:w-100,h-100/" + parsedLink;
    } else if (type === "thumbnail") {
      return ipfsGatewayNeeded
        ? `${parsedLink}` 
        : "https://ik.imagekit.io/xyvsisxky/tr:w-300,h-300/" + parsedLink;
    } else if (type === "detail") {
      return ipfsGatewayNeeded
        ? `${parsedLink}` 
        : "https://ik.imagekit.io/xyvsisxky/tr:w-600/" + parsedLink;
    } else if (parsedLink == null || parsedLink == "") {
      return "https://i.imgur.com/8W8NoO1.png";
    }

    // ! Issues with img compression and 401 via pinata
    // if (excluded || type === "fullSize" || link.includes("find.mypinata")) {
    //   return parsedLink;
    // } else if (type === "icon") {
    //   return ipfsGatewayNeeded
    //     ? parsedLink + "?&img-width=100&img-height=100&img-fit=cover&pinataGatewayToken=bB9fSKu7aVWjHnxPTv8iYGVlQOkWVl1aNM7sC9xtLQxu41FK7VHJybMhp5EOFmIH"
    //     : "https://ik.imagekit.io/xyvsisxky/tr:w-100,h-100/" + parsedLink;
    // } else if (type === "thumbnail") {
    //   return ipfsGatewayNeeded
    //     ? parsedLink + "?img-width=300&img-height=300&img-fit=cover&pinataGatewayToken=bB9fSKu7aVWjHnxPTv8iYGVlQOkWVl1aNM7sC9xtLQxu41FK7VHJybMhp5EOFmIH"
    //     : "https://ik.imagekit.io/xyvsisxky/tr:w-300,h-300/" + parsedLink;
    // } else if (type === "detail") {
    //   return ipfsGatewayNeeded
    //     ? parsedLink + "?img-width=800&img-fit=cover&pinataGatewayToken=bB9fSKu7aVWjHnxPTv8iYGVlQOkWVl1aNM7sC9xtLQxu41FK7VHJybMhp5EOFmIH"
    //     : "https://ik.imagekit.io/xyvsisxky/tr:w-600/" + parsedLink;
    // } else if (parsedLink == null || parsedLink == "") {
    //   return "https://i.imgur.com/8W8NoO1.png";
    // }

    // if ( || excluded) {
    //   return parsedLink;
    // } else if (type === "maxWidth") {

    //   return "https://ik.imagekit.io/xyvsisxky/tr:w-600/" + parsedLink;

    // } else
    //   // return "https://ik.imagekit.io/xyvsisxky/" + nameTextOverlay + "/https://find.xyz" + link;
    // } else if (parsedLink == null || parsedLink == "") {
    //   return "https://i.imgur.com/8W8NoO1.png";
    // } else if (parsedLink.includes("https://ik.imagekit.io")) {
    //   return parsedLink;
    // } else {
    //   return "https://ik.imagekit.io/xyvsisxky/tr:w-300,h-300/" + parsedLink;
    // }
  }
};

export default parseImgUrl;

//testing
// export const parseIPFSLink = (link, type) => {
//   let parsedLink = null;
//   if (link.includes("ipfs://")) {
//     // 	// //console.log("It does include!")
//     parsedLink = link.replace("ipfs://", "https://find.mypinata.cloud/ipfs/");
//   } else if (link.includes("gateway.pinata")) {
//     parsedLink = link.replace("gateway.pinata", "find.mypinata");
//   } else {
//     parsedLink = link;
//   }

//   //here add type conditional maxwidth/thumbnail

//   if (type === "thumbnail") {
//     parsedLink = parsedLink + "?img-width=300&img-height=300&img-fit=cover";
//   } else if (type === "maxWidth") {
//     parsedLink = parsedLink + "?img-width=800";
//   }

//   return parsedLink;
// };

// //compressed img thumbs using imgkit or pinata compression depending on gateway
// export const parseImgUrlThumb = (link) => {
//   let parsedLink = null;
//   if (link.includes("ipfs://")) {
//     // 	// //console.log("It does include!")
//     parsedLink =
//       link.replace("ipfs://", "https://find.mypinata.cloud/ipfs/") +
//       "?img-width=300&img-height=300&img-fit=cover";
//   } else if (link.includes("gateway.pinata")) {
//     parsedLink =
//       link.replace("gateway.pinata", "find.mypinata") +
//       "?img-width=300&img-height=300&img-fit=cover";
//   } else {
//     parsedLink = "https://ik.imagekit.io/xyvsisxky/tr:w-300,h-300/" + link;
//   }
//   return parsedLink;
// };

// //less compressed detail img using imgkit or pinata compression depending on gateway

// export const parseImgUrlDetail = (link) => {};

// // full fat media using gateway if IPFS hash
// export const parseImgUrlFullFat = (link) => {};
