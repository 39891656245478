import React from "react";
import "./DropPage.scss";

const DropPageMintBoxSplash = () => {
  return (
    <div id="drop-page-mint-box-splash-wrapper">
      <div>
        <span id="drop-page-mint-box-splash-title-span">Art Collaboration</span>
      </div>
      {/* <div>
        <span id="drop-page-mint-box-splash-subtitle-span">
          Welcome to Party Favorz!
        </span>
      </div> */}
      <br />

      <div>
        <span id="drop-page-mint-box-splash-desc-span">
          The Party Favors and guest artist Drekwon have teamed up to create
          some amazing artwork and throw an awesome Party Bash!
        </span>
      </div>
      <br />
      <div>
        <span id="drop-page-mint-box-splash-desc-span">
          From DJ sets, to awesome Giveaways, these Party Favors will ensure you
          have access to all the fun happening!
        </span>
      </div>
    </div>
  );
};

export default DropPageMintBoxSplash;
