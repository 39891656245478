import { atom, useAtom } from "react-atomic-state";

const walletType = atom(null);
const userWalletStatus = atom(null);

export const setWalletType = (user) => {
  // console.log(user);
  if (user && user.loggedIn && user.services) {
    walletType.set(user.services[0].provider.name);
  } else {
    walletType.set("none");
  }
};

export const setUserWalletStatus = (user) => {
  // console.log(user);
  if (user) {
    userWalletStatus.set(user);
  }
};

export const useWalletType = () => useAtom(walletType);
export const useUserWalletStatus = () => useAtom(userWalletStatus);
