import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import parseNameLink from "../../../../../functions/parseNameLink";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./detailspropertycard.css";
import TraitsDisplay from "./TraitsDisplay";
const CatalogDetailsPropertyCard = ({ listingStatus }) => {
  // console.log(listingStatus);

  let listingDetails;
  let itemRoyalties = null;
  let itemTags = null;
  let itemScalars = null;
  let itemMedia = null;
  let itemThumbnail = null;
  let itemAllowedListings = null;
  let itemCurrentListings = {
    forAuction: false,
    forSale: false,
    offered: false,
  };
  let itemCollectionDescription = null;
  let itemCollectionName = null;
  let itemCatalogName = null;
  let itemEdition = null;
  let itemEditionMax = null;
  let itemCollectionContract = null;
  let itemOwner = null;
  let itemOwnerAddress = null;
  let itemType = null;
  let itemIsListed = null;
  let itemRarity = null;
  let itemTraits = null;
  let itemTraitsSorted = null;
  let itemDisplay = null;

  if (Object.keys(listingStatus.listingDetails).length > 0) {
    if (
      listingStatus.listingDetails.nftDetail &&
      listingStatus.listingDetails.nftDetail.traits
    ) {
      itemTraits = listingStatus.listingDetails.nftDetail.traits;
      itemTraitsSorted = Object.values(itemTraits).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      // console.log(itemTraitsSorted);
    }

    if (listingStatus?.listingDetails?.nftDetail?.collection) {
      itemCollectionName =
        listingStatus.listingDetails.nftDetail.collection.name;

      // console.log(itemCollectionName);
    }
    if (listingStatus?.listingDetails?.nftDetail?.type)
      itemCatalogName =
        listingStatus?.listingDetails?.nftDetail?.type.split(".")[2];
  }

  // console.log(itemCatalogName)

  // console.log(itemTraits);
  if (
    listingStatus.owner.ownerProfile &&
    Object.keys(listingStatus.owner.ownerProfile).length !== 0 &&
    Object.getPrototypeOf(listingStatus.owner.ownerProfile) === Object.prototype
  ) {
    itemOwner = listingStatus.owner.ownerProfile;
  }

  if (listingStatus.indexHit && listingStatus.indexHit.nft_rarity) {
    itemRarity = listingStatus.indexHit.nft_rarity;
  }

  if (listingStatus?.listingDetails?.nftDetail?.collectionName) {
    itemCollectionName = listingStatus.listingDetails.nftDetail.collectionName;
  }

  //market
  // console.log(listingStatus);
  //royalties
  if (
    listingStatus.itemRoyalties.Sale &&
    Object.keys(listingStatus.itemRoyalties.Sale).length !== 0 &&
    Object.getPrototypeOf(listingStatus.itemRoyalties.Sale) === Object.prototype
  ) {
    itemRoyalties = listingStatus.itemRoyalties.Sale;
  }

  //allowedlistings
  if (
    listingStatus.listingDetails.allowedListingActions &&
    Object.keys(listingStatus.listingDetails.allowedListingActions).length !==
      0 &&
    Object.getPrototypeOf(
      listingStatus.listingDetails.allowedListingActions
    ) === Object.prototype
  ) {
    itemAllowedListings = listingStatus.listingDetails.allowedListingActions;
  }

  //currentListings

  if (listingStatus.forAuction === true) {
    itemCurrentListings.forAuction = true;
  }
  if (listingStatus.forSale === true) {
    itemCurrentListings.forSale = true;
  }
  if (listingStatus.offered === true) {
    itemCurrentListings.offered = true;
  }

  //details
  if (listingStatus.listingDetails.nftDetail) {
    listingDetails = listingStatus.listingDetails.nftDetail;
    if (listingStatus.listingDetails.nftDetail.type) {
      itemType = listingStatus.listingDetails.nftDetail.type;
    }

    if (listingStatus.listingDetails.nftDetail.thumbnail) {
      itemThumbnail = listingStatus.listingDetails.nftDetail.thumbnail;
    }

    if (listingStatus.listingDetails.nftDetail.collection.description) {
      itemCollectionDescription =
        listingStatus.listingDetails.nftDetail.collection.description;
    }

    if (
      listingStatus.listingDetails.nftDetail.tags &&
      Object.keys(listingStatus.listingDetails.nftDetail.tags).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.nftDetail.tags) ===
        Object.prototype
    ) {
      itemTags = listingStatus.listingDetails.nftDetail.tags;
    }
    if (
      listingStatus.listingDetails.nftDetail.scalars &&
      Object.keys(listingStatus.listingDetails.nftDetail.scalars).length !==
        0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.nftDetail.scalars) ===
        Object.prototype
    ) {
      itemScalars = listingStatus.listingDetails.nftDetail.scalars;
    }

    if (
      listingStatus.listingDetails.nftDetail.media &&
      Object.keys(listingStatus.listingDetails.nftDetail.media).length !== 0
    ) {
      itemMedia = listingStatus.listingDetails.nftDetail.media;
      // console.log(itemMedia);
    }

    if (listingStatus.itemEdition) {
      itemEdition = listingStatus.itemEdition;
      if (listingStatus.itemEditionMax) {
        itemEditionMax = listingStatus.itemEditionMax;
      }
    }
  }

  // console.log(listingDetails);

  if (listingDetails) {
    return (
      <Card style={{backgroundColor: 'var(--background)'}} id="nft-detail-details-card" className="nft-details-card px-0">
        <Card.Body>
          <Row>
   

            <Col className="px-0" xs="12" >
              {itemEdition && (
                <div className="flex-row pb-4 align-items-center">
                  <i
                    style={{ fontSize: "30px" }}
                    className="fa-solid fa-newspaper mx-1 secColor"
                  ></i>
                  &nbsp; &nbsp;
                  <span className="fw-bold small"> Edition:&nbsp;&nbsp;</span>
                  <span className="secColor small fw-bold">
                    {itemEdition}
                    {itemEditionMax && <>&nbsp;/&nbsp;{itemEditionMax}</>}
                  </span>
                </div>
              )}

              {itemTraits && Object.keys(itemTraits).length > 0 && (
                <>
                  <div className="flex-row pb-4 align-items-center">
                    <i
                      style={{ fontSize: "30px" }}
                      className="fas fa-star mx-1 secColor"
                    />
                    &nbsp;&nbsp;
                    <span className="fw-bold small">Traits:&nbsp;</span>
                  </div>

                  {itemCollectionName &&
                  itemCollectionName !== "Crypto Pharaohs" &&
                  itemCollectionName !== "Pharaoh Cats" &&
                  itemCollectionName !== "The Crypto Piggo NFT Collection" ? (
                    <Row className="pb-3 propRow">
                      <TraitsDisplay traitsList={itemTraitsSorted} itemCatalogName={itemCatalogName}/>
                    </Row>
                  ) : itemCollectionName ===
                    "The Crypto Piggo NFT Collection" ? (
                    <Row className="pb-3 propRow">
                      {itemTraitsSorted.map((properties, i) => {
                        return (
                          <Col
                            key={i}
                            xs="8"
                            sm="6"
                            lg="6"
                            xl="6"
                            xxl="4"
                            className="propertiesTraits mb-3"
                          >
                            <Card>
                              <Card.Body className="traitsWrap">
                                {properties.name && (
                                  <Row>
                                    <span className="fw-bold">
                                      {properties.name}
                                    </span>
                                  </Row>
                                )}

                                {properties.value &&
                                  Object.keys(properties.value).length > 0 && (
                                    <>
                                      {properties.value && (
                                        <span className="themeColor">
                                          {properties.value}
                                        </span>
                                      )}
                                    </>
                                  )}
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <Row className="pb-3 propRow">
                      {itemTraitsSorted.map((properties, i) => {
                        return (
                          <Col
                            key={i}
                            xs="8"
                            sm="6"
                            lg="6"
                            xl="6"
                            xxl="4"
                            className="propertiesTraits mb-3"
                          >
                            <Card>
                              <Card.Body className="traitsWrap">
                                {properties.name && (
                                  <Row>
                                    <span className="fw-bold">
                                      {properties.name}
                                    </span>
                                  </Row>
                                )}

                                {properties.value &&
                                  Object.keys(properties.value).length > 0 && (
                                    <>
                                      {properties.value.name && (
                                        <span className="themeColor">
                                          {properties.value.name}
                                        </span>
                                      )}
                                      {properties.value.additionalData && (
                                        <span className="secColor fw-bold">
                                          {properties.value.additionalData}
                                        </span>
                                      )}
                                      {properties.value.primaryPower && (
                                        <span className="secColor">
                                          {properties.value.primaryPower}
                                        </span>
                                      )}{" "}
                                      {properties.value.secondaryPower && (
                                        <span className="secColor">
                                          {properties.value.secondaryPower}
                                        </span>
                                      )}
                                      {properties.value.rarity && (
                                        <span className="secColor">
                                          {properties.value.rarity}
                                        </span>
                                      )}
                                    </>
                                  )}
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
    // return (
    //   <Card id="nft-detail-details-card" className="nft-details-card">
    //     <Card.Body>
    //       <Row className="py-3 pb-4">
    //         <h1 style={{ textAlign: "start" }}>
    //           {listingDetails.name ? (
    //             listingDetails.name
    //           ) : (
    //             <span>
    //               {listingDetails.collectionName} #{listingDetails.id}
    //             </span>
    //           )}
    //         </h1>
    //         <Col className="propertiesWrap"></Col>
    //       </Row>

    //       {itemOwner && (
    //         <Row className="align-items-center py-3 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Owned By:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">{parseNameLink(itemOwner)}</Col>
    //         </Row>
    //       )}

    //       {itemOwnerAddress && (
    //         <Row className="align-items-center py-3 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Owned By:</h3>
    //           </Col>
    //           <Col className="propertiesWrap"><Link to={'/' + itemOwnerAddress}><span className="fw-bold">{itemOwnerAddress}</span></Link></Col>
    //         </Row>
    //       )}

    //       {itemEdition && (
    //         <Row className="align-items-center y-2 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Edition:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {listingDetails.name ? (
    //               listingDetails.name
    //             ) : (
    //               <span>
    //                 {listingDetails.collectionName}
    //                 {listingDetails.id}
    //               </span>
    //             )}
    //           </Col>
    //         </Row>
    //       )}

    //       {itemThumbnail && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Thumbnail:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             <a
    //               target="_blank"
    //               rel="noreferrer"
    //               href={parseImgUrl(itemThumbnail)}
    //             >
    //               {itemThumbnail}
    //             </a>
    //           </Col>
    //         </Row>
    //       )}

    //       <Row className="py-3 align-items-center">
    //         {listingDetails.id && (
    //           <Col>
    //             <Row className="align-items-center">
    //               <Col xs="12" md="6">
    //                 <h3 className="themeColor">ID:</h3>
    //               </Col>
    //               <Col className="propertiesWrap">{listingDetails.id}</Col>
    //             </Row>
    //           </Col>
    //         )}
    //         {listingDetails.uuid && (
    //           <Col>
    //             <Row className="align-items-center">
    //               <Col xs="12" md="6">
    //                 <h3 className="themeColor">UUID:</h3>
    //               </Col>
    //               <Col className="propertiesWrap">{listingDetails.uuid}</Col>
    //             </Row>
    //           </Col>
    //         )}
    //       </Row>

    //       <Row className="py-3 align-items-center">
    //         <Col xs="12" md="3">
    //           <h3 className="themeColor">Collection:</h3>
    //         </Col>
    // <Col className="propertiesWrap">
    //   <span>
    //     {listingDetails.collectionName}
    //   </span>
    //   <br />
    //   <span>
    //     <a
    //       target="_blank"
    //       rel="noreferrer"
    //       href={
    //         "https://flowscan.org/contract/" +
    //         itemType.replaceAll(".NFT", "")
    //       }
    //     >
    //       {itemType.replaceAll(".NFT", "")}
    //     </a>
    //   </span>
    //   <br />
    //   <span>
    //     <a
    //       target="_blank"
    //       rel="noreferrer"
    //       href={
    //         "https://nft-catalog.vercel.app/catalog/" +
    //         listingDetails.collectionName
    //       }
    //     >
    //     {listingDetails.collectionName} @ Flow NFT Catalog
    //     </a>
    //   </span>{" "}
    //   <br />
    //   {itemCollectionDescription && (
    //     <span>{itemCollectionDescription}</span>
    //   )}
    // </Col>
    //       </Row>

    //       {listingDetails.rarity && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Rarity:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">{listingDetails.rarity}</Col>
    //         </Row>
    //       )}

    //       {itemTags && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Tags:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {Object.keys(listingDetails.tags).map((properties, i) => {
    //               // console.log(listingDetails);
    //               return (
    //                 <div key={i}>
    //                   <span className="fw-bold">
    //                     {properties.replaceAll("_", " ")}:{" "}
    //                   </span>
    //                   {listingDetails.tags[properties]}
    //                 </div>
    //               );
    //             })}
    //           </Col>
    //         </Row>
    //       )}

    //       {itemScalars && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Scalars:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {Object.keys(itemScalars).map((properties, i) => {
    //               // console.log(listingDetails);
    //               return (
    //                 <div key={i}>
    //                   <span className="fw-bold">
    //                     {properties.replaceAll("_", " ")}:{" "}
    //                   </span>

    //                   {itemScalars[properties] * 1}
    //                 </div>
    //               );
    //             })}
    //           </Col>
    //         </Row>
    //       )}

    // {itemAllowedListings && (
    //   <Row className="py-3 align-items-center">
    //     <Col xs="12" md="3">
    //       <h3 className="themeColor">Allowed market actions:</h3>
    //     </Col>
    //     <Col className="propertiesWrap">
    //       <Row>
    //         {Object.keys(itemAllowedListings).map((properties, i) => {
    //           return <div key={i}>{properties}</div>;
    //         })}
    //       </Row>
    //     </Col>
    //   </Row>
    // )}

    //       <Row className="py-3 align-items-center">
    //         <Col xs="12" md="3">
    //           <h3 className="themeColor">Current Market Listings:</h3>
    //         </Col>
    //         <Col className="propertiesWrap">
    // {itemIsListed ? (
    //   <Row>
    //     {itemCurrentListings.forAuction && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Auction</span>
    //       </Link>
    //     )}
    //     {itemCurrentListings.forSale && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Sale</span>
    //       </Link>
    //     )}
    //     {itemCurrentListings.offered && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Offered</span>
    //       </Link>
    //     )}
    //   </Row>
    // ) : (
    //   <Row>
    //     <span>None</span>
    //   </Row>
    // )}
    //         </Col>
    //       </Row>

    //       {itemRoyalties && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Royalties:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    // <Row>
    //   {Object.keys(itemRoyalties).map((properties, i) => {
    //     return (
    //       <Col key={i} xs="auto" className="propertiesRoyalty m-1">
    //         <div>
    //           <span className="fw-bold">Royalty Name:</span>{" "}
    //           {itemRoyalties[properties].royaltyName}
    //         </div>
    //         <div>
    //           <span className="fw-bold">Paid to:</span>{" "}
    //           {itemRoyalties[properties].findName ? (
    //             <Link
    //               style={{ textDecoration: "none" }}
    //               to={"/" + itemRoyalties[properties].findName}
    //             >
    //               <span className="fw-bold">
    //                 <span className="themeColor">
    //                   {itemRoyalties[properties].findName}
    //                 </span>
    //                 .find
    //               </span>
    //             </Link>
    //           ) : (
    //             itemRoyalties[properties].address
    //           )}
    //         </div>
    //         <div>
    //           <span className="fw-bold">Cut percentage:</span>{" "}
    //           {parseFloat(itemRoyalties[properties].cut).toFixed(
    //             2
    //           ) * 100}
    //           %
    //         </div>
    //       </Col>
    //     );
    //   })}
    // </Row>
    //           </Col>
    //         </Row>
    //       )}
    //     </Card.Body>
    //   </Card>
    // );
  } else {
    return <p>No properties</p>;
  }
};

export default CatalogDetailsPropertyCard;
