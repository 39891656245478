import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import UppercaseFirstLetter from "../../../../functions/UppercaseFirstLetter";
import { CollectionTabPage } from "../collectiontabpage/collectiontabpage";
import EmptyWalletPlaceholder from "./EmptyWalletPlaceholder";
import "./ProfileCollectionWalletTabs.scss";

const EmeraldWalletTab = ({
  walletCollection,
  walletAddress,
  profileData,
  type,
  activeTabKey,
}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionData, setSelectedCollectionData] = useState(null);
  const { name, walletProvider, colName } = useParams();
  const navigate = useNavigate();
  function handleChangeCollection(e) {
    if (e) {
      const collectionName = e;
      setSelectedCollection(collectionName);
      setSelectedCollectionData(walletCollection[collectionName]);
      if (type === "owner") {
        navigate("/me/collection/" + activeTabKey + "/" + collectionName);
      } else
        navigate(
          "/" + name + "/collection/" + activeTabKey + "/" + collectionName
        );
    }
  }

  //useeffect sets selected collection to colName url param if there and first if none
  useEffect(() => {
    if (walletCollection && !selectedCollection) {
      if (colName && !selectedCollection) {
        setSelectedCollection(colName);
        setSelectedCollectionData(walletCollection[colName]);
      } else {
        setSelectedCollection(Object.keys(walletCollection)[0]);
        setSelectedCollectionData(
          walletCollection[Object.keys(walletCollection)[0]]
        );
      }
    }
  }, [walletCollection, colName]);

  // console.log(selectedCollection);
  // console.log(selectedCollectionData);

  return (
    <div>
      {Object.keys(walletCollection) &&
      Object.keys(walletCollection).length > 0 ? (
        <div>
          <Row className="my-4 px-3 align-items-center justify-content-between">
            <Col xs="12" md="auto" className="align-items-start">
              <span className="viewing-span">
                Viewing <span>{type === "owner" ? "your" : `${name}'s`}</span>{" "}{UppercaseFirstLetter(activeTabKey)}{' '}
                <span className="fw-bold themeColor">{selectedCollection}</span>{" "}
                collection  
              </span>
            </Col>
            <Col s="12" md="auto" className="align-items-end ">
              <Form.Select
                onChange={(e) => handleChangeCollection(e.currentTarget.value)}
                className="mb-2"
                //   onChange={(e) => setSelectedDropdown(e.target.value)}
              >
                {Object.keys(walletCollection) &&
                  Object.keys(walletCollection).map((collectionName) => {
                    return (
                      <option
                        selected={collectionName === selectedCollection}
                        value={collectionName}
                        key={collectionName}
                      >
                        {collectionName} (
                        {walletCollection[collectionName].length})
                      </option>
                    );
                  })}
              </Form.Select>
            </Col>
          </Row>
          <Tabs className="collection-tabs" activeKey={selectedCollection}>
            {Object.keys(walletCollection) &&
              Object.keys(walletCollection).map((collectionName, i) => {
                return (
                  <Tab
                    title={collectionName}
                    key={collectionName}
                    mountOnEnter
                    eventKey={collectionName}
                  >
                    <Row className="hits-row px-2">
                      <CollectionTabPage
                        collectionData={walletCollection[collectionName]}
                        name={name}
                        address={walletAddress}
                        profileData={profileData}
                        activeTabKey={activeTabKey}
                      />
                    </Row>
                  </Tab>
                );
              })}
          </Tabs>
        </div>
      ) : (
        <EmptyWalletPlaceholder />
      )}{" "}
    </div>
  );
};

export default EmeraldWalletTab;
