import Loading from "../../loading/loading";
import LatestBidsCard from "./latestbidscard";

const LatestBids = ({
  listingDetails,
  isName,
  forAuction,
  listingStatus,
  eventsData,
}) => {
  if (forAuction && listingDetails && eventsData) {
    return (
      <>
        {eventsData.length > 0 && <span className="fw-bold">Latest bids:</span>}
        {eventsData.length > 0 &&
          eventsData.map((activity, i) => {
            return (
              <LatestBidsCard
                key={i}
                activity={activity}
                i={i}
                listingDetails={listingDetails}
                isName={isName}
              />
            );
          })}
      </>
    );
  } else {
    return <Loading />;
  }
};

export default LatestBids;
