import axios from "axios";
import React, { useEffect, useState } from "react";
import { useStateChanged } from "./DisabledState";

const useGetLatestBids = (isName, listingStatus, listingDetails, hits) => {
  const [eventsData, setEventsData] = useState("");
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  // console.log(listingStatus)

  // console.log(listingDetails)
  // listingDetails={
  //     isName
  //       ? listingDetails.leaseStatus
  //       : listingDetails.findMarket.FindMarketAuctionEscrow
  //   }

  useEffect(() => {
    if (hits && listingDetails && listingStatus){
    if (listingStatus.forAuction && listingStatus.auctionStarted) {
      const getEvents = async () => {
        if (!isName) {
          // //console.log("getSales fired")
          let data;
          let res = await axios.get(
            "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
              contractAddress +
              ".FindMarketAuctionEscrow.EnglishAuction&id=" +
              listingDetails.nftDetail.uuid +
              // listingDetails.findMarket.FindMarketAuctionEscrow.listingId +
              "&pageSize=3"
          );
          data = res.data;
          setEventsData(
            data.filter(
              (event) => event.blockEventData.status === "active_ongoing"
            )
          );
        } else {
          // //console.log("getSales fired")
          let data;
          let res = await axios.get(
            "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
              contractAddress +
              ".FIND.EnglishAuction&name=" +
              listingDetails.leaseStatus.name +
              "&pageSize=3"
          );
          data = res.data;
          // console.log(data);
          setEventsData(
            data.filter(
              (event) => event.blockEventData.status === "active_ongoing"
            )
          );
        }
      };

      getEvents();
    }
  }}
  
  , [
    listingStatus?.listingDetails?.nftDetail?.id
  ]);

  return eventsData;
};

export default useGetLatestBids;
