import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  InstantSearch,
  SortBy,
  Configure,
  // SearchBox,
  // Highlight,
  // RefinementList,
  // Pagination,
  // Hits,
  // RangeInput,
  // CurrentRefinements,
  // Menu,
  // ClearRefinements,
  // InfiniteHits,
} from "react-instantsearch-hooks-web";
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";

import "../../App.css";
import "./marketplace.css";

import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { connectStateResults } from "react-instantsearch-dom";
import CustomHits from "../../components/search/CustomHits";
import CustomSearchBox from "../../components/search/CustomSearchBox";
import CustomPagination from "../../components/search/CustomPagination";
import CustomCollectionMenu from "../../components/search/CustomCollectionMenu";
import CustomSaleTypeMenu from "../../components/search/CustomSaleTypeMenu";
import { useLocation, useParams } from "react-router-dom";
import CustomAttributes from "../../components/search/CustomAtrributesMenu";
import CustomCount from "./CustomCount";
import CustomCurrentRefinements from "./CustomCurrentRefinements";
import CustomRarityMenu from "./CustomRarityMenu";
import MpSideBar from "./mpsidebar/mpsidebar";
import CustomRefinementList from "./CustomRefinementList";
import CustomCurrencyRefinement from "./CustomCurrencyRefinement";
import MpMobileSideBar from "./mpmobilesidebar/mpmobilesidebar";
import { history } from "instantsearch.js/es/lib/routers";
import { simple } from "instantsearch.js/es/lib/stateMappings";
import { refineQuery, UseRefineQuery } from "../../functions/refineQuery";
import CustomHitsInfinite from "./CustomHitsInfinite";
import CustomClearRefinements from "./CustomClearRefinements";
import { setShowFilters, useShowFilters } from "../../functions/filtersState";
import { useWalletType } from "../../functions/useWalletType";
const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: typesenseHostUrl,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nft_name, nft_alias, seller_name, seller, listing_alias",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;
// console.log(searchClient)

function MarketPlacePage({ metaTitle }) {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [floatState, setFloatState] = useState("-FLOAT");
  const walletState = useWalletType();
  // console.log(walletState)
  const sessionStorageCache = createInfiniteHitsSessionStorageCache();
  const indexName = "market";
  // const handleShowMobileFilters = () => {
  //   setShowMobileFilters(!showMobileFilters);
  // };
  // const handleShowFilters = () => {
  //   setShowSideBarFilters(!showSideBarFilters);
  // };

  const showFilters = useShowFilters();

  // console.log(showFilters)

  const routing = {
    router: history({
      windowTitle({ collection, query }) {
        const queryTitle = query ? `Results for "${query}"` : metaTitle;
        if (collection && collection.length > 0) {
          return `${collection} / .find`;
        } else if (query) return queryTitle;
        // if (title.includes('/mp'))
        //   return queryTitle;
      },

      createURL({ qsModule, routeState, location }) {
        // console.log(routeState);
        const urlParts = location.href.match(/^(.*?)\/mp/);
        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;
        const queryParameters = {};

        if (routeState.collection) {
          queryParameters.collection =
            routeState.collection.map(encodeURIComponent);
        }
        if (routeState.traits) {
          // let formatTraits = routeState.traits.map(trait =>  trait.split('=').map(encodeURIComponent).join('-'))
          // console.log(formatTraits)
          queryParameters.traits = routeState.traits;
        }
        if (routeState.sale_type) {
          queryParameters.sale_type =
            routeState.sale_type.map(encodeURIComponent);
        }
        if (routeState.rarity) {
          queryParameters.rarity = routeState.rarity.map(encodeURIComponent);
        }
        if (routeState.range && routeState.range.amount) {
          // console.log(routeState.range);
          queryParameters.range = routeState.range.amount
            .split(":")
            .map(encodeURIComponent)
            .join("-");
        }
        if (routeState.query) {
          queryParameters.query = encodeURIComponent(routeState.query);
        }
        if (routeState.page) {
          queryParameters.page = routeState.page;
        }

        if (routeState.currency) {
          queryParameters.currency = routeState.currency;
        }

        if (routeState.sortBy) {
          queryParameters.sortBy = routeState.sortBy
            .split("/")
            .map(encodeURIComponent)
            .join("-");
        }
        const queryString = qsModule.stringify(queryParameters, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        });

        // console.log(queryParameters);

        if (queryString.length > 0) {
          refineQuery(queryString);
        }
        return `${baseUrl}mp${queryString}`;
      },

      parseURL({ qsModule, location }) {
        const pathnameMatches = location.pathname.match(/mp\/(.*?)\/?$/);
        const {
          query = "",
          page,
          collection = [],
          sale_type = [],
          rarity = [],
          range,
          traits = [],
          currency,
          sortBy,
        } = qsModule.parse(location.search.slice(1));

        // console.log(page);
        // `qs` does not return an array when there's a single value.
        const allCollections = Array.isArray(collection)
          ? collection
          : [collection].filter(Boolean);
        const allSalesTypes = Array.isArray(sale_type)
          ? sale_type
          : [sale_type].filter(Boolean);
        const allRarity = Array.isArray(rarity)
          ? rarity
          : [rarity].filter(Boolean);
        const allTraits = Array.isArray(traits)
          ? traits
          : [traits].filter(Boolean);
        const fullRange = {
          ["amount"]: range?.split("-").map(decodeURIComponent).join(":"),
        };
        const fullSortBy = sortBy?.split("-").map(decodeURIComponent).join("/");
        return {
          collection: allCollections.map(decodeURIComponent),
          currency: currency,
          sale_type: allSalesTypes.map(decodeURIComponent),
          rarity: allRarity.map(decodeURIComponent),
          traits: allTraits.map(decodeURIComponent),
          range: fullRange,
          query: decodeURIComponent(query),
          page,
          sortBy: fullSortBy,
        };
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        // console.log(uiState)
        const indexUiState = uiState[indexName];
        // console.log(Object.keys(indexUiState.refinementList))
        const attributes = indexUiState?.refinementList;
        let filteredTraits = [];
        if (attributes) {
          let attributeArr = Object.entries(attributes);
          filteredTraits = attributeArr
            .filter((item) => item[0] !== "nft_alias")
            .filter((item) => item[0] !== "listing_alias")
            .filter((item) => item[0] !== "nft_rarity")
            .filter((item) => item[0] !== "collection_name");

          // console.log(filteredTraits);
        }
        return {
          query: indexUiState?.query,
          currency: indexUiState?.menu?.amount_alias,
          collection: indexUiState?.refinementList?.collection_name,
          sale_type: indexUiState?.refinementList?.listing_alias,
          rarity: indexUiState?.refinementList?.nft_rarity,
          range: indexUiState?.range,
          page: indexUiState?.page,
          sortBy: indexUiState?.sortBy,

          traits: filteredTraits.map((attribute) => {
            return attribute[0] + "=" + attribute[1];
          }),
        };
      },

      routeToState(routeState) {
        // console.log(routeState);
        // let pageNumber;
        // if (routeState.page) {
        //   pageNumber = Number(routeState.page);
        //   console.log(pageNumber);
        // }
        let preTraits = routeState.traits;
        let traitRefinements = {
          refinementList: {
            collection_name: routeState.collection,
            listing_alias: routeState.sale_type,
            nft_rarity: routeState.rarity,
          },
        };
        // console.log(traitRefinements)
        if (routeState.traits) {
          preTraits.forEach((trait) => {
            let traitToAdd = trait.match(/[^=]*/i)[0];
            let valuesToAdd = trait.split(/[=,]/gi);
            valuesToAdd.splice(0, 1);
            traitRefinements.refinementList = {
              ...traitRefinements.refinementList,
              ...{ [traitToAdd]: valuesToAdd },
            };
          });
        }
        return {
          [indexName]: {
            query: routeState.query,
            menu: {
              amount_alias: routeState.currency,
            },
            range: routeState.range,

            refinementList: traitRefinements.refinementList,
            page: routeState.page,
            sortBy: routeState.sortBy,
          },
        };
      },
    },
  };

  // const routing = {
  //   routing: {
  //     router: history(),`
  //   }}

  // const routing = {
  //   router: history(),
  //   stateMapping: simple(),
  // };

  // const onStateChange = ({ uiState, setUiState }) => {
  //   // Custom logic
  //   console.log(uiState)
  //   setUiState(uiState);

  // };
  // const facetFilters =
  //   !walletState ?
  //   floatState ?
  //     ["collection_name:-FLOAT"]:
  //       []
  //   :
  //   floatState ?
  //     walletState === "Dapper Wallet" ?
  //       ["collection_name:-FLOAT", "tenant:-find"]
  //       :
  //       ["collection_name:-FLOAT", "tenant:-find_dapper"]
  //       :
  //       walletState === "Dapper Wallet" ?
  //       ["tenant:-find"]
  //       :
  //       ["tenant:-find_dapper"]

  const [showAll, setShowAll] = useState(false);

  const facetFilters = showAll
    ? floatState
      ? ["collection_name:-FLOAT"]
      : []
    : walletState === "none"
    ? floatState
      ? ["collection_name:-FLOAT"]
      : []
    : walletState === "Dapper Wallet"
    ? floatState
      ? ["collection_name:-FLOAT", "tenant:-find"]
      : ["tenant:-find"]
    : floatState
    ? ["collection_name:-FLOAT", "tenant:-find_dapper"]
    : ["tenant:-find_dapper"];

  return (
    <div className="" id="marketplacepage">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        routing={routing}
      >
        <Configure facetFilters={facetFilters} hitsPerPage="24" />
        <MpMobileSideBar
          showFilters={showFilters}
          floatState={floatState}
          setFloatState={setFloatState}
          showAll={showAll}
          setShowAll={setShowAll}
        />
        <Row className="pt-0 mp-row">
          <Col
            id="filters-col"
            xs="4"
            sm="4"
            md="3"
            lg="3"
            xl="2"
            className={showFilters ? "" : "hidden-filters"}
          >
            <MpSideBar
              floatState={floatState}
              setFloatState={setFloatState}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          </Col>

          <Col>
            <Row id="mp-title-row" className="align-items-center px-0 pb-3">
              <div className="flex-row pt-2 pt-sm-4 pb-2 pb-sm-2">
                <Col xs="auto" className="pb-0 d-none d-sm-block sidebar-col">
                  {!showFilters && (
                    <Button
                      onClick={() => setShowFilters(true)}
                      className="flex-center fw-bold filterButton"
                      style={{
                        backgroundColor: "var(--background-profile)",
                        boxShadow: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "var(--text-primary)",
                      }}
                    >
                      {" "}
                      <i className="fa-solid fa-angle-right" />
                      &nbsp;&nbsp; Filters{" "}
                    </Button>
                  )}
                  {/* <ClearRefinements/> */}
                </Col>
                <Col className="px-0">
                  <CustomCount />
                </Col>
                <Col></Col>
                <Col className="d-none d-lg-block" lg="2">
                  <CustomCurrencyRefinement />
                </Col>
                &nbsp;
                <Col className="d-none d-lg-block" lg="2">
                  <SortBy
                    items={[
                      { label: "Sort By", value: "market" },
                      { label: "Price ▲", value: "market/sort/amount:asc" },
                      {
                        label: "Price ▼",
                        value: "market/sort/amount:desc",
                      },
                      {
                        label: "Ends soonest",
                        value: "market/sort/ends_at:asc",
                      },
                      {
                        label: "Activity",
                        value: "market/sort/updated_at:desc",
                      },
                    ]}
                    classNames={{ select: "mpSortBy form-select" }}
                  />
                </Col>
                &nbsp;
              </div>
              <div
                className="d-flex d-lg-none align-items-center px-2 "
                id="mp-title-mobile-row"
              >
                <Col xs="4">
                  <div className="d-sm-none">
                    <Button
                      onClick={() => setShowFilters(true)}
                      className="flex-center fw-bold filterButton px-0"
                      style={{
                        backgroundColor: "var(--background-profile)",
                        boxShadow: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "var(--text-primary)",
                      }}
                    >
                      {" "}
                      <i className="fa-solid fa-angle-right" />
                      &nbsp;&nbsp; Filters{" "}
                    </Button>
                  </div>
                </Col>
                <Col xs="8">
                  <Row className="align-items-end justify-content-end">
                    <Col className="" xs="5">
                      <CustomCurrencyRefinement />
                    </Col>
                    &nbsp;
                    <Col className="" xs="5">
                      <SortBy
                        items={[
                          { label: "Sort By", value: "market" },
                          { label: "Price ▲", value: "market/sort/amount:asc" },
                          {
                            label: "Price ▼",
                            value: "market/sort/amount:desc",
                          },
                          {
                            label: "Ends soonest",
                            value: "market/sort/ends_at:asc",
                          },
                          {
                            label: "Activity",
                            value: "market/sort/updated_at:asc",
                          },
                        ]}
                        classNames={{ select: "mpSortBy form-select" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </div>
              <Row>
                <Col className="px-0 px-sm-3">
                  <CustomCurrentRefinements />
                </Col>
              </Row>
            </Row>

            {/* <Row>
              <Col className="px-0 px-sm-3">
                {/* <ClearRefinements/>
                  <CustomClearRefinements /> */}
            {/* </Col>
            </Row> */}
            <Row className="hits-row">
              <CustomHitsInfinite
                // Optional parameters
                showPrevious={true}
                // hitComponent={function}
                // translations={object}
                cache={sessionStorageCache}
              />
            </Row>
          </Col>
        </Row>
      </InstantSearch>
    </div>
  );
}

export default MarketPlacePage;
