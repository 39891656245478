import React, { useEffect, useState } from "react";
import DropPageBannerBio from "./DropPageBannerBio";
import DropPageHeader from "./DropPageHeader";
import DropPageMintBox from "./DropPageMintBox";
import "./DropPage.scss";
import DropPageInfo from "./DropPageInfo";
import { Row } from "react-bootstrap";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import { Script, Tx } from "../../functions/script";
import { useNavigate, useParams } from "react-router";
import { useUserProfile } from "../../functions/useUserProfile";

const DropPage = () => {
  //  partyfavorz 2
  const [packDetails, setPackDetails] = useState();
  const [numOfPacks, setNumOfPacks] = useState(1);
  let { colName, packId } = useParams();
  const navigate = useNavigate();
  const user = useUserProfile();


  useEffect(() => {
    if (user && user.loggedIn) {
      async function getFindPackSaleDetailsWithUser() {
        const res = await Script(scripts.getFindPackSaleDetailsWithUser, {
          packTypeName: colName,
          packTypeId: parseInt(packId),
          user: user.addr,
        });
        // console.log(res);
        setPackDetails(res);
      }
      try {
        getFindPackSaleDetailsWithUser();
      } catch (error) {
        console.log(error);
      }
    } else {
      async function getAllFindPackSaleDetails() {
        const res = await Script(scripts.getAllFindPackSaleDetails, {
          packTypeName: colName,
        });
        // console.log(res);

        // console.log(res)
        // console.log(res);
        setPackDetails(res[packId]);
      }
      try {
        getAllFindPackSaleDetails();
      } catch (error) {
        console.log(error);
      }
    }
  }, [user, colName]);

  // console.log(
  //   parseFloat(packDetails.saleInfos[0].price).toFixed(2) * parseInt(numOfPacks)
  // );

  const purchasePack = async () => {
    try {
      await Tx({
        tx: transactions.buyFindPack,
        args: {
          packTypeName: colName,
          packTypeId: parseInt(packId),
          numberOfPacks: parseInt(numOfPacks),
          totalAmount:
            parseFloat(packDetails.saleInfos[0].price).toFixed(2) *
            parseInt(numOfPacks),
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            navigate("/me/collection/main/FindPack");
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div id="drop-page-wrapper">
      <DropPageHeader />
      <DropPageBannerBio packDetails={packDetails} />
      <DropPageMintBox
        packDetails={packDetails}
        numOfPacks={numOfPacks}
        setNumOfPacks={setNumOfPacks}
        purchasePack={purchasePack}
      />
      <DropPageInfo />
    </div>
  );
};

export default DropPage;
