import React from "react";
import { Col, Row } from "react-bootstrap";
import ColDetailBurnItem from "./ColDetailBurnItem";
import ColDetailOwnItemSaleOptions from "./ColDetailOwnItemSaleOptions";
import ColDetailOwnItemSendItem from "./ColDetailOwnItemSendItem";

const ColDetailOwnItemCatalogOptions = ({
  listingStatus,
  showSalesModal,
  setShowSalesModal,
}) => {

  const canBeListed = listingStatus.allowedListingActions || listingStatus.dapperAllowedListingActions ?
                      Object.keys(listingStatus.allowedListingActions).length > 0 || Object.keys(listingStatus.dapperAllowedListingActions).length > 0 && true
                      :
                      false

  return (
    <div id="col-detail-own-item-options">
      <Row className="mb-3">
        {canBeListed &&
            <>
              <Col>
                <ColDetailOwnItemSaleOptions
                  showSalesModal={showSalesModal}
                  setShowSalesModal={setShowSalesModal}
                  listingStatus={listingStatus}
                />
              </Col>
              <Col>
                <ColDetailOwnItemSendItem listingStatus={listingStatus} />
              </Col>
            </>
          }

        {/* {listingStatus.dapperAllowedListingActions &&
          Object.keys(listingStatus.dapperAllowedListingActions).length > 0 && (
            <>
              <Col>
                <ColDetailOwnItemSaleOptions
                  showSalesModal={showSalesModal}
                  setShowSalesModal={setShowSalesModal}
                  listingStatus={listingStatus}
                />
              </Col>
              <Col>
                <ColDetailOwnItemSendItem listingStatus={listingStatus} />
              </Col>
            </>
          )} */}
      </Row>
      {/* <Row>
        <Col align='center'>
          <ColDetailBurnItem listingStatus={listingStatus} />
        </Col>
      </Row> */}
    </div>
  );
};

export default ColDetailOwnItemCatalogOptions;
