import React, {  useEffect } from "react";
import { Col } from "react-bootstrap";
import { InstantSearch } from "react-instantsearch-hooks-web";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { Autocomplete } from "../search/autocomplete/Autocomplete";
import "./navheadsearchbar.css";

const NavheadSearchBar = () => {
  const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
  const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;
  
  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "nft_name, collection_name, seller_name, seller",
      //filter_by: "collection_name:bl0x"
    },
  });
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  useEffect(() => {
    document.body.classList.add("main-nav");
  }, []);

  return (
    <>
      <Col className="d-none d-lg-block" xs="1"></Col>
      <Col align="center" className="d-none d-lg-block">
        <InstantSearch indexName="market" searchClient={searchClient}>
          <Autocomplete
            searchClient={searchClient}
            placeholder="Search for names, profiles, or NFTs"
            detachedMediaQuery="none"
            openOnFocus
          />
        </InstantSearch>
        {/* <AutocompleteNew searchClient={searchClient} /> */}
      </Col>{" "}
    </>
  );
};

export default NavheadSearchBar;
