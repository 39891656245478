import { useState } from "react";
import { useFormStatus } from "../../../../../functions/DisabledState";
import { Button, Form, Row, Col } from "react-bootstrap";
import { handleListForSale } from "../../../../../functions/txfunctions";
import toast from "react-hot-toast";

export function ListForSale({ lease, walletType, validUntil, user }) {
  const [amount, setAmount] = useState(null);
  // //console.log(lease);
  // //console.log(amount)
// console.log(user)
  function updateField(e) {
    const varVal = e.target.value;
    //now validate
    if (varVal < 1) {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");
    } else {
      e.target.classList.add("is-valid");
      e.target.classList.remove("is-invalid");
    }
    setAmount(varVal);
  }

  // const handleListForSale = async (e) => {
  //   if (!amount || amount < 1) {
  //     document.getElementById("amount").classList.add("is-invalid");
  //     document.getElementById("amount").classList.remove("is-valid");
  //     e.preventDefault();
  //     e.stopPropagation();
  //     return;
  //   }
  //   //console.log(
  //     "Starting sale listing for name " + lease + " for FUSD " + amount
  //   );

  //   try {
  //     await Tx({
  //       tx: transactions.listForSale,
  //       args: {
  //         name: lease,
  //         directSellPrice: parseFloat(amount).toFixed(2),
  //       },

  //       callbacks: {
  //         async onSuccess(status) {
  //           //console.log("Success - " + lease + " listed for FUSD" + amount);
  //           ReactGA.event({
  //             category: "User",
  //             action: "Listed Name '" + lease + "' for set price sale",
  //             label: "Profile",
  //           });
  //           navigate("/me/names");
  //           window.scrollTo(0, 0);
  //         },
  //       },
  //     });
  //   } catch (e) {
  //     //console.log(e);
  //   }
  // };

  const submitListForSale = (e) => {
    e.preventDefault();

    if (amount < 1) {
      toast.error("Please enter an amount of 1 or higher");
    } else {
      handleListForSale(lease, amount, user.profile.address, validUntil, walletType);
    }
  };

  return (
    <Form onSubmit={submitListForSale} noValidate>
      <fieldset disabled={useFormStatus()}>
        <Row>
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Label align="left">Sale Price:</Form.Label>
              <Form.Control
                id="bidPrice"
                placeholder="Enter an amount in FUSD"
                type="number"
                onChange={updateField}
                required
                min="0"
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col
            xs="12"
            md="6"
            align="center"
            className="mt-3 mt-md-auto ms-auto"
          >
            <Button
              type="submit"
              className="w-100"
              variant="find-outline-commerce-dark"
            >
              List name
            </Button>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}
