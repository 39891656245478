import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../functions/DisabledState";
import {
  handleCancelBid,
  handleIncreaseBid,
} from "../../../../functions/txfunctions";

export function IncreaseRemoveOffer({ bid }) {
  // //console.log(bid)

  let offerType = "auction bid";
  if (bid.type === "blind") {
    offerType = "offer";
  }

  const [formValues, setFormValues] = useImmer([
    {
      id: "bidAmt",
      value: bid.amount - bid.amount + 1,
    },
    {
      id: "name",
      value: bid.name,
    },
  ]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value - bid.amount;
    });
  }

  return (
    <Container className="commerceinfo p-3">
      <Row className="my-3 align-items-center">
        <Col xs="12" md="auto">
          You have made an {offerType} on <b><span className="themeColor">{bid.name}</span>.find</b> of{" "}
          <b>{bid.amount * 1} FUSD</b>
        </Col>
        {offerType !== "auction bid" && (
          <Col xs="auto" className="ms-auto">
            <Button
              onClick={() => handleCancelBid(bid.name)}
              variant="find-outline-commerce-red"
            >
              Cancel
            </Button>
          </Col>
        )}
      </Row>

      <div className="seperator100 my-3"></div>

      <Row>
        <Col className="pb-3">
          You can increase by entering at least <b>{bid.amount * 1 + 1} FUSD:</b>
        </Col>
      </Row>

      <fieldset disabled={useFormStatus()}>
      <Form noValidate className="form-inputs">
          <Row className="align-items-center">
          <Col>
            <Form.Control
              type="number"
              name="bidAmt"
              required
              min="0"
              defaultValue={bid.amount * 1 + 1}
              placeholder="Enter an amount in FUSD"
              onChange={(e) => {
                updateField(e);
                if (e.target.value <= bid.amount) {
                  e.target.classList.add("is-invalid");
                  e.target.classList.remove("is-valid");
                } else {
                  e.target.classList.add("is-valid");
                  e.target.classList.remove("is-invalid");
                }
              }}
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={() => {
                if (formValues[0].value * 1 > 0) {
                  //console.log("increase fired");
                  handleIncreaseBid(formValues);
                } else {
                  console.log(
                    "failed amount is: " + JSON.stringify(formValues, null, 2)
                  );
                }
              }}
              variant="find-outline-commerce-dark"
            >
              Increase
            </Button>
          </Col>
          </Row>
        </Form>
      </fieldset>


     
    </Container>
  );
}
