import React from "react";
import ColDetailOwnItemCatalogOptions from "./ColDetailOwnItemCatalogOptions";
import ColDetailOwnItemMakePfp from "./ColDetailOwnItemMakePfp";
import ColDetailPackOpen from "./ColDetailPackOpen";

const ColDetailOwnItem = ({
  listingStatus,
  colName,
  setOpenPackClicked,
  openPackClicked,
  showSalesModal,
  setShowSalesModal,
}) => {
  return (
    <>
      {listingStatus.aCatalogItem && (
        <ColDetailOwnItemCatalogOptions
          showSalesModal={showSalesModal}
          setShowSalesModal={setShowSalesModal}
          listingStatus={listingStatus}
        />
      )}
      {(colName === "bl0xPack" ||
        colName === "Bl0xPack" ||
        colName === "FindPack") && (
        <ColDetailPackOpen
          listingStatus={listingStatus}
          colName={colName}
          setOpenPackClicked={setOpenPackClicked}
          openPackClicked={openPackClicked}
        />
      )}
      <ColDetailOwnItemMakePfp listingStatus={listingStatus} />
    </>
  );
};

export default ColDetailOwnItem;
