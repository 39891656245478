import React from "react";
import "./collectionnft.scss";
import Image from "material-ui-image";

const CollectionNFTMedia = ({
  itemName,
  itemCollection,
  mediaUrl,
  mediaType,
  onSaleNFT,
  onAuctionNFT,
}) => {
  const isVideo = mediaType === "video";

  let media = isVideo ? (
    <video
      crossOrigin="anonymous"
      key={mediaUrl}
      src={mediaUrl}
      type="video/mp4"
      muted
      alt={"Video of " + mediaUrl}
      loop=""
      playsInline=""
      controls
      onMouseOver={(event) => event.target.play()}
      onMouseOut={(event) => event.target.pause()}
    >
      Sorry this video is not supported by your browser
    </video>
  ) : (
    <img
      crossOrigin="anonymous"
      alt={`Picture of ${itemName}`}
      src={mediaUrl}
    />
  );

  // We can add more conditions here
  const needsWrapper = itemCollection === "Wearables"

  if(needsWrapper){
    const collectionClass = itemCollection.toLowerCase()
    const wrapperClassName = ["collection-nft-media-wrapper__image-wrapper", collectionClass].join(' ')
    media = (
      <div className={wrapperClassName}>
        {media}
      </div>
    )
  }

  return (
    <div className="collection-nft-media-wrapper">
      {media}
      <div
        style={{
          position: "absolute",
          bottom: "0",
          margin: "5px",
          display: "flex",
        }}
      >
        {onSaleNFT && <div className="tag for-sale">For Sale</div>}
        {onAuctionNFT && <div className="tag auction">Auction</div>}
      </div>
    </div>
  );
};

export default CollectionNFTMedia;
