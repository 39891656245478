import { useEffect, useState } from "react";
import { useStateChanged } from "./DisabledState";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "./script";

const network = process.env.REACT_APP_NETWORK;

const useGetFactoryCollectionsMulti = (addressArray) => {
  const [collectionItems, setCollectionItems] = useState();
  const [collectionCounter, setCollectionCounter] = useState(0);
  const [getFinished, setGetFinished] = useState(false);

  const getResponse = async (script, address) => {
    const response = await Script(scripts[script], {
      user: address,
      collections: [],
    });
    // console.log(response)
    return response;
  };

  useEffect(() => {
    if (addressArray && !collectionItems) {
      // console.log(addressArray);
      async function getUserCollections(addr) {
        // here we will rename to getAlcehmy1IDs for example
        setCollectionCounter(0);
        setGetFinished(false);
        setCollectionItems(null);
        if (network === "mainnet") {
          const collectionResponse1 = await getResponse("getAlchemy4IDs", addr);
          setCollectionItems((collectionItems) => {
            setCollectionCounter((collectionCounter) => collectionCounter + 1);
            return { ...collectionItems, [addr]: { ...collectionResponse1 } };
          });
        }
        const collectionResponse2 = await getResponse("getAlchemy1IDs", addr);

        if (network === "mainnet") {
          const collectionResponse3 = await getResponse("getAlchemy2IDs", addr);
          const collectionResponse4 = await getResponse("getAlchemy3IDs", addr);
          setCollectionItems((collectionItems) => {
            setCollectionCounter((collectionCounter) => collectionCounter + 1);

            return {
              ...collectionItems,
              [addr]: { ...collectionItems[addr], ...collectionResponse3 },
            };
          });
          setCollectionItems((collectionItems) => {
            setCollectionCounter((collectionCounter) => collectionCounter + 1);

            return {
              ...collectionItems,
              [addr]: { ...collectionItems[addr], ...collectionResponse4 },
            };
          });
        }
        const collectionResponse5 = await getResponse("getSocksIDs", addr);
        const collectionResponse6 = await getResponse("getNFTCatalogIDs", addr);

        // const collectionResponse6 =
        // await Script(scripts.getNFTCatalogIDs, {
        //   user: addr,
        //   collections: [],
        // });

        if (network === "mainnet") {
          setCollectionItems((collectionItems) => {
            setCollectionCounter((collectionCounter) => collectionCounter + 1);

            return {
              ...collectionItems,
              [addr]: { ...collectionItems[addr], ...collectionResponse2 },
            };
          });
        } else {
          setCollectionItems((collectionItems) => {
            setCollectionCounter((collectionCounter) => collectionCounter + 1);
            return { ...collectionItems, [addr]: { ...collectionResponse2 } };
          });
        }

        setCollectionItems((collectionItems) => {
          setCollectionCounter((collectionCounter) => collectionCounter + 1);

          return {
            ...collectionItems,
            [addr]: { ...collectionItems[addr], ...collectionResponse5 },
          };
        });
        setCollectionItems((collectionItems) => {
          setCollectionCounter((collectionCounter) => collectionCounter + 1);

          return {
            ...collectionItems,
            [addr]: { ...collectionItems[addr], ...collectionResponse6 },
          };
        });
      }
      try {
        addressArray.map((address) => {
          getUserCollections(address);
        });
        // console.log(collectionItems)
      } catch (error) {
        throw error;
      }

      // eslint-disable-next-line
    }
  }, [addressArray, useStateChanged()]);

  // console.log(collectionItems)
  // console.log(getFinished)

  useEffect(() => {
    // console.log("current", collectionCounter);
    // console.log("aim", 6 * (addressArray.length));
    // console.log(getFinished);
    // console.log(collectionItems);

    // ! There is a bug here - for some addresses the response from getNFTCatalogIDs gives 400 error and doesnt increase counter
    // ! see https://linear.app/findonflow/issue/FIND-687/400-response-error-from-getnftcatalogids-script-on-certain-addresses
    // ! collection counter getFinished should be 6 * address array .length but as this was not hitting due to above bug I lessened it
    // ! This may cause some edge collection routing cases trying at access data before the get is finished
    // * Update - changes in here and script.js mean the error handling is done there and collectioncounter continues to increment to wanted number of 6 * arrayaddress.lenght

    if (addressArray.length > 1) {
      if (
        (network === "testnet" &&
          collectionCounter === 3 * addressArray.length) ||
        (network === "mainnet" && collectionCounter >= 6 * addressArray.length)
      ) {
        setGetFinished(true);
      }
    } else {
      if (
        (network === "testnet" && collectionCounter === 3) ||
        (network === "mainnet" && collectionCounter === 6)
      ) {
        setGetFinished(true);
      }
    }
  }, [collectionCounter, addressArray]);

  return {
    collectionItems,
    getFinished,
  };
};

export default useGetFactoryCollectionsMulti;
