import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useLatestEvent } from "../../functions/LatestEvent";
import LiveFeedDataBox from "./LiveFeedDataBox";
import LiveFeedHeader from "./LiveFeedHeader";
import LiveFeedTabs from "./LiveFeedTabs";
import LiveFeedThoughtBox from "./LiveFeedThoughtBox";
import "./LiveFeedWrapper.scss";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../functions/script";
import { useUserProfile } from "../../functions/useUserProfile";
import { useStateChanged } from "../../functions/DisabledState";

const LiveFeedWrapper = () => {
  const [currentTab, setCurrentTab] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [graffleUrl, setGraffleUrl] = useState(null);
  const [grafflePageSize, setGrafflePageSize] = useState(50);
  const [runOnce, setRunOnce] = useState(false);
  const [thoughtsIds, setThoughtsIds] = useState([]);
  const [thoughtsAddrs, setThoughtsAddrs] = useState([]);
  const [thoughtsData, setThoughtsData] = useState(null);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const { eventType } = useParams();
  const navigate = useNavigate();
  const graffleBaseUrl = process.env.REACT_APP_GRAFFLE_SEARCH_URL + "search?";
  const newStreamEvent = useLatestEvent();
  const user = useUserProfile();

  // console.log(thoughtsIds)
  // console.log(thoughtsAddrs)
  // console.log("data", thoughtsData);

  // console.log(location)
  // console.log(eventsData);
  // console.log(eventType)
  // console.log(currentTab)

  //graffle request - needs a timeset between request/switchg tabs
  useEffect(() => {
    grafflePageSize === 50 && setEventsData(null);
    if (graffleUrl && graffleUrl !== 'none') {
      const getEvents = async () => {
        // //console.log("getSales fired")
        let data;
        let res = await axios.get(graffleUrl + "&pageSize=" + grafflePageSize);
        data = res.data;

        //we can filter at this switch if needed for tenant / duplicate etc.
        switch (currentTab) {
          case "All":
            setEventsData(data);
            break;

          case "Latest sales":
            setEventsData(
              data.filter(
                (event) => event.blockEventData.tenant !== "onefootball"
              )
            );
            break;

          case "Latest listings":
            setEventsData(data);
            break;

          case "Thoughts":
            setThoughtsData(null);
            setThoughtsIds([]);
            setThoughtsAddrs([]);
            data.forEach((item) => {
              setThoughtsIds((thoughtsIds) => [
                ...thoughtsIds,
                item.blockEventData.id,
              ]);
              setThoughtsAddrs((thoughtsAddrs) => [
                ...thoughtsAddrs,
                item.blockEventData.creator,
              ]);
            });

            break;

          default:
            setEventsData(data);
            return;
        }
      };
      getEvents();
      // console.log(eventsData.length)
    }
  }, [graffleUrl, grafflePageSize]);

  useEffect(() => {
    if (thoughtsIds) {
      setThoughtsData(null);
      async function getFindThoughts(addresses, ids) {
        const getThoughts = await Script(scripts.getFindThoughts, {
          addresses: addresses,
          ids: ids,
        });
        setThoughtsData(getThoughts);
      }
      try {
        // console.log("this");
        getFindThoughts(thoughtsAddrs, thoughtsIds);
        // console.log(thoughtsData);
      } catch (error) {
        console.log(error);
      }
    }
  }, [thoughtsIds]);

  //set graffle query based on tab
  useEffect(() => {
    // console.log(currentTab);
    setGrafflePageSize(50);
    setGraffleUrl(null);
    setEventsData(null);
    setThoughtsData(null);

    switch (currentTab) {
      case "All":
        setGraffleUrl(graffleBaseUrl);
        navigate("/lf/all");
        break;

      case "Latest sales":
        setGraffleUrl(
          graffleBaseUrl +
            `eventType=A.${contractAddress}.FIND.Sale,A.${contractAddress}.FindMarketSale.Sale&status=sold`
        );
        navigate("/lf/sales");
        break;

      case "Latest listings":
        setGraffleUrl(
          graffleBaseUrl +
            `eventType=A.${contractAddress}.FIND.Sale,A.${contractAddress}.FIND.EnglishAuction,A.${contractAddress}.FindMarketSale.Sale,A.${contractAddress}.FindMarketAuctionEscrow.EnglishAuction&status=active_listed`
        );
        navigate("/lf/listings");
        break;

        case "Burns":
        setGraffleUrl(
          graffleBaseUrl +
            `eventType=A.${contractAddress}.FindFurnace.Burned&context.tenant=find`
        );
        navigate("/lf/burns");
        break;

      case "Mints":
        setGraffleUrl(
          graffleBaseUrl +
            `eventType=A.${contractAddress}.FindForge.Minted,A.7620acf6d7f2468a.Bl0xPack.PackReveal,A.${contractAddress}.FindPack.PackReveal`
        );
        navigate("/lf/mints");
        break;

      case "Trending":
        setGraffleUrl("none");
        navigate("/lf/trending");
        break;

      case "Thoughts":
        setGraffleUrl(
          graffleBaseUrl +
            `eventType=A.${contractAddress}.FindThoughts.Published`
        );
        navigate("/lf/thoughts");
        break;

      default:
        return;
    }
  }, [currentTab]);

  //set tab based on eventType params
  useEffect(() => {
    if (eventType) {
      switch (eventType.toLowerCase()) {
        case "all":
          setCurrentTab("All");
          break;

        case "sales":
          setCurrentTab("Latest sales");
          break;

        case "listings":
          setCurrentTab("Latest listings");
          break;

          case "burns":
            setCurrentTab("Burns");
            break;

        case "mints":
          setCurrentTab("Mints");
          break;

        case "trending":
          setCurrentTab("Trending");
          break;

        case "thoughts":
          setCurrentTab("Thoughts");
          break;

        default:
          // setCurrentTab("All");
          return;
      }
    } else {
      setCurrentTab("All");
    }
  }, []);

  //detect new stream event and append to events data
  useEffect(() => {
    if (eventsData && newStreamEvent && newStreamEvent.id) {
      const data = [newStreamEvent, ...eventsData];
      // to do - conditionally update data with new stream event in relevant tab - add sales, mint, etc
      switch (currentTab) {
        case "All":
          setEventsData(data);
          break;
        case "Latest sales":
          // setEventsData(data);
          break;

        case "Latest listings":
          // setEventsData(data);
          break;

        default:
          // setEventsData(data);
          return;
      }
    }
  }, [newStreamEvent]);
  return (
    <div id="live-feed-wrapper">
      <Row className="my-4">
        <LiveFeedHeader />
      </Row>
      <Row className="my-2 my-md-4">
        <LiveFeedTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </Row>
      <Row id="live-feed-thought-box-row" className="my-2 my-md-4">
        <div id="thought-box-outer-outer">
          <LiveFeedThoughtBox user={user} />
        </div>
      </Row>
      <Row id="live-feed-data-box-row" className="my-2 my-md-4">
        <LiveFeedDataBox
          eventsData={eventsData}
          thoughtsData={thoughtsData}
          currentTab={currentTab}
          user={user}
        />
      </Row>
      {eventsData &&
        Object.keys(eventsData).length > 0 &&
        Object.keys(eventsData).length % 50 === 0 && (
          <Button
            variant="find"
            onClick={() =>
              setGrafflePageSize((grafflePageSize) => grafflePageSize + 50)
            }
          >
            Load more...
          </Button>
        )}
    </div>
  );
};

export default LiveFeedWrapper;
