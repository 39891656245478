import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./marketplaceitemboughtmodal.css";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import { useUserProfile } from "../../../functions/useUserProfile";

const MarketplaceItemBoughtModal = ({
  nftDetails,
  isName,
  imgUrl,
  query,
}) => {
  // console.log(nftDetails);
  const [nftLink, setNftLink] = useState("");
  const [mpLink, setMpLink] = useState("/mp");

  const findUser = useUserProfile()

  useEffect(() => {
    if (query) {
      setMpLink("/mp" + query);
    } else {
      setMpLink("/mp");
    }
  }, [query]);
  

  useEffect(() => {
    if (nftDetails.collection_name) {
      setNftLink(
        "/me/collection/main/" +
          nftDetails.nft_alias +
          "/" +
          nftDetails.nft_id
      );
      if (nftDetails.nft_type.includes("Dandy")) {
        setNftLink("/me/collection/Dandy/" + nftDetails.nft_id);
      }
    } else if (!nftDetails.collection_name) {
      // console.log("this")
      setNftLink(
        "/me/collection/main/" + nftDetails.collection + "/" + nftDetails.id
      );
    }
  }, [nftDetails]);

  let nftName;
  if (nftDetails.nft_name) {
    nftName = nftDetails.nft_name;
  } else {
    nftName = nftDetails.name;
  }

  let modalImgSrc;
  if (imgUrl) {
    modalImgSrc = imgUrl;
  } else {
    modalImgSrc = nftDetails.nft_thumbnail?.replace(
      "ipfs://",
      "https://find.mypinata.cloud/ipfs/"
    );
  }

  let title = `I Just purchased ${
    !isName ? nftName : nftName + ".find"
  }. Come check it out on my profile now!`;
  let url = `https://find.xyz/${
    findUser.profile.findName
      ? findUser.profile.findName
      : findUser.profile.address
  }/collection/main/${
    nftDetails.nft_type.includes("Dandy")
      ? "Dandy"
      : nftDetails.collection_alias
  }/${nftDetails.nft_id} 
  `;

  return (
    <Container id="mp-bought-success-modal" className="p-3">
      <Modal.Header className="successHeader justify-content-center px-5">
        <h3>Woo hoo! Your purchase is complete!</h3>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p className="modalText px-5">
              Your purchase of{" "}
              {!isName ? (
                <span className="fw-bold">{nftName}</span>
              ) : (
                <span className="fw-bold">
                  <span className="themeColor">{nftName}</span>.find
                </span>
              )}{" "}
              is complete, check it out on your profile now.
            </p>
          </Col>
        </Row>
        <Image
        crossOrigin="anonymous"
          className="modal-img"
          fluid={+true}
          src={isName ? "/assets/img/marketplace/find_name_with_find.png" : modalImgSrc}
        />

        <Row className="align-items-center justify-content-center pt-2">
          <Col align='center' xs="12" >
            <Link to={isName ? "/me/names" : nftLink}>
              <Button variant="find-nochange-dark">
                {isName ? "View Name" : "View NFT"}
              </Button>
            </Link>
          </Col>
          <Col align='center' xs="12" className="pt-3">
            <Link to={mpLink}>
              <Button variant="find-text">Back to marketplace</Button>
            </Link>
          </Col>
        </Row>
        <div className="seperator100 my-4"></div>
        <Row className="justify-content-center">
          <p className="modalText">Share your new purchase</p>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto" className="my-auto modalFooterSocials">
            <TwitterShareButton
              className="m-1"
              title={title}
              url={url + " " + modalImgSrc}
              hashtags={["foundonflow", "onFlow"]}
            >
              <i className="fab fa-twitter m-1 shareIcons" />
            </TwitterShareButton>
            <FacebookShareButton className="m-1" quote={title} url={url}>
              <i className="fab fa-facebook m-1 shareIcons" />
            </FacebookShareButton>
            <LinkedinShareButton className="m-1" url={url}>
              <i className="fab fa-linkedin m-1 shareIcons" />
            </LinkedinShareButton>

            {/* <a
              href="/"
              target="_blank"
              style={{ color: "var(--text-secondary"}}
              rel="noreferrer"
              alt="Twitter"
            >
              <i className="fab fa-twitter m-1"></i>
            </a> */}
            {/* <a href="/">
              <i
                className="fab fa-instagram m-1"
                style={{ color: "var(--text-secondary" }}
                rel="noreferrer"
                alt="Instagram"
              ></i>
            </a> */}
            {/* <a
              href="/"
              target="_blank"
              style={{ color: "var(--text-secondary" }}
              rel="noreferrer"
              alt="Discord"
            >
              <i className="fab fa-discord m-1"></i>
            </a> */}
            {/* <a href="/">
              <i
                className="fab fa-youtube m-1"
                style={{ color: "var(--text-secondary" }}
                rel="noreferrer"
                alt="YouTube"
              ></i>
            </a> */}
          </Col>
        </Row>
      </Modal.Body>
    </Container>
  );
};

export default MarketplaceItemBoughtModal;
