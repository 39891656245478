import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import EventsSwitch from "../../../../../functions/EventsSwitch";
import parseNameLink from "../../../../../functions/parseNameLink";
import { Script } from "../../../../../functions/script";
import { scripts } from "@findonflow/find-flow-contracts";
import parseImgUrl from "../../../../../functions/parseImgUrl";

const ColDetailLatestBid = ({ latestBid }) => {
  const [updatedProfile, setUpdatedProfile] = useState(null);
  let profileAddress = latestBid.blockEventData.buyer;

  useEffect(() => {
    if (profileAddress) {
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(profileAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [latestBid]);
  
  if (latestBid && updatedProfile)
    return (
      <Row className="align-items-center pb-3">
        <Col xs="auto">
          <Image
          crossOrigin="anonymous"
            className=""
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
            }}
            src={
              updatedProfile
                ? parseImgUrl(updatedProfile.avatar, 'thumbnail')
                :  latestBid.blockEventData.buyerAvatar
                ? parseImgUrl(latestBid.blockEventData.buyerAvatar, 'thumbnail')
                : "/assets/img/store/fnamestore.png"
            }
          />
        </Col>
        &nbsp;<Col>{parseNameLink(updatedProfile)}</Col>
      </Row>
    );
};

export default ColDetailLatestBid;
