import React from "react";
import { Link, useParams } from "react-router-dom";
import FlowFtSticker from "../../../../forms/ftstickers/flowftsticker";
import "./ColDetailAvailableOn.scss";

const ColDetailAvailableOnFindAuction = ({ listingStatus }) => {
  const onDetails = listingStatus.allCurrentListings.forAuction.auctionListing;
  const { colName, nft } = useParams();

  console.log(listingStatus);
  return (
    <div className="available-on-wrapper-find p-3 my-4">
      <div className="available-on-row">
        <div className="available-on-icon-col">
          <img
            id="icon-img"
            src="/assets/img/profile/collection/nft-detail/on-find.png"
          />
          <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
        </div>
        <div className="available-on-info-col">
          <div className="available-on-info-row-1">.find market</div>
          <div className="available-on-info-row-2">For auction</div>
        </div>
        <div className="available-on-price-col">
          <div className="available-on-price-row-1">
            <div>{onDetails.amount * 1}</div>&nbsp;
            <div>
              {onDetails.ftAlias === "Flow" ? (
                <FlowFtSticker noBg={"true"} />
              ) : (
                onDetails.ftAlias
              )}
            </div>
            &nbsp;
            <Link to={`/mp/${colName}/${listingStatus.itemUuid}`}>
              <div>
                <img
                  style={{ marginBottom: "3px" }}
                  src="/assets/img/profile/collection/nft-detail/chevron-right.svg"
                />
              </div>
            </Link>
          </div>
          <div className="available-on-price-row-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default ColDetailAvailableOnFindAuction;
