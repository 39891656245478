import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import CharityAuctionWrapper from "../components/CharityAuction/CharityAuctionWrapper";
import { Helmet } from "react-helmet";

const CharityAuction = () => {
  const [indexName, setIndexName] = useState("market");
  // const { colName } = useParams();

  const sellerAddress = "0xa3127d4e2030b125";
  const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
  const typesenseHostUrl =
    process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "nft_name, collection_name, seller_name, seller",
    },
  });
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  return (
    <Container
      fluid={+true}
      className="pb-5"
      style={{
        backgroundColor: "var(--background-profile)",
        minHeight: "calc(100vh - 218px)",
      }}
    >
      <Helmet>
        <title>
          Flow and Friends Fantasy Football League for Charity - Auction Event /
          .find
        </title>
        <meta
          name="description"
          content="Flow and Friends Fantasy Football League for Charity - Auction Event"
        />
      </Helmet>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          hitsPerPage={18}
          facetFilters={[
            "seller:" + sellerAddress,
            // "listing_alias:-DirectOffer",
          ]}
        />

        <CharityAuctionWrapper />
      </InstantSearch>
    </Container>
  );
};

export default CharityAuction;
