import React from "react";
import { img } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "./parseImgUrl";

const getTicker = (activity) => {
  if(!activity.blockEventData){
    return ""
  }

  const haveFUTVault =
    activity.blockEventData.vaultType ===
    "A.82ec283f88a62e65.FlowUtilityToken.Vault";

  const haveFLOWVault =
    activity.blockEventData.vaultType ===
      "A.1654653399040a61.FlowToken.Vault" ||
    "A.7e60df042a9c0868.FlowToken.Vault";

  const haveFUSDVault =
    activity.blockEventData.ftType === "A.3c5959b568896393.FUSD.Vault";

  const haveDUCVault =
    activity.blockEventData.vaultType === "A.82ec283f88a62e65.DapperUtilityCoin.Vault"

  if(haveDUCVault){
    return "$"
  }

  if (haveFUTVault || haveFLOWVault) {
    return "FLOW";
  }

  if (haveFUSDVault) {
    return "FUSD";
  }
};

const EventsSwitch = (activity) => {
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  let isName = "";
  let eventIcon = "";
  let whatHappened;
  let itemName = activity.blockEventData.name
    ? activity.blockEventData.name
    : activity.blockEventData.collectionName && activity.blockEventData.id
    ? activity.blockEventData.collectionName + " #" + activity.blockEventData.id
    : activity.blockEventData.id
    ? activity.blockEventData.id
    : "";
  let viewItem = "";
  let txAmount = "";
  let txFtType = "";
  let imgUrl = "https://find.xyz/find.png";
  let fromName = "";
  let toName = "";
  let itemType = "";
  let buyerName = "";
  let eventType = "event type:";
  let notifiable = false;
  let reason = "";
  let explanation = "";
  let toAddress = "";
  let message = "";
  let fromIsName;
  let toIsName;
  let collectionName;
  let creatorView = null;
  let viewThoughtLink = null;
  // console.log(activity)
  if (activity.blockEventData.nft) {
    imgUrl = parseImgUrl(activity.blockEventData.nft.thumbnail, "icon");
  }
  if (activity.blockEventData.nftThumbnail) {
    imgUrl = parseImgUrl(
      activity.blockEventData.nftThumbnail.replaceAll(" ", ""),
      "icon"
    );
  }
  if (activity.blockEventData.nftImage) {
    imgUrl = parseImgUrl(activity.blockEventData.nftImage, "icon");
  }

  switch (activity.flowEventId) {
    //successful ticket redeemed
    case "A." + contractAddress + ".FindLostAndFoundWrapper.TicketRedeemed":
      imgUrl = parseImgUrl(activity.blockEventData.thumbnail, "icon");
      eventIcon = "🎁";
      //console.log(collection)
      whatHappened = "A gifted item was redeemed";
      fromName = activity.blockEventData.receiver;

      fromIsName = activity.blockEventData.senderName ? true : false;
      toIsName = activity.blockEventData.receiverName ? true : false;
      // viewItem = activity.blockEventData.receiverName
      //   ? "/" +
      //     activity.blockEventData.receiverName +
      //     "/collection/" +
      //     collection[2] +
      //     "/" +
      //     activity.blockEventData.uuid
      //   : activity.blockEventData.receiver +
      //     "/collection/" +
      //     collection[2] +
      //     "/" +
      //     activity.blockEventData.uuid;
      itemType = "nft";
      eventType = "Gifted:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        toIsName: toIsName,
        eventType: eventType,
        itemType: itemType,
        notifiable: true,
        reason: "You have redeemed a gifted NFT",
      };

    //failed ticket redeemed
    case "A." + contractAddress + ".FindLostAndFoundWrapper.TicketRedeemFailed":
      imgUrl = parseImgUrl(activity.blockEventData.thumbnail, "icon");
      eventIcon = "🛑";
      //console.log(collection)
      whatHappened =
        "A gifted item redeem failed due to " + activity.blockEventData.remark;
      fromName = activity.blockEventData.receiver;
      fromIsName = activity.blockEventData.senderName ? true : false;
      toIsName = activity.blockEventData.receiverName ? true : false;
      // viewItem = activity.blockEventData.receiverName
      //   ? "/" +
      //     activity.blockEventData.receiverName +
      //     "/collection/" +
      //     collection[2] +
      //     "/" +
      //     activity.blockEventData.uuid
      //   : activity.blockEventData.receiver +
      //     "/collection/" +
      //     collection[2] +
      //     "/" +
      //     activity.blockEventData.uuid;
      itemType = "nft";
      eventType = "Gifted:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        toIsName: toIsName,
        eventType: eventType,
        itemType: itemType,
        notifiable: true,
        reason: "You failed to redeem a gifted NFT",
      };

    //an NFT is sent
    case "A." + contractAddress + ".FindLostAndFoundWrapper.NFTDeposited":
      imgUrl = parseImgUrl(activity.blockEventData.thumbnail, "icon");
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      let collection = activity.blockEventData.type.split(".");
      //console.log(collection)
      whatHappened = "was sent as a gift";
      fromName = activity.blockEventData.senderName
        ? activity.blockEventData.senderName
        : activity.blockEventData.sender;
      toName = activity.blockEventData.receiverName
        ? activity.blockEventData.receiverName
        : activity.blockEventData.receiver;
      fromIsName = activity.blockEventData.senderName ? true : false;
      toIsName = activity.blockEventData.receiverName ? true : false;
      viewItem = activity.blockEventData.receiverName
        ? "/" +
          activity.blockEventData.receiverName +
          "/collection/main/" +
          collection[2] +
          "/" +
          activity.blockEventData.id
        : "/" +
          activity.blockEventData.receiver +
          "/collection/main/" +
          collection[2] +
          "/" +
          activity.blockEventData.id;
      itemType = "nft";
      eventType = "Gifted:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        toIsName: toIsName,
        eventType: eventType,
        itemType: itemType,
        notifiable: true,
        reason: "You have received an NFT",
      };

    //nft sent via airdrop
    case "A." + contractAddress + ".FindAirdropper.Airdropped":
      imgUrl = parseImgUrl(activity.blockEventData.nftInfo.thumbnail, "icon");
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      itemName = !activity.blockEventData.nftInfo
        ? activity.blockEventData.title
        : activity.blockEventData.nftInfo.name;
      let nftCollection = !activity.blockEventData.nftInfo
        ? activity.blockEventData.type.split(".")
        : activity.blockEventData.nftInfo.type.split(".");
      //console.log(collection)
      let nftId = !activity.blockEventData.nftInfo
        ? activity.blockEventData.id
        : activity.blockEventData.nftInfo.id;
      whatHappened = "was airdropped";
      fromName = activity.blockEventData.fromName
        ? activity.blockEventData.fromName
        : activity.blockEventData.from;
      toName = activity.blockEventData.toName
        ? activity.blockEventData.toName
        : activity.blockEventData.to;
      fromIsName = activity.blockEventData.fromName ? true : false;
      toIsName = activity.blockEventData.toName ? true : false;
      viewItem = activity.blockEventData.toName
        ? "/" +
          activity.blockEventData.toName +
          "/collection/main/" +
          nftCollection[2] +
          "/" +
          nftId
        : "/" +
          activity.blockEventData.to +
          "/collection/main/" +
          nftCollection[2] +
          "/" +
          nftId;
      itemType = "nft";
      eventType = "Gifted:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        toIsName: toIsName,
        eventType: eventType,
        itemType: itemType,
        notifiable: true,
        reason: "You have received an NFT",
      };

    //an NFT is sent to unlinked collection
    case "A." + contractAddress + ".FindLostAndFoundWrapper.TicketDeposited":
      imgUrl = parseImgUrl(activity.blockEventData.thumbnail, "icon");
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      //console.log(collection)
      whatHappened = "was airdropped to an unlinked collection";
      fromName = activity.blockEventData.senderName
        ? activity.blockEventData.senderName
        : activity.blockEventData.sender;
      toName = activity.blockEventData.receiverName
        ? activity.blockEventData.receiverName
        : activity.blockEventData.receiver;
      fromIsName = activity.blockEventData.senderName ? true : false;
      toIsName = activity.blockEventData.receiverName ? true : false;

      itemType = "nft";
      eventType = "Gifted:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        toIsName: toIsName,
        eventType: eventType,
        itemType: itemType,
        notifiable: true,
        reason: "You have received an NFT",
      };

    //name was registered
    case "A." + contractAddress + ".FIND.Register":
      eventIcon = "🎉";
      whatHappened = "was registered";
      fromName = activity.blockEventData.owner;

      viewItem = "/" + activity.blockEventData.name + "/names";
      itemType = "name";
      eventType = "Registered:";

      return {
        fromIsName: fromIsName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
      };

    //profile was created
    case "A." + contractAddress + ".Profile.Created":
      eventIcon = "🎉";
      itemName = activity.blockEventData.userName;
      whatHappened = "created a profile";
      fromName = activity.blockEventData.account;
      eventType = "Created Profile:";
      itemType = "update";
      viewItem = "/" + activity.blockEventData.account;
      // viewItem = (
      //   <Link to={"/" + activity.blockEventData.name}>View Profile</Link>
      // );

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
      };

    //profile was updated
    case "A." + contractAddress + ".Profile.Updated":
      eventIcon = "📝";
      whatHappened = "updated their profile";
      itemName = activity.blockEventData.findName
        ? activity.blockEventData.findName
        : activity.blockEventData.userName;

      viewItem = activity.blockEventData.findName
        ? "/" + activity.blockEventData.findName
        : "/" + activity.blockEventData.account;
      fromName = activity.blockEventData.findName
        ? activity.blockEventData.findName
        : activity.blockEventData.account;
      eventType = "Updated Profile:";
      itemType = "update";
      if (activity.blockEventData.findName) {
        isName = true;
        fromIsName = true;
      }

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        isName: isName,
        fromIsName: fromIsName,
      };

    //ft was gifted
    case "A." + contractAddress + ".FIND.FungibleTokenSent":
      whatHappened = "was funded";
      eventIcon = "🎁";
      itemType = "gift";
      txAmount = activity.blockEventData.amount;
      message = activity.blockEventData.message;
      txFtType = getTicker(activity);

      if (activity.blockEventData.fromName) {
        fromName = activity.blockEventData.fromName;
        fromIsName = true;
      } else {
        fromName = activity.blockEventData.from;
      }

      if (
        activity.blockEventData.name &&
        activity.blockEventData.name !== activity.blockEventData.toAddress
      ) {
        toName = activity.blockEventData.name;
        toIsName = true;
        itemType = "name";
      } else {
        toName = activity.blockEventData.toAddress;
      }

      // fromName = activity.blockEventData.fromName
      //   ? activity.blockEventData.fromName
      //   : activity.blockEventData.from;
      // if (fromName = activity.blockEventData.fromName) {
      //   fromIsName = true;

      // }
      // if (fromName = activity.blockEventData.name) {
      //   itemType = 'name'
      //   toIsName = true
      // }

      viewItem = activity.blockEventData.fromName
        ? "/" + activity.blockEventData.fromName
        : "/" + activity.blockEventData.from;

      explanation =
        fromName +
        " just sent you " +
        txAmount +
        " " +
        txFtType +
        " with message: " +
        message;
      eventType = "Gifted:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        notifiable: true,
        reason: "Token(s) received",
        explanation: explanation,
        toAddress: activity.blockEventData.toAddress,
        itemType: itemType,
        fromIsName,
      };

    //nft listed for sale
    case "A." + contractAddress + ".FindMarketSale.Sale":
      fromName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      viewItem =
        "/mp/" +
        activity?.blockEventData?.nft?.collectionName +
        "/" +
        activity?.blockEventData?.id;
      if (activity.blockEventData.sellerName) {
        fromIsName = true;
      }
      if (activity.blockEventData.status === "cancel") {
        whatHappened = "was removed from sale";
        eventType = "Delisted:";
        viewItem =
          "/" +
          (activity.blockEventData.sellerName
            ? activity.blockEventData.sellerName
            : activity.blockEventData.seller) +
          "/collection/main/" +
          activity?.blockEventData?.nft?.type?.split(".")[2] +
          "/" +
          activity?.blockEventData?.nft?.id;
      } else if (activity.blockEventData.status === "sold") {
        whatHappened = "was sold";
        toName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        if (activity.blockEventData.buyerName) {
          toIsName = true;
        }
        eventType = "Sold:";
        viewItem =
          "/mp/" +
          activity?.blockEventData?.nft?.collectionName +
          "/" +
          activity?.blockEventData?.id;
      } else {
        whatHappened = "was listed for sale";
        viewItem =
          "/mp/" +
          activity?.blockEventData?.nft?.collectionName +
          "/" +
          activity?.blockEventData?.id;
        eventType = "Listed for Sale:";
      }
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      itemName = activity?.blockEventData?.nft?.name
        ? activity?.blockEventData?.nft?.name
        : activity?.blockEventData?.nft?.collectionName
        ? activity.blockEventData?.nft?.collectionName +
          " #" +
          activity.blockEventData?.nft?.id
        : "";

      // itemName = activity.blockEventData.nft.name

      txAmount = activity.blockEventData.amount;

      itemType = "nft";
      txFtType = getTicker(activity);

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        itemType: itemType,
        buyerName: buyerName,
        eventType: eventType,
        fromIsName: fromIsName,
        toIsName: toIsName,
      };

    //nft listed for auction
    case "A." + contractAddress + ".FindMarketAuctionEscrow.EnglishAuction":
      fromName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      if (activity.blockEventData.sellerName) {
        fromIsName = true;
      }
      if (activity.blockEventData.status === "cancel_listing") {
        whatHappened = "was removed from auction";
        eventType = "Delisted:";
      } else if (activity.blockEventData.status === "cancel_reserved_not_met") {
        whatHappened = "an auction ended below reserve price";
        eventType = "Ended Below Reserve:";
      } else if (activity.blockEventData.status === "active_ongoing") {
        whatHappened = "a bid was made";
        eventType = "Bid:";
        fromName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        toName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        if (activity.blockEventData.sellerName) {
          toIsName = true;
        } else {
          toIsName = false;
        }
        if (activity.blockEventData.buyerName) {
          fromIsName = true;
        } else {
          fromIsName = false;
        }
      } else if (activity.blockEventData.status === "sold") {
        whatHappened = "was sold via auction";
        buyerName = activity.blockEventData.buyerName;
        eventType = "Sold:";
        toName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        if (activity.blockEventData.buyerName) {
          toIsName = true;
        }
      } else {
        whatHappened = "was listed for auction";
        eventType = "Listed for Auction:";
      }
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      itemName = activity.blockEventData.nft.name
        ? activity.blockEventData.nft.name
        : activity.blockEventData.nft.collectionName +
          " #" +
          activity.blockEventData.nft.id;
      txAmount = activity.blockEventData.amount;

      itemType = "nft";
      txFtType = getTicker(activity);

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        itemType: itemType,
        buyerName: buyerName,
        eventType: eventType,
        fromIsName: fromIsName,
        toIsName: toIsName,
        // notifiable: notifiable,
        // reason: reason,
        // explanation: explanation,
        // toAddress: toAddress,
      };

    //offer made/withdrawn on unlisted nft.
    case "A." + contractAddress + ".FindMarketDirectOfferEscrow.DirectOffer":
      txFtType = getTicker(activity);
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl ? imgUrl : "/assets/img/store/fnamestore.png"}
        />
      );
      itemName = activity.blockEventData.nft?.name
        ? activity.blockEventData.nft.name
        : activity.blockEventData.nft?.collectionName
        ? activity.blockEventData.nft.collectionName +
          " #" +
          activity.blockEventData.nft.id
        : activity.blockEventData.id;
      txAmount = activity.blockEventData.amount;
      itemType = "nft";
      if (activity.blockEventData.status === "cancel") {
        if (activity.blockEventData.buyerName) {
          fromName = activity.blockEventData.buyerName;
          fromIsName = true;
        } else {
          fromName = activity.blockEventData.buyer;
        }
        if (activity.blockEventData.sellerName) {
          toName = activity.blockEventData.sellerName;
          toIsName = true;
        } else {
          toName = activity.blockEventData.seller;
        }
        whatHappened = "an offer was withdrawn";
        toAddress = activity.blockEventData.seller;
        reason = "Offer withdrawn";
        eventType = "Withdrew Offer:";
        notifiable = true;
        fromName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        toName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        explanation =
          toName +
          " has withdrawn their offer of " +
          activity.blockEventData.amount +
          " " +
          txFtType +
          " for " +
          itemName;
      } else if (activity.blockEventData.status === "sold") {
        whatHappened = "was sold via direct offer";
        if (activity.blockEventData.sellerName) {
          fromName = activity.blockEventData.sellerName;
          fromIsName = true;
        } else {
          fromName = activity.blockEventData.seller;
          fromIsName = false;
        }
        if (activity.blockEventData.buyerName) {
          toName = activity.blockEventData.buyerName;
          toIsName = true;
        } else {
          toName = activity.blockEventData.buyer;
          toIsName = false;
        }
        buyerName = activity.blockEventData.buyerName;
        toAddress = activity.blockEventData.buyer;
        eventType = "Sold:";
        reason = "Offer accepted";
        notifiable = true;
        explanation =
          fromName +
          " just accepted " +
          activity.blockEventData.amount +
          " " +
          txFtType +
          " for " +
          itemName;
      } else {
        eventType = "Offered:";

        whatHappened = "an offer was made";

        if (activity.blockEventData.buyerName) {
          fromName = activity.blockEventData.buyerName;
          fromIsName = true;
        } else {
          fromName = activity.blockEventData.buyer;
        }
        if (activity.blockEventData.sellerName) {
          toName = activity.blockEventData.sellerName;
          toIsName = true;
        } else {
          toName = activity.blockEventData.seller;
        }
        toAddress = activity.blockEventData.seller;
        reason = "You've received an offer";
        notifiable = true;
        explanation =
          fromName +
          " just offered " +
          activity.blockEventData.amount +
          " " +
          txFtType +
          " for " +
          itemName;
      }
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        fromIsName: fromIsName,
        toIsName: toIsName,
        itemType: itemType,
        buyerName: buyerName,
        eventType: eventType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
      };

    //royalties paid on NFT
    case "A." + contractAddress + ".FindMarket.RoyaltyPaid":
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      itemType = "nft";
      if (activity.blockEventData.royaltyName === "artist") {
        whatHappened = "royalties paid to artist on NFT sale";
      } else if (activity.blockEventData.royaltyName === "minter") {
        whatHappened = "royalties paid to minter on NFT sale";
      } else if (activity.blockEventData.royaltyName === "platform") {
        whatHappened = "royalties paid to platform on NFT sale";
      } else if (activity.blockEventData.royaltyName === "find") {
        whatHappened = "royalties paid to .find on NFT sale";
      } else {
        whatHappened =
          "royalties paid to " +
          activity.blockEventData.royaltyName +
          " on NFT sale";
      }
      itemName = activity.blockEventData.nft.name
        ? activity.blockEventData.nft.name
        : activity.blockEventData.nft.collectionName +
          " #" +
          activity.blockEventData.nft.id;
      txAmount = activity.blockEventData.amount;
      fromName = activity.blockEventData.address;
      txFtType = getTicker(activity);

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
      };

    //direct offer made for name
    case "A." + contractAddress + ".FIND.DirectOffer":
      fromName = activity.blockEventData.buyerName
        ? activity.blockEventData.buyerName
        : activity.blockEventData.buyer;
      if (activity.blockEventData.buyerName) {
        fromIsName = true;
      }
      toName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      if (activity.blockEventData.sellerName) {
        toIsName = true;
      }

      if (activity.blockEventData.status === "active_offered") {
        eventIcon = "💸";
        whatHappened = "an offer was made";
        txAmount = activity.blockEventData.amount;
        txFtType = "FUSD";
        itemName = itemName;
        eventType = "Offered:";

        viewItem = (
          <Link to={"/" + activity.blockEventData.name}>View Name</Link>
        );
        itemType = "name";
        notifiable = true;
        reason = "Offer received";
        explanation =
          fromName +
          " just offered " +
          txAmount +
          " " +
          txFtType +
          " for " +
          itemName +
          ".find";
        toAddress = activity.blockEventData.seller;
      }
      if (activity.blockEventData.status === "cancel_rejected") {
        if (activity.blockEventData.buyerName) {
          fromName = activity.blockEventData.buyerName;
          fromIsName = true;
        } else {
          fromName = activity.blockEventData.buyer;
        }
        if (activity.blockEventData.sellerName) {
          toName = activity.blockEventData.sellerName;
          toIsName = true;
        } else {
          toName = activity.blockEventData.seller;
        }
        eventIcon = "🔙";
        whatHappened = "an offer was withdrawn";
        txAmount = activity.blockEventData.amount;
        txFtType = "FUSD";
        itemName = itemName;
        eventType = "Withdrew Offer:";

        viewItem = (
          <Link to={"/" + activity.blockEventData.name}>View Name</Link>
        );
        itemType = "name";
        notifiable = true;
        reason = "Offer withdrawn";
        explanation =
          fromName +
          " withdrew their offer of " +
          txAmount +
          " " +
          txFtType +
          " for " +
          itemName +
          ".find";
        toAddress = activity.blockEventData.seller;
      }
      if (activity.blockEventData.status === "sold") {
        fromName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        if (activity.blockEventData.sellerName) {
          fromIsName = true;
        }
        toName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        if (activity.blockEventData.buyerName) {
          toIsName = true;
        }
        eventIcon = "💰";
        whatHappened = "was sold via accepted offer";
        eventType = "Offer Accepted:";
        txAmount = activity.blockEventData.amount;
        txFtType = "FUSD";
        itemName = itemName;
        fromName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        viewItem = (
          <Link to={"/" + activity.blockEventData.name}>View Name</Link>
        );
        itemType = "name";
        notifiable = true;
        reason = "Offer Accepted";
        explanation =
          fromName +
          " accepted your offer of " +
          txAmount +
          " " +
          txFtType +
          " for " +
          itemName +
          ".find";
        toAddress = activity.blockEventData.seller;
      }
      if (activity.blockEventData.status === "rejected") {
        eventIcon = "❌";
        eventType = "Rejected:";
        whatHappened = "an offer was rejected";
        txAmount = activity.blockEventData.amount;
        txFtType = "FUSD";
        itemName = itemName;
        fromName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        viewItem = (
          <Link to={"/" + activity.blockEventData.name}>View Name</Link>
        );
        itemType = "name";
        notifiable = true;
        reason = "Offer Rejected";
        explanation =
          fromName +
          " rejected your offer of " +
          txAmount +
          " " +
          txFtType +
          " for " +
          itemName +
          ".find";
        toAddress = activity.blockEventData.seller;
      }

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
        toIsName: toIsName,
        fromIsName: fromIsName,
      };

    //direct offer removed from name
    case "A." + contractAddress + ".FIND.DirectOfferCanceled":
      fromName = activity.blockEventData.buyerName
        ? activity.blockEventData.buyerName
        : activity.blockEventData.buyer;
      if (activity.blockEventData.buyerName) {
        fromIsName = true;
      }
      toName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      if (activity.blockEventData.sellerName) {
        toIsName = true;
      }
      eventIcon = "🔙";
      whatHappened = "an offer was withdrawn";
      itemType = "name";
      notifiable = true;
      reason = "Offer withdrawn";
      explanation =
        fromName +
        " withdrew their offer of " +
        txAmount +
        " " +
        txFtType +
        " for " +
        itemName +
        ".find";
      toAddress = activity.blockEventData.seller;

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
      };

    //name listed/removed from directsale
    case "A." + contractAddress + ".FIND.Sale":
      fromName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      if (activity.blockEventData.sellerName) {
        fromIsName = true;
      }

      if (activity.blockEventData.status === "cancel") {
        whatHappened = "was removed from sale";
        itemType = "name";
        viewItem = `/${itemName}/names  `;

        eventIcon = "🔙";
        eventType = "Delisted:";
      } else if (activity.blockEventData.status === "sold") {
        whatHappened = "was sold via direct sale";
        eventIcon = "💰";
        toName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        if (activity.blockEventData.buyerName) {
          toIsName = true;
        }
        viewItem = "/" + activity.blockEventData.name + "/names";
        eventType = "Sold:";
      } else {
        whatHappened = "was listed for sale";
        eventIcon = "🛒";
        eventType = "Listed:";
        viewItem = `/${itemName}/names  `;
      }
      txAmount = activity.blockEventData.amount;
      txFtType = activity.blockEventData.vaultType.split(".")[2];
      itemType = "name";

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        buyerName: buyerName,
        eventType: eventType,
        itemType: itemType,
        toIsName: toIsName,
        fromIsName: fromIsName,
      };

    //name listed/removed from auction
    case "A." + contractAddress + ".FIND.EnglishAuction":
      isName = true;
      viewItem = (
        <Link to={"/" + activity.blockEventData.name}>View Auction</Link>
      );
      fromName = activity.blockEventData.sellerName
        ? activity.blockEventData.sellerName
        : activity.blockEventData.seller;
      if (activity.blockEventData.sellerName) {
        fromIsName = true;
      }
      if (activity.blockEventData.status === "cancel_listing") {
        whatHappened = "was removed from auction";
        eventIcon = "🔙";
        eventType = "Withdrew:";
        viewItem = (
          <Link to={"/" + activity.blockEventData.name}>View Auction</Link>
        );
      } else if (activity.blockEventData.status === "sold") {
        whatHappened = "was sold via auction";
        eventIcon = "💰";
        buyerName = activity.blockEventData.buyerName;
        fromName = activity.blockEventData.sellerName;
        viewItem = "/" + activity.blockEventData.name + "/names";
        eventType = "Sold:";
        toName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        if (activity.blockEventData.buyerName) {
          toIsName = true;
        }
      } else if (activity.blockEventData.status === "active_ongoing") {
        whatHappened = "a bid was made";
        eventType = "Bid:";
        eventIcon = "💸";
        fromName = activity.blockEventData.buyerName
          ? activity.blockEventData.buyerName
          : activity.blockEventData.buyer;
        toName = activity.blockEventData.sellerName
          ? activity.blockEventData.sellerName
          : activity.blockEventData.seller;
        if (activity.blockEventData.buyerName) {
          fromIsName = true;
        }
        if (activity.blockEventData.sellerName) {
          toIsName = true;
        }
      } else {
        whatHappened = "was listed for auction";
        itemType = "name";
        eventIcon = "🛒";
        eventType = "Listed:";
      }
      txAmount = activity.blockEventData.amount;
      txFtType = activity.blockEventData.vaultType.split(".")[2];
      itemType = "name";
      return {
        buyerName: buyerName,
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        toName: toName,
        eventType: eventType,
        itemType: itemType,
        fromIsName: fromIsName,
        toIsName: toIsName,
        isName: isName,
      };

    //name transferred to another user
    case "A." + contractAddress + ".FIND.Moved":
      whatHappened = "was transferred";
      eventIcon = "🎁";
      viewItem = "/" + activity.blockEventData.name;
      fromName = activity.blockEventData.previousOwner;
      toName = activity.blockEventData.newOwner;
      itemType = "name";
      eventType = "Transfered: ";
      notifiable = true;
      reason = "You just received a name";
      explanation =
        itemName +
        " was just sent to you by " +
        activity.blockEventData.previousOwner;
      toAddress = activity.blockEventData.newOwner;

      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
        toName: toName,
      };

    //findpack purchased
    case "A." + contractAddress + ".FindPack.Purchased":
      whatHappened = `A ${activity.blockEventData.packTypeName} pack was purchased`;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={
            "https://find.mypinata.cloud/ipfs/QmWXrHbmCZJKvD4wQbwt8ZNj3e9pdFbzLTshEtMRH6ZNo6"
          }
        />
      );
      itemName = activity.blockEventData.nftName;
      fromName = activity.blockEventData.address;
      txAmount = activity.blockEventData.amount;
      txFtType = "Flow";

      viewItem =
        "/" + activity.blockEventData.address + "/collection/main/FindPack";

      eventType = "Bought:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        viewItem: viewItem,
      };

    //findpack opened
    case "A." + contractAddress + ".FindPack.Opened":
      whatHappened = `A  ${activity.blockEventData.packTypeName} pack was opened`;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={
            "https://find.mypinata.cloud/ipfs/QmWXrHbmCZJKvD4wQbwt8ZNj3e9pdFbzLTshEtMRH6ZNo6"
          }
        />
      );
      itemName = activity.blockEventData.nftName;
      fromName = activity.blockEventData.address;
      txAmount = activity.blockEventData.nftRarity;
      viewItem =
        "/" +
        activity.blockEventData.address +
        "/collection/main/Bl0x" +
        "/" +
        activity.blockEventData.rewardId;
      eventType = "Opened:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        viewItem: viewItem,
      };

    //findpack revealed
    case "A." + contractAddress + ".FindPack.PackReveal":
      whatHappened = `a ${activity.blockEventData.packTypeName} pack was revealed`;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={parseImgUrl(
            activity.blockEventData.rewardFields.nftImage,
            "icon"
          )}
        />
      );
      itemName = activity.blockEventData.rewardFields.nftName;
      fromName = activity.blockEventData.address;
      txAmount = activity.blockEventData.nftRarity;
      viewItem =
        "/" +
        activity.blockEventData.address +
        "/collection/main/PartyFavorz" +
        "/" +
        activity.blockEventData.rewardId;
      eventType = "Opened:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        viewItem: viewItem,
      };

    //findpack fulfilled
    case "A." + contractAddress + ".FindPack.Fulfilled":
      whatHappened = `a ${activity.blockEventData.packTypeName} pack was fulfilled`;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={
            "https://find.mypinata.cloud/ipfs/QmWXrHbmCZJKvD4wQbwt8ZNj3e9pdFbzLTshEtMRH6ZNo6"
          }
        />
      );
      itemName = activity.blockEventData.nftName;
      fromName = activity.blockEventData.address;
      txAmount = activity.blockEventData.nftRarity;
      viewItem =
        "/" +
        activity.blockEventData.address +
        "/collection/main/Bl0x" +
        "/" +
        activity.blockEventData.rewardId;
      eventType = "Fulfilled:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        viewItem: viewItem,
      };

    //old fundwidget event (replaced)
    case "A." + contractAddress + ".Profile.Verification":
      whatHappened = "outdated fundwidget tx .Profile.Verification";
      eventIcon = "🤷‍♂";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
      };

    //blox pack opened
    case "A.7620acf6d7f2468a.Bl0xPack.PackReveal":
      whatHappened = "was opened from a pack";
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      itemName = activity.blockEventData.nftName;
      fromName = activity.blockEventData.address;
      txAmount = activity.blockEventData.nftRarity;
      viewItem =
        "/" +
        activity.blockEventData.address +
        "/collection/main/Bl0x" +
        "/" +
        activity.blockEventData.rewardId;
      eventType = "Opened:";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        viewItem: viewItem,
      };

    //findforge minted
    case "A." + contractAddress + ".FindForge.Minted":
      whatHappened = "was minted";
      let nftType = activity.blockEventData.nftType
        ? activity.blockEventData.nftType.split(".")[2]
        : null;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );

      if (activity.blockEventData.fromName) {
        fromName = activity.blockEventData.fromName;
        fromIsName = true;
      } else {
        fromName = activity.blockEventData.from;
        fromIsName = false;
      }
      itemName =
        activity.blockEventData.nftName + " " + activity.blockEventData.id;
      eventType = "Minted:";
      viewItem = activity.blockEventData.fromName
        ? "/" +
          activity.blockEventData.fromName +
          "/" +
          nftType +
          "/" +
          activity.blockEventData.id
        : "/" +
          "/" +
          activity.blockEventData.from +
          "/" +
          nftType +
          "/" +
          activity.blockEventData.id;
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
        toIsName: toIsName,
        fromIsName: fromIsName,
      };

    //findforge burned
    case "A." + contractAddress + ".FindFurnace.Burned":
      whatHappened = `was burned ${
        activity.blockEventData.context.message
          ? "with the message " +
            '"' +
            activity.blockEventData.context.message +
            '"'
          : ""
      }`;

      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={parseImgUrl(activity.blockEventData.nftInfo.thumbnail, "icon")}
        />
      );

      if (activity.blockEventData.fromName) {
        fromName = activity.blockEventData.fromName;
        fromIsName = true;
      } else {
        fromName = activity.blockEventData.from;
        fromIsName = false;
      }
      itemName =
        activity.blockEventData.nftInfo.name +
        " " +
        activity.blockEventData.nftInfo.id;
      eventType = "Burned:";
      viewItem = null;
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
        itemType: itemType,
        notifiable: notifiable,
        reason: reason,
        explanation: explanation,
        toAddress: toAddress,
        toIsName: toIsName,
        fromIsName: fromIsName,
      };

    //a thought is published
    case "A." + contractAddress + ".FindThoughts.Published":
      itemName = null;
      whatHappened = activity.blockEventData.message;
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
      };

    //a thought is reacted to
    case "A." + contractAddress + ".FindThoughts.Reacted":
      creatorView = activity.blockEventData.creatorName
        ? activity.blockEventData.creatorName
        : activity.blockEventData.creator;
      viewThoughtLink = (
        <Link
          className="fw-bold secColor"
          to={`/${creatorView}/thoughts/${activity.blockEventData.id}`}
        >
          thought
        </Link>
      );

      if (activity.blockEventData.byName) {
        itemType = "name";
        fromName = activity.blockEventData.byName;
        fromIsName = true;
        viewItem = "/" + activity.blockEventData.byName;
      } else {
        fromName = activity.blockEventData.by;
        viewItem = "/" + activity.blockEventData.by;
      }

      itemName = activity.blockEventData.byName
        ? activity.blockEventData.byName
        : activity.blockEventData.by;

      whatHappened = "reacted to a ";
      eventIcon = activity.blockEventData.reaction;
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        fromIsName: fromIsName,
        eventType: eventType,
        itemType,
        viewThoughtLink,
      };

    //a thought is edited
    case "A." + contractAddress + ".FindThoughts.Edited":
      <img
        crossOrigin="anonymous"
        style={{ width: "48px", height: "48px", borderRadius: "50%" }}
        src={imgUrl}
      />;
      creatorView = activity.blockEventData.creatorName
        ? activity.blockEventData.creatorName
        : activity.blockEventData.creator;
      viewThoughtLink = (
        <Link to={`/${creatorView}/thoughts/${activity.blockEventData.id}`}>
          thought
        </Link>
      );

      if (activity.blockEventData.creatorName) {
        itemType = "name";
        fromName = activity.blockEventData.creatorName;
        fromIsName = true;
        viewItem = "/" + activity.blockEventData.creatorName;
      } else {
        fromName = activity.blockEventData.creator;
        viewItem = "/" + activity.blockEventData.creator;
      }

      itemName = activity.blockEventData.creatorName
        ? activity.blockEventData.creatorName
        : activity.blockEventData.creator;

      whatHappened = "toggle hide on a ";
      eventIcon = "👀";
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        fromIsName: fromIsName,
        eventType: eventType,
        itemType,
        viewThoughtLink,
      };

    //legacy name events
    //sale
    case "A." + contractAddress + ".FIND.ForSale":
      if (activity.blockEventData.active === true) {
        whatHappened = "was listed for sale";
        eventType = "Listed:";
      } else {
        whatHappened = "was removed from sale";
        eventType = "Withdrew:";
      }

      fromName = activity.blockEventData.name
        ? activity.blockEventData.owner
        : activity.blockEventData.seller;
      if (activity.blockEventData.name) {
        fromIsName = true;
      }

      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      txAmount = activity.blockEventData.directSellPrice;
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
      };

    //auction
    case "A." + contractAddress + ".FIND.ForAuction":
      if (activity.blockEventData.active === true) {
        whatHappened = "was listed for auction";
        eventType = "Listed:";
      } else {
        whatHappened = "was removed from auction";
        eventType = "Withdrew:";
      }
      fromName = activity.blockEventData.name
        ? activity.blockEventData.owner
        : activity.blockEventData.seller;
      if (activity.blockEventData.name) {
        fromIsName = true;
      }
      txAmount = activity.blockEventData.auctionStartPrice;
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      return {
        eventIcon: eventIcon,
        whatHappened: whatHappened,
        itemName: itemName,
        viewItem: viewItem,
        txAmount: txAmount,
        txFtType: txFtType,
        imgUrl: imgUrl,
        fromName: fromName,
        eventType: eventType,
      };

    //default case (not specified)
    default:
      eventIcon = (
        <img
          crossOrigin="anonymous"
          style={{ width: "48px", height: "48px", borderRadius: "50%" }}
          src={imgUrl}
        />
      );
      return {
        eventIcon: eventIcon,
        whatHappened: `A ${activity.flowEventId.split(".")[2]} ${
          activity.flowEventId.split(".")[3]
        } event occured`,
        viewItem: "",
        txAmount: "",
        txFtType: "",
        imgUrl: "",
        fromName: "",
        eventType: "event type:",
      };
  }
};

export default EventsSwitch;
