import React, { useEffect, useState } from "react";
import parseImgUrl, { parseIPFSLink } from "../../functions/parseImgUrl";
import Loading from "../loading/loading";
import LoadingThumb from "../loading/LoadingThumb";

const CustomImageThumb = ({ imgUrl, isName }) => {
  const [thumbImgUrl, setThumbImgUrl] = useState(null);

  useEffect(() => {
    if (imgUrl) {
      if (isName) {
        setThumbImgUrl(imgUrl);
      } else {
        setThumbImgUrl(parseImgUrl(imgUrl, "thumbnail"));
    
      }
    }
  }, [imgUrl]);

  return (
      <img
        crossOrigin="anonymous"
        // alt={`Picture of ${imgUrl}`}
        src={thumbImgUrl}
      />
  );
};

export default CustomImageThumb;
