import React from "react";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import { Results } from "../components";

import searchClient from "../../service/search-client";
import { singleCollection } from "../components/utils";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";

const EquipHelmet = ({ meta }) => {
  const { metaTitle, metaDescription } = meta;
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
};

export default function Marketplace(props) {
  const { findUser, ...meta } = props;

  const indexName = "market";
  const filters = singleCollection("Wearables");

  return (
    <>
      <EquipHelmet meta={meta} />

      {!findUser && <Breadcrumbs />}

      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        routing={true}
      >
        <Configure filters={filters} hitsPerPage="20" />
        <Results />
      </InstantSearch>
    </>
  );
}
