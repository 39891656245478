import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import MarketPlacePage from "../components/search/marketplacepage";
import "../components/search/marketplace.css";
import { Helmet } from "react-helmet";

const MarketPlace = ({ findUser, metaTitle, metaDescription }) => {
  return (
      <>
      {!findUser && <Breadcrumbs />}
      <Container
      className={!findUser && 'py-0'}
        id="marketplace"
        fluid={+true}
        style={{
          backgroundColor: "var(--background-profile)",
          minHeight: "calc(100vh - 218px)",
        }}
      >
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <Row className="">
          <Col className="px-0">
            <MarketPlacePage metaTitle={metaTitle}/>
          </Col>
        </Row>
      </Container>
      </>
    );
  
};

export default MarketPlace;
