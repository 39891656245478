import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useFormStatus } from "../../../functions/DisabledState";
import {
  handleSendNameToAddress,
  handleSendNameToName,
} from "../../../functions/txfunctions";
import { useWalletType } from "../../../functions/useWalletType";

// two functions to hook up handleSendNameToAddress - params
// handleSendNameToName
//check if user input is a name or add
//if it is valid flow add then sendname to add
//else if it is a valid name then send to name

const TransferName = ({ lease }) => {
  const [toName, setToName] = useState("");
  const name = lease.name.toLowerCase();
  const walletType = useWalletType()

  const updateField = (e) => {
    if (/[^a-z0-9-]/.test(e.target.value) || e.target.value === "") {
      document.getElementById("transfer").classList.add("is-invalid");
      document.getElementById("transfer").classList.remove("is-valid");
    } else {
      document.getElementById("transfer").classList.add("is-valid");
      document.getElementById("transfer").classList.remove("is-invalid");
    }
    setToName(
      e.target.value
        .replace(/\b(.find)\b/i, "")
        .replace(/[^a-z0-9-]/g, "")
        .toLowerCase()
    );
    // //console.log(e.target);
    // //console.log("name is " + name);
    // //console.log("toName is " + toName);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (/[^a-z0-9-]/.test(toName) || toName === "") {
      toast.error("Please enter a valid .find name or flow wallet '0x...' address");
      document.getElementById("transfer").classList.add("is-invalid");
      document.getElementById("transfer").classList.remove("is-valid");
      return;
    }
    if (toName.length === 18 && toName.includes("0x")) {
      handleSendNameToAddress(name, toName, walletType);
    } else {
      handleSendNameToName(name, toName.toLowerCase(), walletType);
    }
    return;
  };

  // if (toName.length < 3 || toName.length > 16) {
  //   return;
  // };

  return (
    <>
      <Row className="align-items-center">
        <Col className="pb-3">Transfer <b><span className="themeColor">{lease.name}</span>.find</b> to somebody else</Col>
      </Row>
      <fieldset onSubmit={handleSubmit} disabled={useFormStatus()}>
        <Form className="form-inputs">
          <Row className="align-items-center">
            <Col>
              <Form.Control
                id="transfer"
                type="text"
                placeholder="Enter a valid .find name or flow wallet '0x...' address"
                onChange={updateField}
              />
            </Col>
            <Col xs="auto">
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="find-outline-commerce-dark"
              >
                Transfer
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    </>
  );
};

export default TransferName;
