import { Col, Container, Row } from "react-bootstrap";
import LivefeedDataBox from "../components/livefeed/livefeeddatabox/livefeeddatabox";
// import LivefeedStats from "../components/livefeed/livefeedstats/livefeedstats";
import LivefeedPageSplash from "../components/livefeed/livefeedpagesplash/livefeedpagesplash";
import { BuiltOn } from "../components/home/builton/builton";
import { JoinDiscord } from "../components/home/joindiscord/joindiscord";
// import LivefeedStatsBanner from "../components/livefeed/livefeedstatsbanner/livefeedstatsbanner";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import { Helmet } from "react-helmet";
import LiveFeedWrapper from "../components/livefeed/LiveFeedWrapper";
// import "../components/livefeed/livefeedbg.css";

const LiveFeed = ({ findUser, newStreamEvent, metaTitle, metaDescription }) => {
  return (
    <div className="content-container-v3">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <LiveFeedWrapper />
    </div>
  );
};

export default LiveFeed;

// if (findUser) {
//   return (
//     <>
//       <Container
//         fluid={+true}
//         style={{
//           backgroundColor: "var(--background-profile)",
//           minHeight: "calc(100vh - 218px)",
//           paddingBottom: "4rem",

//         }}
//       >
//         <Helmet>
//           <title>{metaTitle}</title>
//           <meta name="description" content={metaDescription} />
//         </Helmet>
//         <Container className="content-container">
//           <Row className="pt-4 justify-content-center">
//             {/* <div className="lf-background g-0 mx-0 px-0"> </div> */}

//             <Col className="px-0" xs="12" lg="10">
//               <LivefeedDataBox/>
//             </Col>
//             {/* <Col xs="12" lg="2" className=" d-none d-lg-block">
//               <LivefeedStats />
//             </Col> */}
//           </Row>
//         </Container>
//         <Row>
//         <JoinDiscord /></Row>
//       </Container>

//     </>
//   );
// } else {
//   return (
//     <>
//       <Breadcrumbs />
//       <LivefeedPageSplash />{" "}
//       <Container
//         className="pt-4"
//         fluid={+true}
//         style={{
//           backgroundColor: "var(--background-profile)",
//           minHeight: "calc(100vh - 218px)",
//           position: "relative",
//         }}
//       >
//         <Helmet>
//           <title>{metaTitle}</title>
//           <meta name="description" content={metaDescription} />
//         </Helmet>
//         <Container className="content-container pb-3">
//           <div className="lf-background g-0 mx-0 px-0"> </div>
//           <LivefeedDataBox />
//           {/* <LivefeedStatsBanner /> */}
//         </Container>
//       </Container>
//       <JoinDiscord />
//       <BuiltOn />
//     </>
//   );
// }
