import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SearchedFor from "../searchedfor/searchedfor";
import SearchResultsNameAvailable from "../searchresultsnameavailable/searchresultsnameavailable";
import * as fcl from "@onflow/fcl";
import "./registername.css";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";

const RegisterName = ({ profileData, searchedName }) => {
  document.title = ".find - Register " + searchedName + " now!";
  // //console.log(profileData);
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  // //console.log(user);

  //here if logged in then show this page with sidebar
  //else just show this page
  if (user.loggedIn) {
    return (
      <>
        {/* <SearchedFor profileData={profileData} searchedName={searchedName} /> */}

        <Container
          fluid={+true}
          id="register-name-wrapper"
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px)",
          }}
        >

              <SearchResultsNameAvailable
                profileData={profileData}
                searchedName={searchedName}
              />
              {/* <FooterLinks /> */}
            

        </Container>
      </>
    );
  } else {
    return (
      <>
      <Breadcrumbs/>
        <SearchedFor profileData={profileData} searchedName={searchedName} />
        <SearchResultsNameAvailable
          profileData={profileData}
          searchedName={searchedName}
        />
      </>
    );
  }
};

export default RegisterName;
