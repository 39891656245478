import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomImageFull from "../CustomImage/CustomImageFull";

const DropPageInfoLastDrop = () => {
  const sectionTitle = "Last Month's Collection!";
  const sectionDesc = [
    `Last month (OCT-2022) we partnered with Fright Club to create an awesome collection & Party!`,
    `If you managed to collect all three, you will be recieving a free airdropped Party Favor from this month's collection!`,
    `What theme will NEXT MONTH'S party bring?!`,
  ];

  return (
    <div id="drop-page-last-drop-wrapper">
      <div id="drop-page-last-drop-row">
        <div id="drop-page-last-drop-text">
          <span id="drop-page-last-drop-title-span">{sectionTitle}</span> <br />
          {sectionDesc &&
            sectionDesc.map((sectionSpan, i) => {
              return (
                <React.Fragment key={i}>
                  <span id="drop-page-last-drop-desc-span">{sectionSpan}</span>
                  <br />
                </React.Fragment>
              );
            })}
        </div>
        <div id="drop-page-last-drop-img">
          <Link to={"/mp/PartyFavorz"}>
            <CustomImageFull imgUrl={"https://find.mypinata.cloud/ipfs/QmWfWFG4krKMZ5u1wHyq1oFG4zjvQY2Rk4rQ1rHC9vBXA9"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DropPageInfoLastDrop;
