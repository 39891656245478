import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import "./breadcrumbs.css";

const Breadcrumbs = ({page}) => {
  // console.log(page)
  const [currentPage, setCurrentPage] = useState();
  const [currentLink, setCurrentLink] = useState("");

  const [itemDetail, setItemDetail] = useState();

  const location = useLocation();
  let url = "https://find.xyz/" + location.pathname
  let title = `Check this out on the .find marketplace`

  useEffect(() => {
    if (location.pathname === "/lf") {
      setCurrentPage("Livefeed");
    } else if (location.pathname.includes("/mp")) {
      setCurrentPage("Marketplace");
      setCurrentLink("/mp");
      if (location.pathname.includes("/mp") && location.pathname.length > 3) {
        let pathArrayFull = location.pathname.split("/")
        let pathArray = pathArrayFull.filter(x => x !== "" && x !== "mp")
        //console.log(pathArray)
        setItemDetail(pathArray);
      } else {
        setItemDetail("")
      }
    } else if (location.pathname === "/p/explore") {
      setCurrentPage("Explore");
    } else if (location.pathname === "/p/helphub") {
      setCurrentPage("HelpHub");
    } else if (location.pathname === "/p/contact") {
      setCurrentPage("Contact");
    }
   else if (location.pathname === "/p/testnethub") {
    setCurrentPage("Testnet Hub");
  }
  else if (location.pathname === "/p/privacy") {
    setCurrentPage("Privacy");
  }
  else if (location.pathname === "/p/terms") {
    setCurrentPage("Terms");
  }
    else {
      setCurrentPage(location.pathname.replace("/", ""));
    }
  }, [location.pathname]);

  //switch - serparate / into array

  // //console.log(location);

  return (
    <Container fluid={+true} id="breadcrumbs-container">
      <Row className="py-3">
        <Col>
          <i className="fa-solid fa-magnifying-glass"></i> &nbsp;
          <Link className="bc-link" to="/">
            Home
          </Link>
          &nbsp;&gt;&nbsp;
          <Link className="bc-link" to={currentLink}>
            {currentPage}
          </Link>
          {itemDetail && (
            <>
            {itemDetail.map((item, i) => {
              if(itemDetail.length !== i+1){
              return(<React.Fragment key={i}>
                &nbsp;&gt;&nbsp;
              <Link  className="bc-link" to={"/mp/"+item}>
                {item.replace("FIND", ".find").replaceAll("%20", " ")}
              </Link>{" "}
              </React.Fragment>)} else {
                return(<span key={i}>&nbsp;&gt;&nbsp;{item.replace("FIND", ".find").replaceAll("%20", " ")}</span>)
              }
            })}
            </>
          )}
          {page==="mpdetail" &&
          <span style={{float: "right"}} >
          <TwitterShareButton
              className="m-1"
              title={title}
              url={url}
              hashtags={['findonflow','onFlow']}

            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <FacebookShareButton
              className="m-1"
              quote={title}
              url={url}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <LinkedinShareButton className="m-1" url={url}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </span>}
        </Col>
        {/* <Col>
          <Link to="/">Home</Link>
          &nbsp;&gt;&nbsp;
          <Link to="lf">Livefeed</Link>
        </Col> */}
      </Row>
    </Container>
  );
};

export default Breadcrumbs;
